/* eslint-disable no-console */
/* eslint-disable no-lone-blocks */
import {
  CloseCircleOutlined,
  LoadingOutlined,
  WarningFilled
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Tooltip,
  Typography,
  Upload,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import { Option } from 'antd/es/mentions';
import axios from 'axios';
import { getType } from 'mime';
import React, { useEffect, useState } from 'react';
import { ReactComponent as UploadImage } from '../../assets/upload.svg';
import {
  addSystemUser,
  getFileSignedUrl,
  postUploadPhoto
} from '../../common/common-repository';
import CommonTable from '../../common/components/CommonTable';
import CountryCodePicker from '../../common/components/CountryCodePicker';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import { DEBOUNCE_TIME, GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { authenticateUser } from '../auth/auth-repository';
import {
  getAllotedEquipmentsToSampleCollector,
  getAlreadyExistEmails,
  getSystemUsers,
  toggleSystemUserAvailability,
  toggleSystemUserEnable,
  updateSystemUser,
  uploadUserDocument
} from './users-repository';
import './users.less';

function Users() {
  const [form] = Form.useForm();
  const { required, number } = formValidatorRules;
  const limit = GLOBAL_PAGINATION_LIMIT;
  // STATES

  const startingStateForDoc = {
    isUploading: false,
    url: '',
    type: 'UNKNOWN'
  };

  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [systemUsers, setSystemUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [openDisableUser, setOpenDisableUser] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [gender, setGender] = useState('MALE');
  const [isSavingData, setIsSavingData] = useState(false);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [userName, setUserName] = useState('');
  const [aadharFront, setAadharFront] = useState(startingStateForDoc);
  const [aadharBack, setAadharBack] = useState(startingStateForDoc);
  const [panCard, setPanCard] = useState(startingStateForDoc);
  const [codeValue, setCodeValue] = useState();
  const [user, setUser] = useState();
  const [sampleCollectorDetails, setSampleCollectorDetails] = useState();
  const [degreeCertificate, setDegreeCertificate] =
    useState(startingStateForDoc);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  const [equipmentList, setEquipmentList] = useState([]);
  const fetchSystemUsers = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getSystemUsers({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setSystemUsers(response.users.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    authenticateUser().then((currentUser) => {
      if (currentUser !== null) {
        setUser(currentUser);
        fetchSystemUsers({
          currentQuery: '',
          currentSkip: 0
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setGender('MALE');
    setAadharFront(startingStateForDoc);
    setAadharBack(startingStateForDoc);
    setPanCard(startingStateForDoc);
    setDegreeCertificate(startingStateForDoc);
    setUploadedImage(undefined);
    setIsCreateModalOpen(false);
    setCodeValue();
    setIsUpdateUser(false);
  };

  // TOGGLE ENABLE - DISABLE
  const toggleEnabled = async (index) => {
    systemUsers[index] = {
      ...systemUsers[index],
      isDisabled: !(systemUsers[index].isDisabled || false)
    };
    toggleSystemUserEnable(systemUsers[index].id);
    setSystemUsers(systemUsers.slice());
  };

  // TOGGLE AVAILABILITY
  const toggleFeatured = async (index) => {
    systemUsers[index] = {
      ...systemUsers[index],
      isAvailable: !systemUsers[index].isAvailable
    };
    toggleSystemUserAvailability(systemUsers[index].id);
    setSystemUsers(systemUsers.slice());
  };

  // UPDATE FORM MODAL
  const userUpdate = async (index, value, record) => {
    setLoadingData(true);
    setIsUpdateUser(true);
    if (record.role === 'SAMPLE_COLLECTION_PARTNER') {
      const res = await getAllotedEquipmentsToSampleCollector(record.id);
      setEquipmentList(res);
      setTimeout(() => {
        setIsCreateModalOpen(true);
        setLoadingData(false);
        setUserName(value);
        form.setFieldsValue({
          name: record.name,
          role: record.role,
          email: record.email,
          age: record.age,
          countryCode: (record.countryCode || '91').replace('+', ''),
          mobileNo: record.mobileNumber
        });
        setGender(record.sex);
        const { documents = [] } = record;
        setAadharFront(startingStateForDoc);
        setAadharBack(startingStateForDoc);
        setPanCard(startingStateForDoc);
        setDegreeCertificate(startingStateForDoc);
        for (let i = 0; i < documents.length; i += 1) {
          const document = documents[i];
          if (document.title === 'ADDHAR-FRONT') {
            setAadharFront({
              isUploading: false,
              url: document.url,
              type: document.type
            });
          }
          if (document.title === 'ADDHAR-BACK') {
            setAadharBack({
              isUploading: false,
              url: document.url,
              type: document.type
            });
          }
          if (document.title === 'PAN-CARD') {
            setPanCard({
              isUploading: false,
              url: document.url,
              type: document.type
            });
          }
          if (document.title === 'DEGREE') {
            setDegreeCertificate({
              isUploading: false,
              url: document.url,
              type: document.type
            });
          }
        }
        console.log(equipmentList);
      }, 500);
    }
    setTimeout(() => {
      setIsCreateModalOpen(true);
      setLoadingData(false);
      setUserName(value);
      form.setFieldsValue({
        name: record.name,
        role: record.role,
        email: record.email,
        age: record.age,
        countryCode: (record.countryCode || '91').replace('+', ''),
        mobileNo: record.mobileNumber
      });
      setGender(record.sex);
      const { documents = [] } = record;
      setAadharFront(startingStateForDoc);
      setAadharBack(startingStateForDoc);
      setPanCard(startingStateForDoc);
      setDegreeCertificate(startingStateForDoc);
      for (let i = 0; i < documents.length; i += 1) {
        const document = documents[i];
        if (document.title === 'ADDHAR-FRONT') {
          setAadharFront({
            isUploading: false,
            url: document.url,
            type: document.type
          });
        }
        if (document.title === 'ADDHAR-BACK') {
          setAadharBack({
            isUploading: false,
            url: document.url,
            type: document.type
          });
        }
        if (document.title === 'PAN-CARD') {
          setPanCard({
            isUploading: false,
            url: document.url,
            type: document.type
          });
        }
        if (document.title === 'DEGREE') {
          setDegreeCertificate({
            isUploading: false,
            url: document.url,
            type: document.type
          });
        }
      }
    }, 500);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (value, record, index) => {
        if (value.length < 20) {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setSelectedId(record.id);
                setUploadedImage(record.avatar);
                setSampleCollectorDetails(record);
                setTimeout(() => {
                  userUpdate(index, value, record);
                }, 200);
              }}
            >
              {value}
            </Typography>
          );
        }
        {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setSelectedId(record.id);
                setUploadedImage(record.avatar);
                setTimeout(() => {
                  userUpdate(index, value, record);
                }, 200);
              }}
            >
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 20)}...
              </Tooltip>
            </Typography>
          );
        }
      }
    },

    {
      title: 'User role',
      dataIndex: 'role',
      key: 'role',
      width: 200
    },
    {
      title: 'Mobile number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      width: 100,
      render: (value, record) => {
        return (
          <p>
            {record.countryCode}-{value}
          </p>
        );
      }
    },
    {
      title: 'Email id',
      dataIndex: 'email',
      key: 'email',
      width: 150
    },
    {
      title: 'Action',
      dataIndex: 'isDisabled',
      key: 'isDisabled',
      width: 100,
      render: (value, record, index) => {
        return value ? (
          <span
            className="disable-class"
            onClick={() => {
              toggleEnabled(index);
            }}
          >
            Disabled
          </span>
        ) : (
          <span
            className="enable-class"
            onClick={() => {
              if (record.id === user.id) {
                message.error("You can't disable own account");
                return;
              }
              setCurrentSelectedIndex(index);
              setTimeout(() => {
                setOpenDisableUser(true);
              }, 300);
            }}
          >
            Enabled
          </span>
        );
      }
    },
    {
      title: ' Availability',
      dataIndex: 'isAvailable',
      key: 'isAvailable',
      align: 'center',
      width: 100,
      render: (value, record, index) => {
        if (record.role === 'SAMPLE_COLLECTION_PARTNER')
          return (
            <Checkbox
              defaultChecked={value}
              // eslint-disable-next-line no-unneeded-ternary
              disabled={record.isDisabled}
              onClick={() => toggleFeatured(index)}
            />
          );
        return <div />;
      }
    }
  ];

  // DOC-IMG UPLOAD
  const uploadDocument = async (selectedFile, docType) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be pdf/png/jpg/jpeg`);
      return;
    }
    if (docType === 0) {
      setAadharFront({
        url: aadharFront.url,
        isUploading: true,
        type: 'UNKNOWN'
      });
    } else if (docType === 1) {
      setAadharBack({
        url: aadharBack.url,
        isUploading: true,
        type: 'UNKNOWN'
      });
    } else if (docType === 2) {
      setPanCard({
        url: panCard.url,
        type: 'UNKNOWN',
        isUploading: true
      });
    } else {
      setDegreeCertificate({
        url: degreeCertificate.url,
        type: 'UNKNOWN',
        isUploading: true
      });
    }
    const response = await uploadUserDocument(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    if (docType === 0) {
      setAadharFront({
        url: url,
        type: fileType,
        isUploading: false
      });
    } else if (docType === 1) {
      setAadharBack({
        url: url,
        type: fileType,
        isUploading: false
      });
    } else if (docType === 2) {
      setPanCard({
        url: url,
        type: fileType,
        isUploading: false
      });
    } else {
      setDegreeCertificate({
        url: url,
        isUploading: false,
        type: fileType
      });
    }
  };

  const discountChange = (e) => {
    e.preventDefault();
    setGender(e.target.value);
  };

  // VALIDATING IMG AS SVG
  const beforeUpload = (file) => {
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error(`File must smaller than 10MB!`);
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  // BANNER UPLOAD
  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await postUploadPhoto(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  // SUBMIT FORM
  const onSubmitForm = async (formValue) => {
    const { name, role, email, mobileNo, age, countryCode } = formValue;
    if (!uploadedImage) {
      message.error('Image is not uploaded');
      return;
    }
    if (parseInt(age, 10) < 0) {
      message.error('Invalid age');
      return;
    }
    if (mobileNo.length !== 10) {
      message.error('Invalid mobile number');
      return;
    }
    if (name.length > 50) {
      message.error('Exceeded name limit');
      return;
    }
    if (codeValue === true) {
      message.error('Email already exist');
      return;
    }
    setIsSavingData(true);

    const createdUser = await addSystemUser({
      name,
      role,
      email,
      countryCode: `+${countryCode || '91'}`,
      mobileNumber: mobileNo,
      age: parseInt(age, 10),
      sex: gender,
      avatar: uploadedImage,
      docs: [
        { title: 'ADDHAR-FRONT', url: aadharFront.url, type: aadharFront.type },
        { title: 'ADDHAR-BACK', url: aadharBack.url, type: aadharBack.type },
        { title: 'PAN-CARD', url: panCard.url, type: panCard.type },
        {
          title: 'DEGREE',
          url: degreeCertificate.url,
          type: degreeCertificate.type
        }
      ]
    });
    if (
      systemUsers.length === 0 ||
      systemUsers.length < limit ||
      systemUsers[systemUsers.length - 1].serialNumber + 1 ===
        createdUser.serialNumber
    ) {
      const updatedUser = systemUsers.slice();
      updatedUser.push(createdUser);
      setSystemUsers(updatedUser.slice());
    }
    form.resetFields();
    setUploadedImage(undefined);
    setGender('MALE');
    setAadharFront(startingStateForDoc);
    setAadharBack(startingStateForDoc);
    setDegreeCertificate(startingStateForDoc);
    setPanCard(startingStateForDoc);
    setIsSavingData(false);
    setCodeValue();
    setIsCreateModalOpen(false);
    fetchSystemUsers({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  // UPDATE FORM
  const onSubmitUpdateUser = async (formValue) => {
    const { name, role, email, mobileNo, age, countryCode } = formValue;
    if (!uploadedImage) {
      message.error('Image is not uploaded');
      return;
    }
    if (parseInt(age, 10) < 0) {
      message.error('Invalid age');
      return;
    }
    if (mobileNo.length !== 10) {
      message.error('Invalid mobile number');
      return;
    }
    if (name.length > 50) {
      message.error('Exceeded name limit');
      return;
    }
    if (codeValue === true) {
      message.error('Email already exist');
      return;
    }
    setIsSavingData(true);

    const updatedUser = await updateSystemUser({
      name,
      role,
      email,
      countryCode: `+${countryCode || '91'}`,
      mobileNumber: mobileNo,
      age: parseInt(age, 10),
      avatar: uploadedImage,
      sex: gender,
      docs: [
        { title: 'ADDHAR-FRONT', url: aadharFront.url, type: aadharFront.type },
        { title: 'ADDHAR-BACK', url: aadharBack.url, type: aadharBack.type },
        { title: 'PAN-CARD', url: panCard.url, type: panCard.type },
        {
          title: 'DEGREE',
          url: degreeCertificate.url,
          type: degreeCertificate.type
        }
      ],
      systemUserId: selectedId
    });
    const updatedUsers = systemUsers.slice();
    updatedUsers[currentSelectedIndex] = updatedUser;
    setSystemUsers(updatedUsers);
    form?.resetFields();
    setUploadedImage(undefined);
    setGender('MALE');
    setAadharFront(startingStateForDoc);
    setAadharBack(startingStateForDoc);
    setDegreeCertificate(startingStateForDoc);
    setPanCard(startingStateForDoc);
    setIsSavingData(false);
    setIsUpdateUser(false);
    setCodeValue();
    setIsCreateModalOpen(false);
  };

  const emailAlreadyExist = async (e) => {
    const typedMail = e.target.value;
    const id = selectedId;
    if (isUpdateUser) {
      await getAlreadyExistEmails(id, typedMail).then((res) => {
        setCodeValue(res);
      });
    } else {
      const nullId = null;
      await getAlreadyExistEmails(nullId, typedMail).then((res) => {
        setCodeValue(res);
      });
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Users
            </Typography.Text>
            <SearchComponent
              name="Users"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchSystemUsers({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button
            onClick={() => setIsCreateModalOpen(true)}
            className="create-btn"
          >
            + Create New
          </Button>
          {/* CREATE MODAL */}
          <Modal
            open={isCreateModalOpen}
            onCancel={handleCancel}
            className="create-test-modal create-wrapper"
            centered
            title={isUpdateUser ? userName : 'Create User'}
          >
            <Divider />
            <Form
              onFinish={isUpdateUser ? onSubmitUpdateUser : onSubmitForm}
              form={form}
              layout="vertical"
            >
              <div className="card-height">
                <Form.Item name="upload">
                  <div className="profile">
                    <Upload
                      name="upload-icon"
                      onChange={onIconUpload}
                      accept="image/*"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                    >
                      {uploadedImage && !uploadingImage && (
                        <img
                          className="uploaded-image"
                          src={uploadedImage}
                          alt="xyz"
                        />
                      )}
                      {!uploadedImage && !uploadingImage && (
                        <UploadImage className="sample-image" />
                      )}
                      {uploadingImage && <LoadingOutlined />}
                      <p>Upload user image</p>
                    </Upload>
                  </div>
                </Form.Item>
                <div className="row-one">
                  <Form.Item rules={[required]} name="name" label="Name">
                    <Input type="text" placeholder="Enter name" />
                  </Form.Item>
                  <Form.Item rules={[required]} name="role" label="Role">
                    <Select placeholder="Select role">
                      <Option value="DELIVERY_PARTNER">DELIVERY_PARTNER</Option>
                      <Option value="SAMPLE_COLLECTION_PARTNER">
                        SAMPLE_COLLECTION_PARTNER
                      </Option>
                      <Option value="OPERATOR">OPERATOR</Option>
                      <Option value="ADMIN">ADMIN</Option>
                      <Option value="SUPER_ADMIN">SUPER_ADMIN</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="row-one">
                  <div className="email-container">
                    <Form.Item rules={[required]} name="email" label="Email id">
                      <Input
                        type="email"
                        placeholder="Enter email id"
                        onChange={emailAlreadyExist}
                      />
                    </Form.Item>
                    {codeValue === true ? (
                      <span>Email already exist</span>
                    ) : null}
                  </div>

                  <Form.Item
                    rules={[
                      required,
                      number,
                      () => ({
                        validator(rule, value) {
                          console.log(value.length);
                          if (value.length > 10) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise?.reject('Number limit exceeded');
                          }
                          return Promise?.resolve();
                        }
                      })
                    ]}
                    className="mobile-number"
                    name="mobileNo"
                    label="Mobile number"
                  >
                    <Input
                      type="text"
                      addonBefore={<CountryCodePicker name="countryCode" />}
                      placeholder="Enter mobile number"
                    />
                  </Form.Item>
                </div>
                <div className="row-one">
                  <Form.Item rules={[required]} name="age" label="Age">
                    <InputNumber
                      controls={false}
                      type="text"
                      placeholder="Enter age"
                    />
                  </Form.Item>

                  <div className="age-box">
                    <p className="gender"> Sex</p>
                    <Radio.Group onChange={discountChange} value={gender}>
                      <Radio value="MALE">Male</Radio>
                      <Radio value="FEMALE">Female</Radio>
                      <Radio value="OTHER"> Other</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <Divider dashed />
                <div className="documents">
                  <Typography.Text>Upload docs</Typography.Text>
                </div>
                <div className="aadhar-front">
                  {aadharFront.url ? (
                    <a
                      style={{ paddingLeft: '10px' }}
                      href={aadharFront.url ? aadharFront.url : '#'}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Aadhar card front
                    </a>
                  ) : (
                    <p
                      style={{ paddingLeft: '10px' }}
                      // href={aadharFront.url ? aadharFront.url : '#'}
                      target="_blank"
                      // rel="noreferrer"
                    >
                      Aadhar card front
                    </p>
                  )}
                  <Form.Item name="aadhar-front">
                    <Upload
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={(doc) => {
                        uploadDocument(doc, 0);
                      }}
                    >
                      <Button loading={aadharFront.isUploading}>
                        {aadharFront.url ? 'Re-Upload' : 'Upload'}
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>
                <div className="aadhar-front">
                  {aadharBack.url ? (
                    <a
                      style={{ paddingLeft: '10px' }}
                      href={aadharBack.url ? aadharBack.url : '#'}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Aadhar card back
                    </a>
                  ) : (
                    <p
                      style={{ paddingLeft: '10px' }}
                      // href={aadharBack.url ? aadharBack.url : '#'}
                      target="_blank"
                      // rel="noreferrer"
                    >
                      Aadhar card back
                    </p>
                  )}
                  <Form.Item name="aadhar-back">
                    <Upload
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={(doc) => {
                        uploadDocument(doc, 1);
                      }}
                    >
                      <Button loading={aadharBack.isUploading}>
                        {aadharBack.url ? 'Re-Upload' : 'Upload'}
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>
                <div className="aadhar-front">
                  {panCard.url ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ paddingLeft: '10px' }}
                      href={panCard.url ? panCard.url : '#'}
                    >
                      Pan card
                    </a>
                  ) : (
                    <p
                      target="_blank"
                      // rel="noreferrer"
                      style={{ paddingLeft: '10px' }}
                      // href={panCard.url ? panCard.url : '#'}
                    >
                      Pan card
                    </p>
                  )}
                  <Form.Item name="panCard">
                    <Upload
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={(doc) => {
                        uploadDocument(doc, 2);
                      }}
                    >
                      <Button loading={panCard.isUploading}>
                        {panCard.url ? 'Re-Upload' : 'Upload'}
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>
                <div className="aadhar-front">
                  {degreeCertificate.url ? (
                    <a
                      style={{ paddingLeft: '10px' }}
                      target="_blank"
                      rel="noreferrer"
                      href={degreeCertificate.url ? degreeCertificate.url : '#'}
                    >
                      Degree certificate
                    </a>
                  ) : (
                    <p
                      style={{ paddingLeft: '10px' }}
                      target="_blank"
                      // rel="noreferrer"
                      // href={degreeCertificate.url ? degreeCertificate.url : '#'}
                    >
                      Degree certificate
                    </p>
                  )}
                  <Form.Item name="degreeCertificate">
                    <Upload
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={(doc) => {
                        uploadDocument(doc, 3);
                      }}
                    >
                      <Button loading={degreeCertificate.isUploading}>
                        {degreeCertificate.url ? 'Re-Upload' : 'Upload'}
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>
                <Divider />
                {sampleCollectorDetails?.role ===
                'SAMPLE_COLLECTION_PARTNER' ? (
                  <div className="equipments-assigned">
                    <Typography.Text>Equipments Assigned</Typography.Text>
                    <div className="eqp-list">
                      {equipmentList.length > 0
                        ? equipmentList.map((eqp) => {
                            return (
                              <ul key={eqp.equipment.id}>
                                <li>
                                  {eqp.equipment.name} x {eqp.quantity}
                                </li>
                              </ul>
                            );
                          })
                        : 'EQUIPMENTS NOT YET ASSIGNED'}
                    </div>
                  </div>
                ) : null}
              </div>
              <Divider />
              <Button
                loading={isSavingData}
                htmlType="submit"
                className="create-btn-modal"
              >
                {isUpdateUser ? 'Save' : 'Create'}
              </Button>
            </Form>
          </Modal>
          {/* DISABLE MODAL */}
          <Modal
            title={
              <div>
                <Space className="warning-container">
                  <WarningFilled />
                  Disable User
                </Space>
              </div>
            }
            centered
            closeIcon={<CloseCircleOutlined className="close-btn" />}
            open={openDisableUser}
            okButtonProps={{ type: 'primary', danger: 'danger' }}
            cancelButtonProps={{ className: 'no-display' }}
            okText="Disable user"
            onOk={() => {
              toggleEnabled(currentSelectedIndex);
              setOpenDisableUser(false);
            }}
            onCancel={() => setOpenDisableUser(false)}
          >
            <Divider />
            <p>
              Are you sure you want to disable this user?
              {/* {currentSelectedIndex !== undefined && (
                <b>{systemUsers[currentSelectedIndex].name}</b>
              )} */}
            </p>
            <Divider />
          </Modal>
        </div>
      </Header>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={systemUsers}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchSystemUsers({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Users;
