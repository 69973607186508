/* eslint-disable no-console */
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
  Upload
} from 'antd';
import { getType } from 'mime';
import React, { useEffect, useState } from 'react';
// Icons
import {
  DeleteOutlined,
  FileImageOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  StarFilled,
  StarOutlined
} from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import {
  getFileSignedUrl,
  postUploadPhoto
} from '../../common/common-repository';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import { DEBOUNCE_TIME, GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import {
  clearSearchField,
  formValidatorRules,
  withHttp
} from '../../common/utils';
import { getLiveTestsAndPackages } from '../tests/test-repository';
import {
  createCtaBanner,
  getCtaBanners,
  removeCtaBanner,
  toggleCtaFirstTime,
  toggleFeatureCtaBanner,
  toggleGuestCtaBanner,
  updateCtaBanner,
  updateTestToCtaBanner
} from './cta-repository';
import './cta.less';

function CtaBanners() {
  const { required } = formValidatorRules;
  const [form] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateBanner, setIsUpdateBanner] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [isSavingData, setIsSavingData] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [selectedId, setSelectedId] = useState('');
  const [bannerName, setBannerName] = useState('');
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  const [linkModal, setLinkModal] = useState(false);
  const [showingTestResults, setShowingTestResults] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const [fetchedTests, setFetchedTests] = useState([]);
  const [isFetchingTests, setIsFetchingTests] = useState(false);
  const [bannerIdForLink, setBannerIdForLink] = useState('');

  // FETCHING BANNERS
  const fetchBanners = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getCtaBanners({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setBanners(response.CtaBanner.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  // FETCHING TESTS

  const fetchLiveTests = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getLiveTestsAndPackages({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { tests } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < tests.length; i += 1) {
        const test = tests[i];
        const { id } = test;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedTests.length; j += 1) {
          if (selectedTests[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          test
        });
      }
      setFetchedTests(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetchingTests(false);
  };

  useEffect(() => {
    fetchBanners({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // VALIDATING IMG AS SVG
  const beforeUpload = (file) => {
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error(`File must smaller than 10MB!`);
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  // BANNER UPLOAD
  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await postUploadPhoto(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  // CREATE NEW MODAL
  const handleCancel = () => {
    form.resetFields();
    setUploadedImage(undefined);
    setIsModalOpen(false);
    setIsUpdateBanner(false);
  };

  // UPDATE MODAL
  const getEquipmentByIdToUpdate = (record, value) => {
    setLoadingData(true);
    setTimeout(() => {
      setBannerName(value);
      form.setFieldsValue({
        title: record.title,
        buttonTitle: record.buttonTitle,
        content: record.content,
        coupon: record.coupon
      });
      setIsModalOpen(true);
      setSelectedId(record.id);
      setLoadingData(false);
    }, 500);
  };

  const deleteBanner = async (bannerId, index) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Deleting banner'
    });
    await removeCtaBanner(bannerId);
    const delBanner = banners.slice();
    delBanner.splice(index, 1);
    message.open({
      key,
      type: 'success',
      content: 'Deleted'
    });
    setBanners(delBanner);
  };

  // TOGGLE FEATURE
  const toggleFeatured = (index) => {
    banners[index] = {
      ...banners[index],
      isFeatured: !banners[index].isFeatured
    };
    toggleFeatureCtaBanner(banners[index].id);
    setBanners(banners.slice());
  };

  // TOGGLE 1st TIME
  const toggleFirstTime = async (index) => {
    banners[index] = {
      ...banners[index],
      isFirstTime: !banners[index].isFirstTime
    };
    toggleCtaFirstTime(banners[index].id);
    setBanners(banners.slice());
  };

  console.log(banners);

  // TOGGLE GUEST
  const toggleGuest = async (index) => {
    banners[index] = {
      ...banners[index],
      isGuestUser: !banners[index].isGuestUser
    };
    toggleGuestCtaBanner(banners[index].id);
    setBanners(banners.slice());
  };

  const handleCancelLinkModal = () => {
    form.resetFields();
    setShowingTestResults(false);
    clearSearchField('Tests');
    setBannerIdForLink('');
    setBannerName('');
    setSelectedTests([]);
    setLinkModal(false);
  };

  const getTestsBelongsToCategory = async (value, record) => {
    setLoadingData(true);
    setBannerIdForLink(value);
    const { test, title } = record;
    setBannerName(title);
    if (test) {
      setSelectedTests([test]);
    } else {
      setSelectedTests([]);
    }
    setTimeout(() => {
      setLoadingData(false);
      setLinkModal(true);
    }, 500);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'isFeatured',
      width: 50,
      align: 'center',
      key: '1',
      render: (value, record, index) => {
        return value ? (
          <StarFilled
            className="filled-star"
            onClick={() => {
              toggleFeatured(index);
            }}
          />
        ) : (
          <StarOutlined
            className="outlined-star"
            onClick={() => {
              toggleFeatured(index);
            }}
          />
        );
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: '2',
      render: (value, record, index) => {
        if (value.length < 20) {
          return (
            <Typography.Text
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setUploadedImage(record.thumbnail);
                setIsUpdateBanner(true);
                setCurrentSelectedIndex(index);
                setTimeout(() => {
                  getEquipmentByIdToUpdate(record, value);
                }, 200);
              }}
            >
              {value}
            </Typography.Text>
          );
        }
        return (
          <Typography.Text
            className="clickable-title"
            onClick={() => {
              form?.resetFields();
              setUploadedImage(record.thumbnail);
              setIsUpdateBanner(true);
              setCurrentSelectedIndex(index);
              setTimeout(() => {
                getEquipmentByIdToUpdate(record, value);
              }, 200);
            }}
          >
            <Tooltip title={value} color="#232e78">
              {value.slice(0, 20)}...
            </Tooltip>
          </Typography.Text>
        );
      }
    },
    {
      title: 'Image',
      dataIndex: 'thumbnail',
      key: '3',
      render: (value) => {
        return (
          <a href={withHttp(value)} target="blank">
            image.png
          </a>
        );
      }
    },
    {
      title: '',
      dataIndex: 'title',
      key: '4',
      render: (value, record, index) => {
        return (
          <Popconfirm
            title="Delete"
            description="Are you sure to delete this banner?"
            onConfirm={() => {
              deleteBanner(record.id, index);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button className="delete-btn-banner">
              Delete <DeleteOutlined />
            </Button>
          </Popconfirm>
        );
      }
    },
    {
      title: '1st time user',
      dataIndex: 'isFirstTime',
      key: '4',
      render: (value, record, index) => {
        return (
          <Checkbox
            defaultChecked={value}
            onClick={() => toggleFirstTime(index)}
          />
        );
      }
    },
    {
      title: 'Guest user',
      dataIndex: 'isGuestUser',
      key: '4',
      render: (value, record, index) => {
        return (
          <Checkbox defaultChecked={value} onClick={() => toggleGuest(index)} />
        );
      }
    },
    {
      title: 'Link Test/Package',
      dataIndex: 'id',
      key: '5',
      align: 'center',
      render: (value, record) => {
        return (
          <PlusCircleOutlined
            onClick={() => {
              setTimeout(() => {
                getTestsBelongsToCategory(value, record);
              }, 200);
            }}
            // onClick={() => setAddTestModal(true)}
            style={{
              cursor: 'pointer'
            }}
          />
        );
      }
    }
  ];

  // CREATE BANNER FORM
  const createBannerForm = async (formValue) => {
    const { title, buttonTitle, content, coupon } = formValue;
    if (!uploadedImage) {
      message.error('Image is not uploaded');
      return;
    }

    if (title.length < 2) {
      message.error('Title length should be greater');
      return;
    }
    setIsSavingData(true);

    const payLoad = {
      title,
      buttonTitle,
      coupon,
      content,
      thumbnail: uploadedImage
    };

    const createdBanner = await createCtaBanner(payLoad);
    if (
      banners.length === 0 ||
      banners.length < limit ||
      banners[banners.length - 1].serialNumber + 1 ===
        createdBanner.serialNumber
    ) {
      const updatedBanner = banners.slice();
      updatedBanner.push(createdBanner);
      setBanners(updatedBanner.slice());
    }
    form.resetFields();
    setUploadedImage(undefined);
    setIsSavingData(false);
    setIsModalOpen(false);
    fetchBanners({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  // UPDATE BANNER FORM
  const updateBannerForm = async (formValue) => {
    const { title, buttonTitle, content, coupon } = formValue;
    if (!uploadedImage) {
      message.error('Image is not uploaded');
      return;
    }

    if (title.length < 2) {
      message.error('Title length should be greater');
      return;
    }
    setIsSavingData(true);
    const payload = {
      title,
      buttonTitle,
      content,
      coupon,
      thumbnail: uploadedImage
    };

    const updatedBanner = await updateCtaBanner(payload, selectedId);
    const updatedBanners = banners.slice();
    updatedBanners[currentSelectedIndex] = updatedBanner;
    setBanners(updatedBanners);
    form.resetFields();
    setUploadedImage(undefined);
    setIsSavingData(false);
    setIsUpdateBanner(false);
    setIsModalOpen(false);
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              CTA Banners
            </Typography.Text>
            <SearchComponent
              name=" CTA Banners"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchBanners({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button
            onClick={() => {
              setIsModalOpen(true);
            }}
            className="create-btn"
          >
            + Create New
          </Button>
        </div>
      </Header>
      {/* CREATE MODAL */}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        className="create-equipment-modal"
        footer={null}
        centered
        title={isUpdateBanner ? bannerName : 'Create CTA Banner'}
      >
        <Divider />
        <Form
          form={form}
          onFinish={isUpdateBanner ? updateBannerForm : createBannerForm}
          className="equipment-form"
          layout="vertical"
        >
          <div className="equipment-form-control banner-form cta-form">
            <Form.Item rules={[required]} name="title" label="Banner Title">
              <Input placeholder="Enter banner title" />
            </Form.Item>
            <div className="cta-title-html">
              <Form.Item
                rules={[required]}
                name="content"
                label="Banner Content"
              >
                <Input.TextArea placeholder="Enter banner content" />
              </Form.Item>
              <Button
                type="link"
                onClick={() => {
                  const url = 'https://wordtohtml.net/';
                  // eslint-disable-next-line no-undef
                  window.open(url, '_blank');
                }}
              >
                Click to generate instructions
              </Button>
            </div>
            <div className="form-separator">
              <Form.Item
                rules={[required]}
                name="buttonTitle"
                label="Button Title"
              >
                <Input className="inp-equiment" type="text" />
              </Form.Item>
              <Form.Item rules={[required]} name="coupon" label="Coupon">
                <Input className="inp-equiment" type="text" />
              </Form.Item>
            </div>

            <Form.Item name="upload">
              <div className="profile-test banner-img">
                <Upload
                  name="upload-banner "
                  onChange={onIconUpload}
                  accept="image/*"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                >
                  {uploadedImage && !uploadingImage && (
                    <img
                      src={uploadedImage}
                      className="sample-img "
                      alt="xyz"
                    />
                  )}
                  {!uploadedImage && !uploadingImage && (
                    <p className="upload-banner-text">
                      <FileImageOutlined />
                    </p>
                  )}
                  {uploadingImage && <LoadingOutlined />}
                </Upload>
              </div>
            </Form.Item>
            <Divider />
            <div className="banner-align">
              <Button
                loading={isSavingData}
                htmlType="submit"
                className="create-btn-modal banner-btn"
              >
                {isUpdateBanner ? 'Update' : 'Create'}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>

      {/* CREATE MODAL */}
      <Modal
        open={linkModal}
        onCancel={handleCancelLinkModal}
        className="create-test-modal"
        centered
        title={bannerName}
      >
        <Divider />
        <Form className="equipment-form" layout="vertical">
          <div className="equipment-form-control timely-offers">
            <div>
              <div>
                <div className="tests-modal-search">
                  <SearchComponent
                    name="Tests"
                    debounceTime={DEBOUNCE_TIME}
                    getData={fetchLiveTests}
                  />
                  {!isFetchingTests && showingTestResults && (
                    <div className="search-result" id="test-searcher">
                      {fetchedTests.length <= 0
                        ? null
                        : fetchedTests.map((currentTest) => {
                            const { isSelected, test } = currentTest;
                            return (
                              <div className="tests-list" key={test.id}>
                                <Typography.Text>{test.title}</Typography.Text>
                                {isSelected ? (
                                  <Button
                                    type="text"
                                    onClick={() => {
                                      const tests = selectedTests.slice();
                                      for (
                                        let i = 0;
                                        i < tests.length;
                                        i += 1
                                      ) {
                                        if (tests[i].id === test.id) {
                                          tests.splice(i, 1);
                                        }
                                      }
                                      const tempFetchedTests =
                                        fetchedTests.slice();
                                      for (
                                        let i = 0;
                                        i < tempFetchedTests.length;
                                        i += 1
                                      ) {
                                        if (
                                          tempFetchedTests[i].test.id ===
                                          test.id
                                        ) {
                                          tempFetchedTests[i] = {
                                            ...tempFetchedTests[i],
                                            isSelected: false
                                          };
                                          break;
                                        }
                                      }
                                      setSelectedTests(tests);
                                      setFetchedTests(tempFetchedTests);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      selectedTests[0] = test;
                                      setShowingTestResults(false);
                                    }}
                                    type="default"
                                  >
                                    Add
                                  </Button>
                                )}
                              </div>
                            );
                          })}
                    </div>
                  )}
                </div>
                <div className="selected-test-container">
                  {selectedTests?.length <= 0 ? (
                    <p>Select any test</p>
                  ) : (
                    selectedTests.map((currentTest) => {
                      const { id, title } = currentTest;
                      return (
                        <div className="tests-list" key={id}>
                          <Typography.Text>{title}</Typography.Text>
                          <Button
                            type="text"
                            onClick={() => {
                              const tests = selectedTests.slice();
                              for (let i = 0; i < tests.length; i += 1) {
                                if (tests[i].id === id) {
                                  tests.splice(i, 1);
                                }
                              }
                              setSelectedTests(tests);
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <div className="banner-align">
              <Button
                loading={isSavingData}
                htmlType="submit"
                className="create-btn-modal banner-btn"
                onClick={async () => {
                  let data = {};
                  if (selectedTests?.length <= 0) {
                    data = {
                      test: null,
                      bannerId: bannerIdForLink
                    };
                  } else {
                    data = {
                      test: selectedTests[0].id || '',
                      bannerId: bannerIdForLink
                    };
                  }
                  setIsSavingData(true);
                  await updateTestToCtaBanner(data);
                  fetchBanners({
                    currentQuery: '',
                    currentSkip: 0
                  });
                  setIsSavingData(false);
                  handleCancelLinkModal();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
      <div className="equipments-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            rowKey={(record) => record.id}
            columns={columns}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={banners}
            onChange={(onChange) => {
              setLoadingData(true);
              fetchBanners({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default CtaBanners;
