import { gql } from '@apollo/client';

export const GET_ADDRESS_TEMPLATES = gql`
  query getAllTemplateAddresses($filters: PaginationInput!) {
    getAllTemplateAddresses(filters: $filters) {
      templateAddresses {
        id
        addressLine1
        addressLine2
        pincode
        state
        city
        serialNumber
        location {
          id
          latitude
          longitude
          pincode
          state
          address
          city
        }
      }
      total
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
