/* eslint-disable no-lone-blocks */
import { LoadingOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Space,
  Tag,
  Tooltip,
  Typography,
  Upload,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { ReactComponent as Warning } from '../../../../assets/icons/warning.svg';
import { ReactComponent as UploadImage } from '../../../../assets/upload.svg';
import {
  getFileSignedUrl,
  postUploadIcon
} from '../../../../common/common-repository';
import CommonTable from '../../../../common/components/CommonTable';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import SearchComponent from '../../../../common/components/SearchDebounce';
import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT
} from '../../../../common/constants';
import {
  clearSearchField,
  formValidatorRules,
  withHttp
} from '../../../../common/utils';
import {
  createCategory,
  getAllTestLinkedToCategory,
  getCategories,
  getLiveTestsAndPackages,
  toggleEnabledCategory,
  toggleFeaturedCategory,
  updateCategory
} from '../../test-repository';
import './test-categories.less';

const { required } = formValidatorRules;
const limit = GLOBAL_PAGINATION_LIMIT;
function TestCategories() {
  const [form] = Form.useForm();
  const [loadingData, setLoadingData] = useState(true);
  const [isSavingData, setIsSavingData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showingTestResults, setShowingTestResults] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [fetchedTests, setFetchedTests] = useState([]);
  const [isFetchingTests, setIsFetchingTests] = useState(false);
  const [query, setQuery] = useState('');
  const [openDisableTest, setOpenDisableTest] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [paginationProp, setPaginationProp] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [isUpdateCategory, setIsUpdateCategory] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);

  const fetchLiveTests = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getLiveTestsAndPackages({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { tests } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < tests.length; i += 1) {
        const test = tests[i];
        const { id } = test;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedTests.length; j += 1) {
          if (selectedTests[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          test
        });
      }
      setFetchedTests(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetchingTests(false);
  };

  const getTestsBelongsToCategory = async (index, value) => {
    setLoadingData(true);
    const tests = await getAllTestLinkedToCategory(categories[index].id);
    setSelectedTests(tests);
    setCategoryName(value);
    setTimeout(() => {
      setLoadingData(false);
      setIsModalOpen(true);
      form.setFieldsValue({
        name: categories[index].name
      });
    }, 500);
  };

  const fetchCategories = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getCategories({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setCategories(response.categories.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchCategories({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleFeatured = async (index) => {
    categories[index] = {
      ...categories[index],
      isFeatured: !categories[index].isFeatured
    };
    toggleFeaturedCategory(categories[index].id);
    setCategories(categories.slice());
  };
  const toggleEnabled = async (index) => {
    categories[index] = {
      ...categories[index],
      isDisabled: !(categories[index].isDisabled || false)
    };
    if (categories[index].isFeatured === true) {
      categories[index] = {
        ...categories[index],
        isFeatured: !categories[index].isFeatured
      };
    }
    toggleEnabledCategory(categories[index].id);
    setCategories(categories.slice());
  };

  const columns = [
    {
      title: '',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
      width: 50,
      align: 'center',
      render: (value, record, index) => {
        return value ? (
          <StarFilled
            className="filled-star"
            onClick={() => {
              toggleFeatured(index);
            }}
          />
        ) : (
          <StarOutlined
            className={`outlined-star${
              record.isDisabled ? ' disable-action' : ''
            }`}
            onClick={() => {
              if (record.isDisabled) {
                return;
              }
              toggleFeatured(index);
            }}
          />
        );
      }
    },
    {
      title: '#',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      width: 75,
      align: 'center',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Category',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (value, record, index) => {
        if (value.length < 20) {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateCategory(true);
                setUploadedImage(record.thumbnail);
                setTimeout(() => {
                  getTestsBelongsToCategory(index, value);
                }, 200);
              }}
            >
              {value}
            </Typography>
          );
        }
        {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateCategory(true);
                setUploadedImage(record.thumbnail);
                setTimeout(() => {
                  getTestsBelongsToCategory(index, value);
                }, 200);
              }}
            >
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 20)}...
              </Tooltip>
            </Typography>
          );
        }
      }
    },
    {
      title: 'Image',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: 200,
      render: (value) => {
        return (
          <a href={withHttp(value)} target="blank">
            image.svg
          </a>
        );
      }
    },
    {
      title: 'Test',
      dataIndex: 'linkedTests',
      key: 'linkedTests',
      render: (value) => {
        const tests = [];
        for (let i = 0; i < value.tests.length; i += 1) {
          tests.push(<Tag className="test-tags">{value.tests[i].title}</Tag>);
        }
        return (
          <Space size={0}>
            {tests}
            {value.total - tests.length > 0 ? (
              <Tag color="blue" className="text-tags">
                +{value.total - tests.length}
              </Tag>
            ) : (
              <span />
            )}
          </Space>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'isDisabled',
      key: 'isDisabled',
      width: 100,
      align: 'center',
      render: (value, record, index) => {
        return value ? (
          <span
            className="disable-class"
            onClick={() => {
              // eslint-disable-next-line no-console
              if (record.linkedTests.total === 0) {
                message.error(
                  "Category can't be enabled unless it has at-least test"
                );
                toggleEnabled(index);
              }
              toggleEnabled(index);
            }}
          >
            Disable
          </span>
        ) : (
          <span
            className="enable-class"
            onClick={() => {
              setCurrentSelectedIndex(index);
              setTimeout(() => {
                setOpenDisableTest(true);
              }, 300);
            }}
          >
            Enable
          </span>
        );
      }
    }
  ];

  const handleCancel = () => {
    form?.resetFields();
    clearSearchField('Tests');
    setIsUpdateCategory(false);
    setUploadedImage(undefined);
    setIsModalOpen(false);
  };

  // FORM SUBMITTING UPDATE FORM
  const onSubmitUpdateCategory = async (formValue) => {
    const { name } = formValue;
    const testsId = [];
    if (selectedTests.length === 0) {
      message.error("Category can't be updated unless it has at-least test");
      return;
    }
    setIsSavingData(true);
    for (let i = 0; i < selectedTests.length; i += 1) {
      testsId.push(selectedTests[i].id);
    }
    const updatedCategory = await updateCategory({
      name,
      thumbnail: uploadedImage,
      testsId: testsId,
      categoryId: categories[currentSelectedIndex].id
    });
    const updatedCategories = categories.slice();
    updatedCategories[currentSelectedIndex] = updatedCategory;
    setCategories(updatedCategories);
    form?.resetFields();
    setUploadedImage(undefined);
    setIsSavingData(false);
    setIsUpdateCategory(false);
    clearSearchField('Tests');
    setIsModalOpen(false);
  };

  // FORM SUBMITTING CREATE FORM
  const onSubmitCreateCategory = async (formValue) => {
    if (!uploadedImage) {
      message.error('Image is not uploaded');
      return;
    }
    const { name } = formValue;
    const testsId = [];
    if (selectedTests.length === 0) {
      message.error("Category can't be created unless it has at-least test");
      return;
    }
    setIsSavingData(true);
    for (let i = 0; i < selectedTests.length; i += 1) {
      testsId.push(selectedTests[i].id);
    }
    const createdCategory = await createCategory({
      name,
      thumbnail: uploadedImage,
      testsId: testsId
    });
    if (
      categories.length === 0 ||
      categories.length < limit ||
      categories[categories.length - 1].order + 1 === createdCategory.order
    ) {
      const updatedCategories = categories.slice();
      updatedCategories.push(createdCategory);
      setCategories(updatedCategories);
    }
    form.resetFields();
    setUploadedImage(undefined);
    setIsSavingData(false);
    setIsUpdateCategory(false);
    clearSearchField('Tests');
    setIsModalOpen(false);
    fetchCategories({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };
  // VALIDATING IMG AS SVG
  const beforeUpload = (file) => {
    const isSvg = file.type === 'image/svg+xml';
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error('Image must smaller than 10MB!');
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    if (!isSvg) {
      message.error(`${file?.name} is not a svg file`);
    }
    return false;
  };

  const onIconUpload = async (selectedFile) => {
    setUploadingImage(true);
    const fileName = selectedFile.file.name;
    const response = await postUploadIcon(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': 'image/svg+xml'
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };
  return (
    <div>
      <Modal
        title={
          <div>
            <Space className="warning-container">
              <Warning />
              Disable category
            </Space>
          </div>
        }
        centered
        closeIcon={<Close className="close-btn" />}
        open={openDisableTest}
        okButtonProps={{ type: 'primary', danger: 'danger' }}
        cancelButtonProps={{ className: 'no-display' }}
        okText="Disable category"
        onOk={() => {
          toggleEnabled(currentSelectedIndex);
          setOpenDisableTest(false);
        }}
        onCancel={() => setOpenDisableTest(false)}
      >
        <Divider />
        <p>
          Are you sure you want to disable this category?
          {/* {currentSelectedIndex !== undefined && (
            <b>{categories[currentSelectedIndex].name}</b>
          )} */}
        </p>
        <Divider />
      </Modal>
      <div className="content-wrapper">
        <Header className="main-header">
          <div className="header-spacer">
            <Space>
              <Typography.Text className="main-header-title">
                Categories
              </Typography.Text>
              <SearchComponent
                name="Categories"
                debounceTime={DEBOUNCE_TIME}
                getData={(value) => {
                  setQuery(value);
                  setLoadingData(true);
                  fetchCategories({
                    currentQuery: value,
                    currentSkip: 0
                  });
                }}
              />
            </Space>
            <Button
              onClick={() => {
                setIsModalOpen(true);
                setShowingTestResults(false);
                setSelectedTests([]);
                setFetchedTests([]);
              }}
              className="create-btn"
            >
              + Create New
            </Button>
            {/* CREATE MODAL */}
            <Modal
              className="create-test-modal"
              centered
              title={isUpdateCategory ? categoryName : 'Create Category'}
              open={isModalOpen}
              onCancel={handleCancel}
            >
              <Divider />
              <Form
                onFinish={
                  isUpdateCategory
                    ? onSubmitUpdateCategory
                    : onSubmitCreateCategory
                }
                form={form}
                layout="vertical"
              >
                <Form.Item name="upload">
                  <div className="profile">
                    <Upload
                      name="upload-icon"
                      onChange={onIconUpload}
                      accept=".svg"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                    >
                      {uploadedImage && !uploadingImage && (
                        <Avatar src={uploadedImage} alt="Selected image" />
                      )}
                      {!uploadedImage && !uploadingImage && (
                        <UploadImage className="category-image" />
                      )}
                      {uploadingImage && <LoadingOutlined />}
                      <p>Upload category image</p>
                    </Upload>
                  </div>
                </Form.Item>
                <Form.Item
                  name="name"
                  label="Create category "
                  rules={[required]}
                >
                  <Input name="category" type="text" />
                </Form.Item>
                <div className="test-available">
                  <Typography.Text>
                    <div
                      style={{
                        display: 'flex'
                      }}
                    >
                      <span>*</span>Tests
                    </div>
                    &nbsp;
                    <span className="w-10" />
                    <span className="available-num">
                      {selectedTests.length}
                    </span>
                  </Typography.Text>
                </div>

                <div className="tests-modal-search">
                  <SearchComponent
                    name="Tests"
                    debounceTime={DEBOUNCE_TIME}
                    getData={fetchLiveTests}
                  />

                  {!isFetchingTests && showingTestResults && (
                    <div className="search-result" id="test-searcher">
                      {fetchedTests.length <= 0 ? (
                        <Empty />
                      ) : (
                        fetchedTests.map((currentTest) => {
                          const { isSelected, test } = currentTest;
                          return (
                            <div className="tests-list" key={test.id}>
                              <Typography.Text>{test.title}</Typography.Text>
                              {isSelected ? (
                                <Button
                                  type="text"
                                  onClick={() => {
                                    const tests = selectedTests.slice();
                                    for (let i = 0; i < tests.length; i += 1) {
                                      if (tests[i].id === test.id) {
                                        tests.splice(i, 1);
                                      }
                                    }
                                    const tempFetchedTests =
                                      fetchedTests.slice();
                                    for (
                                      let i = 0;
                                      i < tempFetchedTests.length;
                                      i += 1
                                    ) {
                                      if (
                                        tempFetchedTests[i].test.id === test.id
                                      ) {
                                        tempFetchedTests[i] = {
                                          ...tempFetchedTests[i],
                                          isSelected: false
                                        };
                                        break;
                                      }
                                    }
                                    setSelectedTests(tests);
                                    setFetchedTests(tempFetchedTests);
                                  }}
                                >
                                  Remove
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    const tests = selectedTests.slice();
                                    tests.push(test);
                                    const tempFetchedTests =
                                      fetchedTests.slice();
                                    for (
                                      let i = 0;
                                      i < tempFetchedTests.length;
                                      i += 1
                                    ) {
                                      if (
                                        tempFetchedTests[i].test.id === test.id
                                      ) {
                                        tempFetchedTests[i] = {
                                          ...tempFetchedTests[i],
                                          isSelected: true
                                        };
                                        break;
                                      }
                                    }
                                    setSelectedTests(tests);
                                    setFetchedTests(tempFetchedTests);
                                  }}
                                  type="default"
                                >
                                  Add
                                </Button>
                              )}
                            </div>
                          );
                        })
                      )}
                    </div>
                  )}
                </div>

                <div className="selected-test-container category-container">
                  {selectedTests.length <= 0 ? (
                    <Empty />
                  ) : (
                    selectedTests.map((currentTest) => {
                      const { id, title } = currentTest;
                      return (
                        <div className="tests-list" key={id}>
                          <Typography.Text>{title}</Typography.Text>
                          <Button
                            type="text"
                            onClick={() => {
                              const tests = selectedTests.slice();
                              for (let i = 0; i < tests.length; i += 1) {
                                if (tests[i].id === id) {
                                  tests.splice(i, 1);
                                }
                              }
                              setSelectedTests(tests);
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      );
                    })
                  )}
                </div>
                <Divider />
                <div className="create-wrapper">
                  <Button
                    htmlType="submit"
                    loading={isSavingData}
                    className="create-btn-modal"
                  >
                    {isUpdateCategory ? 'Update' : 'Create'}
                  </Button>
                </div>
              </Form>
            </Modal>
          </div>
        </Header>
        <div className="main-content test-categories-content">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              data={categories}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchCategories({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default TestCategories;
