import { gql } from '@apollo/client';

export const GET_FILE_SIGNED_URL = gql`
  query getFileSignedUrl($filePath: String!) {
    getFileSignedUrl(filePath: $filePath) {
      signedUrl
    }
  }
`;

export const GET_DATA_COUNT = gql`
  query getLiveDataCount {
    getLiveDataCount {
      activeReportCount
      prescriptionsCount
      customerCartCount
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
