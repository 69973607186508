import { gql } from '@apollo/client';

export const GET_ACTIVE_PRESCRIPTIONS = gql`
  query getActivePrescriptions($filters: GetAllCustomerInput!) {
    getActivePrescriptions(filters: $filters) {
      total
      prescriptions {
        id
        url
        status
        createdAt
        updatedAt
        customer {
          id
          name
        }
      }
    }
  }
`;

export const GET_HISTORY_PRESCRIPTIONS = gql`
  query getHistoryPrescriptions($filters: GetAllCustomerInput!) {
    getHistoryPrescriptions(filters: $filters) {
      total
      prescriptions {
        id
        url
        status
        createdAt
        updatedAt
        customer {
          id
          name
        }
      }
    }
  }
`;
