/* eslint-disable no-console */
import {
  DownOutlined,
  UploadOutlined,
  UpOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, message, Table, Tooltip, Typography, Upload } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReactComponent as TickCircle } from '../../../assets/icons/tick-circle.svg';
import {
  getFileSignedUrl,
  postUploadReport
} from '../../../common/common-repository';
import CommonTable from '../../../common/components/CommonTable';
import LoaderComponent from '../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../../common/constants';
import {
  getCurrentSampleCollectedOrders,
  uploadReport,
  uploadWholeReport
} from '../labReports-repository';

function CurrentReports({ query, forceToRefetch }) {
  // CONSTANTS
  const limit = GLOBAL_PAGINATION_LIMIT;

  //  STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [currentSampleCollectedByOrder, setCurrentSampleCollectedByOrder] =
    useState([]);
  const [paginationProp, setPaginationProp] = useState();

  const fetchCurrentSamplesByOrders = async ({ currentSkip }) => {
    try {
      //
      const response = await getCurrentSampleCollectedOrders({
        skip: currentSkip,
        limit: limit,
        query: query.trim()
      });

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setCurrentSampleCollectedByOrder(response.orders.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchCurrentSamplesByOrders({
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, forceToRefetch]);

  const beforeUpload = (file) => {
    const isPDF = file.type === 'application/pdf';
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error('PDF must smaller than 10MB!');
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    if (!isPDF) {
      message.error(`${file?.name} is not a svg file`);
    }
    return false;
  };

  const onPDFUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const response = await postUploadReport(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': 'application/pdf'
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    return url;
  };

  const columns = [
    {
      title: 'Order#',
      dataIndex: 'orderId'
    },
    {
      title: 'Patient',
      dataIndex: 'patient',
      render: (value) => {
        return <Typography.Text>{value.name}</Typography.Text>;
      }
    },
    {
      title: 'Gender',
      dataIndex: 'patient',
      render: (value) => {
        return <Typography.Text>{value.sex}</Typography.Text>;
      }
    },
    {
      title: 'Age',
      dataIndex: 'patient',
      render: (value) => {
        return <Typography.Text>{value.age}</Typography.Text>;
      }
    },
    {
      title: '# reports',
      dataIndex: 'reports',
      align: 'center',
      render: (value, record) => {
        return `${record.tests.length}`;
      }
    },
    {
      title: 'Sample tube no.',
      dataIndex: 'requiredSamples',
      render: (record) => {
        const data = [];
        for (let i = 0; i < record.length; i += 1) {
          data.push(record[i].linkedEquipment);
        }
        return (
          <span>
            {data.length > 1 ? (
              <p>
                <Tooltip
                  className="tooltipNo"
                  color="#232e78"
                  placement="topLeft"
                  title={`${data}`}
                >
                  {data[0]}..
                </Tooltip>
              </p>
            ) : (
              <Tooltip
                className="tooltipNo"
                color="#232e78"
                placement="topLeft"
                title={`${data}`}
              >
                {data[0]}..
              </Tooltip>
            )}
          </span>
        );
      }
    },
    {
      title: 'Full report',
      dataIndex: 'wholeReport',
      render: (value, record, index) => {
        if (value?.isApproved)
          return (
            <TickCircle
              style={{
                height: '30px',
                width: '30px'
              }}
            />
          );
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {value?.reportUrl && (
              <a
                href={value?.reportUrl}
                style={{
                  width: '40%',
                  textOverflow: 'ellipsis'
                }}
                target="_blank"
                rel="noreferrer"
              >
                {`${record.orderId}#${index + 1}`}.pdf
              </a>
            )}
            {value?.rejectionReason && (
              <Tooltip
                className="tooltipNo"
                color="red"
                placement="topLeft"
                title={value?.rejectionReason}
              >
                <WarningOutlined
                  style={{
                    fontSize: '20px',
                    color: 'red'
                  }}
                />
              </Tooltip>
            )}
            {(value?.reportUrl || value?.rejectionReason) && (
              <div style={{ width: '10px' }} />
            )}
            <div
              className={value?.reportUrl ? '' : 'upload-icon-container'}
              onClick={() => {}}
            >
              <Upload
                name="upload-pdf"
                onChange={async (selectedFile) => {
                  const key = 'updatable';
                  message.open({
                    key,
                    type: 'loading',
                    content: 'Wait...! uploading pdf',
                    duration: 0
                  });
                  const pdfURL = await onPDFUpload(selectedFile);
                  const result = await uploadWholeReport({
                    orderId: record.id,
                    reportFileUrl: pdfURL
                  });
                  if (result) {
                    message.open({
                      key,
                      type: 'success',
                      content: 'PDF Uploaded successfully',
                      duration: 1
                    });
                    const currentSampleCollectedByOrderTemp =
                      currentSampleCollectedByOrder.slice();
                    let currentReports =
                      currentSampleCollectedByOrderTemp[index].wholeReport;
                    currentReports = {
                      ...currentReports,
                      reportUrl: pdfURL,
                      uploadedAt: new Date(),
                      isApproved: false
                    };
                    currentSampleCollectedByOrderTemp[index] = {
                      ...currentSampleCollectedByOrderTemp[index],
                      wholeReport: currentReports
                    };
                    setCurrentSampleCollectedByOrder(
                      currentSampleCollectedByOrderTemp
                    );
                  } else {
                    message.open({
                      key,
                      type: 'error',
                      content: 'PDF Failed to upload',
                      duration: 1
                    });
                  }
                }}
                accept=".pdf"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {value?.reportUrl ? (
                  <Button
                    type="primary"
                    ghost
                    style={{
                      color: '#232e78',
                      borderColor: '#232e78'
                    }}
                  >
                    Upload
                  </Button>
                ) : (
                  <UploadOutlined />
                )}
              </Upload>
            </div>
          </div>
        );
      }
    }
  ];

  return (
    <div className="main-content test-categories-content">
      {loading ? (
        <LoaderComponent />
      ) : (
        <CommonTable
          columns={columns}
          rowKey={(record) => record.id}
          loadingData={loadingData}
          paginationConfig={paginationProp}
          data={currentSampleCollectedByOrder}
          onChange={(onChange) => {
            // eslint-disable-next-line no-console
            setLoadingData(true);
            fetchCurrentSamplesByOrders({
              // currentQuery: query,
              currentSkip: (onChange.current - 1) * limit
            });
          }}
          expandable={{
            rowExpandable: () => true,
            // eslint-disable-next-line react/no-unstable-nested-components
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpOutlined
                  className="up-down-btn"
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <DownOutlined
                  className="up-down-btn"
                  onClick={(e) => onExpand(record, e)}
                />
              ),

            // eslint-disable-next-line react/no-unstable-nested-components
            expandedRowRender: (parent, index) => {
              const { reports } = parent;
              const { wholeReport } = parent;
              console.log(wholeReport);
              const expandedCol = [
                {
                  title: '#Test',
                  dataIndex: 'orderId',
                  width: 150,
                  render: () => {
                    return `${parent.orderId}#${index + 1}`;
                  }
                },
                {
                  title: 'Test name',
                  dataIndex: 'orderId',
                  render: (value, record) => {
                    return `${record.title}`;
                  }
                },
                {
                  title: 'Upload',
                  width: 250,
                  dataIndex: 'id',
                  render: (value) => {
                    let report;
                    let indexOfReport;
                    for (let i = 0; i < reports.length; i += 1) {
                      const currentReport = reports[i];
                      if (value === currentReport.test) {
                        report = currentReport;
                        indexOfReport = i;
                        break;
                      }
                    }
                    if (!report) {
                      return <div />;
                    }
                    if (report.isApproved)
                      return (
                        <TickCircle
                          style={{
                            height: '30px',
                            width: '30px'
                          }}
                        />
                      );
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {report.reportUrl && (
                          <a
                            href={report.reportUrl}
                            style={{
                              width: '40%',
                              textOverflow: 'ellipsis'
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {`${parent.orderId}#${index + 1}`}.pdf
                          </a>
                        )}
                        {report.rejectionReason && (
                          <Tooltip
                            className="tooltipNo"
                            color="red"
                            placement="topLeft"
                            title={report.rejectionReason}
                          >
                            <WarningOutlined
                              style={{
                                fontSize: '20px',
                                color: 'red'
                              }}
                            />
                          </Tooltip>
                        )}
                        {(report.reportUrl || report.rejectionReason) && (
                          <div style={{ width: '10px' }} />
                        )}
                        <div
                          className={
                            report.reportUrl ? '' : 'upload-icon-container'
                          }
                          onClick={() => {}}
                        >
                          <Upload
                            disabled={!!wholeReport}
                            name="upload-pdf"
                            onChange={async (selectedFile) => {
                              const key = 'updatable';
                              message.open({
                                key,
                                type: 'loading',
                                content: 'Wait...! uploading pdf',
                                duration: 0
                              });
                              const pdfURL = await onPDFUpload(selectedFile);

                              const result = await uploadReport({
                                orderId: parent.id,
                                reportFileUrl: pdfURL,
                                testId: report.test
                              });
                              if (result) {
                                message.open({
                                  key,
                                  type: 'success',
                                  content: 'PDF Uploaded successfully',
                                  duration: 1
                                });
                                const currentSampleCollectedByOrderTemp =
                                  currentSampleCollectedByOrder.slice();
                                const currentReports =
                                  currentSampleCollectedByOrderTemp[
                                    index
                                  ].reports.slice();
                                currentReports[indexOfReport] = {
                                  ...currentReports[indexOfReport],
                                  reportUrl: pdfURL,
                                  uploadedAt: new Date(),
                                  isApproved: false
                                };
                                currentSampleCollectedByOrderTemp[index] = {
                                  ...currentSampleCollectedByOrderTemp[index],
                                  reports: currentReports
                                };
                                setCurrentSampleCollectedByOrder(
                                  currentSampleCollectedByOrderTemp
                                );
                              } else {
                                message.open({
                                  key,
                                  type: 'error',
                                  content: 'PDF Failed to upload',
                                  duration: 1
                                });
                              }
                            }}
                            accept=".pdf"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                          >
                            {report.reportUrl ? (
                              <Button
                                disabled={!!wholeReport}
                                type="primary"
                                ghost
                                style={{
                                  color: '#232e78',
                                  borderColor: '#232e78'
                                }}
                              >
                                Upload
                              </Button>
                            ) : (
                              <UploadOutlined />
                            )}
                          </Upload>
                        </div>
                      </div>
                    );
                  }
                }
              ];
              return (
                <Table
                  // eslint-disable-next-line no-shadow
                  rowKey={(record) => record.id}
                  className="extended-table"
                  columns={expandedCol}
                  dataSource={parent.tests}
                  pagination={false}
                />
              );
            }
          }}
        />
      )}
    </div>
  );
}

export default CurrentReports;
