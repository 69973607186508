import { Form, React, Select } from 'antd';

const { Option } = Select;

function CountryCodePicker({ name, isDisabled, initialValue }) {
  // const { required } = formValidatorRules;
  return (
    <Form.Item name={name} noStyle initialValue={initialValue}>
      <Select
        defaultValue="91"
        disabled={isDisabled}
        className="ant-select-selector-picker"
      >
        <Option value="91">+91</Option>
        <Option value="1">+1</Option>
      </Select>
    </Form.Item>
  );
}
export default CountryCodePicker;
