import { gql } from '@apollo/client';

export const GET_SLOTS_BY_DAY = gql`
  query getSlotsCategorizedByDay($filter: FetchSlotsInput!) {
    getSlotsCategorizedByDay(filter: $filter) {
      morning {
        id
        name
        startTime
        day
        maxBookingLimit
      }
      afternoon {
        id
        name
        startTime
        day
        maxBookingLimit
      }
      evening {
        id
        name
        startTime
        day
        maxBookingLimit
      }
    }
  }
`;

export const GET_MONTH_CALENDER_HOLIDAYS = gql`
  query getMonthCalendarHolidays($month: String!, $year: String!) {
    getMonthCalendarHolidays(month: $month, year: $year)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
