import { gql } from '@apollo/client';

export const GET_ALL_DISEASES = gql`
  query GetDiseases($filters: GetDiseasesInput!) {
    getDiseases(filters: $filters) {
      diseases {
        createdAt
        id
        isFeatured
        name
        dynamicLink
        diseaseSlug
        actualCount
        noOfAssessment
        order
        linkedTests {
          id
          title
        }
        questions {
          id
        }
        serialNumber
        thumbnail
        updatedAt
      }
      total
    }
  }
`;

export const GET_ALL_DISEASES_CATEGORIES = gql`
  query getDiseasesCategoriesAsAdmin($filters: GetDiseasesCategoriesInput) {
    getDiseasesCategoriesAsAdmin(filters: $filters) {
      categories {
        createdAt
        id
        isDisabled
        isFeatured
        doctors {
          id
          name
        }
        linkedDiseases {
          id
          name
        }
        name
        serialNumber
        # thumbnail
      }
      total
    }
  }
`;

export const GET_DISEASE_BY_ID = gql`
  query GetDiseasesByIdAsAdmin($diseasesId: String!) {
    getDiseasesByIdAsAdmin(diseasesId: $diseasesId) {
      diseases {
        id
        name
        diseaseSlug
        actualCount
        dynamicLink
        questions {
          id
          optionType
          questionText
          options {
            id
            optionText
          }
        }
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
