import { gql } from '@apollo/client';

export const ASSIGN_EQUIPMENT = gql`
  mutation assignEquipment(
    $sampleCollectorId: String!
    $equipmentId: String!
    $quantity: Int!
    $availableQuantity: Int!
  ) {
    assignEquipment(
      data: {
        sampleCollectorId: $sampleCollectorId
        equipmentId: $equipmentId
        quantity: $quantity
        availableQuantity: $availableQuantity
      }
    )
  }
`;

export const ASSIGN_MULTIPLE_EQUIPMENTS = gql`
  mutation assignMultipleEquipments(
    $sampleCollectorId: String!
    $assignEquipments: [AllotedEquipmentInput!]!
  ) {
    assignMultipleEquipments(
      data: {
        sampleCollectorId: $sampleCollectorId
        assignEquipments: $assignEquipments
      }
    )
  }
`;

export const UPDATE_ASSIGN_EQUIPMENTS = gql`
  mutation assignEquipmentsToCollector(
    $assignEquipments: [AssignEquipmentWhileUpdate]
    $id: String!
  ) {
    assignEquipmentsToCollectors(assignEquipments: $assignEquipments, id: $id) {
      equipment {
        id
        name
        serialNumber
        minimumQuantity
        stock {
          id
          availableQuantity
        }
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
