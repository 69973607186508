import { Divider, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { getDoctorDetails } from '../../doctors-tab/doctor-repository';

export function OrderDetails({ order }) {
  const [doctorName, setDoctorName] = useState('');

  const handleViewDetails = async () => {
    if (order?.doctorAssigned) {
      const response = await getDoctorDetails(order.doctorAssigned);
      setDoctorName(response?.name || '');
    } else {
      setDoctorName('');
    }
  };

  useEffect(() => {
    handleViewDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Divider className="divider-details" />
      <div
        style={{
          paddingTop: '10px',
          marginLeft: '30px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%'
        }}
        className="print-details"
      >
        <Typography.Title
          style={{
            fontSize: '30px',
            textAlign: 'center',
            textDecoration: 'underline',
            paddingBottom: '20px',
            paddingRight: '50px'
          }}
        >
          DIAGNOSTICS AT DOORSTEP
        </Typography.Title>
        <div>
          <Typography.Title
            style={{
              fontSize: '18px'
            }}
          >
            PATIENT DETAILS
          </Typography.Title>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '16px',
              paddingLeft: '10px'
            }}
          >
            <span>
              <b> PATIENT NAME</b> : {order?.patient?.name}
            </span>
            <span>
              <b> PATIENT AGE</b> : {order?.patient?.age}
            </span>
            <span>
              <b> PATIENT GENDER</b> : {order?.patient?.sex}
            </span>
            <span>
              <b> ORDER ID</b> : {order?.orderId}
            </span>
            <span>
              <b> ADDRESS</b> : {order?.address?.addressLine1},
              {order?.address?.addressLine2},{order?.address?.state},
              {order?.address?.city},{order?.address?.pincode}
            </span>
            <span>
              <b> BOOKED ON</b> :{' '}
              {dayjs(order?.scheduledAt).format('DD/MM/YYYY')}
            </span>
            <span>
              <b> LINKED DOCTOR</b> :{doctorName}
            </span>
          </div>
        </div>
        <div>
          <Typography.Title
            style={{
              fontSize: '18px'
            }}
          >
            TESTS DETAILS
          </Typography.Title>
          {order?.orderSummary?.tests?.map((test, index) => {
            return (
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: '16px',
                  paddingLeft: '10px'
                }}
                className="tests"
                key={test.testId}
              >
                <b key={test.testId}>
                  {index + 1}) {test.title}
                </b>
              </span>
            );
          })}
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              fontSize: '16px'
            }}
          >
            <Typography.Title
              style={{
                fontSize: '16px',
                textDecoration: 'underline'
              }}
            >
              SAMPLES COLLECTED
            </Typography.Title>
            {order?.requiredSamples?.map((samp) => {
              // eslint-disable-next-line react/jsx-key
              return (
                // eslint-disable-next-line react/jsx-key
                <span
                  style={{
                    display: 'flex',
                    paddingLeft: '10px'
                  }}
                >
                  <b>{samp.sample.name}</b> :&nbsp;
                  {samp.linkedEquipment.join(',')}
                </span>
              );
            })}
          </span>
        </div>
        <div className="sample-details">
          <Typography.Title
            style={{
              fontSize: '18px'
            }}
          >
            SAMPLE COLLECTION DETAILS
          </Typography.Title>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '16px',
              paddingLeft: '10px'
            }}
          >
            <span>
              <b> SAMPLE COLLECTED ON</b> :&nbsp;
              {!order?.sampleCollectedAt
                ? 'Not yet collected'
                : dayjs(order?.sampleCollectedAt).format('DD/MM/YYYY')}
            </span>
            <span>
              <b> SAMPLE COLLECTED TIME</b> :&nbsp;
              {!order?.sampleCollectedAt
                ? 'Not yet collected'
                : dayjs(order?.sampleCollectedAt).format('hh:mm a')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
