import client from '../../apollo';
import {
  CREATE_OFFERS,
  TOGGLE_AUTO_APPLY,
  TOGGLE_ENABLE_OFFER,
  TOGGLE_FEATURE_OFFER,
  TOGGLE_HIDDEN_OFFER,
  UPDATE_LINKED_TESTS,
  UPDATE_OFFER
} from './graphql/mutation';
import { CHECK_COUPON, GET_OFFERS, OFFER_DETAILS } from './graphql/query';

export const getOffers = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_OFFERS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getOffers;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const createOffer = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_OFFERS,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.createOffer?.offer;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const toggleFeatureOffer = async (offerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_FEATURE_OFFER,
      fetchPolicy: 'network-only',
      variables: {
        offerId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const toggleEnabledOffer = async (offerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_ENABLE_OFFER,
      fetchPolicy: 'network-only',
      variables: {
        offerId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const updateOffer = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_OFFER,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.updateOffer?.offer;
  } catch (error) {
    return false;
  }
};

export const checkIfCouponCodeExist = async (code) => {
  try {
    const response = await client.query({
      query: CHECK_COUPON,
      fetchPolicy: 'network-only',
      variables: { code }
    });
    return response?.data?.checkIfCouponCodeExist;
  } catch (error) {
    return false;
  }
};

export const updateLinkedTest = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_LINKED_TESTS,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const toggleAutoApply = async (offerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_AUTO_APPLY,
      fetchPolicy: 'network-only',
      variables: {
        offerId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const toggleHiddenOffer = async (offerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_HIDDEN_OFFER,
      fetchPolicy: 'network-only',
      variables: {
        offerId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const getOfferDetailsById = async (data) => {
  try {
    const response = await client.query({
      query: OFFER_DETAILS,
      fetchPolicy: 'network-only',
      variables: {
        filters: data
      }
    });
    return response?.data?.getCustomerCouponHistory;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const test = async () => {};
