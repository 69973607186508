import client from '../../apollo';
import {
  CREATE_BANNER,
  DELETE_BANNER,
  LINK_TEST_WITH_BANNER,
  TOGGLE_BANNER_FIRST_TIME,
  TOGGLE_FEATURE_BANNER,
  UPDATE_BANNER
} from './graphql/mutation';
import { GET_BANNER_AS_ADMIN } from './graphql/query';

export const getBannersAsAdmin = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_BANNER_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getBannersAsAdmin;
  } catch (error) {
    return [];
  }
};

export const createBanner = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_BANNER,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.createBanner?.banner;
  } catch (error) {
    return false;
  }
};
export const toggleFeatureBanner = async (id) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_FEATURE_BANNER,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const updateBanner = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_BANNER,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.updateBanner?.banner;
  } catch (error) {
    return false;
  }
};

export const removeBanner = async (bannerId) => {
  try {
    const response = await client.query({
      query: DELETE_BANNER,
      fetchPolicy: 'network-only',
      variables: {
        bannerId
      }
    });
    return response?.data;
  } catch (error) {
    return [];
  }
};

export const toggleBannerFirstTime = async (bannerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_BANNER_FIRST_TIME,
      fetchPolicy: 'network-only',
      variables: {
        bannerId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const updateTestToBanner = async (data) => {
  const { test, bannerId } = data;
  try {
    const response = await client.mutate({
      mutation: LINK_TEST_WITH_BANNER,
      fetchPolicy: 'network-only',
      variables: {
        test,
        bannerId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const sample = () => {};
