import { gql } from '@apollo/client';

export const CREATE_TIMELY_OFFER = gql`
  mutation createTimelyOffer(
    $data: CreateTimelyOfferInput!
    $offerType: OfferLinkedTo!
  ) {
    createTimelyOffer(data: $data, offerType: $offerType) {
      offer {
        id
        title
        thumbnailUrl
        startDate
        tillValid
        serialNumber
        test {
          id
          title
        }
        category {
          id
          name
        }
        categoryTests {
          id
          title
        }
        offerPriceDetails {
          originalPrice
          totalDiscount
          discountedPrice
        }
        isDisabled
      }
    }
  }
`;

// TOGGLE ENABLE OFFER
export const TOGGLE_ENABLE_TIMELY_OFFER = gql`
  mutation toggleTimelyOfferEnable($offerId: String!) {
    toggleTimelyOfferEnable(offerId: $offerId) {
      result
    }
  }
`;

export const UPDATE_TIMELY_OFFER = gql`
  mutation updateTimelyOffer(
    $title: String
    $thumbnailUrl: String
    $tillValid: Date
    $startDate: Date
    $offerId: String!
  ) {
    updateTimelyOffer(
      data: {
        title: $title
        thumbnailUrl: $thumbnailUrl
        startDate: $startDate
        tillValid: $tillValid
      }
      offerId: $offerId
    ) {
      offer {
        id
        title
        thumbnailUrl
        startDate
        tillValid
        serialNumber
        test {
          id
          title
        }
        category {
          id
          name
        }
        categoryTests {
          id
          title
        }
        offerPriceDetails {
          originalPrice
          totalDiscount
          discountedPrice
        }
        isDisabled
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
