/* eslint-disable no-console */
import {
  CloseCircleFilled,
  LoadingOutlined,
  WarningFilled
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Space,
  Tag,
  Tooltip,
  Typography,
  Upload,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import { getType } from 'mime';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  getFileSignedUrl,
  postUploadPhoto
} from '../../common/common-repository';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import { DEBOUNCE_TIME, GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import {
  clearSearchField,
  formValidatorRules,
  withHttp
} from '../../common/utils';
import {
  getCategories,
  getLiveTestsAndPackages
} from '../tests/test-repository';
import {
  createTimelyOffer,
  getTimelyOffers,
  toggleTimelyOfferEnable,
  updateTimelyOffer
} from './timely-offer-repository';

import './timely-offers.less';

function TimelyOffers() {
  const { required } = formValidatorRules;
  const [form] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;
  const dateFormat = 'DD/MM/YYYY';

  // STATES
  const [uploadedImage, setUploadedImage] = useState('');
  const [offerType, setOfferType] = useState('CATEGORY');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [showingTestResults, setShowingTestResults] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const [fetchedTests, setFetchedTests] = useState([]);
  const [isFetchingTests, setIsFetchingTests] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [timelyOffers, setTimelyOffers] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [isSavingData, setIsSavingData] = useState(false);
  const [showingCategoryResults, setShowingCategoryResults] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [fetchedCategories, setFetchedCategories] = useState([]);
  const [isFetchingCategories, setIsFetchingCategories] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [openDisableOffer, setOpenDisableOffer] = useState(false);
  const [isUpdateOffer, setIsUpdateOffer] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [offerName, setOfferName] = useState('');
  const [todaysDate, setTodaysDate] = useState();
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);

  const fetchTimelyOffers = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getTimelyOffers({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setTimelyOffers(response.offers.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchTimelyOffers({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCategories = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingCategoryResults(false);
      return;
    }
    setShowingCategoryResults(true);
    setIsFetchingCategories(true);
    try {
      const response = await getCategories({
        skip: 0,
        limit: 3,
        query: currentSearch,
        onlyEnabled: true
      });
      const { categories } = response;
      const currentFetchedCategories = [];
      for (let i = 0; i < categories.length; i += 1) {
        const category = categories[i];
        const { id } = category;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedCategories.length; j += 1) {
          if (selectedCategories[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedCategories.push({
          isSelected: isAlreadySelected,
          category
        });
      }
      setFetchedCategories(currentFetchedCategories);
    } catch (error) {
      //
    }
    setIsFetchingCategories(false);
  };

  const fetchLiveTests = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getLiveTestsAndPackages({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { tests } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < tests.length; i += 1) {
        const test = tests[i];
        const { id } = test;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedTests.length; j += 1) {
          if (selectedTests[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          test
        });
      }
      setFetchedTests(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetchingTests(false);
  };

  const discountChange = (e) => {
    e.preventDefault();
    setOfferType(e.target.value);

    if (offerType === 'CATEGORY') {
      clearSearchField('Categories');
      setShowingTestResults(false);
      setSelectedCategories([]);
    } else {
      clearSearchField('Tests');
      setShowingTestResults(false);
      setSelectedTests([]);
    }
  };

  const toggleEnabled = async (index) => {
    timelyOffers[index] = {
      ...timelyOffers[index],
      isDisabled: !(timelyOffers[index].isDisabled || false)
    };
    toggleTimelyOfferEnable(timelyOffers[index].id);
    setTimelyOffers(timelyOffers.slice());
  };

  // VALIDATING IMG AS SVG
  const beforeUpload = (file) => {
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error(`File must smaller than 10MB!`);
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  // BANNER UPLOAD
  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await postUploadPhoto(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  // UPDATE MODAL
  const offerUpdate = async (index, value, record) => {
    setLoadingData(true);
    setIsUpdateOffer(true);
    setTimeout(() => {
      setIsModalOpen(true);
      setLoadingData(false);
      setOfferName(value);
      form.setFieldsValue({
        title: record.title,
        thumbnailUrl: uploadedImage,
        startDate: dayjs(record.startDate),
        validTill: dayjs(record.tillValid)
      });
    }, 500);
  };

  // COLUMNS
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (value, record, index) => {
        if (value.length < 25) {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateOffer(true);
                setSelectedId(record.id);
                setUploadedImage(record.thumbnailUrl);
                setTimeout(() => {
                  offerUpdate(index, value, record);
                }, 200);
              }}
            >
              {value}
            </Typography>
          );
        }
        // eslint-disable-next-line no-lone-blocks
        {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateOffer(true);
                setSelectedId(record.id);
                setUploadedImage(record.thumbnailUrl);
                setTimeout(() => {
                  offerUpdate(index, value, record);
                }, 200);
              }}
            >
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 25)}...
              </Tooltip>
            </Typography>
          );
        }
      }
    },
    {
      title: 'Image',
      dataIndex: 'thumbnailUrl',
      key: 'thumbnailUrl',
      render: (value) => {
        return (
          <a href={withHttp(value)} target="blank">
            image.png
          </a>
        );
      }
    },
    {
      title: 'Discounted Price',
      dataIndex: 'offerPriceDetails',
      key: 'createdAt',
      render: (value) => {
        return `₹  ${value.discountedPrice}`;
      }
    },
    {
      title: 'Total Discount',
      dataIndex: 'offerPriceDetails',
      key: 'createdAt',
      render: (value) => {
        return `${value.totalDiscount.toFixed(2)} %`;
      }
    },
    {
      title: 'Start from',
      dataIndex: 'startDate',
      key: 'createdAt',
      render: (value) => {
        return moment(value).format('DD/MM/YYYY');
      }
    },
    {
      title: 'Valid till',
      dataIndex: 'tillValid',
      key: 'createdAt',
      render: (value) => {
        return moment(value).format('DD/MM/YYYY');
      }
    },
    {
      title: 'Status',
      dataIndex: 'tillValid',
      key: 'tillValid',
      render: (value, record) => {
        const now = new Date();
        const tillValid = new Date(value);
        const startDate = new Date(record.startDate);
        if (record.isDisabled) {
          return <Tag className="expired-tag">Inactive</Tag>;
        }
        if (startDate > now) {
          return <Tag className="future-tag">Future</Tag>;
        }
        if (tillValid < now) {
          return <Tag className="expired-tag">Expired</Tag>;
        }
        return <Tag className="active-tag">Active</Tag>;
      }
    },

    {
      title: 'Action',
      dataIndex: 'isDisabled',
      key: 'isDisabled',
      render: (value, record, index) => {
        return value ? (
          <span
            className="disable-class"
            onClick={() => {
              if (record.offerLinkedTo === 'TEST' && record.test.archive) {
                message.error('Test is disabled');
                return;
              }
              if (record.offerLinkedTo === 'TEST' && record.test.isSandbox) {
                message.error('Test is not live yet');
                return;
              }
              if (
                record.offerLinkedTo !== 'TEST' &&
                record.categoryTests.length === 0
              ) {
                message.error(
                  "Category can't be enabled unless it has at-least test"
                );
                return;
              }
              toggleEnabled(index);
            }}
          >
            Disabled
          </span>
        ) : (
          <span
            className="enable-class"
            onClick={() => {
              setCurrentSelectedIndex(index);
              setTimeout(() => {
                setOpenDisableOffer(true);
              }, 300);
            }}
          >
            Enabled
          </span>
        );
      }
    }
  ];

  const handleCancel = () => {
    form.resetFields();
    setOfferType('CATEGORY');
    setUploadedImage(undefined);
    if (!isUpdateOffer) {
      if (offerType === 'CATEGORY') {
        clearSearchField('Categories');
      } else {
        clearSearchField('Tests');
      }
    }
    setShowingCategoryResults(false);
    setShowingTestResults(false);
    setSelectedCategories([]);
    setSelectedTests([]);
    setIsUpdateOffer(false);
    setIsModalOpen(false);
  };

  // CREATE FORM
  const createTimelyOfferForm = async (formValue) => {
    const { title, startDate, validTill } = formValue;
    const newStartDate = startDate.toDate().toISOString();
    const newValidTill = validTill.toDate().toISOString();
    if (newValidTill < newStartDate) {
      message.error('Invalid dates');
      return;
    }
    if (!uploadedImage) {
      message.error('Image is not uploaded');
      return;
    }
    if (selectedCategories.length === 0 && selectedTests.length === 0) {
      message.error('Please add category or test');
      return;
    }
    if (title.length > 50) {
      message.error('Exceeded title limit');
      return;
    }
    setIsSavingData(true);
    const createdTimelyOffer = await createTimelyOffer(
      {
        title,
        category: selectedCategories[0]?.id || null,
        test: selectedTests[0]?.id || null,
        thumbnailUrl: uploadedImage,
        startDate: newStartDate,
        tillValid: newValidTill
      },
      offerType
    );
    if (
      timelyOffers.length === 0 ||
      timelyOffers.length < limit ||
      timelyOffers[timelyOffers.length - 1].serialNumber + 1 ===
        createdTimelyOffer.serialNumber
    ) {
      const updatedTimelyOffer = timelyOffers.slice();
      updatedTimelyOffer.push(createdTimelyOffer);
      setTimelyOffers(updatedTimelyOffer.slice());
    }
    form.resetFields();
    setUploadedImage(undefined);
    setOfferType('CATEGORY');
    if (offerType === 'CATEGORY') {
      clearSearchField('Categories');
    } else {
      clearSearchField('Tests');
    }
    setSelectedCategories([]);
    setSelectedTests([]);
    setIsSavingData(false);
    setIsModalOpen(false);
    fetchTimelyOffers({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  // UPDATE FORM
  const updateOfferForm = async (formValue) => {
    const { title, startDate, validTill } = formValue;
    const newStartDate = startDate.toDate().toISOString();
    const newValidTill = validTill.toDate().toISOString();
    if (newValidTill < newStartDate) {
      message.error('Invalid dates');
      return;
    }
    if (!uploadedImage) {
      message.error('Image is not uploaded');
      return;
    }
    if (title.length > 50) {
      message.error('Exceeded title limit');
      return;
    }
    setIsSavingData(true);
    const updatedOffer = await updateTimelyOffer({
      title,
      offerId: selectedId,
      thumbnailUrl: uploadedImage,
      startDate: newStartDate,
      tillValid: newValidTill
    });
    const updatedOffers = timelyOffers.slice();
    updatedOffers[currentSelectedIndex] = updatedOffer;
    setTimelyOffers(updatedOffers);
    form?.resetFields();
    setUploadedImage(undefined);
    setSelectedCategories([]);
    setSelectedTests([]);
    setIsSavingData(false);
    setIsModalOpen(false);
    setIsUpdateOffer(false);
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Timely offers
            </Typography.Text>
            <SearchComponent
              name="Timely offers"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchTimelyOffers({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button onClick={() => setIsModalOpen(true)} className="create-btn">
            + Create
          </Button>
          {/* DISABLE MODAL */}
          <Modal
            title={
              <div>
                <Space className="warning-container">
                  <WarningFilled />
                  Disable offer
                </Space>
              </div>
            }
            centered
            closeIcon={<CloseCircleFilled className="close-btn" />}
            open={openDisableOffer}
            okButtonProps={{ type: 'primary', danger: 'danger' }}
            cancelButtonProps={{ className: 'no-display' }}
            okText="Disable offer"
            onOk={() => {
              toggleEnabled(currentSelectedIndex);
              setOpenDisableOffer(false);
            }}
            onCancel={() => setOpenDisableOffer(false)}
          >
            <Divider />
            <p>Are you sure you want to disable this offer?</p>
            <Divider />
          </Modal>
          {/* CREATE MODAL */}
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            className="create-test-modal"
            centered
            title={isUpdateOffer ? offerName : 'Add Timely Offer'}
          >
            <Divider />
            <Form
              form={form}
              onFinish={isUpdateOffer ? updateOfferForm : createTimelyOfferForm}
              className="equipment-form"
              layout="vertical"
            >
              <div className="equipment-form-control timely-offers">
                <Form.Item
                  rules={[required]}
                  name="title"
                  label="Timely offer title"
                >
                  <Input
                    className="inp-equiment"
                    name="equipment"
                    type="text"
                  />
                </Form.Item>
                <Form.Item name="upload">
                  <div className="profile-test timely-uploader">
                    <Upload
                      name="upload-banner "
                      onChange={onIconUpload}
                      accept="image/*"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                    >
                      {uploadedImage && !uploadingImage && (
                        <>
                          <img
                            src={uploadedImage}
                            className="sample-img "
                            alt="xyz"
                          />
                          <p>Re-Upload offer image</p>
                        </>
                      )}
                      {!uploadedImage && !uploadingImage && (
                        <span className="upload-banner-text">
                          <p>Upload Offer Image</p>
                        </span>
                      )}
                      {uploadingImage && <LoadingOutlined />}
                    </Upload>
                  </div>
                </Form.Item>
                {!isUpdateOffer && (
                  <div className="discount-items timely-radio">
                    <Radio.Group onChange={discountChange} value={offerType}>
                      <Radio value="CATEGORY">CATEGORY</Radio>
                      <Radio value="TEST">TEST</Radio>
                    </Radio.Group>
                  </div>
                )}
                {!isUpdateOffer && (
                  <div>
                    {offerType === 'CATEGORY' ? (
                      <div>
                        <div className="tests-modal-search">
                          <SearchComponent
                            name="Categories"
                            debounceTime={DEBOUNCE_TIME}
                            getData={fetchCategories}
                          />
                          {!isFetchingCategories && showingCategoryResults && (
                            <div className="search-result" id="test-searcher">
                              {fetchedCategories.length <= 0
                                ? null
                                : fetchedCategories.map((currentCategory) => {
                                    const { isSelected, category } =
                                      currentCategory;
                                    return (
                                      <div
                                        className="tests-list"
                                        key={category.id}
                                      >
                                        <Typography.Text>
                                          {category.name}
                                        </Typography.Text>
                                        {isSelected ? (
                                          <Button
                                            type="text"
                                            onClick={() => {
                                              const categories =
                                                selectedCategories.slice();
                                              for (
                                                let i = 0;
                                                i < categories.length;
                                                i += 1
                                              ) {
                                                if (
                                                  categories[i].id ===
                                                  category.id
                                                ) {
                                                  categories.splice(i, 1);
                                                }
                                              }
                                              const tempFetchedCategories =
                                                fetchedCategories.slice();
                                              for (
                                                let i = 0;
                                                i <
                                                tempFetchedCategories.length;
                                                i += 1
                                              ) {
                                                if (
                                                  tempFetchedCategories[i]
                                                    .category.id === category.id
                                                ) {
                                                  tempFetchedCategories[i] = {
                                                    ...tempFetchedCategories[i],
                                                    isSelected: false
                                                  };
                                                  break;
                                                }
                                              }
                                              setSelectedCategories(categories);
                                              setFetchedCategories(
                                                tempFetchedCategories
                                              );
                                              setShowingCategoryResults(false);
                                            }}
                                          >
                                            Remove
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={() => {
                                              selectedCategories[0] = category;
                                              setShowingCategoryResults(false);
                                            }}
                                            type="default"
                                          >
                                            Add
                                          </Button>
                                        )}
                                      </div>
                                    );
                                  })}
                            </div>
                          )}
                        </div>
                        <div className="selected-test-container timely-selected-container">
                          {selectedCategories.length <= 0 ? (
                            <p>Select any category</p>
                          ) : (
                            selectedCategories.map((currentCategory) => {
                              const { id, name } = currentCategory;
                              return (
                                <div className="tests-list" key={id}>
                                  <Typography.Text>{name}</Typography.Text>
                                  <Button
                                    type="text"
                                    onClick={() => {
                                      const categories =
                                        selectedCategories.slice();
                                      for (
                                        let i = 0;
                                        i < categories.length;
                                        i += 1
                                      ) {
                                        if (categories[i].id === id) {
                                          categories.splice(i, 1);
                                        }
                                      }
                                      setSelectedCategories(categories);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="tests-modal-search">
                          <SearchComponent
                            name="Tests"
                            debounceTime={DEBOUNCE_TIME}
                            getData={fetchLiveTests}
                          />

                          {!isFetchingTests && showingTestResults && (
                            <div className="search-result" id="test-searcher">
                              {fetchedTests.length <= 0
                                ? null
                                : fetchedTests.map((currentTest) => {
                                    const { isSelected, test } = currentTest;
                                    return (
                                      <div className="tests-list" key={test.id}>
                                        <Typography.Text>
                                          {test.title}
                                        </Typography.Text>
                                        {isSelected ? (
                                          <Button
                                            type="text"
                                            onClick={() => {
                                              const tests =
                                                selectedTests.slice();
                                              for (
                                                let i = 0;
                                                i < tests.length;
                                                i += 1
                                              ) {
                                                if (tests[i].id === test.id) {
                                                  tests.splice(i, 1);
                                                }
                                              }
                                              const tempFetchedTests =
                                                fetchedTests.slice();
                                              for (
                                                let i = 0;
                                                i < tempFetchedTests.length;
                                                i += 1
                                              ) {
                                                if (
                                                  tempFetchedTests[i].test
                                                    .id === test.id
                                                ) {
                                                  tempFetchedTests[i] = {
                                                    ...tempFetchedTests[i],
                                                    isSelected: false
                                                  };
                                                  break;
                                                }
                                              }
                                              setSelectedTests(tests);
                                              setFetchedTests(tempFetchedTests);
                                            }}
                                          >
                                            Remove
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={() => {
                                              selectedTests[0] = test;
                                              setShowingTestResults(false);
                                            }}
                                            type="default"
                                          >
                                            Add
                                          </Button>
                                        )}
                                      </div>
                                    );
                                  })}
                            </div>
                          )}
                        </div>
                        <div className="selected-test-container">
                          {selectedTests.length <= 0 ? (
                            <p>Select any test</p>
                          ) : (
                            selectedTests.map((currentTest) => {
                              const { id, title } = currentTest;
                              return (
                                <div className="tests-list" key={id}>
                                  <Typography.Text>{title}</Typography.Text>
                                  <Button
                                    type="text"
                                    onClick={() => {
                                      const tests = selectedTests.slice();
                                      for (
                                        let i = 0;
                                        i < tests.length;
                                        i += 1
                                      ) {
                                        if (tests[i].id === id) {
                                          tests.splice(i, 1);
                                        }
                                      }
                                      setSelectedTests(tests);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="bottom-timely">
                  <div className="timely-date">
                    <Form.Item
                      rules={[required]}
                      name="startDate"
                      label="Start date?"
                    >
                      <DatePicker
                        format={dateFormat}
                        onChange={(value) => setTodaysDate(value)}
                      />
                    </Form.Item>
                  </div>
                  <div className="timely-date">
                    <Form.Item
                      rules={[required]}
                      name="validTill"
                      label="Till what date it is valid?"
                    >
                      <DatePicker
                        format={dateFormat}
                        disabledDate={(value) =>
                          new Date(todaysDate + 24 * 60 * 60 * 1000) > value
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <Divider />
                <div className="banner-align">
                  <Button
                    loading={isSavingData}
                    htmlType="submit"
                    className="create-btn-modal banner-btn"
                  >
                    {isUpdateOffer ? 'Update' : '   Create'}
                  </Button>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </Header>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={timelyOffers}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchTimelyOffers({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
        {/* <Table columns={columns} /> */}
      </div>
    </div>
  );
}
export default TimelyOffers;
