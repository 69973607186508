import { gql } from '@apollo/client';

export const GET_MESSAGES = gql`
  query getNotifications($filters: GetBannerInput!) {
    getNotifications(filters: $filters) {
      total
      notifications {
        id
        title
        description
        imageUrl
        buttonTitle
        linkedTest
        scheduledNotification {
          sendAt
        }
      }
    }
  }
`;

export const GET_SCHEDULED_NOTIFICATION_DETAILS = gql`
  query getScheduledNotification(
    $notificationId: String!
    $filters: GetAllCustomerInput
  ) {
    getScheduledNotification(
      notificationId: $notificationId
      filters: $filters
    ) {
      notifications {
        id
        notification {
          id
          title
          description
          buttonTitle
          linkedTest
        }
        sendAt
        status
        sendTo
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
