import { Layout } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import ContentRoutes from './ContentRoutes';
import './app.less';
import LabSidebar from './components/LabSideBar/LabSidebar';
import AppSidebar from './components/appSideBar/AppSidebar';

function App({ user, setUser }) {
  const breakpoint = {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px'
  };
  let isLab = false;
  if (user && user.role === 'OPERATOR') {
    isLab = true;
  }

  return (
    <div className="app-content">
      <Layout>
        <Sider breakpoint={breakpoint} width={224} theme="light">
          {isLab ? (
            <LabSidebar user={user} setUser={setUser} />
          ) : (
            <AppSidebar user={user} setUser={setUser} />
          )}
        </Sider>
        <Content>
          <ContentRoutes user={user} />
        </Content>
      </Layout>
    </div>
  );
}

export default App;
