/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
import { StarFilled, StarOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Empty,
  Form,
  Modal,
  Popconfirm,
  Space,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../../../common/components/CommonTable';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import SearchComponent from '../../../../common/components/SearchDebounce';
import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT
} from '../../../../common/constants';
import { clearSearchField } from '../../../../common/utils';
import {
  addRemoveAddons,
  getALlAddons,
  getLiveTestsAndPackages,
  removeAddon,
  toggleFeaturedAddon
} from '../../test-repository';

const limit = GLOBAL_PAGINATION_LIMIT;
function TestAddons() {
  const [form] = Form.useForm();
  const [loadingData, setLoadingData] = useState(true);
  const [isSavingData, setIsSavingData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showingTestResults, setShowingTestResults] = useState(false);
  const [addons, setAddons] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [fetchedTests, setFetchedTests] = useState([]);
  const [isFetchingTests, setIsFetchingTests] = useState(false);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);

  const fetchLiveTests = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getLiveTestsAndPackages({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { tests } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < tests.length; i += 1) {
        const test = tests[i];
        const { id } = test;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedTests.length; j += 1) {
          if (selectedTests[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          test
        });
      }
      setFetchedTests(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetchingTests(false);
  };

  const fetchAddons = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getALlAddons({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setAddons(response.addOnsTests.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchAddons({
      currentQuery: '',
      currentSkip: currentGlobalSkip
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TOGGLE ADDON

  const toggleFeatured = async (index) => {
    addons[index] = {
      ...addons[index],
      isFeatured: !addons[index].isFeatured
    };
    await toggleFeaturedAddon(addons[index].id);
    setAddons(addons.slice());
  };

  // REMOVE ADDON

  const removeAddonFromList = async (addonId, index) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Removing addon'
    });
    await removeAddon(addonId);
    const updatedAddons = addons.slice();
    updatedAddons.splice(index, 1);
    message.open({
      key,
      type: 'success',
      content: 'Addon removed'
    });
    setAddons(updatedAddons);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'isFeatured',
      key: 'isFeatured',

      render: (value, record, index) => {
        return value ? (
          <StarFilled
            className="filled-star"
            onClick={() => {
              toggleFeatured(index);
            }}
          />
        ) : (
          <StarOutlined
            className={`outlined-star${
              record.isDisabled ? ' disable-action' : ''
            }`}
            onClick={() => {
              if (record.isDisabled) {
                return;
              }
              toggleFeatured(index);
            }}
          />
        );
      }
    },
    {
      title: '#',
      dataIndex: 'serialNumber',
      key: 'serialNumber',

      render: (value) => {
        return value;
      }
    },

    {
      title: 'Test',
      dataIndex: 'title',
      key: 'title',

      render: (value) => {
        return value;
      }
    },
    {
      title: '',
      dataIndex: 'isFeatured',
      key: 'isFeatured',

      render: (value, record, index) => {
        return (
          <Popconfirm
            title="Remove"
            description="Are you sure to remove this addon?"
            onConfirm={() => {
              removeAddonFromList(record.id, index);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button className="delete-btn-banner">Remove</Button>
          </Popconfirm>
        );
      }
    }
  ];

  const handleCancel = () => {
    clearSearchField('Tests');
    setIsModalOpen(false);
  };

  // FORM SUBMITTING UPDATE FORM

  // FORM SUBMITTING CREATE FORM
  const onSubmitCreateCategory = async () => {
    const testsId = [];
    if (selectedTests.length === 0) {
      message.error('Select at least one test');
      return;
    }
    setIsSavingData(true);
    for (let i = 0; i < selectedTests.length; i += 1) {
      testsId.push(selectedTests[i].id);
    }
    await addRemoveAddons(testsId);
    fetchAddons({
      currentQuery: '',
      currentSkip: currentGlobalSkip
    });
    setIsSavingData(false);
    setSelectedTests([]);
    clearSearchField('Tests');
    setIsModalOpen(false);
  };

  const handleAddRemoveAddon = () => {
    setLoadingData(true);
    setIsModalOpen(true);
    setShowingTestResults(false);
    setLoadingData(false);
  };

  return (
    <div>
      <div className="content-wrapper">
        <Header className="main-header">
          <div className="header-spacer">
            <Space>
              <Typography.Text className="main-header-title">
                Add-ons
              </Typography.Text>
              <SearchComponent
                name="Add-ons"
                debounceTime={DEBOUNCE_TIME}
                getData={(value) => {
                  setQuery(value);
                  setLoadingData(true);
                  fetchAddons({
                    currentQuery: value,
                    currentSkip: currentGlobalSkip
                  });
                }}
              />
            </Space>
            <Button onClick={handleAddRemoveAddon} className="create-btn">
              Add Add-ons
            </Button>
            {/* CREATE MODAL */}
            <Modal
              className="create-test-modal"
              centered
              title="Add Add-ons"
              open={isModalOpen}
              onCancel={handleCancel}
            >
              <Divider />
              <Form
                onFinish={onSubmitCreateCategory}
                form={form}
                layout="vertical"
              >
                {/* <div className="test-available">

                </div> */}
                <div className="tests-modal-search">
                  <SearchComponent
                    name="Tests"
                    debounceTime={DEBOUNCE_TIME}
                    getData={fetchLiveTests}
                  />

                  {!isFetchingTests && showingTestResults && (
                    <div className="search-result" id="test-searcher">
                      {fetchedTests.length <= 0 ? (
                        <Empty />
                      ) : (
                        fetchedTests.map((currentTest) => {
                          const { isSelected, test } = currentTest;
                          const alreadyPresentId = addons.some((val) => {
                            const { test: addonTest } = val;
                            return addonTest.id === test.id;
                          });

                          return (
                            <div className="tests-list" key={test.id}>
                              <Typography.Text>{test.title}</Typography.Text>
                              {isSelected ? (
                                <Button
                                  type="text"
                                  onClick={() => {
                                    const tests = selectedTests.slice();
                                    for (let i = 0; i < tests.length; i += 1) {
                                      if (tests[i].id === test.id) {
                                        tests.splice(i, 1);
                                      }
                                    }
                                    const tempFetchedTests =
                                      fetchedTests.slice();
                                    for (
                                      let i = 0;
                                      i < tempFetchedTests.length;
                                      i += 1
                                    ) {
                                      if (
                                        tempFetchedTests[i].test.id === test.id
                                      ) {
                                        tempFetchedTests[i] = {
                                          ...tempFetchedTests[i],
                                          isSelected: false
                                        };
                                        break;
                                      }
                                    }
                                    setSelectedTests(tests);
                                    setFetchedTests(tempFetchedTests);
                                  }}
                                >
                                  Remove
                                </Button>
                              ) : alreadyPresentId ? null : (
                                <Button
                                  onClick={() => {
                                    const tests = selectedTests.slice();
                                    tests.push(test);
                                    const tempFetchedTests =
                                      fetchedTests.slice();
                                    for (
                                      let i = 0;
                                      i < tempFetchedTests.length;
                                      i += 1
                                    ) {
                                      if (
                                        tempFetchedTests[i].test.id === test.id
                                      ) {
                                        tempFetchedTests[i] = {
                                          ...tempFetchedTests[i],
                                          isSelected: true
                                        };
                                        break;
                                      }
                                    }
                                    setSelectedTests(tests);
                                    setFetchedTests(tempFetchedTests);
                                  }}
                                  type="default"
                                >
                                  Add
                                </Button>
                              )}
                            </div>
                          );
                        })
                      )}
                    </div>
                  )}
                </div>

                <div className="selected-test-container category-container">
                  {selectedTests.length <= 0 ? (
                    <Empty />
                  ) : (
                    selectedTests.map((currentTest) => {
                      const { id, title } = currentTest;
                      return (
                        <div className="tests-list" key={id}>
                          <Typography.Text>{title}</Typography.Text>
                          <Button
                            type="text"
                            onClick={() => {
                              const tests = selectedTests.slice();
                              for (let i = 0; i < tests.length; i += 1) {
                                if (tests[i].id === id) {
                                  tests.splice(i, 1);
                                }
                              }
                              setSelectedTests(tests);
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      );
                    })
                  )}
                </div>
                <Divider />
                <div className="create-wrapper">
                  <Button
                    htmlType="submit"
                    loading={isSavingData}
                    className="create-btn-modal"
                  >
                    Add
                  </Button>
                </div>
              </Form>
            </Modal>
          </div>
        </Header>
        <div className="main-content test-categories-content">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              data={addons}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchAddons({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default TestAddons;
