import { Avatar, Badge, Menu, Popconfirm, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './sidebar.less';

// LOGO
import { LogoutOutlined } from '@ant-design/icons';
import { BsCartFill, BsClipboard2Pulse, BsLink45Deg } from 'react-icons/bs';
import profile from '../../../assets/fakeProfile.svg';
import logo from '../../../assets/loginLogo.svg';

// Components Icons svg
import { ReactComponent as Configurations } from '../../../assets/icons/configurations.svg';
import { ReactComponent as Customers } from '../../../assets/icons/customers.svg';
import { ReactComponent as Dashboard } from '../../../assets/icons/dashboard.svg';
import { ReactComponent as DoctorIcon } from '../../../assets/icons/doctor-tab.svg';
import { ReactComponent as Ellipse } from '../../../assets/icons/ellipse.svg';
import { ReactComponent as Home } from '../../../assets/icons/home.svg';
import { ReactComponent as Notifications } from '../../../assets/icons/notification.svg';
import { ReactComponent as Offers } from '../../../assets/icons/offers.svg';
import { ReactComponent as Prescriptions } from '../../../assets/icons/prescriptions.svg';
import { ReactComponent as Reports } from '../../../assets/icons/reports.svg';
import { ReactComponent as Testimonials } from '../../../assets/icons/testimonials.svg';
import { ReactComponent as Tests } from '../../../assets/icons/tests.svg';
import { ReactComponent as Timesheet } from '../../../assets/icons/timesheet.svg';
import { ReactComponent as Users } from '../../../assets/icons/users.svg';
import { getLiveDataCount } from '../../../common/common-repository';
import { signOutAdmin } from '../../../modules/auth/auth-repository';

function AppSidebar({ user }) {
  const isSystemType = user.role;
  // Navigate
  const navigate = useNavigate();

  // STATES
  const [activePrescriptionCount, setActivePrescriptionCount] = useState(0);
  const [activeReportsCount, setActiveReportsCount] = useState(0);
  const [activeCartCount, setActiveCartCount] = useState(0);

  let intervalId;

  const fetchLiveDataCount = async () => {
    try {
      //
      const response = await getLiveDataCount();
      setActiveReportsCount(response.activeReportCount);
      setActivePrescriptionCount(response.prescriptionsCount);
      setActiveCartCount(response.customerCartCount);
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    fetchLiveDataCount();
    if (intervalId) {
      clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    intervalId = setInterval(() => {
      fetchLiveDataCount();
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-sidebar">
      <div className="sidebar-logo">
        <img src={logo} alt="logo" />
      </div>
      {isSystemType !== 'ADMIN' ? (
        <Menu
          onClick={(item) => {
            navigate(item.key);
          }}
          // eslint-disable-next-line no-undef
          defaultSelectedKeys={[window.location.pathname]}
          // eslint-disable-next-line no-undef
          selectedKeys={[window.location.pathname]}
          mode="inline"
          items={[
            {
              label: 'Dashboard',
              key: '/',
              icon: <Dashboard />
            },
            {
              label: 'Customers',
              key: '/customers/1',
              icon: <Customers />
            },
            {
              label: 'Home',
              key: '/home',
              icon: <Home />,
              children: [
                {
                  label: 'Banners',
                  key: '/home/banners',
                  icon: <Ellipse />
                },
                {
                  label: 'CTA Banners',
                  key: '/home/cta-banners',
                  icon: <Ellipse />
                },
                {
                  label: 'Timely Offers',
                  key: '/home/timely-offers',
                  icon: <Ellipse />
                },
                {
                  label: 'Rule Based',
                  key: '/home/rule-based',
                  icon: <Ellipse />
                },
                {
                  label: 'Pin-codes',
                  key: '/home/pin-codes',
                  icon: <Ellipse />
                }
              ]
            },
            {
              label: 'Tests',
              key: '/tests',
              icon: <Tests />,
              children: [
                {
                  label: 'All tests',
                  key: '/test/all-tests',
                  icon: <Ellipse />
                },
                {
                  label: 'Categories',
                  key: '/test/categories',
                  icon: <Ellipse />
                },
                {
                  label: 'Equipments',
                  key: '/test/equipments',
                  icon: <Ellipse />
                },
                {
                  label: 'Samples',
                  key: '/test/samples',
                  icon: <Ellipse />
                },
                {
                  label: 'Add-ons',
                  key: '/test/addons',
                  icon: <Ellipse />
                }
              ]
            },
            {
              label: (
                <span className="prescription-count">
                  Reports
                  <Badge
                    count={activeReportsCount}
                    overflowCount={activeReportsCount}
                    size="small"
                  />
                </span>
              ),
              key: '/reports/active/1',
              icon: <Reports />
            },
            {
              label: 'Labs',
              key: '/labs',
              icon: <Home />
            },
            {
              label: (
                <span className="prescription-count">
                  Prescriptions
                  <Badge
                    count={activePrescriptionCount}
                    overflowCount={activePrescriptionCount}
                    size="small"
                  />
                </span>
              ),
              key: '/prescriptions',
              icon: <Prescriptions />
            },
            {
              label: 'Offers',
              key: '/offers',
              icon: <Offers />
            },
            {
              label: 'Users',
              key: '/users',
              icon: <Users />
            },
            {
              label: 'Time sheet',
              key: '/time-sheet',
              icon: <Timesheet />
            },
            {
              label: 'Testimonials',
              key: '/testimonials',
              icon: <Testimonials />
            },

            {
              label: 'Notifications',
              key: '/messages',
              icon: <Notifications />
            },
            {
              label: (
                <span className="prescription-count">
                  Added Carts
                  <Badge
                    count={activeCartCount}
                    overflowCount={activeCartCount}
                    size="small"
                  />
                </span>
              ),
              key: '/added-carts',
              icon: <BsCartFill size={20} color="white" />
            },
            {
              label: 'Template Address',
              key: '/template-address',
              icon: <Home />
            },
            {
              label: 'Doctors',
              key: '/doctors',
              icon: <DoctorIcon />
            },
            {
              label: 'Risk Assessment',
              key: '/risk-assessment',
              icon: <BsClipboard2Pulse size={20} />,
              children: [
                {
                  label: 'Categories',
                  key: '/disease-categories',
                  icon: <Ellipse />
                },
                {
                  label: 'Diseases',
                  key: '/diseases/1',
                  icon: <Ellipse />
                }
              ]
            },
            {
              label: 'Deep Link Monitor',
              key: '/deep-link-monitor',
              icon: <BsLink45Deg size={25} />
            },
            {
              label: 'Configurations',
              key: '/configurations',
              icon: <Configurations />
            }
          ]}
        />
      ) : (
        <Menu
          onClick={(item) => {
            navigate(item.key);
          }}
          // eslint-disable-next-line no-undef
          defaultSelectedKeys={[window.location.pathname]}
          // eslint-disable-next-line no-undef
          selectedKeys={[window.location.pathname]}
          mode="inline"
          items={[
            {
              label: 'Customers',
              key: '/customers/1',
              icon: <Customers />
            },

            {
              label: 'Testimonials',
              key: '/testimonials',
              icon: <Testimonials />
            },

            {
              label: 'Notifications',
              key: '/messages',
              icon: <Notifications />
            }
          ]}
        />
      )}
      <div className="profile-content-wrapper">
        <Space size={10} className="profile-content">
          <Avatar src={profile} size="large" />
          <Typography.Text>{user.name}</Typography.Text>
          <Popconfirm
            title="Logout"
            description="Are you sure to logout from system?"
            onConfirm={async () => {
              await signOutAdmin();
              // eslint-disable-next-line no-restricted-globals, no-undef
              location.href = '/';
            }}
            okText="Yes"
            cancelText="No"
          >
            <LogoutOutlined className="logout-icon" />
          </Popconfirm>
        </Space>
      </div>
    </div>
  );
}

export default AppSidebar;
