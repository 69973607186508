/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-danger */
/* eslint-disable no-console */
import {
  ArrowRightOutlined,
  ReloadOutlined,
  StarFilled,
  StarOutlined
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Modal,
  Tag,
  Tooltip,
  Typography,
  message
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../../../../common/components/CommonTable';
import LoaderComponent from '../../../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';
import {
  getAllLiveTests,
  getTestChangeLogs,
  toggleFeaturedTest
} from '../../../test-repository';

function LiveTests({
  openUpdateTestModel,
  parentWantToLoad,
  reloadData,
  query,
  forceToRefetch
}) {
  // STATES
  const limit = GLOBAL_PAGINATION_LIMIT;
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [liveTestsData, setLiveTestsData] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [globalCurrentSkip, setGlobalCurrentSkip] = useState();
  const [historyModal, setHistoryModal] = useState(false);
  const [titleName, setTitleName] = useState('');
  const [allLogs, setAllLogs] = useState([]);

  const toggleFeatured = async (index) => {
    liveTestsData[index] = {
      ...liveTestsData[index],
      isFeatured: !liveTestsData[index].isFeatured
    };
    toggleFeaturedTest(liveTestsData[index].id);
    setLiveTestsData(liveTestsData.slice());
  };
  const fetchAllLiveTests = async ({ currentSkip, sort }) => {
    const payload = {
      skip: currentSkip,
      limit: limit,
      query: query.trim(),
      sort
    };

    try {
      setGlobalCurrentSkip(currentSkip);
      const response = await getAllLiveTests(payload);
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setLiveTestsData(response.tests.slice());
    } catch (error) {
      //
    }

    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchAllLiveTests({
      currentSkip: globalCurrentSkip || 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData, query, forceToRefetch]);

  // HISTORY MODAL FUNCTION
  const openHistoryModal = async (orderId, record) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Fetching logs...'
    });

    const response = await getTestChangeLogs(
      {
        skip: 0,
        limit: 1000000
      },
      orderId
    );

    setAllLogs(response.logs);
    message.open({
      key,
      type: 'success',
      content: 'Logs fetched!'
    });
    setHistoryModal(true);
    setTitleName(record.title);
  };

  const handleCancel = () => {
    setHistoryModal(false);
  };

  const handlePageChange = (page) => {
    // Use the navigate function to update the URL
    navigate(`/test/all-tests/${page}`);
  };

  const openInNewTab = (data) => {
    const { dynamicLink } = data;
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(dynamicLink).then(() => {
      message.success('Link copied to clipboard');
    });
  };

  const columns = [
    {
      title: '',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
      width: 50,
      align: 'center',
      render: (value, record, index) => {
        return value ? (
          <StarFilled
            className="filled-star"
            onClick={() => {
              toggleFeatured(index);
            }}
          />
        ) : (
          <StarOutlined
            className={`outlined-star${
              record.archive ? ' disable-action' : ''
            }`}
            onClick={() => {
              if (record.archive) {
                return;
              }
              // if (!record.thumbnailUrl) {
              //   message.error('Thumbnail is required');
              //   return;
              // }
              toggleFeatured(index);
            }}
          />
        );
      }
    },
    {
      title: '#',
      dataIndex: 'serialNumber',
      key: '1',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Test',
      dataIndex: 'title',
      key: '2',
      render: (value, record) => {
        if (value.length < 20) {
          return (
            <Typography.Text
              className="clickable-title"
              onClick={() => {
                openUpdateTestModel(record.id);
              }}
            >
              {value}
            </Typography.Text>
          );
        }
        {
          return (
            <Typography.Text
              className="clickable-title"
              onClick={() => {
                openUpdateTestModel(record.id);
              }}
            >
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 20)}...
              </Tooltip>
            </Typography.Text>
          );
        }
      }
    },
    {
      title: 'Samples',
      dataIndex: 'samples',
      key: '3',
      render: (value) => {
        const sampleImg = [];
        for (let j = 0; j < value.length; j += 1) {
          sampleImg.push(
            <img
              className="sampleImg"
              alt={value[j].id}
              size="small"
              src={value[j].thumbnail}
            />
          );
        }
        return sampleImg;
      }
    },
    {
      title: ' Equipments',
      dataIndex: 'equipmentsRequired',
      key: '4',
      render: (value) => {
        const equipmentsTitle = [];
        const limitLength = value.length <= 2 ? value.length : 2;
        for (let i = 0; i < limitLength; i += 1) {
          equipmentsTitle.push(
            <Tag className="title-tag">
              <Tag color="green" className="count-tag">
                {value[i].requiredQuantity}
              </Tag>
              {value[i].equipment.name}
            </Tag>
          );
        }
        const remainingValue = value.length - 2;
        return remainingValue <= 0 ? (
          <div>{equipmentsTitle}</div>
        ) : (
          <div>
            {equipmentsTitle} <Tag color="blue">+{remainingValue}</Tag>
          </div>
        );
      }
    },

    {
      title: 'Total amt',
      dataIndex: 'price',
      key: '5',
      render: (value) => {
        return `₹ ${value}`;
      }
    },
    {
      title: 'Offers',
      dataIndex: 'discount',
      key: '6',
      render: (value, record) => {
        return (
          <span>
            {value} {record.kind === 'PERCENTAGE' ? '% OFF' : '₹ FLAT OFF'}
          </span>
        );
      }
    },
    {
      title: 'Last updated',
      dataIndex: 'updatedAt',
      key: '7',
      render: (value) => {
        const date = new Date(Number(value));
        const newDate = formatDate(date, 'DD/MM/YYYY');
        return newDate;
      }
    },
    {
      title: 'No. Of times booked',
      dataIndex: 'noOfTimesBooked',
      key: '8',
      sorter: true
    },
    {
      title: '',
      dataIndex: 'serialNumber',
      key: '9',
      render: (value, record) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px'
            }}
          >
            <Button
              type="text"
              onClick={() => {
                openHistoryModal(record.id, record);
              }}
            >
              <ReloadOutlined />
            </Button>
            <Button
              type="text"
              onClick={() => {
                openInNewTab(record);
              }}
            >
              <AiOutlineShareAlt size={20} />
            </Button>
          </div>
        );
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: '10',
      render: (value) => {
        return (
          <ArrowRightOutlined
            onClick={() => handlePageChange(value)}
            style={{
              cursor: 'pointer',
              fontSize: '20px'
            }}
          />
        );
      }
    }
  ];

  return (
    <div className="sample-table">
      {/* CREATE MODAL */}
      <Modal
        open={historyModal}
        onCancel={handleCancel}
        className="create-test-modal"
        centered
        title={`${titleName || null} > Change History`}
      >
        <Divider />
        {allLogs
          ? allLogs.map((log) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <div className="history-tab">
                  <div className="left-side">
                    <div className="line" />
                    <p dangerouslySetInnerHTML={{ __html: log.message }} />
                    <span>
                      by <b>{log.actionBy.name}</b>
                    </span>
                  </div>
                  <div className="right-side">
                    <p>
                      {moment(allLogs.createdAt).format('DD/MM/YY')} |{' '}
                      {moment(allLogs.createdAt).format('HH:MM A')}
                    </p>
                  </div>
                </div>
              );
            })
          : null}
      </Modal>
      {loading ? (
        <LoaderComponent />
      ) : (
        <CommonTable
          columns={columns}
          rowKey={(record) => record.id}
          loadingData={parentWantToLoad || loadingData}
          paginationConfig={paginationProp}
          data={liveTestsData}
          onChange={(onChange, filters, sorter) => {
            // eslint-disable-next-line no-console
            setLoadingData(true);
            fetchAllLiveTests({
              currentSkip: (onChange.current - 1) * limit,
              sort: {
                key: sorter.field,
                value: sorter.order
              }
            });
          }}
        />
      )}
    </div>
  );
}

export default LiveTests;
