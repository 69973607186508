import { gql } from '@apollo/client';

export const GET_SAMPLE_COLLECTED_ORDERS = gql`
  query getSampleCollectedOrders($filters: GetOrdersInput) {
    getSampleCollectedOrders(filters: $filters) {
      total
      orders {
        id
        scheduledAt
        sampleCollectedAt
        sampleSubmitTo {
          id
          name
          displayName
        }
        orderId
        patient {
          name
          age
          sex
          id
        }
        requiredSamples {
          sample {
            id
            name
          }
          linkedEquipment
        }
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
