import { gql } from '@apollo/client';

export const GET_RULE_BASED_OFFERS = gql`
  query getRuleBasedOfferAsAdmin($filters: GetAllCustomerInput) {
    getRuleBasedOfferAsAdmin(filters: $filters) {
      offers {
        id
        serialNumber
        title
        kind
        value
        isFeatured
      }
      total
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
