import { gql } from '@apollo/client';

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;

export const GET_ALL_DEEP_LINKS = gql`
  query GetAllDeepLinks($filters: GetDeepLinksInput) {
    getAllDeepLinks(filters: $filters) {
      deepLinks {
        id
        link
        description
        clickCount
        serialNumber
        order
        createdAt
        updatedAt
      }
      total
    }
  }
`;
