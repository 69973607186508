import { gql } from '@apollo/client';

// CREATE EQUIPMENT
export const CREATE_LAB = gql`
  mutation createLab(
    $name: String!
    $displayName: String!
    $address: String!
    $countryCode: String!
    $mobileNumber: String!
    $thumbnail: String
  ) {
    createLab(
      data: {
        name: $name
        displayName: $displayName
        address: $address
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        thumbnail: $thumbnail
      }
    ) {
      lab {
        id
        name
        displayName
        address
        countryCode
        mobileNumber
        thumbnail
      }
    }
  }
`;

export const UPDATE_LAB = gql`
  mutation updateLab(
    $name: String!
    $displayName: String!
    $address: String!
    $countryCode: String!
    $mobileNumber: String!
    $thumbnail: String
    $labId: String!
  ) {
    updateLab(
      data: {
        name: $name
        displayName: $displayName
        address: $address
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        thumbnail: $thumbnail
      }
      labId: $labId
    ) {
      lab {
        id
        name
        displayName
        address
        countryCode
        mobileNumber
        thumbnail
      }
    }
  }
`;

export const ENABLE_MOBILE_LAB = gql`
  mutation toggleIsFeatureLab($labId: String!) {
    toggleIsFeatureLab(labId: $labId)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
