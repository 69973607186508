import { gql } from '@apollo/client';

export const UPLOAD_DOCUMENT_IMAGE = gql`
  mutation uploadUserDocument($fileName: String!) {
    uploadUserDocument(fileName: $fileName) {
      signedUrl
      getUrl
      fileName
    }
  }
`;

export const ADD_SYSTEM_USER = gql`
  mutation addSystemUser(
    $name: String!
    $role: SystemUserRole!
    $email: String!
    $countryCode: String!
    $mobileNumber: String!
    $age: Int!
    $sex: Sex!
    $avatar: String!
    $docs: [UserDocumentsInput]!
  ) {
    addSystemUser(
      data: {
        name: $name
        role: $role
        email: $email
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        age: $age
        sex: $sex
        docs: $docs
        avatar: $avatar
      }
    ) {
      user {
        id
        email
        name
        avatar
        role
        age
        sex
        countryCode
        mobileNumber
        isDisabled
        isAvailable
        documents {
          title
          url
          type
        }
      }
    }
  }
`;

// UPDATE USER
export const UPDATE_USER = gql`
  mutation updateSystemUser(
    $name: String!
    $role: SystemUserRole!
    $email: String!
    $countryCode: String!
    $mobileNumber: String!
    $age: Int!
    $sex: Sex!
    $docs: [UserDocumentsInput]!
    $systemUserId: String!
    $avatar: String!
  ) {
    updateSystemUser(
      data: {
        name: $name
        avatar: $avatar
        role: $role
        email: $email
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        age: $age
        sex: $sex
        docs: $docs
      }
      systemUserId: $systemUserId
    ) {
      user {
        id
        email
        name
        avatar
        role
        age
        sex
        countryCode
        mobileNumber
        isDisabled
        isAvailable
        documents {
          title
          url
          type
        }
      }
    }
  }
`;

// TOGGLE ENABLE USER
export const TOGGLE_ENABLE_USER = gql`
  mutation toggleSystemUserEnable($systemUserId: String!) {
    toggleSystemUserEnable(systemUserId: $systemUserId) {
      result
    }
  }
`;

export const TOGGLE_USER_AVAILABILITY = gql`
  mutation toggleSystemUserAvailability($systemUserId: String!) {
    toggleSystemUserAvailability(systemUserId: $systemUserId) {
      result
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
