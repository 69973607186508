import client from '../apollo';
import { ADD_SYSTEM_USER } from '../modules/users/graphql/mutation';
import { UPLOAD_ICON, UPLOAD_PHOTO, UPLOAD_REPORT } from './graphql/mutation';
import { GET_DATA_COUNT, GET_FILE_SIGNED_URL } from './graphql/query';

export const postUploadIcon = async (fileName) => {
  try {
    const response = await client.mutate({
      mutation: UPLOAD_ICON,
      fetchPolicy: 'network-only',
      variables: {
        fileName
      }
    });
    return response?.data?.uploadIcon;
  } catch (error) {
    return '';
  }
};

export const postUploadReport = async (fileName) => {
  try {
    const response = await client.mutate({
      mutation: UPLOAD_REPORT,
      fetchPolicy: 'network-only',
      variables: {
        fileName
      }
    });
    return response?.data?.uploadReportFile;
  } catch (error) {
    return '';
  }
};

export const postUploadPhoto = async (fileName) => {
  try {
    const response = await client.mutate({
      mutation: UPLOAD_PHOTO,
      fetchPolicy: 'network-only',
      variables: {
        fileName
      }
    });
    return response?.data?.uploadPhoto;
  } catch (error) {
    return '';
  }
};

export const getFileSignedUrl = async (filePath) => {
  try {
    const response = await client.query({
      query: GET_FILE_SIGNED_URL,
      fetchPolicy: 'network-only',
      variables: {
        filePath
      }
    });
    return response?.data?.getFileSignedUrl?.signedUrl;
  } catch (error) {
    return '';
  }
};

export const addSystemUser = async (data) => {
  try {
    const response = await client.mutate({
      mutation: ADD_SYSTEM_USER,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.addSystemUser?.user;
  } catch (error) {
    return false;
  }
};

export const getLiveDataCount = async () => {
  try {
    const response = await client.query({
      query: GET_DATA_COUNT,
      fetchPolicy: 'network-only'
    });
    return response?.data?.getLiveDataCount;
  } catch (error) {
    return '';
  }
};
