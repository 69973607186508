import { gql } from '@apollo/client';

export const HARD_REFRESH_OVERALL_DATA = gql`
  mutation refreshOverallDashboardData {
    refreshOverallDashboardData {
      totalCustomers
      totalOrders
      totalRevenue
      totalProductionCost
      totalConvenienceFees
      totalAppliedDiscount
      totalCoinsUsed
      totalFreeReports
      totalPaidReports
      totalDeletedAccounts
      totalMaleCustomers
      totalFemaleCustomers
      totalRepeatedCustomer
      totalMoreThanThreeOrdersRepeatedCustomer
      totalMoreThanFiveOrdersRepeatedCustomer
      totalPatient
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
