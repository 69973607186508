import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import App from './app/App';
import { ROUTES } from './common/constants';
import { authenticateUser, signOutAdmin } from './modules/auth/auth-repository';
import ForgotPassword from './modules/auth/forgot-pass/ForgotPassword';
import Login from './modules/auth/login/Login';

function AppRoutes() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  if (
    (user && user.role === 'DELIVERY_PARTNER') ||
    (user && user.role === 'SAMPLE_COLLECTION_PARTNER')
  ) {
    signOutAdmin().then(() => {
      // eslint-disable-next-line no-restricted-globals, no-undef
      location.href = ROUTES.LOGIN;
    });
  }

  useEffect(() => {
    setTimeout(async () => {
      authenticateUser().then((result) => {
        setUser(result);
        setIsLoading(false);
      });
    }, 800);
  }, []);
  if (isLoading) {
    return <div />;
  }
  return (
    <Routes>
      <Route
        path={ROUTES.LOGIN}
        element={
          <PublicRoutes user={user}>
            <Login setUser={setUser} />
          </PublicRoutes>
        }
      />
      <Route
        path={ROUTES.FORGOT_PASSWORD}
        element={
          <PublicRoutes user={user}>
            <ForgotPassword />
          </PublicRoutes>
        }
      />
      <Route
        path="/*"
        element={
          <PrivateRoute user={user}>
            <App user={user} setUser={setUser} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
export default AppRoutes;
