import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import {
  AiOutlineCloseCircle,
  AiOutlineDelete,
  AiOutlineEye
} from 'react-icons/ai';

import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Back } from '../../../assets/icons/back.svg';

import CommonTable from '../../../common/components/CommonTable';
import LoaderComponent from '../../../common/components/LoaderComponent';
import { formValidatorRules } from '../../../common/utils';
import {
  addQuestionToDisease,
  getDiseaseById,
  removeQuestionFromDIsease,
  updateQuestionToDisease
} from '../risk-repository';

function DiseaseQuestions() {
  const { required } = formValidatorRules;
  const [form] = Form.useForm();
  const { id: diseaseId } = useParams();
  const navigate = useNavigate();

  // STATES
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [allQuestions, setAllQuestions] = useState([]);
  const [fetchedDisease, setFetchedDisease] = useState(null);
  const [isSavingData, setIsSavingData] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [ansType, setAnsType] = useState('CHECKBOX');
  const [answers, setAnswers] = useState([{ id: Date.now() }]);
  // const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [questionId, setQuestionId] = useState('');
  const [isOptionsModal, setIsOptionsModal] = useState(false);
  const [singleQuestion, setSingleQuestion] = useState(null);

  const addAnswerField = () => {
    setAnswers([...answers, { id: Date.now() }]); // Add a new answer field
  };

  const removeAnswerField = (id) => {
    if (answers.length > 1) {
      // Create a new array without the removed answer
      const filteredArray = answers.filter((answer) => answer.id !== id);

      // Set the answers state to the filtered array
      setAnswers(filteredArray);

      // Reset the form fields for the removed answer
      // const indexToRemove = answers.findIndex((answer) => answer.id === id);
      // form.resetFields([`answer${indexToRemove + 1}`]);
    }
  };

  // FETCHING REMOTE CONFIG
  const fetchAllQuestions = async () => {
    try {
      const response = await getDiseaseById(diseaseId);
      const { diseases } = response;
      setFetchedDisease(diseases);
      setAllQuestions(diseases.questions);
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchAllQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setQuestionId('');
    setAnsType('CHECKBOX');
    setAnswers([{ id: Date.now() }]);
    setIsUpdateModal(false);
    setIsModalOpen(false);
  };

  const handleCancelOptions = () => {
    setIsOptionsModal(false);
    setSingleQuestion(null);
  };

  const handleAnsType = (e) => {
    setAnsType(e.target.value);
  };

  const getQuestionToUpdate = (value, record) => {
    setLoadingData(true);
    setQuestionId(value);
    setTimeout(() => {
      form.setFieldsValue({
        question: record.questionText
      });
      setAnsType(record.optionType);
      // Populate the answers in the form based on the options
      record.options.forEach((option) => {
        form.setFieldsValue({
          [`${option.id}`]: option.optionText // Set each answer dynamically
        });
      });

      // Set the number of answers in the state
      setAnswers(
        record.options.map((option) => ({
          id: option.id // Use option ID if you want to track specific options
        }))
      );
      setIsModalOpen(true);
      setLoadingData(false);
    }, 500);
  };

  // ANSWERS
  const getAllOptions = (record) => {
    setLoadingData(true);
    setTimeout(() => {
      setSingleQuestion(record);
      setIsOptionsModal(true);
      setLoadingData(false);
    }, 500);
  };

  const removeQuestionFromList = async (value, data) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Deleting question...'
    });

    try {
      const questionList = allQuestions.slice();
      const payload = {
        diseasesId: diseaseId,
        questionsIds: [data.id]
      };
      await removeQuestionFromDIsease(payload);
      const updatedArray = questionList.filter(
        (question) => question.id !== data.id
      );
      setAllQuestions(updatedArray);
      message.open({
        key,
        type: 'success',
        content: 'Question deleted'
      });
      // message.destroy(key);
    } catch (error) {
      message.open({
        key,
        type: 'error',
        content: 'Failed to delete question'
      });
      // message.destroy(key);
    }
  };

  const columns = [
    {
      title: 'Questions',
      dataIndex: 'questionText',
      key: '1'
    },

    {
      title: 'Answers',
      dataIndex: 'options',
      align: 'center',
      key: '2',
      render: (value, record) => {
        return (
          <AiOutlineEye
            className="btn-style"
            size={20}
            onClick={() => {
              setTimeout(() => {
                getAllOptions(record);
              }, 200);
            }}
          />
        );
      }
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      align: 'center',
      key: '3',
      render: (value, record) => {
        return (
          <div className="question-btn">
            <EditOutlined
              className="btn-style"
              size={20}
              onClick={() => {
                setIsUpdateModal(true);
                form.resetFields();
                // setCurrentSelectedIndex(index);
                setTimeout(() => {
                  getQuestionToUpdate(value, record);
                }, 200);
              }}
            />
            <AiOutlineDelete
              className="btn-style"
              size={20}
              color="red"
              onClick={() => removeQuestionFromList(value, record)}
            />
          </div>
        );
      }
    }
  ];

  const handleSubmit = async (formValues) => {
    const allAnswers = [];
    // Extract answers into the answers array
    // eslint-disable-next-line no-restricted-syntax
    for (const key in formValues) {
      if (!key.startsWith('question')) {
        allAnswers.push({ optionText: formValues[key] });
      }
    }

    const payload = {
      diseasesId: diseaseId,
      questions: [
        {
          questionText: formValues.question,
          options: allAnswers,
          optionType: ansType
        }
      ]
    };

    try {
      setIsSavingData(true);
      const responseData = await addQuestionToDisease(payload);
      const { questions } = responseData;
      setAllQuestions(questions);
      handleCancel();
      setIsSavingData(false);
    } catch (error) {
      setIsSavingData(false);
      message.error('Failed to add question');
    }
  };

  const updateQuestionSubmit = async (formValues) => {
    const allAnswers = [];

    // Extract answers into the answers array
    // eslint-disable-next-line no-restricted-syntax
    for (const key in formValues) {
      if (!key.startsWith('question')) {
        allAnswers.push({ optionText: formValues[key] });
      }
    }

    const payload = {
      diseasesId: diseaseId,
      questionId: questionId,
      question: {
        questionText: formValues.question,
        options: allAnswers,
        optionType: ansType
      }
    };

    try {
      setIsSavingData(true);
      const responseData = await updateQuestionToDisease(payload);
      const { questions } = responseData;
      setAllQuestions(questions);
      handleCancel();
      setIsSavingData(false);
    } catch (error) {
      setIsSavingData(false);
      message.error('Failed to update question');
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Typography.Text className="main-header-title">
              {fetchedDisease?.name || ' Questions'}
            </Typography.Text>
          </Space>
          <Button onClick={() => setIsModalOpen(true)} className="create-btn">
            + Create
          </Button>

          {/* CREATE MODAL */}
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            className="offer-modal"
            title={isUpdateModal ? 'Update Question' : 'Add Question'}
            centered
            footer={null} // To customize footer, you can add your buttons here
          >
            <Divider />
            <div className="form-contents">
              <Form
                form={form}
                onFinish={isUpdateModal ? updateQuestionSubmit : handleSubmit}
                className="equipment-form"
                layout="vertical"
              >
                <Row className="upper-contents">
                  <Col>
                    <Form.Item
                      rules={[required]}
                      name="question"
                      className="input-title"
                      label="Question"
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 3 }}
                        rows={6}
                        placeholder="Your Question..."
                        size="middle"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="answers-area">
                  {answers.map((answer, index) => (
                    <Row className="upper-contents " key={answer.id}>
                      <Col className="question-col">
                        <Form.Item
                          rules={[required]}
                          name={`${answer.id}`}
                          label={`Ans ${index + 1}`}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2 }}
                            rows={5}
                            placeholder="Enter your answers"
                            size="middle"
                          />
                        </Form.Item>

                        {answers.length > 1 && (
                          <Button
                            type="link"
                            onClick={() => removeAnswerField(answer.id, index)}
                            style={{ color: 'red' }}
                          >
                            <AiOutlineCloseCircle size={25} />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  ))}

                  <div className="risk-btn">
                    <Button
                      type="dashed"
                      className="add-answer-btn"
                      onClick={addAnswerField}
                    >
                      + Add Answer
                    </Button>
                    <div className="ans-type-section">
                      <span>Select answer type</span>
                      <Radio.Group onChange={handleAnsType} value={ansType}>
                        <Radio value="CHECKBOX">Checkbox</Radio>
                        <Radio value="RADIO">Radio</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>

                <Divider />
                <div className="timely-btn-space ">
                  <Button loading={isSavingData} htmlType="submit">
                    {isUpdateModal ? 'Update' : 'Add'}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </div>

        {/* ANSWERS MODAL */}
        {/* CREATE MODAL */}
        <Modal
          open={isOptionsModal}
          onCancel={handleCancelOptions}
          className="offer-modal"
          title="Answers"
          centered
          footer={null} // To customize footer, you can add your buttons here
        >
          <Divider />
          <div className="answers-content">
            {singleQuestion ? (
              <div>
                <div className="question-text">
                  {singleQuestion.questionText}
                </div>
                <div className="answers-section">
                  {singleQuestion.options.map((ans, i) => {
                    return (
                      <div className="single-ans" key={ans.id}>
                        {i + 1}: {ans.optionText}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <Empty />
            )}
          </div>
        </Modal>
      </Header>

      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={false}
            data={allQuestions}
          />
        )}
      </div>
    </div>
  );
}
export default DiseaseQuestions;
