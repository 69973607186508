import { gql } from '@apollo/client';

export const UPDATE_REMOTE_CONFIG = gql`
  mutation updateRemoteConfigValue($data: ConfigInput!) {
    updateRemoteConfigValue(data: $data)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
