/* eslint-disable no-console */
import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Calendar,
  Col,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Segmented,
  Space,
  Spin,
  TimePicker,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ReactComponent as AfternoonImage } from '../../assets/slots/afternoon.svg';
import { ReactComponent as EveningImage } from '../../assets/slots/evening.svg';
import { ReactComponent as MorningImage } from '../../assets/slots/morning.svg';
import { SLOT_ENUM } from '../../common/constants';
import { convertHHmmAToMinutes } from '../../common/time';
import './time-sheet.less';
import {
  addSlotToDay,
  getMonthCalendarHolidays,
  getSlotsByDay,
  removeAllSlotsByDay,
  removeSlotMaxLimit,
  removeSlotToDay,
  toggleCalendarHoliday,
  updateSlotMaxLimit
} from './timesheet-repository';

function TimeSheet() {
  const [weekday, setWeekday] = useState('MONDAY');
  const [slotData, setSlotData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeValue, setTimeValue] = useState(null);
  const [isSavingTime, setIsSavingTime] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [data, setData] = useState([]);
  const [isDisable, setIsDisable] = useState(true);
  const [disableRemove, setDisableRemove] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [isPanelChanged, setIsPanelChanged] = useState(false);

  const weekDayOptions = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY'
  ];
  const format = 'h:mm A';

  const getWeekData = async (value) => {
    setLoading(true);
    setWeekday(value);
    const response = await getSlotsByDay({
      day: value
    });
    const { morning, afternoon, evening } = response;
    if (
      morning.length === 0 &&
      afternoon.length === 0 &&
      evening.length === 0
    ) {
      setDisableRemove(true);
    } else {
      setDisableRemove(false);
    }
    setSlotData(response);
    setLoading(false);
  };

  const holidayDataForMonth = async (month, year) => {
    const response = await getMonthCalendarHolidays(
      month.toString(),
      year.toString()
    );
    setData(response);
  };
  useEffect(() => {
    getWeekData(weekday);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear);
    holidayDataForMonth(currentMonth.toString().padStart(2, '0'), currentYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPanelChanged) {
      setIsModalVisible(false); // Close the modal when panel changes
      setIsPanelChanged(false); // Reset isPanelChanged after closing modal
    }
  }, [isPanelChanged]);

  const addSlotSubmit = async () => {
    const minutes = `${timeValue.$H}:${timeValue.$m}`;
    const newMinutes = convertHHmmAToMinutes(minutes);
    try {
      setIsSavingTime(true);
      await addSlotToDay({
        day: weekday,
        startTime: newMinutes
      });
      getWeekData(weekday);
      setTimeValue(null);
      setIsDisable(true);
      setIsSavingTime(false);
    } catch (error) {
      setTimeValue(null);
      setIsDisable(true);
      setIsSavingTime(false);
    }
  };

  const removeSlot = async (slotId) => {
    setLoading(true);
    await removeSlotToDay(slotId);
    await getWeekData(weekday);
    setLoading(false);
  };

  const removeAllSlots = async () => {
    setLoading(true);
    await removeAllSlotsByDay(weekday);
    await getWeekData(weekday);
    setLoading(false);
  };

  const debouncedHandleInputChange = debounce(async (value, ID) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Saving data'
    });
    if (!value) {
      await removeSlotMaxLimit(ID);
      getWeekData(weekday);
    } else {
      await updateSlotMaxLimit(ID, parseInt(value, 10));
      getWeekData(weekday);
    }
    message.open({
      key,
      type: 'success',
      content: 'Data saved',
      duration: 1
    });
  }, 1000);

  const handleInputChange = (value, ID) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [ID]: value
    }));

    debouncedHandleInputChange(value, ID);
  };

  const handleDateSelect = (value) => {
    setSelectedDate(value);
    setIsDateSelected(true); // Set isDateSelected to true when a date is selected
    setIsModalVisible(true);
  };

  const toggleDateConfirmation = async () => {
    await toggleCalendarHoliday(dayjs(selectedDate).format('DD/MM/YYYY'));
    setIsModalVisible(false);
    const selectedDateIndex = selectedDate.date() - 1;
    const newDates = [...data];
    newDates[selectedDateIndex] = !newDates[selectedDateIndex];
    setData(newDates);
    setSelectedDate(null);
    setIsDateSelected(false); // Reset isDateSelected when date toggle is confirmed
    message.success('Done');
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedDate(null);
    setIsDateSelected(false); // Reset isDateSelected when modal is canceled
  };

  // Function to handle month selection
  const onPanelChange = (value) => {
    const selectedMon = value.month() + 1;
    const selectedYe = value.year();

    if (selectedMon !== selectedMonth || selectedYe !== selectedYear) {
      setSelectedMonth(selectedMon);
      setSelectedYear(selectedYe);
      holidayDataForMonth(selectedMon.toString().padStart(2, '0'), selectedYe);
      setSelectedDate(null); // Reset selectedDate only if month/year changed
      setIsDateSelected(false); // Reset isDateSelected on month/year change
      setIsPanelChanged(true); // Set isPanelChanged to true when panel changes
    }
  };

  const dateCellRender = (value) => {
    const date = value.date();
    const isInSelectedMonth =
      value.month() + 1 === selectedMonth && value.year() === selectedYear;

    if (isInSelectedMonth) {
      const isDateHighlighted = data[date - 1];

      return isDateHighlighted ? (
        <div style={{ position: 'relative', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#eb5757',
              width: '100%',
              height: '5px',
              borderRadius: '10px'
            }}
          />
        </div>
      ) : null;
    }
    return null;
  };

  const selectOptions = [];
  Object.keys(SLOT_ENUM).forEach((c) => {
    selectOptions.push({ label: c, value: c });
  });

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <Typography.Text className="main-header-title">
          Time Sheet
        </Typography.Text>
      </Header>
      <div className="time-sheet-content">
        <div className="days">
          <Segmented
            options={weekDayOptions}
            value={weekday}
            onChange={(value) => {
              getWeekData(value);
            }}
          />
        </div>
        <div className="time-slots">
          <div className="title">
            <Typography.Text>Time Slots</Typography.Text>
            <Popconfirm
              disabled={disableRemove}
              title="Remove all slots"
              description="Are you sure want to remove all slots?"
              onConfirm={removeAllSlots}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={disableRemove} type="text">
                Remove All Slots
              </Button>
            </Popconfirm>
          </div>
          <div className="add-slot">
            <h4>Add Slot</h4>
            <Space>
              <TimePicker
                use12Hours
                value={timeValue}
                format={format}
                onChange={(value) => {
                  setTimeValue(value);
                  setIsDisable(false);
                }}
                className="time-picker"
                placeholder="00:00 am/pm"
              />
              {/* <Select
                options={selectOptions}
                placeholder="Select event"
                value={event}
                defaultValue={SLOT_ENUM.MORNING}
                onChange={(value) => setEvent(value)}
              /> */}
              <Button
                disabled={isDisable}
                loading={isSavingTime}
                onClick={addSlotSubmit}
                className={isDisable ? 'disable-btn' : 'save-btn'}
              >
                Save
              </Button>
            </Space>
          </div>

          {loading ? (
            <Spin />
          ) : (
            <div className="available-slots">
              <p> Available time slots</p>
              <div className="available-times">
                <div className="time-condition">
                  {' '}
                  <MorningImage /> Morning
                </div>
                <Row gutter={20} className="time-rows">
                  {slotData
                    ? slotData?.morning?.map((item) => {
                        return (
                          <Col key={item.id}>
                            <div className="time-slot time-slot-class">
                              <div>
                                <span>{item.name}</span>
                                <span>
                                  <DeleteOutlined
                                    onClick={() => {
                                      removeSlot(item.id);
                                    }}
                                  />
                                </span>
                              </div>
                              <div className="time-config-inp">
                                <InputNumber
                                  controls={false}
                                  value={
                                    inputValues[item.id] || item.maxBookingLimit
                                  }
                                  onChange={(value) =>
                                    handleInputChange(value, item.id)
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                        );
                      })
                    : null}
                </Row>
              </div>
              <div className="available-times">
                <div className="time-condition">
                  <AfternoonImage /> Afternoon
                </div>
                <Row gutter={20} className="time-rows">
                  {slotData
                    ? slotData?.afternoon?.map((item) => {
                        return (
                          <Col key={item.id}>
                            <div className="time-slot time-slot-class">
                              <div>
                                <span>{item.name}</span>
                                <span>
                                  <DeleteOutlined
                                    onClick={() => {
                                      removeSlot(item.id);
                                    }}
                                  />
                                </span>
                              </div>
                              <div className="time-config-inp">
                                <InputNumber
                                  controls={false}
                                  value={
                                    inputValues[item.id] || item.maxBookingLimit
                                  }
                                  onChange={(value) =>
                                    handleInputChange(value, item.id)
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                        );
                      })
                    : null}
                </Row>
              </div>
              <div className="available-times">
                <div className="time-condition">
                  <EveningImage /> Evening{' '}
                </div>
                <Row gutter={20} className="time-rows">
                  {slotData
                    ? slotData?.evening?.map((item) => {
                        return (
                          <Col key={item.id}>
                            <div className="time-slot time-slot-class">
                              <div>
                                <span>{item.name}</span>
                                <span>
                                  <DeleteOutlined
                                    onClick={() => {
                                      removeSlot(item.id);
                                    }}
                                  />
                                </span>
                              </div>
                              <div className="time-config-inp">
                                <InputNumber
                                  controls={false}
                                  value={
                                    inputValues[item.id] || item.maxBookingLimit
                                  }
                                  onChange={(value) =>
                                    handleInputChange(value, item.id)
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                        );
                      })
                    : null}
                </Row>
              </div>
            </div>
          )}
        </div>
        <div className="holiday-calender">
          <Calendar
            mode="month"
            fullscreen={false}
            dateCellRender={dateCellRender}
            onSelect={(value) => {
              const isInSelectedMonth =
                value.month() + 1 === selectedMonth &&
                value.year() === selectedYear;

              if (isInSelectedMonth) {
                handleDateSelect(value);
              }
            }}
            onPanelChange={onPanelChange}
          />
          {isDateSelected && ( // Conditionally render the Modal based on isDateSelected
            <Modal
              title={`${dayjs(selectedDate).format('DD/MM/YYYY')}`}
              open={isModalVisible}
              onCancel={handleModalCancel}
              footer={[
                <Button key="cancel" onClick={handleModalCancel}>
                  Cancel
                </Button>,
                <Button
                  key="confirm"
                  type="primary"
                  onClick={toggleDateConfirmation}
                >
                  {data[selectedDate.date() - 1]
                    ? 'Undo Holiday'
                    : 'Set Holiday'}
                </Button>
              ]}
            >
              {data[selectedDate.date() - 1]
                ? 'Are you sure you want to undo this date holiday?'
                : 'Are you sure you want to set this date as a full day holiday?'}
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
}
export default TimeSheet;
