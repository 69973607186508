import { gql } from '@apollo/client';

export const SEND_TEMPLATE_CART_MESSAGE = gql`
  mutation sendInteraktCampaign($customerId: String!, $templateId: String!) {
    sendInteraktCampaign(customerId: $customerId, templateId: $templateId)
  }
`;

export const SEND_TEMPLATE_CART_MESSAGE_TO_ALL = gql`
  mutation sendInteraktCampaignToAllCustomers($templateId: String!) {
    sendInteraktCampaignToAllCustomers(templateId: $templateId) {
      cartId
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
