/* eslint-disable no-console */
import { StarFilled, StarOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Rate,
  Space,
  Tooltip,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import { formValidatorRules, formatDate } from '../../common/utils';
import {
  createTestimonialAsAdmin,
  getTestimonialsAsAdmin,
  toggleFeatureTestimonial
} from '../tests/test-repository';
import './testimonials.less';

function Testimonials() {
  const { required } = formValidatorRules;
  const [form] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [testimonials, setTestimonials] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [ratings, setRatings] = useState(1);
  const [isSavingData, setIsSavingData] = useState(false);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  const [totalFeaturedCount, setTotalFeaturedCount] = useState(0);

  // FETCHING TESTIMONIALS
  const fetchTestimonials = async ({ currentSkip }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getTestimonialsAsAdmin({
        skip: currentSkip,
        limit: limit
        // query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setTestimonials(response.testimonials.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  const fetchTotalFeaturedCount = async () => {
    try {
      const totalFeatured = await getTestimonialsAsAdmin({
        skip: 0,
        limit: 100000
        // query: currentQuery
      });
      // console.log([totalFeatured]);
      const totalFeaturedLength = totalFeatured.testimonials.filter(
        (test) => test.isFeatured
      ).length;

      setTotalFeaturedCount(totalFeaturedLength);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchTestimonials({
      currentSkip: 0
    });

    fetchTotalFeaturedCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTotalFeaturedCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonials]);

  // TOGGLE FEATURE
  const toggleFeatured = async (index) => {
    const newTestimonials = [...testimonials];
    const selectedTestimonial = newTestimonials[index];

    if (!selectedTestimonial.isFeatured && totalFeaturedCount >= 4) {
      message.error('Maximum 4 testimonials can be featured');
      // If trying to select a fifth testimonial, return without making changes
      return;
    }
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Processing'
    });

    newTestimonials[index] = {
      ...selectedTestimonial,
      isFeatured: !selectedTestimonial.isFeatured
    };

    const res = await toggleFeatureTestimonial(newTestimonials[index].id);
    if (res) {
      setTestimonials(newTestimonials);
    }
    message.destroy(key);
    message.success('Done');
  };
  // const toggleFeatured = async (index) => {
  //   testimonials[index] = {
  //     ...testimonials[index],
  //     isFeatured: !testimonials[index].isFeatured
  //   };
  //   const res = await toggleFeatureTestimonial(testimonials[index].id);
  //   if (res) {
  //     setTestimonials(testimonials.slice());
  //   }
  // };

  // COLUMNS
  const columns = [
    {
      title: '',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
      align: 'center',
      render: (value, record, index) => {
        return value ? (
          <StarFilled
            className="filled-star"
            onClick={() => {
              toggleFeatured(index);
            }}
          />
        ) : (
          <StarOutlined
            className="outlined-star"
            onClick={() => {
              toggleFeatured(index);
            }}
          />
        );
      }
    },

    {
      title: 'Testimonials',
      dataIndex: 'testimonial',
      key: 'testimonial',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Customer',
      dataIndex: 'user',
      key: 'user',
      render: (value) => {
        if (value.length < 20) {
          return value;
        }
        // eslint-disable-next-line no-lone-blocks
        {
          return (
            <Tooltip title={value} color="#232e78">
              {value.slice(0, 20)}...
            </Tooltip>
          );
        }
      }
    },
    {
      title: 'Ratings',
      dataIndex: 'ratings',
      key: 'ratings',
      align: 'center',
      render: (value) => {
        return (
          <>
            {value} <StarFilled className="filled-star" />
          </>
        );
      }
    },
    {
      title: 'Received on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (value) => {
        const date = new Date(Number(value));
        const newDate = formatDate(date, 'DD/MM/YYYY');
        return newDate;
      }
    }
  ];
  // MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setRatings(1);
    form.resetFields();
    setIsModalOpen(false);
  };

  const submitForm = async (formValue) => {
    const { testimonial, user } = formValue;
    if (user.length > 50) {
      message.error('Exceeded user name limit');
    }

    setIsSavingData(true);
    const createdTestimonial = await createTestimonialAsAdmin({
      user,
      testimonial,
      ratings
    });
    if (
      testimonials.length === 0 ||
      testimonials.length < limit ||
      testimonials[testimonials.length - 1].serialNumber + 1 ===
        createdTestimonial.serialNumber
    ) {
      const updatedTestimonial = testimonials.slice();
      updatedTestimonial.push(createdTestimonial);
      setTestimonials(updatedTestimonial.slice());
    }
    form.resetFields();
    setRatings(1);
    setIsSavingData(false);
    setIsModalOpen(false);
    fetchTestimonials({
      currentSkip: currentGlobalSkip
    });
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Testimonials
            </Typography.Text>
          </Space>
          <Button onClick={() => setIsModalOpen(true)} className="create-btn">
            Add Testimonial
          </Button>
          {/* CREATE MODAL */}
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            className="create-test-modal"
            centered
            title="Add Testimony"
          >
            <Divider />
            <Form
              onFinish={submitForm}
              form={form}
              // onFinish={submitForm}
              className="equipment-form"
              layout="vertical"
            >
              <div className="create-form-control">
                <Form.Item rules={[required]} name="user" label="User ">
                  {/* <Input name="availableQuantity" type="number" /> */}
                  <Input className="inp-equiment" name="user" type="text" />
                </Form.Item>
              </div>
              <Form.Item
                rules={[required]}
                name="testimonial"
                label="Testimonial"
              >
                {/* <Input name="availableQuantity" type="number" /> */}
                <Input
                  className="inp-equiment"
                  name="testimonial"
                  type="text"
                />
              </Form.Item>
              <Form.Item rules={[required]}>
                <span>Ratings:</span>
                <Rate
                  value={ratings}
                  defaultValue={1}
                  onChange={(value) => {
                    if (value === 0) {
                      setRatings(1);
                    } else {
                      setRatings(value);
                    }
                  }}
                />
              </Form.Item>
              <Divider />
              <div className="create-wrapper">
                <Button
                  loading={isSavingData}
                  htmlType="submit"
                  className="create-btn-modal"
                >
                  Create
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </Header>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={testimonials}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchTestimonials({
                // currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default Testimonials;
