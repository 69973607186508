import { Button, DatePicker, Select, Space, Tabs, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CalenderIcon } from '../../assets/calendar.svg';
import { ReactComponent as Back } from '../../assets/icons/back.svg';
import {
  getStartAndEndDateFromWeek,
  toLocalISOString
} from '../../common/utils';
import DoctorRevenue from './components/DoctorRevenue';
import LinkedOrders from './components/LinkedOrders';

// import './reports.less';

function DoctorsReport() {
  const navigate = useNavigate();
  const { id: docID } = useParams();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  // STATES

  const [type, setType] = useState('all');
  const [startDateVal, setStartDateVal] = useState(null);
  const [endDateVal, setEndDateVal] = useState(null);
  const [forcedRefetch, setForcedRefetch] = useState(false);

  const handleTabChange = () => {
    setStartDateVal(null);
    setEndDateVal(null);
    setForcedRefetch(!forcedRefetch);
    setType('all');
  };

  const dateSelectionType = () => {
    switch (type) {
      case 'date':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(value) => {
              const utcTime = value;
              const utcDate = new Date(utcTime);
              // Convert to IST by adding 5 hours and 30 minutes
              utcDate.setHours(utcDate.getHours() + 5);
              utcDate.setMinutes(utcDate.getMinutes() + 30);
              const istTime = dayjs(utcDate.toISOString()).format('YYYY-MM-DD');
              setStartDateVal(istTime);
              setEndDateVal(istTime);
            }} // Pass the function reference without invoking it
          />
        );
      case 'week':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(date, dateString) => {
              const match = dateString.match(/^(\d+)-(\d+)(?:st|nd|rd|th)$/);
              if (match) {
                const year = match[1];
                const day = match[2];
                const { startDate, endDate } = getStartAndEndDateFromWeek(
                  year,
                  day
                );
                setStartDateVal(dayjs(startDate).format('YYYY-MM-DD'));
                setEndDateVal(dayjs(endDate).format('YYYY-MM-DD'));
              } else {
                //
              }
            }}
          />
        );
      case 'month':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(value) => {
              const selectedDate = new Date(value);

              // Set the start date to the 1st day of the selected month
              const startDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                1
              );
              // Calculate the end of the month
              const endOfMonth = new Date(startDate);
              endOfMonth.setMonth(startDate.getMonth() + 1, 0); // Set the day to 0 of the next month (last day of the current month)

              // Check if the year is a leap year
              const year = startDate.getFullYear();
              const isLeapYear =
                (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

              if (isLeapYear) {
                // Modify endOfMonth for leap years (February has 29 days)
                if (startDate.getMonth() === 1) {
                  // February (0-based index)
                  endOfMonth.setDate(29);
                }
              } else {
                //
              }

              // Set your values
              const startDateValue = toLocalISOString(startDate);
              const endDateValue = toLocalISOString(endOfMonth);

              setStartDateVal(dayjs(startDateValue).format('YYYY-MM-DD'));
              setEndDateVal(dayjs(endDateValue).format('YYYY-MM-DD'));
            }}
          />
        );
      case 'custom':
        return (
          <RangePicker
            suffixIcon={<CalenderIcon />}
            onChange={(value) => {
              if (value && value.length === 2) {
                setStartDateVal(dayjs(value[0]).format('YYYY-MM-DD'));
                setEndDateVal(dayjs(value[1]).format('YYYY-MM-DD'));
              } else {
                setStartDateVal(undefined); // Clear the start date when no range is selected
                setEndDateVal(undefined); // Clear the end date when no range is selected
              }
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Space>
              <Typography.Text className="main-header-title">
                More Details
              </Typography.Text>
            </Space>
          </Space>
        </div>
      </Header>
      <div className="content content-class ">
        <div className="tabs-doctor-reports ">
          <Tabs
            onTabClick={handleTabChange} // Call the handleTabChange function
            // defaultActiveKey={defaultActiveTabKey}
            items={[
              {
                key: '1',
                label: `Linked Orders`,
                children: (
                  <LinkedOrders
                    startDateVal={startDateVal}
                    endDateVal={endDateVal}
                    doctorId={docID}
                    forcedRefetch={forcedRefetch}
                  />
                )
              },
              {
                key: '2',
                label: `Revenue`,
                children: (
                  <DoctorRevenue
                    startDateVal={startDateVal}
                    endDateVal={endDateVal}
                    doctorId={docID}
                    forcedRefetch={forcedRefetch}
                  />
                )
              }
            ]}
          />
        </div>
        <div className="select-date-time selector-class">
          <Select
            className="day-selector"
            value={type}
            onChange={(value) => {
              setType(value);
              if (value === 'all') {
                setStartDateVal(null);
                setEndDateVal(null);
              }
            }}
          >
            <Option value="all">All</Option>
            <Option value="date">Date</Option>
            <Option value="week">Week</Option>
            <Option value="month">Month</Option>
            <Option value="custom">Custom</Option>
          </Select>
          {dateSelectionType()}
        </div>
      </div>
    </div>
  );
}
export default DoctorsReport;
