/* eslint-disable no-console */
import client from '../../../../apollo';
import {
  ASSIGN_EQUIPMENT,
  ASSIGN_MULTIPLE_EQUIPMENTS,
  UPDATE_ASSIGN_EQUIPMENTS
} from './graphql/mutation';
import { GET_SAMPLE_COLLECTORS } from './graphql/query';

export const getSampleCollectors = async () => {
  try {
    const response = await client.query({
      query: GET_SAMPLE_COLLECTORS,
      fetchPolicy: 'network-only'
    });
    return response?.data?.getSampleCollectors;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const assignEquipment = async (data) => {
  try {
    const response = await client.mutate({
      mutation: ASSIGN_EQUIPMENT,
      fetchPolicy: 'network-only',
      variables: data
    });
    // eslint-disable-next-line no-console
    return response?.data?.assignEquipment;
  } catch (error) {
    return '';
  }
};

export const assignMultipleEquipments = async (data) => {
  try {
    await client.mutate({
      mutation: ASSIGN_MULTIPLE_EQUIPMENTS,
      fetchPolicy: 'network-only',
      variables: data
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const assignEquipmentsToCollector = async (data, id) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_ASSIGN_EQUIPMENTS,
      fetchPolicy: 'network-only',
      variables: {
        assignEquipments: data,
        id
      }
    });
    return response?.data?.assignEquipmentsToCollectors?.equipment;
  } catch (error) {
    return false;
  }
};
