import { gql } from '@apollo/client';

export const GET_LABS = gql`
  query getLabs($filters: GetBannerInput!) {
    getLabs(filters: $filters) {
      total
      labs {
        id
        name
        displayName
        address
        countryCode
        mobileNumber
        thumbnail
        isFeatured
      }
    }
  }
`;
export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
