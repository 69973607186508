/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import {
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useState } from 'react';

// Icons
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import config from '../../common/config';
import { DEBOUNCE_TIME, GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import { clearSearchField, formValidatorRules } from '../../common/utils';
import {
  createCustomerAddressAsAdmin,
  deleteTemplateAddress,
  getAddressTemplates,
  updateTemplateAddress
} from './template-address-repository';

function TemplateAddress() {
  const { required } = formValidatorRules;
  const [addressForm] = Form.useForm();
  const [locationForm] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [addressTemplates, setAddressTemplates] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [selectedId, setSelectedId] = useState('');
  const [addAddressCustomer, setAddAddressCustomer] = useState(false);
  const [location, setLocation] = useState();
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(-1);
  const [openLocationPicker, setOpenLocationPicker] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [isSavingAddress, setIsSavingAddress] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);

  // FETCHING BANNERS
  const fetchAddressTemplates = async ({ currentSkip }) => {
    try {
      //

      const response = await getAddressTemplates({
        skip: currentSkip,
        limit: limit
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setAddressTemplates(response.templateAddresses.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchAddressTemplates({
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CREATE NEW MODAL
  const handleCancelAddress = () => {
    addressForm.resetFields();
    setLocation(undefined);
    setAddAddressCustomer(false);
    setIsUpdate(false);
    setSelectedId('');
  };

  const handleLocationPickerCancel = () => {
    locationForm.resetFields();
    setSelectedAddressIndex(-1);
    setOpenLocationPicker(false);
    clearSearchField('Location');
  };

  const fetchAddresses = async (value) => {
    const auth = getAuth();
    let userToken = '';
    const currentUser = auth?.currentUser;
    if (currentUser) {
      userToken = await currentUser.getIdToken(true);
    }
    const request = {
      method: 'get',
      url: `${config.SERVER_URL}api/get-location-suggestions?search=${value}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    };
    setLoadingSearchResults(true);
    const response = await axios(request);
    setPredictions(response.data.predictions);
    setLoadingSearchResults(false);
  };

  const selectAddress = async (placeId) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Location is getting selected'
    });
    const auth = getAuth();
    let userToken = '';
    const currentUser = auth?.currentUser;
    if (currentUser) {
      userToken = await currentUser.getIdToken(true);
    }
    const request = {
      method: 'get',
      url: `${config.SERVER_URL}api/decode-location-with-id?id=${placeId}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    };
    const response = await axios(request);
    addressForm.setFieldsValue({
      pincode: response.data.pincode,
      state: response.data.state,
      city: response.data.city
    });
    if (selectedAddressIndex === -1) {
      setLocation(response.data);
    } else {
      const currentLocation = response.data;
      const tempAddresses = addresses.slice();
      tempAddresses[selectedAddressIndex] = {
        ...tempAddresses[selectedAddressIndex],
        location: currentLocation
      };
      setAddresses(tempAddresses);
    }
    setSelectedAddressIndex(-1);
    clearSearchField('Location');
    locationForm.resetFields();
    setOpenLocationPicker(false);
    message.open({
      key,
      type: 'success',
      content: 'Location picked',
      duration: 1
    });
  };

  // UPDATE FORM MODAL
  const userUpdate = async (index, value, record) => {
    setLoadingData(true);
    setIsUpdate(true);
    setTimeout(() => {
      setAddAddressCustomer(true);
      setLoadingData(false);
      setLocation(value);
      addressForm.setFieldsValue({
        addressLine1: record.addressLine1,
        addressLine2: record.addressLine2,
        city: record.city,
        state: record.state,
        pincode: record.pincode
      });
    }, 500);
  };

  const columns = [
    {
      title: 'Sr.No',
      dataIndex: 'serialNumber',
      key: '1',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Address',
      dataIndex: 'location',
      key: '2',
      render: (value, record) => {
        const { addressLine1, addressLine2, city, state, pincode } = record;
        return `${addressLine1},${addressLine2},${city},${pincode},${state}`;
      }
    },
    {
      title: 'City',
      dataIndex: 'location',
      key: '3',
      render: (value, record) => {
        const { city } = record.location;
        return `${city}`;
      }
    },
    {
      title: 'State',
      dataIndex: 'location',
      key: '4',
      render: (value, record) => {
        const { state } = record.location;
        return `${state}`;
      }
    },
    {
      title: '',
      dataIndex: 'location',
      key: '5',
      render: (value, record, index) => {
        return (
          <EditOutlined
            style={{
              cursor: 'pointer',
              fontSize: '20px'
            }}
            onClick={() => {
              addressForm?.resetFields();

              setSelectedId(record.id);
              setTimeout(() => {
                userUpdate(index, value, record);
              }, 200);
            }}
          />
        );
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: '5',
      render: (value) => {
        return (
          <Popconfirm
            title="Delete template address"
            description="Are you sure want to delete this address?"
            onConfirm={async () => {
              await deleteTemplateAddress(value);
              fetchAddressTemplates({
                currentSkip: pageNum
              });
            }}
          >
            <DeleteOutlined
              style={{
                color: '#eb5757',
                cursor: 'pointer',
                fontSize: '20px'
              }}
            />
          </Popconfirm>
        );
      }
    }
  ];

  const addNewAddress = async (formValue) => {
    if (!location) {
      message.error('Location is not selected');
      return;
    }
    if (formValue.mobileNumber < 0) {
      message.error('Invalid number');
      return;
    }
    if (formValue.pincode < 0) {
      message.error('Invalid pincode');
      return;
    }
    const locationData = {
      address: location.address,
      city: location.city,
      latitude: location.latitude,
      longitude: location.longitude,
      pincode: formValue.pincode,
      state: location.state
    };

    const data = {
      addressLine1: formValue.addressLine1,
      addressLine2: formValue.addressLine2,
      city: formValue.city,
      state: formValue.state,
      pincode: formValue.pincode,
      location: locationData
    };
    setIsSavingAddress(true);
    const createdNewTemplate = await createCustomerAddressAsAdmin(data);
    if (createdNewTemplate === false) {
      setIsSavingAddress(false);
      return false;
    }
    // if (
    //   addressTemplates.length === 0 ||
    //   addressTemplates.length < limit ||
    //   addressTemplates[addressTemplates.length - 1].serialNumber + 1 ===
    //     createdNewTemplate.serialNumber
    // ) {
    //   const updatedTemplates = addressTemplates.slice();
    //   updatedTemplates.push(createdNewTemplate);
    //   setAddressTemplates(updatedTemplates.slice());
    // }
    fetchAddressTemplates({
      currentSkip: pageNum
    });
    addressForm.resetFields();
    setLocation(undefined);
    setIsSavingAddress(false);
    setAddAddressCustomer(false);
  };

  const updateTempAddress = async (formValue) => {
    if (!location) {
      message.error('Location is not selected');
      return;
    }
    if (formValue.mobileNumber < 0) {
      message.error('Invalid number');
      return;
    }
    if (formValue.pincode < 0) {
      message.error('Invalid pincode');
      return;
    }

    const locationData = {
      address: location.address,
      city: formValue.city,
      latitude: location.latitude,
      longitude: location.longitude,
      pincode: formValue.pincode,
      state: location.state
    };

    const data = {
      addressLine1: formValue.addressLine1,
      addressLine2: formValue.addressLine2,
      city: formValue.city,
      state: formValue.state,
      pincode: formValue.pincode,
      location: locationData
    };
    setIsSavingAddress(true);
    const updatedTempAddress = await updateTemplateAddress(selectedId, data);
    if (updatedTempAddress === false) {
      setIsSavingAddress(false);
      return false;
    }
    fetchAddressTemplates({
      currentSkip: pageNum
    });
    addressForm.resetFields();
    setLocation(undefined);
    setIsSavingAddress(false);
    setAddAddressCustomer(false);
    setSelectedId('');
    setIsUpdate(false);
    //
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Template Addresses
            </Typography.Text>
          </Space>
          <Button
            onClick={() => {
              setAddAddressCustomer(true);
            }}
            className="create-btn"
          >
            + Create New
          </Button>
        </div>
      </Header>
      {/* ADD NEW ADDRESS MODAL */}
      <Modal
        open={addAddressCustomer}
        onCancel={handleCancelAddress}
        className="create-patient-modal addressModal"
        centered
        title={isUpdate ? 'Update Address' : 'Create Address'}
      >
        <Divider />
        <Form
          layout="vertical"
          form={addressForm}
          onFinish={isUpdate ? updateTempAddress : addNewAddress}
        >
          <div className="patient-container">
            <Row className="row-one">
              <Col span={12} className="left">
                <Form.Item rules={[required]} name="pincode" label="Pincode">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col span={12} className="right">
                <Form.Item rules={[required]} name="state" label="State">
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="row-one">
              <Col span={12} className="left">
                <Form.Item
                  rules={[required]}
                  name="addressLine1"
                  label="Address Line 1"
                >
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col span={12} className="right">
                <Form.Item
                  rules={[required]}
                  name="addressLine2"
                  label="Address Line 2"
                >
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="row-one">
              <Col span={12} className="left">
                <Form.Item rules={[required]} name="city" label="City">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col span={12} className="right">
                <Form.Item label="Location address">
                  <Button
                    style={{
                      width: '100%',
                      height: '48px'
                    }}
                    onClick={() => {
                      setOpenLocationPicker(true);
                    }}
                  >
                    {location ? 'Change location' : 'Select location'}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="create-wrapper">
            <Button
              loading={isSavingAddress}
              htmlType="submit"
              className="add-patient-btn"
            >
              {isUpdate ? 'Update Address' : 'Add Address'}
            </Button>
          </div>
        </Form>
      </Modal>

      {/* LOCATION PICKER MODAL */}
      <Modal
        open={openLocationPicker}
        onCancel={handleLocationPickerCancel}
        centered
        footer={null}
        title="Pick location"
      >
        <Form form={locationForm}>
          <SearchComponent
            name="Location"
            debounceTime={DEBOUNCE_TIME}
            getData={(value) => {
              fetchAddresses(value);
            }}
            style={{
              height: '45px',
              borderRadius: '10px'
            }}
          />

          {loadingSearchResults ? (
            <div className="empty-state">
              <LoaderComponent />
            </div>
          ) : predictions.length === 0 ? (
            <Empty className="empty-state" />
          ) : (
            <div className="fixed-height">
              {predictions.map((prediction, index) => {
                const predictionId = `prediction-${index}`;
                return (
                  <div
                    className="location-container"
                    key={predictionId}
                    onClick={() => {
                      const { place_id: placeId } = prediction;
                      selectAddress(placeId);
                    }}
                  >
                    {prediction.description}
                  </div>
                );
              })}
            </div>
          )}
        </Form>
      </Modal>

      <div className="equipments-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            rowKey={(record) => record.id}
            columns={columns}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={addressTemplates}
            onChange={(onChange) => {
              setLoadingData(true);
              setPageNum((onChange.current - 1) * limit);
              fetchAddressTemplates({
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default TemplateAddress;
