import { gql } from '@apollo/client';

// CANCEL PRESCRIPTION
export const CANCEL_PRESCRIPTION = gql`
  mutation cancelPrescriptionAsAdmin($prescriptionId: String!) {
    cancelPrescriptionAsAdmin(prescriptionId: $prescriptionId)
  }
`;

// APPROVE PRESCRIPTION
export const APPROVE_PRESCRIPTION = gql`
  mutation resolvePrescription($prescriptionId: String!) {
    resolvePrescription(prescriptionId: $prescriptionId)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
