/* eslint-disable no-console */
import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  InputNumber,
  Modal,
  Space,
  Typography
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import { formValidatorRules } from '../../common/utils';
import {
  getRemoteConfigValue,
  updateRemoteConfigValue
} from './configurations-repository';
import './configurations.less';

function Configurations() {
  const { required } = formValidatorRules;
  const [form] = Form.useForm();

  // STATES
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [remoteConfig, setRemoteConfig] = useState([]);
  const [isSavingData, setIsSavingData] = useState(false);
  const [remoteName, setRemoteName] = useState('');

  // FETCHING REMOTE CONFIG
  const fetchRemoteConfig = async () => {
    try {
      const response = await getRemoteConfigValue();
      setRemoteConfig(response.data.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchRemoteConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: 'Configuration name',
      dataIndex: 'template',
      key: '1'
    },

    {
      title: 'Value',
      dataIndex: 'value',
      key: '2'
    },
    {
      title: '',
      dataIndex: 'template',
      key: '3',
      render: (value, record) => {
        setRemoteName(value);
        return (
          <EditOutlined
            onClick={() => {
              setIsModalOpen(true);
              form.setFieldsValue({
                configValue: record.value
              });
            }}
          />
        );
      }
    }
  ];

  const submitConfigForm = async (formValue) => {
    const data = {
      template: remoteName,
      value: formValue.configValue
    };
    setIsSavingData(true);
    await updateRemoteConfigValue(data);
    form.resetFields();
    setRemoteName('');
    setIsSavingData(false);
    setIsModalOpen(false);
    fetchRemoteConfig();
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Configurations
            </Typography.Text>
          </Space>
        </div>
      </Header>
      {/* MODAL */}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        className="create-test-modal config-modal"
        title={remoteName || 'Edit'}
      >
        <Divider />
        <Form
          layout="vertical"
          form={form}
          className="config-form"
          onFinish={submitConfigForm}
        >
          <Form.Item rules={[required]} name="configValue">
            <InputNumber controls={false} />
          </Form.Item>
          <Divider />
          <Button
            loading={isSavingData}
            className="configuration-btn"
            htmlType="submit"
          >
            Save
          </Button>
        </Form>
      </Modal>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={false}
            data={remoteConfig}
          />
        )}
      </div>
    </div>
  );
}
export default Configurations;
