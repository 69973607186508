/* eslint-disable react/react-in-jsx-scope */
import { Tag } from 'antd';
import { formatDate } from '../utils';

// eslint-disable-next-line import/prefer-default-export
export const getTag = (paymentStatus) => {
  // 'PAID', 'CANCELLED', 'PENDING', 'FAILED', 'USER_DROPPED',
  let color = 'gold';
  if (paymentStatus === 'PAID' || paymentStatus === 'PAID_OFFLINE') {
    color = 'green';
  } else if (
    paymentStatus === 'FAILED' ||
    paymentStatus === 'CANCELLED' ||
    paymentStatus === 'USER_DROPPED'
  ) {
    color = 'red';
  }
  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Tag className="tag" color={color}>
      {paymentStatus}
    </Tag>
  );
};

export const getOrderStatusTags = (orderStatus) => {
  switch (orderStatus) {
    case 'BOOKED':
      return (
        <Tag className="order-status-tag" color="blue">
          {orderStatus}
        </Tag>
      );
    case 'CANCELLED':
      return (
        <Tag className="order-status-tag" color="red">
          {orderStatus}
        </Tag>
      );
    case 'COLLECTED':
      return (
        <Tag className="order-status-tag" color="orange">
          {orderStatus}
        </Tag>
      );
    case 'REPORT_GENERATED':
      return (
        <Tag className="order-status-tag" color="purple">
          {orderStatus}
        </Tag>
      );
    case 'REPORT_PUBLISHED':
      return (
        <Tag className="order-status-tag" color="green">
          {orderStatus}
        </Tag>
      );
    case 'REFUND_INITIATED':
      return (
        <Tag className="order-status-tag" color="volcano">
          {orderStatus}
        </Tag>
      );
    case 'COMPLETED':
      return (
        <Tag className="order-status-tag" color="gold">
          {orderStatus}
        </Tag>
      );
    case 'REFUND_COMPLETED':
      return (
        <Tag className="order-status-tag" color="lime">
          {orderStatus}
        </Tag>
      );
    case 'FAILURE':
      return (
        <Tag className="order-status-tag" color="magenta">
          {orderStatus}
        </Tag>
      );
    default:
      return (
        <Tag className="order-status-tag" color="default">
          {orderStatus}
        </Tag>
      );
  }
};

export const getPayVia = (value) => {
  let paymentVia = 'ONLINE';
  if (value === 'PAY_AT_SAMPLE_COLLECTION') {
    paymentVia = 'AT COLLECTION';
  }
  if (value === 'COINS') {
    paymentVia = 'COINS';
  }
  return paymentVia;
};

export const getSourceTag = (source) => {
  // eslint-disable-next-line default-case
  switch (source) {
    case 'MOBILE':
      return (
        <Tag className="tag" color="green">
          {source}
        </Tag>
      );

    case 'ADMIN':
      return (
        <Tag className="tag" color="red">
          {source}
        </Tag>
      );
    case 'WEB':
      return (
        <Tag className="tag" color="gold">
          {source}
        </Tag>
      );
  }
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
export const normalizeEnums = (value) => {
  return value.replace(new RegExp(escapeRegExp('_'), 'g'), ' ');
};
export const getDateIntoDDMMYYYY = (date) => {
  const formatted = formatDate(date, 'DD MM YYYY');
  return formatted.replace(new RegExp(escapeRegExp(' '), 'g'), '');
};

export const getDifferenceInDays = (date1, date2) => {
  const dateNew1 = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );
  const dateNew2 = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );
  const diffTime = Math.abs(dateNew1 - dateNew2);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
