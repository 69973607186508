import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import '../../app/app.less';

let searchDebounce = null;

function SearchComponent(props) {
  const { debounceTime, name = '', getData, initialValue, ...rest } = props;
  const [query, setQuery] = useState(initialValue);

  const handleChange = ({ target: { value } }) => {
    setQuery(value);
    if (getData) {
      if (searchDebounce) {
        searchDebounce?.cancel();
        searchDebounce = null;
      }
      searchDebounce = debounce(getData, debounceTime || 1000);
      searchDebounce(value);
    }
  };

  return (
    <Input
      size="small"
      className="search-debounce-input"
      placeholder={`Search ${name}`}
      value={query}
      name={name}
      allowClear
      onChange={handleChange}
      prefix={<SearchOutlined />}
      {...rest}
    />
  );
}

export default SearchComponent;
