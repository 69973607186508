import { gql } from '@apollo/client';

// CREATE RULE BASED OFFERS
export const CREATE_RULE_BASED_OFFERS = gql`
  mutation createRuleBasedOffer(
    $title: String!
    $kind: DiscountKind!
    $value: Float!
  ) {
    createRuleBasedOffer(data: { title: $title, kind: $kind, value: $value }) {
      offer {
        id
        serialNumber
        title
        kind
        value
        isFeatured
      }
    }
  }
`;

// UPDATE RULE BASED OFFERS
export const UPDATE_RULE_BASED_OFFERS = gql`
  mutation updateRuleBasedOffer(
    $title: String!
    $kind: DiscountKind!
    $value: Float!
    $offerId: String!
  ) {
    updateRuleBasedOffer(
      data: { title: $title, kind: $kind, value: $value }
      offerId: $offerId
    ) {
      offer {
        id
        serialNumber
        title
        kind
        value
        isFeatured
      }
    }
  }
`;

// TOGGLE FEATURE
export const TOGGLE_FEATURE_RULE_BASED = gql`
  mutation toggleFeatureRuleBasedOffer($offerId: String!) {
    toggleFeatureRuleBasedOffer(offerId: $offerId) {
      result
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
