import { gql } from '@apollo/client';

export const GET_SAMPLE_COLLECTORS = gql`
  query getSampleCollectors {
    getSampleCollectors {
      sampleCollectors {
        id
        name
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
