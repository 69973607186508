import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../../common/components/CommonTable';
import LoaderComponent from '../../../common/components/LoaderComponent';
import SearchComponent from '../../../common/components/SearchDebounce';
import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT
} from '../../../common/constants';
import { clearSearchField, formValidatorRules } from '../../../common/utils';
import { getDoctorsList } from '../../doctors-tab/doctor-repository';
import '../risk-index.less';
import {
  createNewDiseaseCategory,
  getAllDiseaseCategories,
  getAllDiseases,
  linkDoctorToCategory,
  toggleActionDiseaseCategory,
  updateDiseaseCategory
} from '../risk-repository';

function DiseaseCategories() {
  const { required } = formValidatorRules;
  const [form] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [isSavingData, setIsSavingData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  // const [uploadedImage, setUploadedImage] = useState();
  // const [uploadingImage, setUploadingImage] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [selectedId, setSelectedId] = useState('');
  const [diseaseName, setDiseaseName] = useState('');
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [fetchedDiseases, setFetchedDiseases] = useState([]);
  const [isFetchingDisease, setIsFetchingDisease] = useState(false);
  const [showingDiseaseResults, setShowingDiseaseResults] = useState(false);
  const [isLinkModal, setIsLinkModal] = useState(false);
  const [isFetchingDoc, setIsFetchingDoc] = useState(false);
  const [showingDoctorResults, setShowingDoctorResults] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [fetchedDocs, setFetchedDocs] = useState([]);

  const getAllCategories = async ({ currentSkip, currentQuery }) => {
    try {
      setCurrentGlobalSkip(currentSkip);
      const response = await getAllDiseaseCategories({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setCategoryList(response.categories.slice());
      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getAllCategories({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MODAL
  const handleCancel = () => {
    form?.resetFields();
    clearSearchField('Diseases');
    // setUploadedImage(undefined);
    setSelectedId('');
    setDiseaseName('');
    setSelectedDiseases([]);
    setIsUpdateModal(false);
    setIsModalOpen(false);
  };

  // UPDATE MODAL
  const getDiseaseToUpdate = (record, value) => {
    setLoadingData(true);
    setTimeout(() => {
      setDiseaseName(value);
      form.setFieldsValue({
        diseaseTitle: record.name
        // noOfAssessment: record.noOfAssessment
      });
      setIsModalOpen(true);
      setSelectedDiseases(record.linkedDiseases);
      setSelectedId(record.id);
      setLoadingData(false);
    }, 500);
  };

  const getDoctorLink = (record) => {
    const { id, name, doctors } = record;
    setLoadingData(true);
    setTimeout(() => {
      setDiseaseName(name);
      setIsLinkModal(true);
      setSelectedDocs(doctors);
      setSelectedId(id);
      setLoadingData(false);
    }, 500);
  };

  // TOGGLE FEATURE
  // const toggleFeatured = async (index) => {
  //   const key = 'updatable';
  //   message.open({
  //     key,
  //     type: 'loading',
  //     content: 'Loading'
  //   });
  //   categoryList[index] = {
  //     ...categoryList[index],
  //     isFeatured: !categoryList[index].isFeatured
  //   };

  //   await toggleFeatureDiseaseCategory(categoryList[index].id);
  //   getAllCategories({
  //     currentQuery: query,
  //     currentSkip: currentGlobalSkip
  //   });
  //   message.open({
  //     key,
  //     type: 'success',
  //     content: 'Done'
  //   });
  //   message.destroy(key);
  // };

  const toggleAction = async (index) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Loading'
    });
    categoryList[index] = {
      ...categoryList[index],
      isDisabled: !categoryList[index].isDisabled
    };

    await toggleActionDiseaseCategory(categoryList[index].id);
    getAllCategories({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
    message.open({
      key,
      type: 'success',
      content: 'Done'
    });
    message.destroy(key);
  };

  const fetchDiseasesList = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingDiseaseResults(false);
      return;
    }
    setShowingDiseaseResults(true);
    setIsFetchingDisease(true);
    try {
      const response = await getAllDiseases({
        skip: 0,
        limit: 3,
        query: currentSearch
      });

      const { diseases } = response;
      const currentFetchedDiseases = [];
      for (let i = 0; i < diseases.length; i += 1) {
        const disease = diseases[i];
        const { id } = disease;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedDiseases.length; j += 1) {
          if (selectedDiseases[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedDiseases.push({
          isSelected: isAlreadySelected,
          disease
        });
      }
      setFetchedDiseases(currentFetchedDiseases);
      setIsFetchingDisease(false);
    } catch (error) {
      setIsFetchingDisease(false);
    }
  };

  const fetchDoctorList = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingDoctorResults(false);
      return;
    }
    setShowingDoctorResults(true);
    setIsFetchingDoc(true);
    try {
      const response = await getDoctorsList({
        skip: 0,
        limit: 3,
        query: currentSearch
      });

      const { doctors } = response;
      const currentFetchedDocs = [];
      for (let i = 0; i < doctors.length; i += 1) {
        const doctor = doctors[i];
        const { id } = doctor;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedDocs.length; j += 1) {
          if (selectedDocs[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedDocs.push({
          isSelected: isAlreadySelected,
          doctor
        });
      }
      setFetchedDocs(currentFetchedDocs);
      setIsFetchingDoc(false);
    } catch (error) {
      setIsFetchingDoc(false);
    }
  };

  const handleCancelLinkModal = () => {
    setDiseaseName('');
    setSelectedId('');
    clearSearchField('Doctors');
    setFetchedDocs([]);
    setSelectedDocs([]);
    setIsLinkModal(false);
  };

  const columns = [
    // {
    //   title: '',
    //   dataIndex: 'isFeatured',

    //   align: 'center',
    //   key: '1',
    //   render: (value, record, index) => {
    //     return value ? (
    //       <StarFilled
    //         className="filled-star"
    //         onClick={() => {
    //           toggleFeatured(index);
    //         }}
    //       />
    //     ) : (
    //       <StarOutlined
    //         className="outlined-star"
    //         onClick={() => {
    //           toggleFeatured(index);
    //         }}
    //       />
    //     );
    //   }
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: '2',
      render: (value, record, index) => {
        return (
          <Typography.Text
            className="clickable-title"
            onClick={() => {
              form?.resetFields();
              // setUploadedImage(record.thumbnail);
              setIsUpdateModal(true);
              setCurrentSelectedIndex(index);
              setTimeout(() => {
                getDiseaseToUpdate(record, value);
              }, 200);
            }}
          >
            {value}
          </Typography.Text>
        );
      }
    },

    {
      title: 'Actions',
      dataIndex: 'id',
      key: '5',
      align: 'center',
      render: (value, record, index) => {
        const { isDisabled } = record;
        return (
          <div className="btn-space">
            <Button
              className="update-btn-banner"
              onClick={() => toggleAction(index)}
            >
              {isDisabled ? 'Enable' : 'Disable'}
            </Button>
            <PlusCircleOutlined
              size={40}
              onClick={() => {
                form?.resetFields();
                setTimeout(() => {
                  getDoctorLink(record);
                }, 200);
              }}
            />
          </div>
        );
      }
    }
  ];

  const createForm = async (formValues) => {
    if (selectedDiseases.length < 1) {
      message.error('Minimum 1 disease is required');
      return;
    }
    // if (!uploadedImage) {
    //   message.error('Thumbnail is not uploaded');
    //   return;
    // }

    const diseases = [];
    for (let i = 0; i < selectedDiseases.length; i += 1) {
      const element = selectedDiseases[i];
      diseases.push(element.id);
    }

    const payload = {
      name: formValues.diseaseTitle,
      // thumbnail: uploadedImage,
      diseases
    };

    setIsSavingData(true);
    const createDiseaseCategory = await createNewDiseaseCategory(payload);
    if (
      categoryList.length === 0 ||
      categoryList.length < limit ||
      categoryList[categoryList.length - 1].serialNumber + 1 ===
        createDiseaseCategory?.serialNumber
    ) {
      const updatedDisease = categoryList.slice();
      updatedDisease.push(createDiseaseCategory);
      setCategoryList(updatedDisease.slice());
    }
    form.resetFields();
    // setUploadedImage(undefined);
    setIsSavingData(false);
    setIsModalOpen(false);
    handleCancel();
    getAllCategories({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  const updateForm = async (formValues) => {
    if (selectedDiseases.length < 1) {
      message.error('Minimum 1 disease is required');
      return;
    }
    // if (!uploadedImage) {
    //   message.error('Thumbnail is not uploaded');
    //   return;
    // }

    const diseases = [];
    for (let i = 0; i < selectedDiseases.length; i += 1) {
      const element = selectedDiseases[i];
      diseases.push(element.id);
    }

    const payload = {
      name: formValues.diseaseTitle,
      // thumbnail: uploadedImage,
      diseases
    };

    setIsSavingData(true);
    const updatedDisease = await updateDiseaseCategory(payload, selectedId);
    const diseaseNewList = categoryList.slice();
    diseaseNewList[currentSelectedIndex] = updatedDisease;
    setCategoryList(diseaseNewList);
    handleCancel();
    setIsSavingData(false);
  };

  // const onIconUpload = async (selectedFile) => {
  //   setUploadingImage(true);
  //   const fileName = selectedFile.file.name;
  //   const response = await postUploadIcon(fileName);
  //   const { signedUrl, getUrl } = response;
  //   const config = {
  //     method: 'put',
  //     url: signedUrl,
  //     headers: {
  //       'Content-Type': 'image/svg+xml'
  //     },
  //     data: selectedFile.file
  //   };
  //   await axios(config);
  //   const url = await getFileSignedUrl(getUrl);
  //   setUploadedImage(url);
  //   setUploadingImage(false);
  // };

  const handleLinkDoctors = async () => {
    setIsSavingData(true);
    const docIds = [];
    for (let i = 0; i < selectedDocs.length; i += 1) {
      docIds.push(selectedDocs[i].id);
    }

    const data = {
      categoryId: selectedId,
      doctorIds: docIds
    };

    await linkDoctorToCategory(data);
    getAllCategories({
      currentQuery: query
    });
    setIsSavingData(false);
    handleCancelLinkModal();
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Disease Categories
            </Typography.Text>
            <SearchComponent
              name="Category"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                getAllCategories({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button onClick={() => setIsModalOpen(true)} className="create-btn">
            + Create
          </Button>

          {/* CREATE MODAL */}
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            className="offer-modal create-test-modal"
            title={isUpdateModal ? diseaseName : 'Create Category'}
            centered
          >
            <Divider />
            <div className="form-contents">
              <Form
                form={form}
                onFinish={isUpdateModal ? updateForm : createForm}
                className="equipment-form"
                layout="vertical"
              >
                <Row className="upper-contents">
                  {/* <Col className="upload-thumbnail">
                    <Upload
                      name="upload-icon"
                      onChange={onIconUpload}
                      accept=".svg"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                    >
                      {uploadedImage && !uploadingImage && (
                        <Avatar src={uploadedImage} alt="Selected image" />
                      )}
                      {!uploadedImage && !uploadingImage && (
                        <UploadImage className="category-image" />
                      )}
                      {uploadingImage && <LoadingOutlined />}
                      <p>Upload category thumbnail</p>
                    </Upload>
                  </Col> */}
                  <Col>
                    <Form.Item
                      rules={[required]}
                      name="diseaseTitle"
                      className="input-title"
                      label="Category Name"
                    >
                      <Input type="text" placeholder="Category name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="upper-contents">
                  <Col>
                    <div className="tests-modal-search">
                      <SearchComponent
                        name="Diseases"
                        debounceTime={DEBOUNCE_TIME}
                        getData={fetchDiseasesList}
                      />

                      {!isFetchingDisease && showingDiseaseResults && (
                        <div className="search-result" id="test-searcher">
                          {fetchedDiseases.length <= 0 ? (
                            <Empty />
                          ) : (
                            fetchedDiseases.map((currentTest) => {
                              const { isSelected, disease } = currentTest;
                              return (
                                <div className="tests-list" key={disease.id}>
                                  <Typography.Text>
                                    {disease.name}
                                  </Typography.Text>
                                  {isSelected ? (
                                    <Button
                                      type="text"
                                      onClick={() => {
                                        const tests = selectedDiseases.slice();
                                        for (
                                          let i = 0;
                                          i < tests.length;
                                          i += 1
                                        ) {
                                          if (tests[i].id === disease.id) {
                                            tests.splice(i, 1);
                                          }
                                        }
                                        const tempFetchedTests =
                                          fetchedDiseases.slice();
                                        for (
                                          let i = 0;
                                          i < tempFetchedTests.length;
                                          i += 1
                                        ) {
                                          if (
                                            tempFetchedTests[i].test.id ===
                                            disease.id
                                          ) {
                                            tempFetchedTests[i] = {
                                              ...tempFetchedTests[i],
                                              isSelected: false
                                            };
                                            break;
                                          }
                                        }
                                        setSelectedDiseases(tests);
                                        setFetchedDiseases(tempFetchedTests);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        const tests = selectedDiseases.slice();
                                        tests.push(disease);
                                        const tempFetchedTests =
                                          fetchedDiseases.slice();
                                        for (
                                          let i = 0;
                                          i < tempFetchedTests.length;
                                          i += 1
                                        ) {
                                          if (
                                            tempFetchedTests[i].disease.id ===
                                            disease.id
                                          ) {
                                            tempFetchedTests[i] = {
                                              ...tempFetchedTests[i],
                                              isSelected: true
                                            };
                                            break;
                                          }
                                        }
                                        setSelectedDiseases(tests);
                                        setFetchedDiseases(tempFetchedTests);
                                      }}
                                      type="default"
                                    >
                                      Add
                                    </Button>
                                  )}
                                </div>
                              );
                            })
                          )}
                        </div>
                      )}
                    </div>
                    <div className="selected-test-container category-container">
                      {selectedDiseases.length <= 0 ? (
                        <Empty />
                      ) : (
                        selectedDiseases.map((currentTest) => {
                          const { id, name } = currentTest;
                          return (
                            <div className="tests-list" key={id}>
                              <Typography.Text>{name}</Typography.Text>
                              <Button
                                type="text"
                                onClick={() => {
                                  const tests = selectedDiseases.slice();
                                  for (let i = 0; i < tests.length; i += 1) {
                                    if (tests[i].id === id) {
                                      tests.splice(i, 1);
                                    }
                                  }
                                  setSelectedDiseases(tests);
                                }}
                              >
                                Remove
                              </Button>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </Col>
                </Row>
                <Divider />
                <div className="timely-btn-space ">
                  <Button loading={isSavingData} htmlType="submit">
                    {isUpdateModal ? 'Update' : 'Add'}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          {/* DOCTOR LINKING MODAL */}
          <Modal
            title={diseaseName || 'LINK DOCTORS'}
            centered
            className="create-test-modal"
            open={isLinkModal}
            onCancel={handleCancelLinkModal}
          >
            <Divider />
            <div className="tests-modal-search">
              <SearchComponent
                name="Doctors"
                debounceTime={DEBOUNCE_TIME}
                getData={fetchDoctorList}
              />

              {!isFetchingDoc && showingDoctorResults && (
                <div className="search-result" id="test-searcher">
                  {fetchedDocs.length <= 0 ? (
                    <Empty />
                  ) : (
                    fetchedDocs.map((currentTest) => {
                      const { isSelected, doctor } = currentTest;
                      return (
                        <div className="tests-list" key={doctor.id}>
                          <Typography.Text>{doctor.name}</Typography.Text>
                          {isSelected ? (
                            <Button
                              type="text"
                              onClick={() => {
                                const tests = selectedDocs.slice();
                                for (let i = 0; i < tests.length; i += 1) {
                                  if (tests[i].id === doctor.id) {
                                    tests.splice(i, 1);
                                  }
                                }
                                const tempFetchedTests = fetchedDocs.slice();
                                for (
                                  let i = 0;
                                  i < tempFetchedTests.length;
                                  i += 1
                                ) {
                                  if (
                                    tempFetchedTests[i].test.id === doctor.id
                                  ) {
                                    tempFetchedTests[i] = {
                                      ...tempFetchedTests[i],
                                      isSelected: false
                                    };
                                    break;
                                  }
                                }
                                setSelectedDocs(tests);
                                setFetchedDocs(tempFetchedTests);
                              }}
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                const docs = selectedDocs.slice();
                                docs.push(doctor);
                                const tempFetchedDocs = fetchedDocs.slice();
                                for (
                                  let i = 0;
                                  i < tempFetchedDocs.length;
                                  i += 1
                                ) {
                                  if (
                                    tempFetchedDocs[i].doctor.id === doctor.id
                                  ) {
                                    tempFetchedDocs[i] = {
                                      ...tempFetchedDocs[i],
                                      isSelected: true
                                    };
                                    break;
                                  }
                                }
                                setSelectedDocs(docs);
                                setFetchedDocs(tempFetchedDocs);
                              }}
                              type="default"
                            >
                              Add
                            </Button>
                          )}
                        </div>
                      );
                    })
                  )}
                </div>
              )}
            </div>
            <div className="selected-test-container category-container">
              {selectedDocs?.length <= 0 ? (
                <Empty />
              ) : (
                selectedDocs.map((currentTest) => {
                  const { id, name } = currentTest;
                  return (
                    <div className="tests-list" key={id}>
                      <Typography.Text>{name}</Typography.Text>
                      <Button
                        type="text"
                        onClick={() => {
                          const docs = selectedDocs.slice();
                          for (let i = 0; i < docs.length; i += 1) {
                            if (docs[i].id === id) {
                              docs.splice(i, 1);
                            }
                          }
                          setSelectedDocs(docs);
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  );
                })
              )}
            </div>
            <Divider />
            <Button
              loading={isSavingData}
              className="create-btn offer-save-btn"
              onClick={handleLinkDoctors}
            >
              SAVE
            </Button>
          </Modal>
        </div>
      </Header>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record?.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={categoryList}
            onChange={(onChange) => {
              setLoadingData(true);
              getAllCategories({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}

export default DiseaseCategories;
