import { message } from 'antd';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import client from '../../apollo';
import { GET_SYSTEM_USER_IF_EXISTS } from './graphql/query';

export const auth = getAuth();

export const handleFirebaseError = (error) => {
  if (error.code === 'auth/invalid-email') {
    message.error('Invalid email address');
  }
  if (error.code === 'auth/user-not-found') {
    message.error('You have not registered yet');
  }
  if (error.code === 'auth/wrong-password') {
    message.error('Invalid password');
  }
  if (error.code === 'auth/weak-password') {
    message.error('Password must be at least 6 characters long');
  }
  if (error.code === 'auth/email-already-in-use') {
    message.error('Email already in use');
  }
  if (error.code === 'auth/too-many-requests') {
    message.error('Too many requests');
  }
  if (error.code === 'auth/user-disabled') {
    message.error('User disabled');
  }
  if (error.code === 'auth/network-request-failed') {
    message.error('Network request failed');
  }
  if (error.code === 'auth/account-exists-with-different-credential') {
    message.error('Account exists with different credential');
  }
  if (error.code === 'auth/popup-blocked') {
    message.error('Popup blocked');
  }
  if (error.code === 'auth/popup-closed-by-user') {
    message.error('Popup closed by user');
  }
  if (error.code === 'auth/invalid-action-code') {
    message.error('Invalid action code');
  }
};

export const authenticateUser = async () => {
  try {
    const currentUser = auth?.currentUser;

    if (currentUser) {
      const response = await client.query({
        query: GET_SYSTEM_USER_IF_EXISTS,
        variables: {}
      });
      return response?.data?.getSystemUserIfExists?.systemUser;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const signIn = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return await authenticateUser();
  } catch (error) {
    // eslint-disable-next-line no-console
    if (error && error.code) {
      return handleFirebaseError(error);
    }
    throw error;
  }
};

export const signOutAdmin = async () => {
  await signOut(auth);
};
