import { Space, Tabs, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import SearchComponent from '../../common/components/SearchDebounce';
// import ActiveReports from './components/ActiveReports';
// import HistoryReports from './components/HistoryReports';
import { DEBOUNCE_TIME } from '../../common/constants';
import ActivePrescriptions from './components/ActivePrescriptions';
import HistoryPrescriptions from './components/HistoryPrescriptions';
import './prescriptions.less';

function Prescriptions() {
  const [query, setQuery] = useState('');

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Prescriptions
            </Typography.Text>
            <SearchComponent
              name="Prescriptions"
              debounceTime={DEBOUNCE_TIME}
              getData={(change) => {
                setQuery(change);
              }}
            />
          </Space>
        </div>
      </Header>
      <div className="content">
        <div className="tabs-header-reports">
          <Tabs
            items={[
              {
                key: '1',
                label: `Active`,
                children: <ActivePrescriptions query={query} />
              },
              {
                key: '2',
                label: `History`,
                children: <HistoryPrescriptions query={query} />
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
}
export default Prescriptions;
