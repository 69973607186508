import { gql } from '@apollo/client';

export const GET_PINCODES = gql`
  query getPinCodes($filters: GetPinCodesInput!) {
    getPinCodes(filters: $filters) {
      pinCodes {
        id
        code
        area
        isDisable
      }
      total
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
