import { gql } from '@apollo/client';

// GETTING CATEGORIES
export const GET_CATEGORIES = gql`
  query getCategories($filters: GetCategoriesInput) {
    getCategories(filters: $filters) {
      total
      categories {
        id
        name
        isFeatured
        order
        serialNumber
        thumbnail
        isDisabled
        linkedTests {
          total
          tests {
            id
            title
          }
        }
      }
    }
  }
`;

// GETTING EQUIPMENTS
export const GET_EQUIPMENTS = gql`
  query getEquipments($filters: GetEquipmentsInput) {
    getEquipments(filters: $filters) {
      total
      equipments {
        id
        name
        serialNumber
        minimumQuantity
        stock {
          id
          availableQuantity
        }
      }
    }
  }
`;

// GETTING SAMPLES
export const GET_SAMPLES = gql`
  query getSamples($filters: GetSamplesInput) {
    getSamples(filters: $filters) {
      total
      samples {
        id
        name
        serialNumber
        thumbnail
      }
    }
  }
`;

// GETTING TESTIMONIALS
export const GET_TESTIMONIALS = gql`
  query getTestimonialsAsAdmin($filters: GetTestimonialsAsAdminInput) {
    getTestimonialsAsAdmin(filters: $filters) {
      total
      testimonials {
        id
        user
        testimonial
        ratings
        order
        isFeatured
        serialNumber
        createdAt
      }
    }
  }
`;
export const GET_TEST_BY_ID = gql`
  query getTestById($id: String!) {
    getTestById(id: $id) {
      test {
        id
        title
        thumbnailUrl
        discount
        serialNumber
        description
        price
        kind
        isSandbox
        parentTestId
        instruction
        parameters
        reportGeneratedTime
        reportGeneratedKind
        testSlug
        samples {
          id
          name
        }
        equipmentsRequired {
          requiredQuantity
          equipment {
            id
            serialNumber
            minimumQuantity
            name
          }
        }
        isPackage
        productionCostWRTLab {
          productionCost
          lab {
            id
            name
            displayName
            address
            countryCode
            mobileNumber
          }
          isSelected
        }
      }
    }
  }
`;

export const GET_LIVE_TESTS = gql`
  query getLiveTests($filters: LiveTestInput) {
    getLiveTests(filter: $filters) {
      total
      tests {
        id
        thumbnailUrl
        title
        serialNumber
        description
        isSandbox
        parentTestId
        productionCost
        instruction
        isPackage
        noOfTimesBooked
        dynamicLink
      }
    }
  }
`;

export const GET_ALL_LIVE_TESTS = gql`
  query getLiveTests($filters: LiveTestInput) {
    getLiveTests(filter: $filters) {
      total
      tests {
        id
        dynamicLink
        thumbnailUrl
        title
        serialNumber
        order
        isFeatured
        testSlug
        samples {
          serialNumber
          id
          thumbnail
        }
        equipmentsRequired {
          requiredQuantity
          equipment {
            name
          }
        }
        price
        discount
        kind
        updatedAt
        isPackage
        noOfTimesBooked
      }
    }
  }
`;

export const GET_SANDBOX_TESTS = gql`
  query getSandboxTests($filters: GetSandboxTestInput) {
    getSandboxTests(filter: $filters) {
      total
      tests {
        id
        dynamicLink
        thumbnailUrl
        title
        serialNumber
        samples {
          serialNumber
          id
          thumbnail
        }
        equipmentsRequired {
          requiredQuantity
          equipment {
            name
          }
        }
        price
        discount
        kind
        isPackage
        noOfTimesBooked
      }
    }
  }
`;

export const GET_ARCHIVE_TESTS = gql`
  query getArchivedTests($filters: GetLiveTestInput) {
    getArchivedTests(filter: $filters) {
      total
      tests {
        id
        title
        dynamicLink
        serialNumber
        samples {
          serialNumber
          id
          thumbnail
        }
        equipmentsRequired {
          requiredQuantity
          equipment {
            name
          }
        }
        price
        discount
        kind
        isPackage
        noOfTimesBooked
      }
    }
  }
`;

export const GET_LIVE_PACKAGES = gql`
  query getLivePkgs($filters: LiveTestInput) {
    getLivePkgs(filter: $filters) {
      total
      tests {
        id
        dynamicLink
        thumbnailUrl
        title
        serialNumber
        order
        isFeatured
        testSlug
        samples {
          serialNumber
          id
          thumbnail
        }
        equipmentsRequired {
          requiredQuantity
          equipment {
            name
          }
        }
        price
        discount
        kind
        updatedAt
        isPackage
        noOfTimesBooked
      }
    }
  }
`;

export const GET_ALL_TESTS_BY_CATEGORY = gql`
  query getAllTestsByCategory($categoryId: String!) {
    getTestsByCategory(categoryId: $categoryId, filter: {}) {
      tests {
        id
        title
        thumbnailUrl
      }
      total
    }
  }
`;

// GETTING EQUIPMENTS
export const GET_TEST_LOGS = gql`
  query getTestChangeLogs($filters: GetLogsInput!, $id: String!) {
    getTestChangeLogs(filters: $filters, id: $id) {
      total
      logs {
        actionBy {
          name
        }
        createdAt
        message
      }
    }
  }
`;

export const GET_LIVE_TESTS_AND_PACKAGES = gql`
  query getLiveTestsAndPackages($filters: GetLiveTestInput) {
    getLiveTestsAndPackages(filter: $filters) {
      tests {
        id
        title
        serialNumber
        price
      }
      total
    }
  }
`;

export const GET_TEST_CUSTOMERS = gql`
  query getCustomersByTestId($testId: String!, $filters: PaginationInput!) {
    getCustomersByTestId(testId: $testId, filters: $filters) {
      customers {
        id
        name
        age
        email
        countryCode
        mobileNumber
        sex
      }
      total
    }
  }
`;

export const GET_ALL_ADDONS = gql`
  query getAllAddonsTests($filters: GetAddonsTestInput!) {
    getAllAddonsTests(filters: $filters) {
      addOnsTests {
        id
        test {
          id
          title
        }
        title
        order
        serialNumber
        isFeatured
      }
      total
    }
  }
`;
