import { gql } from '@apollo/client';

export const GET_CURRENT_SAMPLE_COLLECTED_ORDERS = gql`
  query getCurrentOrdersAsOperator($filters: GetOrdersInput) {
    getCurrentOrdersAsOperator(filters: $filters) {
      total
      orders {
        orderId
        sampleCollectedAt
        id
        tests {
          id
          title
        }
        patient {
          id
          name
          age
          sex
        }
        requiredSamples {
          linkedEquipment
        }
        reports {
          test
          reportUrl
          isApproved
          uploadedAt
          rejectionReason
        }
        isWholeReportUpload
        wholeReport {
          reportUrl
          isApproved
          uploadedAt
          rejectionReason
        }
      }
    }
  }
`;

export const GET_ORDER_HISTORY = gql`
  query getOrderHistory($filters: GetOrdersInput) {
    getOrderHistory(filters: $filters) {
      total
      orders {
        orderId
        sampleCollectedAt
        id
        tests {
          id
          title
        }
        patient {
          id
          name
          age
          sex
        }
        requiredSamples {
          linkedEquipment
        }
        reports {
          test
          reportUrl
          isApproved
          uploadedAt
          rejectionReason
        }
        isWholeReportUpload
        wholeReport {
          reportUrl
          isApproved
          uploadedAt
          rejectionReason
        }
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
