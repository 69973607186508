import { gql } from '@apollo/client';

// GETTING SAMPLES
export const GET_ALL_CUSTOMER_CARTS = gql`
  query getCustomerCartHistory($filters: GetCustomerCartHistoryInput!) {
    getCustomerCartHistory(filters: $filters) {
      carts {
        id
        subTotal
        createdAt
        tests {
          id
          title
        }
        customer {
          id
          name
          countryCode
          mobileNumber
        }
      }
      total
    }
  }
`;

export const GET_CART_TEMPLATES = gql`
  query getWhatsAppCampaignTemplates {
    getWhatsAppCampaignTemplates {
      templates {
        id
        name
        body
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
