import { gql } from '@apollo/client';

// CREATE CTA BANNER

export const CREATE_CTA_BANNER = gql`
  mutation createCtaBanner($data: CtaBannerInput!) {
    createCtaBanner(data: $data) {
      CtaBanner {
        id
        title
        thumbnail
        buttonTitle
        content
        coupon
        serialNumber
        isFeatured
        test {
          id
          title
        }
      }
    }
  }
`;

// UPDATE BANNER
export const UPDATE_CTA_BANNER = gql`
  mutation updateCtaBanner($data: CtaBannerInput!, $bannerId: String!) {
    updateCtaBanner(data: $data, bannerId: $bannerId) {
      CtaBanner {
        id
        title
        thumbnail
        buttonTitle
        content
        coupon
        serialNumber
        isFeatured
        test {
          id
          title
        }
      }
    }
  }
`;

// TOGGLE FEATURE
export const TOGGLE_FEATURE_CTA_BANNER = gql`
  mutation toggleFeatureCtaBanner($id: String!) {
    toggleFeatureCtaBanner(id: $id) {
      result
    }
  }
`;

// DELETE CTA BANNER
export const DELETE_CTA_BANNER = gql`
  mutation removeCtaBanner($bannerId: String!) {
    removeCtaBanner(bannerId: $bannerId) {
      result
    }
  }
`;

export const LINK_TEST_WITH_CTA_BANNER = gql`
  mutation updateTestToCtaBanner($test: String!, $bannerId: String!) {
    updateTestToCtaBanner(test: $test, bannerId: $bannerId) {
      CtaBanner {
        id
        title
        thumbnail
        order
        serialNumber
        isFeatured
        coupon
        content
        buttonTitle
        test {
          id
          title
        }
      }
    }
  }
`;

export const TOGGLE_CTA_FIRST_TIME = gql`
  mutation toggleCtaBannerFirstTime($bannerId: String!) {
    toggleCtaBannerFirstTime(bannerId: $bannerId)
  }
`;

export const TOGGLE_GUEST_CTA = gql`
  mutation toggleCtaBannerAsGuest($bannerId: String!) {
    toggleCtaBannerAsGuest(bannerId: $bannerId)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
