import client from '../../apollo';
import { CREATE_LAB, ENABLE_MOBILE_LAB, UPDATE_LAB } from './graphql/mutation';
import { GET_LABS } from './graphql/query';

export const getLabs = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_LABS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getLabs;
  } catch (error) {
    return [];
  }
};

export const createLab = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_LAB,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.createLab?.lab;
  } catch (error) {
    return false;
  }
};

export const updateLab = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_LAB,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.updateLab?.lab;
  } catch (error) {
    return false;
  }
};

export const toggleIsFeatureLab = async (labId) => {
  try {
    const response = await client.mutate({
      mutation: ENABLE_MOBILE_LAB,
      fetchPolicy: 'network-only',
      variables: {
        labId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const sample = () => {};
