import { gql } from '@apollo/client';

export const ADD_PINCODE = gql`
  mutation createPinCode($data: createPinCodeInput!) {
    createPinCode(data: $data) {
      isExist
      pinCode {
        id
        code
        area
        isDisable
      }
    }
  }
`;

export const TOGGLE_ENABLE_PINCODE = gql`
  mutation toggleEnablePinCode($pinCodeId: String!) {
    toggleEnablePinCode(pinCodeId: $pinCodeId)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
