import { gql } from '@apollo/client';

export const GET_ALL_DOCTORS = gql`
  query getAllDoctors($filters: getAllDoctorInput!) {
    getAllDoctors(filters: $filters) {
      doctors {
        commissionPercentage
        countryCode
        designation
        isDisabled
        email
        id
        mobileNumber
        name
        revenueGenerated
        doctorSlug
        totalCommissionEarned
        profilePicture
        profileViewCount
      }
      total
    }
  }
`;

export const GET_DOCTOR_BY_ID = gql`
  query GetDoctorByIdAsAdmin($doctorId: String!) {
    getDoctorByIdAsAdmin(doctorId: $doctorId) {
      doctor {
        commissionPercentage
        designation
        email
        isDisabled
        id
        mobileNumber
        name
        experience
        doctorSlug
        revenueGenerated
        totalCommissionEarned
        profilePicture
        digitalProfileUrl
        profileViewCount
        clinicDetails {
          clinicName
          clinicAddress
          morningTime
          eveningTime
          clinicAddressLat
          clinicAddressLong
          location {
            id
            latitude
            longitude
            pincode
            state
            address
            city
          }
        }
        social {
          facebook
          instagram
          linkedin
          youtube
        }
      }
    }
  }
`;

export const GET_DOCTOR_LINKED_ORDERS = gql`
  query GetOrdersByDoctorIdAsAdmin($doctorId: ID!, $filters: GetDoctorInput) {
    getOrdersByDoctorIdAsAdmin(doctorId: $doctorId, filters: $filters) {
      orders {
        id
        orderId
        customer {
          id
          name
        }
        usedCoins
        serialNumber
        orderId
        coinsApplied
        deliveryCharges
        patient {
          id
          name
        }
        scheduledAt
        sampleCollectedAt
        completedAt
        orderStatus
        orderTransitStatus
        offerApplied {
          id
        }
        total
        convenienceFees
        subTotal
        paymentVia
        paymentStatus
        orderSummary {
          subTotal
          cartTotalPrice
          payableToLab
        }
        createdAt
        cancelledBy {
          userRole
        }
        sampleSubmitTo {
          id
          name
        }
        bookedAt
        source
        doctorAssigned
      }
      isOrderExist
    }
  }
`;

export const DOCTOR_REVENUE_ORDERS = gql`
  query GetCompletedOrdersByDoctorIdAsAdmin(
    $doctorId: ID!
    $filters: GetDoctorInput
  ) {
    getCompletedOrdersByDoctorIdAsAdmin(
      doctorId: $doctorId
      filters: $filters
    ) {
      orders {
        id
        orderId
        patient {
          id
          name
        }
        totalAmount
        profitPerOrder
        doctorsCommission
        profitWithoutDoctorCommission
      }
    }
  }
`;

export const GET_XL_DATA = gql`
  query GetExcelForDoctor($filters: getExcelDataInput) {
    getExcelForDoctor(filters: $filters) {
      data {
        orderId
        amountToPay
        customerName
        customerTests
        invoiceAmount
        percentageDefined
        totalRevenueGenerated
      }
    }
  }
`;

export const GET_PROFILE_URL = gql`
  query GetDoctorDigitalProfileByIdAsAdmin($doctorSlug: String!) {
    getDoctorDigitalProfileByIdAsAdmin(doctorSlug: $doctorSlug) {
      publicUrl
    }
  }
`;

export const GET_DOCTOR_CARD_TEMPLATES = gql`
  query {
    getDoctorCardTemplates {
      cardTemplate {
        id
        backUrl
        frontUrl
        createdAt
        updatedAt
        name
      }
    }
  }
`;

export const GENERATE_DOCTOR_CARD = gql`
  mutation ($data: GenerateDoctorCardInput!) {
    generateDoctorCard(data: $data) {
      backUrl
      frontUrl
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
