import { Button, Space, Tooltip, Typography, message } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Back } from '../../assets/icons/back.svg';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT, ROUTES } from '../../common/constants';
import { getRepeatedCustomersData } from './dashboard-repository';

function BookedCustomers() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state || {};
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [paginationProp, setPaginationProp] = useState();

  const fetchRepeatedCustomers = async ({ currentSkip }) => {
    try {
      const payload = {
        startDate: data?.startDate,
        endDate: data?.endDate,
        skip: currentSkip,
        limit: limit
      };
      const response = await getRepeatedCustomersData(data?.value, payload);
      const newPaginationProp = {
        total: response.totalRepeatedCustomer,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setCustomers(response.totalRepeatedCustomerDetails.slice());
      setLoadingData(false);
      setLoading(false);
    } catch (error) {
      setLoadingData(false);
      setLoading(false);
      message.error('Failed to fetch the customers');
    }
  };

  useEffect(() => {
    fetchRepeatedCustomers({ currentSkip: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // COLUMNS
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        const { customerId, customerDetails } = record;
        // eslint-disable-next-line no-lone-blocks
        {
          if (customerDetails.name.length < 20) {
            return (
              <Typography
                className="clickable-title"
                onClick={() => {
                  const url = `${ROUTES.CUSTOMER_DETAIL}?customerId=${customerId}`;
                  navigate(url);
                }}
              >
                {customerDetails.name}
              </Typography>
            );
          }

          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                const url = `${ROUTES.CUSTOMER_DETAIL}?customerId=${customerId}`;
                navigate(url);
              }}
            >
              <Tooltip title={customerDetails.name} color="#232e78">
                {customerDetails.name.slice(0, 20)}...
              </Tooltip>
            </Typography>
          );
        }
      }
    },
    {
      title: 'Mobile number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      render: (value, record) => {
        const { customerDetails } = record;
        return (
          <p>
            {customerDetails.countryCode}-{customerDetails.mobileNumber}
          </p>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (value) => {
        return value || '-';
      }
    },
    {
      title: 'Total Orders',
      dataIndex: 'totalOrders',
      key: 'totalOrders',
      render: (value, record) => {
        const { totalOrders } = record;
        return totalOrders;
      }
    }
  ];

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Typography.Text className="main-header-title">
              Repeated Customers ({data?.value || ''}+)
            </Typography.Text>
          </Space>
          {/* <div className="repeated-customers-date">
            <span>{data?.dataType}</span>
            <span>
              {data?.startDate} - {data?.endDate}
            </span>
          </div> */}
        </div>
        {/* CREATE MODAL */}
      </Header>
      <div className="main-content customers-content">
        <div className="customers-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              data={customers}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchRepeatedCustomers({
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
        {/* DISABLE MODAL */}
      </div>
    </div>
  );
}

export default BookedCustomers;
