import client from '../../apollo';
import {
  CREATE_TIMELY_OFFER,
  TOGGLE_ENABLE_TIMELY_OFFER,
  UPDATE_TIMELY_OFFER
} from './graphql/mutation';
import { GET_TIMELY_OFFERS } from './graphql/query';

export const getTimelyOffers = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_TIMELY_OFFERS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getTimelyOffers;
  } catch (error) {
    return [];
  }
};

export const createTimelyOffer = async (data, offerType) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_TIMELY_OFFER,
      fetchPolicy: 'network-only',
      variables: { data, offerType }
    });

    return response?.data?.createTimelyOffer?.offer;
  } catch (error) {
    return false;
  }
};

export const toggleTimelyOfferEnable = async (offerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_ENABLE_TIMELY_OFFER,
      fetchPolicy: 'network-only',
      variables: {
        offerId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const updateTimelyOffer = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_TIMELY_OFFER,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.updateTimelyOffer?.offer;
  } catch (error) {
    return false;
  }
};

export const test = async () => {};
