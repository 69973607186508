import client from '../../apollo';
import {
  CANCEL_SCHEDULED_NOTIFICATION,
  COMPOSE_MESSAGE,
  CREATE_MESSAGE,
  DELETE_MESSAGE
} from './graphql/mutation';
import {
  GET_MESSAGES,
  GET_SCHEDULED_NOTIFICATION_DETAILS
} from './graphql/query';

export const getNotifications = async (filters) => {
  try {
    const { skip, limit, query } = filters;
    const response = await client.query({
      query: GET_MESSAGES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getNotifications;
  } catch (error) {
    return false;
  }
};

export const createNotification = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_MESSAGE,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.createNotification?.notification;
  } catch (error) {
    return false;
  }
};

export const composeNotification = async (data) => {
  try {
    const response = await client.mutate({
      mutation: COMPOSE_MESSAGE,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const deleteNotification = async (notificationId) => {
  try {
    const response = await client.query({
      query: DELETE_MESSAGE,
      fetchPolicy: 'network-only',
      variables: {
        notificationId
      }
    });
    return response?.data;
  } catch (error) {
    return [];
  }
};

export const getScheduledNotification = async (notificationId, filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_SCHEDULED_NOTIFICATION_DETAILS,
      fetchPolicy: 'network-only',
      variables: {
        notificationId,
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getScheduledNotification;
  } catch (error) {
    return false;
  }
};

export const cancelScheduledNotification = async (notificationId) => {
  try {
    const response = await client.query({
      query: CANCEL_SCHEDULED_NOTIFICATION,
      fetchPolicy: 'network-only',
      variables: {
        notificationId
      }
    });
    return response?.data;
  } catch (error) {
    return [];
  }
};

export const sample = () => {};
