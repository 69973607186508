import { gql } from '@apollo/client';

export const COLLECT_SAMPLES_AS_OPERATOR = gql`
  mutation collectSamplesAsOperator($orderId: String!) {
    collectSamplesAsOperator(orderId: $orderId) {
      isOrderExist
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
