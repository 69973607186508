import { gql } from '@apollo/client';

export const GET_BANNER_AS_ADMIN = gql`
  query getBannersAsAdmin($filters: GetBannerInput!) {
    getBannersAsAdmin(filters: $filters) {
      total
      banners {
        id
        title
        thumbnail
        order
        serialNumber
        isFeatured
        isFirstTime
        test {
          id
          title
        }
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
