/* eslint-disable no-console */
import { Button, DatePicker, Space, Typography, message } from 'antd';
import { Header } from 'antd/es/layout/layout';
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CalenderIcon } from '../../assets/calendar.svg';
import { ReactComponent as Back } from '../../assets/icons/back.svg';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import { downloadAsExcel, getMonthDates } from '../../common/utils';
import { getOfferDetailsById } from './offers-repository';
import './offers.less';

function OfferDetails() {
  const navigate = useNavigate();
  const { oID: orderId } = useParams();
  const limit = GLOBAL_PAGINATION_LIMIT;
  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [offerDetails, setOfferDetails] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [startDateVal, setStartDateVal] = useState(null);
  const [endDateVal, setEndDateVal] = useState(null);
  const [couponCodeVal, setCouponCodeVal] = useState(null);

  const getOfferDetails = async ({ currentSkip }) => {
    try {
      const filters = {
        offerId: orderId,
        skip: currentSkip,
        limit: limit,
        startDate: startDateVal,
        endDate: endDateVal
      };
      const response = await getOfferDetailsById(filters);
      const newPaginationProp = {
        total: response.totalCount,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setCouponCodeVal(response.couponCode);
      setOfferDetails(response.history.slice());
      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
      message.error('Failed to fetch details');
    }
  };

  useEffect(() => {
    getOfferDetails({ currentSkip: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateVal, endDateVal]);

  const columns = [
    {
      title: 'ORDER ID',
      dataIndex: 'orderId',
      key: '1',
      render: (value) => {
        return value?.orderId;
      }
    },
    {
      title: 'Patient',
      dataIndex: 'orderId',
      key: '2',
      render: (value) => {
        return value?.patient?.name;
      }
    },
    {
      title: 'Booked On',
      dataIndex: 'orderId',
      key: '3',
      render: (value) => {
        return dayjs(value?.orderId.bookedAt).format('DD/MM/YYYY');
      }
    },
    {
      title: 'Total Amount',
      dataIndex: 'orderId',
      key: '4',
      render: (value) => {
        return `₹ ${value?.subTotal}`;
      }
    }
  ];

  const exportExelFile = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Processing'
    });
    const toUpdateArray = [];
    const filters = {
      offerId: orderId,
      skip: 0,
      limit: 1000000,
      startDate: startDateVal,
      endDate: endDateVal
    };
    try {
      const response = await getOfferDetailsById(filters);
      const { history } = response;
      if (history.length < 1) {
        message.error('No data to export');
        message.destroy(key);
        return;
      }
      for (let i = 0; i < history.length; i += 1) {
        const element = history[i].orderId;
        toUpdateArray.push({
          ORDER_ID: element.orderId,
          PATIENT: element.patient.name,
          BOOKED_ON: dayjs(element.orderId.bookedAt).format('DD/MM/YYYY'),
          TOTAL_AMT: `₹ ${element?.subTotal}`
        });
      }
      downloadAsExcel(toUpdateArray);
      message.destroy(key);
    } catch (error) {
      message.open({
        key,
        type: 'error',
        content: 'Failed to export'
      });
      message.destroy(key);
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer offer-details-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Typography.Text className="main-header-title">
              {couponCodeVal || 'Offer Details'}
            </Typography.Text>
          </Space>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            {startDateVal && endDateVal && (
              <Button
                className="orderHistory"
                type="default"
                onClick={exportExelFile}
              >
                Export to Excel
              </Button>
            )}
            <DatePicker
              suffixIcon={<CalenderIcon />}
              picker="month"
              onChange={(value) => {
                if (value) {
                  const { startDateValue, endDateValue } = getMonthDates(value);
                  setStartDateVal(startDateValue);
                  setEndDateVal(endDateValue);
                } else {
                  setStartDateVal(null);
                  setEndDateVal(null);
                }
              }}
            />
          </div>
          {/* CREATE MODAL */}
        </div>
      </Header>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={offerDetails}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              getOfferDetails({
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default OfferDetails;
