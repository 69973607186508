import { gql } from '@apollo/client';

// ADD SLOT BY DAY
export const ADD_SLOT_BY_DAY = gql`
  mutation addSlotToDay($day: DAY, $startTime: Int!) {
    addSlotToDay(data: { day: $day, startTime: $startTime }) {
      slot {
        id
        name
        startTime
        day
      }
    }
  }
`;

// REMOVE SLOT BY DAY
export const REMOVE_SLOT_BY_DAY = gql`
  mutation removeSlotToDay($id: String!) {
    removeSlotToDay(id: $id)
  }
`;

export const UPDATE_SLOT_MAX_LIMIT = gql`
  mutation updateSlotMaxLimit($slotId: String!, $limit: Int!) {
    updateSlotMaxLimit(slotId: $slotId, limit: $limit) {
      slot {
        id
        name
        startTime
        maxBookingLimit
        day
      }
    }
  }
`;

export const REMOVE_SLOT_MAX_LIMIT = gql`
  mutation removeSlotMaxLimit($slotId: String!) {
    removeSlotMaxLimit(slotId: $slotId) {
      slot {
        id
        name
        startTime
        maxBookingLimit
        day
      }
    }
  }
`;

export const TOGGLE_HOLIDAY = gql`
  mutation toggleCalendarHoliday($date: String!) {
    toggleCalendarHoliday(date: $date)
  }
`;

// REMOVE SLOT BY DAY
export const REMOVE_ALL_SLOT_BY_DAY = gql`
  mutation removeAllSlotsForDay($day: DAY) {
    removeAllSlotsForDay(day: $day)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
