/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  DatePicker,
  Select,
  Spin,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import './dashboard.less';

// ICONS
import { RetweetOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CalenderIcon } from '../../assets/calendar.svg';
import { ReactComponent as CustomersIcon } from '../../assets/customers.svg';
import { ReactComponent as ReportsIcon } from '../../assets/reports.svg';
import { ReactComponent as DollarIcon } from '../../assets/revenue.svg';
import { ROUTES } from '../../common/constants';
import { getMonthDates, getStartAndEndDateFromWeek } from '../../common/utils';
import {
  getActiveDashboardData,
  getDashboardWithinTimeFrame,
  getMonthlyDashboardData,
  getOverallDashboardData,
  refreshOverallDashboardData
} from './dashboard-repository';

function Dashboard() {
  // STATES
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const [type, setType] = useState('all');
  const [overallData, setOverallData] = useState();
  const [activeData, setActiveData] = useState();
  const [monthlyData, setMonthlyData] = useState();
  const [startDateVal, setStartDateVal] = useState(new Date());
  const [monthlyStartDateVal, setMonthlyStartDateVal] = useState();
  const [monthlyEndDateVal, setMonthlyEndDateVal] = useState();
  const [endDateVal, setEndDateVal] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [overallLoading, setOverallLoading] = useState(false);
  const [overallMonthlyLoading, setOverallMonthlyLoading] = useState(false);

  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const fetchOverallData = async () => {
    setOverallLoading(true);
    try {
      const response = await getOverallDashboardData();
      // eslint-disable-next-line no-console
      setOverallData(response);
    } catch (error) {
      //
    }
    setOverallLoading(false);
  };

  const fetchActiveData = async () => {
    try {
      setIsLoading(true);
      const response = await getActiveDashboardData();
      setActiveData(response);
      setIsLoading(false);
    } catch (error) {
      // Handle error
      setIsLoading(false);
    }
  };

  const fetchOverallDashboardData = async () => {
    if (!startDateVal || !endDateVal) {
      fetchOverallData();
    } else {
      try {
        setOverallLoading(true);
        let response;
        if (startDateVal && endDateVal) {
          response = await getDashboardWithinTimeFrame({
            startDate: startDateVal,
            endDate: endDateVal
          });
        } else if (startDateVal) {
          response = await getDashboardWithinTimeFrame({
            startDate: startDateVal.toISOString()
          });
        } else {
          response = await getDashboardWithinTimeFrame();
        }
        setOverallData(response);
        setOverallLoading(false);
        message.success('Updated');
      } catch (error) {
        // Handle error
        setOverallLoading(false);
      }
    }
  };

  const fetchMOnthlyDashboardData = async () => {
    setOverallMonthlyLoading(true);
    try {
      const response = await getMonthlyDashboardData({
        startDate: monthlyStartDateVal || undefined,
        endDate: monthlyEndDateVal || undefined
      });
      // eslint-disable-next-line no-console
      setMonthlyData(response);
    } catch (error) {
      //
    }
    setOverallMonthlyLoading(false);
  };

  useEffect(() => {
    fetchOverallData();
    fetchActiveData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchMOnthlyDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyEndDateVal, monthlyStartDateVal]);

  let newProfit = 0;
  let profitPerPaidOrder = 0;
  let aov = 0;
  if (overallData) {
    newProfit = overallData.totalRevenue - overallData.totalProductionCost || 0;
    profitPerPaidOrder = newProfit / overallData.totalPaidReports || 0;
    aov =
      overallData.totalRevenue /
        (overallData.totalPaidReports + overallData.totalFreeReports) || 0;
  }

  let newMonthlyProfit = 0;
  let monthlyProfitPerPaidOrder = 0;
  let monthlyAov = 0;
  let freeReportsOrderValue = 0;
  let paidGrossProfit = 0;
  if (monthlyData) {
    newMonthlyProfit =
      monthlyData.totalPaidRevenue - monthlyData.totalPaidProductionCost;
    monthlyProfitPerPaidOrder =
      newMonthlyProfit / monthlyData.totalPaidReports || 0;
    monthlyAov =
      monthlyData.totalPaidRevenue / monthlyData.totalPaidReports || 0;
    paidGrossProfit =
      monthlyData.totalPaidRevenue - monthlyData.totalPaidProductionCost;
    freeReportsOrderValue =
      monthlyData.totalFreeProductionCost / monthlyData.totalFreeReports || 0;
  }

  useEffect(() => {
    if (type === 'all') {
      fetchOverallData();
    } else {
      fetchOverallDashboardData(); // Fetch data with filters when a specific option is selected
    }
  }, [startDateVal, endDateVal, type]);

  const handleNavigation = (dataType, dataValue) => {
    if (dataType === 'MONTHLY') {
      const { startDateValue, endDateValue } = getMonthDates(new Date());
      const data = {
        value: dataValue,
        dataType,
        startDate:
          monthlyStartDateVal || dayjs(startDateValue).format('YYYY-MM-DD'),
        endDate: monthlyEndDateVal || dayjs(endDateValue).format('YYYY-MM-DD')
      };
      navigate(ROUTES.BOOKED_CUSTOMERS, { state: { data } });
    } else {
      const data = {
        value: dataValue,
        dataType,
        startDate:
          type === 'all' ? null : dayjs(startDateVal).format('YYYY-MM-DD'),
        endDate: type === 'all' ? null : dayjs(endDateVal).format('YYYY-MM-DD')
      };
      navigate(ROUTES.BOOKED_CUSTOMERS, { state: { data } });
    }
  };

  const dateSelectionType = () => {
    switch (type) {
      case 'date':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(value) => {
              const utcTime = value;
              const utcDate = new Date(utcTime);
              // Convert to IST by adding 5 hours and 30 minutes
              utcDate.setHours(utcDate.getHours() + 5);
              utcDate.setMinutes(utcDate.getMinutes() + 30);
              const istTime = dayjs(utcDate.toISOString()).format('YYYY-MM-DD');
              setStartDateVal(istTime);
              setEndDateVal(istTime);
            }}
            onOk={fetchOverallDashboardData} // Pass the function reference without invoking it
          />
        );
      case 'week':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(date, dateString) => {
              const match = dateString.match(/^(\d+)-(\d+)(?:st|nd|rd|th)$/);
              if (match) {
                const year = match[1];
                const day = match[2];
                const { startDate, endDate } = getStartAndEndDateFromWeek(
                  year,
                  day
                );
                setStartDateVal(dayjs(startDate).format('YYYY-MM-DD'));
                setEndDateVal(dayjs(endDate).format('YYYY-MM-DD'));
              } else {
                //
              }
            }}
            onOk={fetchOverallDashboardData}
          />
        );
      case 'month':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(value) => {
              const { startDateValue, endDateValue } = getMonthDates(value);

              setStartDateVal(dayjs(startDateValue).format('YYYY-MM-DD'));
              setEndDateVal(dayjs(endDateValue).format('YYYY-MM-DD'));
            }}
            onOk={fetchOverallDashboardData}
          />
        );
      case 'custom':
        return (
          <RangePicker
            suffixIcon={<CalenderIcon />}
            onChange={(value) => {
              if (value && value.length === 2) {
                setStartDateVal(dayjs(value[0]).format('YYYY-MM-DD'));
                setEndDateVal(dayjs(value[1]).format('YYYY-MM-DD'));
              } else {
                setStartDateVal(undefined); // Clear the start date when no range is selected
                setEndDateVal(undefined); // Clear the end date when no range is selected
              }
            }}
            onOk={fetchOverallDashboardData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="content-wrapper  ">
      <Header className="main-header">
        <Typography.Text className="dashboard-title">Dashboard</Typography.Text>
      </Header>
      <div className="main-content dashboard-contents ">
        <div className="overall">
          <div className="overall-spread">
            <Typography.Text>Overall</Typography.Text>
            <div
              style={{
                display: 'flex',
                gap: '10px'
              }}
            >
              <div className="select-date-time">
                <Select
                  className="day-selector"
                  value={type}
                  onChange={(value) => {
                    setType(value);
                  }}
                >
                  <Option value="all">All</Option>
                  <Option value="date">Date</Option>
                  <Option value="week">Week</Option>
                  <Option value="month">Month</Option>
                  <Option value="custom">Custom</Option>
                </Select>
                {dateSelectionType()}
              </div>
              {type === 'all' ? (
                <Button
                  // loading={overallLoading}
                  className="refresh-dashboard"
                  onClick={async () => {
                    setOverallLoading(true);
                    const response = await refreshOverallDashboardData();
                    // eslint-disable-next-line no-console
                    setOverallData(response);
                    setOverallLoading(false);
                  }}
                >
                  <RetweetOutlined />
                </Button>
              ) : null}
            </div>
          </div>
          {overallLoading ? (
            <Spin
              size="default"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '250px'
              }}
            />
          ) : (
            <div>
              {/* 1st row */}
              <div className="overall-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalOrders || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <ReportsIcon />
                      Total Reports
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalCustomers || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Total Customers
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {newProfit.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Gross Profit
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              {/* 2nd row */}
              <div className="overall-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalRevenue?.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Total Revenue
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalProductionCost?.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Total Production Cost
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalConvenienceFees?.toLocaleString(
                      'en-IN',
                      {
                        style: 'currency',
                        currency: 'INR'
                      }
                    ) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Total Convenience Fee
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              {/* 3rd row */}
              <div className="overall-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalAppliedDiscount?.toLocaleString(
                      'en-IN',
                      {
                        style: 'currency',
                        currency: 'INR'
                      }
                    ) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Discount Applied
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalCoinsUsed?.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Coins Applied
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card ">
                  <Typography.Text>
                    {profitPerPaidOrder.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Profit Per Paid Order
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              {/* 4th row */}
              <div className="overall-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalPaidReports || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <ReportsIcon />
                      Paid Reports
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalFreeReports || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <ReportsIcon />
                      Free Reports
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {aov.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Average Order Value
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              {/* 5th row */}
              <div className="overall-contents">
                <Card
                  className="content-card clickable-card"
                  onClick={() => {
                    handleNavigation('DAILY', 1);
                  }}
                >
                  <Typography.Text>
                    {overallData?.totalRepeatedCustomer || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Repeated Customers (1+ orders)
                    </Typography.Text>
                  </div>
                </Card>
                <Card
                  className="content-card clickable-card"
                  onClick={() => {
                    handleNavigation('DAILY', 3);
                  }}
                >
                  <Typography.Text>
                    {overallData?.totalMoreThanThreeOrdersRepeatedCustomer || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Repeated Customers (3+ orders)
                    </Typography.Text>
                  </div>
                </Card>
                <Card
                  className="content-card clickable-card"
                  onClick={() => {
                    handleNavigation('DAILY', 5);
                  }}
                >
                  <Typography.Text>
                    {overallData?.totalMoreThanFiveOrdersRepeatedCustomer || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Repeated Customers (5+ orders)
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              {/* 6th row */}
              <div className="overall-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalDeletedAccounts || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Deleted Customers
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalMaleCustomers || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Male Customers
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalFemaleCustomers || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Female Customers
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              {/* 7th Row */}
              <div className="overall-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {overallData?.totalPatient || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Total Patients
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card empty-class">
                  {/* <Typography.Text>
                    {overallData?.totalMaleCustomers || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Male Customers
                    </Typography.Text>
                  </div> */}
                </Card>
                <Card className="content-card empty-class">
                  {/* <Typography.Text>
                    {overallData?.totalFemaleCustomers || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Female Customers
                    </Typography.Text>
                  </div> */}
                </Card>
              </div>
            </div>
          )}
        </div>
        <div className="active">
          <div className="text-datepicker">
            <Typography.Text>Active</Typography.Text>
          </div>
          {isLoading ? (
            <Spin
              size="default"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '250px'
              }}
            />
          ) : (
            <div>
              <div className="active-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {activeData?.totalReportBooked || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      Reports Booked
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {activeData?.totalSampleCollected || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      Sample Collected
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {activeData?.totalSentToLab || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      Reports Sent To Lab
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              <div className="active-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {activeData?.totalReportUploaded || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      Reports Uploaded
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {activeData?.totalReportPublished || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      Reports Published
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {activeData?.totalReportDelivered || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      Reports Delivered
                    </Typography.Text>
                  </div>
                </Card>
              </div>
            </div>
          )}
        </div>
        <div
          className="overall"
          style={{
            marginTop: '30px'
          }}
        >
          <div className="overall-spread">
            <Typography.Text>Monthly</Typography.Text>
            <div
              style={{
                display: 'flex',
                gap: '10px'
              }}
            >
              <div className="select-date-time">
                <DatePicker
                  suffixIcon={<CalenderIcon />}
                  picker="month"
                  defaultValue={dayjs(new Date())}
                  onChange={(value) => {
                    const { startDateValue, endDateValue } =
                      getMonthDates(value);
                    setMonthlyStartDateVal(
                      dayjs(startDateValue).format('YYYY-MM-DD')
                    );
                    setMonthlyEndDateVal(
                      dayjs(endDateValue).format('YYYY-MM-DD')
                    );
                  }}
                  onOk={fetchMOnthlyDashboardData}
                />
              </div>
            </div>
          </div>
          {overallMonthlyLoading ? (
            <Spin
              size="default"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '250px'
              }}
            />
          ) : (
            <div>
              {/* 1st row */}
              <div className="overall-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {monthlyData?.totalOrders || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <ReportsIcon />
                      Total Reports
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {monthlyData?.totalCustomers || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Total Customers
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {monthlyData?.totalCoinsUsed?.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Coins Applied
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              {/* 2nd row */}
              <div className="overall-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {monthlyData?.totalPaidReports || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <ReportsIcon />
                      Paid Reports
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {monthlyData?.totalFreeReports || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <ReportsIcon />
                      Free Reports
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {monthlyData?.totalAppliedDiscount?.toLocaleString(
                      'en-IN',
                      {
                        style: 'currency',
                        currency: 'INR'
                      }
                    ) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Discount Applied
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              {/* 3rd row */}
              <div className="overall-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {monthlyData?.totalPaidRevenue.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Total Revenue of Paid Reports
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {monthlyData?.totalPaidProductionCost.toLocaleString(
                      'en-IN',
                      {
                        style: 'currency',
                        currency: 'INR'
                      }
                    ) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Paid Reports Production Cost
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {paidGrossProfit.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Gross Profit on Paid Reports
                    </Typography.Text>
                  </div>
                </Card>
              </div>

              {/* 4th row */}
              <div className="overall-contents">
                <Card className="content-card ">
                  <Typography.Text>
                    {monthlyProfitPerPaidOrder.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Profit Per Paid Order
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {monthlyData?.totalFreeProductionCost.toLocaleString(
                      'en-IN',
                      {
                        style: 'currency',
                        currency: 'INR'
                      }
                    ) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Free Reports Production Cost
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card">
                  <Typography.Text>
                    {monthlyAov.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Average Order Value of Paid Reports
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              {/* 5th Row */}
              <div className="overall-contents">
                <Card
                  className="content-card clickable-card"
                  onClick={() => {
                    handleNavigation('MONTHLY', 1);
                  }}
                >
                  <Typography.Text>
                    {monthlyData?.totalRepeatedCustomer || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Repeated Customers (1+ orders)
                    </Typography.Text>
                  </div>
                </Card>
                <Card
                  className="content-card clickable-card"
                  onClick={() => {
                    handleNavigation('MONTHLY', 3);
                  }}
                >
                  <Typography.Text>
                    {monthlyData?.totalMoreThanThreeOrdersRepeatedCustomer || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Repeated Customers (3+ orders)
                    </Typography.Text>
                  </div>
                </Card>
                <Card
                  className="content-card clickable-card"
                  onClick={() => {
                    handleNavigation('MONTHLY', 5);
                  }}
                >
                  <Typography.Text>
                    {monthlyData?.totalMoreThanFiveOrdersRepeatedCustomer || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Repeated Customers (5+ orders)
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              <div className="overall-contents">
                <Card className="content-card ">
                  <Typography.Text>
                    {monthlyData?.totalOrdersWithMobile || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <ReportsIcon />
                      Orders by Mobile
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card ">
                  <Typography.Text>
                    {monthlyData?.totalOrdersWithWeb || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <ReportsIcon />
                      Orders by Web
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card ">
                  <Typography.Text>
                    {monthlyData?.totalOrdersWithAdmin || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <ReportsIcon />
                      Orders by Admin
                    </Typography.Text>
                  </div>
                </Card>
              </div>
              {/* 6th row */}
              <div className="overall-contents">
                <Card className="content-card">
                  <Typography.Text>
                    {freeReportsOrderValue.toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR'
                    }) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Order Value per Free Report
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card ">
                  <Typography.Text>
                    {monthlyData?.totalPatient || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <CustomersIcon />
                      Total Patients
                    </Typography.Text>
                  </div>
                </Card>
                <Card className="content-card empty-class">
                  {/* <Typography.Text>
                    {monthlyData?.totalFreeProductionCost.toLocaleString(
                      'en-IN',
                      {
                        style: 'currency',
                        currency: 'INR'
                      }
                    ) || 0}
                  </Typography.Text>
                  <div className="card-details">
                    <Typography.Text className="text-details">
                      <DollarIcon />
                      Free Reports Production Cost
                    </Typography.Text>
                  </div> */}
                </Card>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
