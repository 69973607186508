/* eslint-disable no-console */
import client from '../../apollo';
import {
  ADD_ADDONS,
  ADD_BULK_TESTS,
  ARCHIVE_TEST,
  CHECK_IF_SLUG_EXIST,
  CREATE_ADMIN_TESTIMONIAL,
  CREATE_CATEGORY,
  CREATE_EQUIPMENT,
  CREATE_SAMPLE,
  CREATE_TEST,
  REMOVE_ADDON,
  SYNC_TEST_TO_LIVE,
  SYNC_TO_LIVE,
  TOGGLE_ENABLE_CATEGORY,
  TOGGLE_FEATURE_ADDON,
  TOGGLE_FEATURE_CATEGORY,
  TOGGLE_FEATURE_TEST,
  TOGGLE_FEATURE_TESTIMONIAL,
  UNARCHIVE_TEST,
  UPDATE_CATEGORY,
  UPDATE_EQUIPMENT,
  UPDATE_SAMPLE,
  UPDATE_TEST
} from './graphql/mutation';
import {
  GET_ALL_ADDONS,
  GET_ALL_LIVE_TESTS,
  GET_ALL_TESTS_BY_CATEGORY,
  GET_ARCHIVE_TESTS,
  GET_CATEGORIES,
  GET_EQUIPMENTS,
  GET_LIVE_PACKAGES,
  GET_LIVE_TESTS,
  GET_LIVE_TESTS_AND_PACKAGES,
  GET_SAMPLES,
  GET_SANDBOX_TESTS,
  GET_TESTIMONIALS,
  GET_TEST_BY_ID,
  GET_TEST_CUSTOMERS,
  GET_TEST_LOGS
} from './graphql/query';

export const getCategories = async (filters) => {
  const { skip, limit, query, onlyEnabled } = filters;
  try {
    const response = await client.query({
      query: GET_CATEGORIES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query,
          onlyEnabled
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getCategories;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getEquipments = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_EQUIPMENTS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getEquipments;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getSamples = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_SAMPLES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getSamples;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getTestimonialsAsAdmin = async (filters) => {
  const { skip, limit } = filters;
  try {
    const response = await client.query({
      query: GET_TESTIMONIALS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getTestimonialsAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

// MUTATION QUERIES

export const toggleFeaturedCategory = async (categoryId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_FEATURE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: {
        categoryId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

// toggleFeaturedTest
export const toggleFeaturedTest = async (testId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_FEATURE_TEST,
      fetchPolicy: 'network-only',
      variables: {
        testId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const toggleEnabledCategory = async (categoryId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_ENABLE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: {
        categoryId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const getTestById = async (id) => {
  try {
    const response = await client.query({
      query: GET_TEST_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getTestById?.test;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const createTest = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_TEST,
      fetchPolicy: 'network-only',
      variables: data
    });
    // eslint-disable-next-line no-console
    return response?.data?.createTest?.test;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const updateTest = async (data, testId) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_TEST,
      fetchPolicy: 'network-only',
      variables: { data, testId }
    });
    // eslint-disable-next-line no-console
    return response?.data?.updateTest?.test;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const createCategory = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: data
    });
    // eslint-disable-next-line no-console
    return response?.data?.createCategory?.category;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const updateCategory = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: data
    });
    // eslint-disable-next-line no-console
    return response?.data?.updateCategory?.category;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const createSample = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_SAMPLE,
      fetchPolicy: 'network-only',
      variables: data
    });
    // eslint-disable-next-line no-console
    return response?.data?.createSample?.sample;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const createEquipment = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_EQUIPMENT,
      fetchPolicy: 'network-only',
      variables: data
    });
    // eslint-disable-next-line no-console
    return response?.data?.createEquipment?.equipment;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const createTestimonialAsAdmin = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_ADMIN_TESTIMONIAL,
      fetchPolicy: 'network-only',
      variables: data
    });
    // eslint-disable-next-line no-console
    return response?.data?.createTestimonialAsAdmin?.testimonial;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const toggleFeatureTestimonial = async (testimonialId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_FEATURE_TESTIMONIAL,
      fetchPolicy: 'network-only',
      variables: {
        testimonialId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};
export const getLiveTests = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_LIVE_TESTS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getLiveTests;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};
export const getAllLiveTests = async (filters) => {
  const { skip, limit, query, sort } = filters;
  const payload =
    !sort || sort.value === undefined
      ? {
          skip,
          limit,
          query
        }
      : { skip, limit, query, sort };

  try {
    const response = await client.query({
      query: GET_ALL_LIVE_TESTS,
      fetchPolicy: 'network-only',
      variables: {
        filters: payload
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getLiveTests;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getSandboxTests = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_SANDBOX_TESTS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getSandboxTests;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getAllTestLinkedToCategory = async (categoryId) => {
  try {
    const response = await client.query({
      query: GET_ALL_TESTS_BY_CATEGORY,
      fetchPolicy: 'network-only',
      variables: {
        categoryId
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getTestsByCategory?.tests;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const updateEquipment = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_EQUIPMENT,
      fetchPolicy: 'network-only',
      variables: data
    });
    // eslint-disable-next-line no-console
    return response?.data?.updateEquipment?.equipment;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const updateSample = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_SAMPLE,
      fetchPolicy: 'network-only',
      variables: data
    });
    // eslint-disable-next-line no-console
    return response?.data?.updateSample?.sample;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const getArchivedTests = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_ARCHIVE_TESTS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getArchivedTests;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const archiveTest = async (id) => {
  try {
    const response = await client.mutate({
      mutation: ARCHIVE_TEST,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const unarchiveTest = async (id) => {
  try {
    const response = await client.mutate({
      mutation: UNARCHIVE_TEST,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const syncSandboxToLive = async () => {
  try {
    await client.mutate({
      mutation: SYNC_TO_LIVE,
      fetchPolicy: 'network-only'
    });
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const syncSandboxTestToLive = async (testId) => {
  try {
    await client.mutate({
      mutation: SYNC_TEST_TO_LIVE,
      fetchPolicy: 'network-only',
      variables: { testId }
    });
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const getTestChangeLogs = async (filters, id) => {
  const { skip, limit } = filters;
  try {
    const response = await client.query({
      query: GET_TEST_LOGS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit
        },
        id
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getTestChangeLogs;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const createTestsInBulk = async (data) => {
  try {
    await client.mutate({
      mutation: ADD_BULK_TESTS,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const getLivePkgs = async (filters) => {
  const { skip, limit, query, sort } = filters;
  const payload =
    !sort || sort.value === undefined
      ? {
          skip,
          limit,
          query
        }
      : { skip, limit, query, sort };

  try {
    const response = await client.query({
      query: GET_LIVE_PACKAGES,
      fetchPolicy: 'network-only',
      variables: {
        filters: payload
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getLivePkgs;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getLiveTestsAndPackages = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_LIVE_TESTS_AND_PACKAGES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getLiveTestsAndPackages;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getTestCustomers = async (testId, filters) => {
  const { skip, limit } = filters;
  try {
    const response = await client.query({
      query: GET_TEST_CUSTOMERS,
      fetchPolicy: 'network-only',
      variables: {
        testId,
        filters: {
          skip,
          limit
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getCustomersByTestId;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getALlAddons = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_ALL_ADDONS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          query,
          limit
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getAllAddonsTests;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const toggleFeaturedAddon = async (addOnsTestId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_FEATURE_ADDON,
      fetchPolicy: 'network-only',
      variables: {
        addOnsTestId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const addRemoveAddons = async (testIds) => {
  try {
    const response = await client.mutate({
      mutation: ADD_ADDONS,
      fetchPolicy: 'network-only',
      variables: { testIds }
    });
    return response?.data?.createAddOnsTest;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const removeAddon = async (addOnsTestId) => {
  try {
    const response = await client.mutate({
      mutation: REMOVE_ADDON,
      fetchPolicy: 'network-only',
      variables: {
        addOnsTestId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const checkIfSlugExist = async (testSlug) => {
  try {
    const response = await client.mutate({
      mutation: CHECK_IF_SLUG_EXIST,
      fetchPolicy: 'network-only',
      variables: {
        testSlug
      }
    });
    return response?.data?.checkIfTestSlugAlreadyExist;
  } catch (error) {
    return false;
  }
};
