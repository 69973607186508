/* eslint-disable no-console */
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SendOutlined
} from '@ant-design/icons';
import { Popconfirm, Tag, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../../common/components/CommonTable';
import LoaderComponent from '../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT, ROUTES } from '../../../common/constants';
import { withHttp } from '../../../common/utils';
import {
  cancelPrescriptionAsAdmin,
  getActivePrescriptions,
  resolvePrescription
} from '../prescriptions-repository';

function ActivePrescriptions({ query }) {
  const limit = GLOBAL_PAGINATION_LIMIT;
  // const format = defaultDateFormat;

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [activePrescriptions, setActivePrescriptions] = useState([]);
  const [paginationProp, setPaginationProp] = useState();

  // FETCHING ACTIVE PRESCRIPTIONS
  const fetchActivePrescriptions = async ({ currentSkip }) => {
    setLoadingData(true);
    try {
      //
      const response = await getActivePrescriptions({
        skip: currentSkip,
        limit: limit,
        query: query
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setActivePrescriptions(response.prescriptions.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchActivePrescriptions({
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const selectRow = (record) => {
    const url = `${ROUTES.CUSTOMER_DETAIL}?customerId=${record.customer.id}`;
    // eslint-disable-next-line no-undef
    window.open(url, '_blank');
  };

  const cancelPrescription = async (pID, index) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Cancelling'
    });
    await cancelPrescriptionAsAdmin(pID);
    const cancelPrescrib = activePrescriptions.slice();
    cancelPrescrib.splice(index, 1);
    message.open({
      key,
      type: 'success',
      content: 'Cancelled'
    });
    setActivePrescriptions(cancelPrescrib);
  };

  const approvePrescription = async (pID, index) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Approving'
    });
    await resolvePrescription(pID);
    const approvePrescrib = activePrescriptions.slice();
    approvePrescrib.splice(index, 1);
    message.open({
      key,
      type: 'success',
      content: 'Approved'
    });
    setActivePrescriptions(approvePrescrib);
  };

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: '1',
      render: (value) => {
        if (value.name.length < 20) {
          return value.name;
        }
        // eslint-disable-next-line no-lone-blocks
        {
          return (
            <Tooltip title={value.name} color="#232e78">
              {value.name.slice(0, 20)}...
            </Tooltip>
          );
        }
      }
    },
    {
      title: 'Uploaded at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => {
        return (
          <span>
            {dayjs(value).format('DD/MM/YYYY')} -{' '}
            {dayjs(value).format('hh:mm A')}
          </span>
        );
      }
    },
    {
      title: 'Prescription',
      dataIndex: 'prescriptions',
      key: '2',
      render: (value, record) => {
        return (
          <a href={withHttp(record.url)} target="blank">
            image.png
          </a>
        );
      }
    },
    {
      title: ' Status',
      dataIndex: 'prescriptions',
      key: '3',
      render: (value, record) => {
        return <Tag className="future-tag future-fix">{record.status}</Tag>;
      }
    },
    {
      title: '',
      dataIndex: 'prescriptions',
      key: '4',
      render: (value, record) => {
        return (
          <SendOutlined
            className="view-more"
            onClick={() => {
              selectRow(record);
            }}
          />
        );
      }
    },
    {
      title: '',
      dataIndex: 'prescriptions',
      key: '5',
      render: (value, record, index) => {
        return (
          <>
            <Popconfirm
              title="Cancel"
              description="Are you sure to cancel this prescription?"
              onConfirm={() => {
                cancelPrescription(record.id, index);
              }}
              okText="Yes"
              cancelText="No"
            >
              <CloseCircleOutlined className="danger-cross" />
            </Popconfirm>

            <Popconfirm
              title="Approve"
              description="Are you sure to approve this prescription?"
              onConfirm={() => {
                approvePrescription(record.id, index);
              }}
              okText="Yes"
              cancelText="No"
            >
              <CheckCircleOutlined className="success-tick" />
            </Popconfirm>
          </>
        );
      }
    }
  ];

  return (
    <div className="main-content test-categories-content">
      {loading ? (
        <LoaderComponent />
      ) : (
        <CommonTable
          columns={columns}
          rowKey={(record) => record.id}
          loadingData={loadingData}
          paginationConfig={paginationProp}
          data={activePrescriptions}
          onChange={(onChange) => {
            // eslint-disable-next-line no-console
            setLoadingData(true);
            fetchActivePrescriptions({
              // currentQuery: query,
              currentSkip: (onChange.current - 1) * limit
            });
          }}
        />
      )}
    </div>
  );
}

export default ActivePrescriptions;
