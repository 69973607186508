import { gql } from '@apollo/client';

export const ADD_SYSTEM_DOCTOR = gql`
  mutation AddSystemDoctor($data: createDoctorInput!) {
    addSystemDoctor(data: $data) {
      doctor {
        commissionPercentage
        countryCode
        designation
        email
        id
        isDisabled
        mobileNumber
        name
        revenueGenerated
        experience
        totalCommissionEarned
        doctorSlug
        profilePicture
        clinicDetails {
          clinicName
          clinicAddress
          morningTime
          eveningTime
          clinicAddressLat
          clinicAddressLong
          location {
            id
            latitude
            longitude
            pincode
            state
            address
            city
          }
        }
        social {
          facebook
          instagram
          youtube
          linkedin
        }
      }
    }
  }
`;

export const UPDATE_DOCTOR_AS_ADMIN = gql`
  mutation UpdateDoctor($doctorId: String!, $data: UpdateDoctorInput!) {
    updateDoctor(doctorId: $doctorId, data: $data) {
      doctor {
        id
        name
        designation
        email
        mobileNumber
        countryCode
        experience
        profilePicture
        social {
          instagram
          facebook
          linkedin
          youtube
        }
        clinicDetails {
          clinicName
          clinicAddress
          morningTime
          eveningTime
          clinicAddressLat
          clinicAddressLong
          location {
            id
            latitude
            longitude
            pincode
            state
            address
            city
          }
        }
        commissionPercentage
        totalCommissionEarned
        revenueGenerated
        isDisabled
      }
    }
  }
`;

export const TOGGLE_ENABLE_DOCTOR = gql`
  mutation ToggleEnableDoctor($doctorId: String!) {
    toggleEnableDoctor(doctorId: $doctorId)
  }
`;

export const REFRESH_DOCTOR_PROFILE_BY_ID = gql`
  mutation RefreshDigitalProfileByDoctorId($doctorId: String!) {
    refreshDigitalProfileByDoctorId(doctorId: $doctorId)
  }
`;

export const REFRESH_ALL_DOC_PROFILE = gql`
  mutation Mutation {
    refreshAllDigitalProfiles
  }
`;

export const CHECK_IF_DOC_SLUG_EXIST = gql`
  mutation checkIfDoctorSlugAlreadyExist($doctorSlug: String!) {
    checkIfDoctorSlugAlreadyExist(doctorSlug: $doctorSlug)
  }
`;

export const SAMPLE = gql`
  mutation {
    checkGraphQLStatus
  }
`;
