import { Button, message, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSourceTag } from '../../../common/components/common-components';
import CommonTable from '../../../common/components/CommonTable';
import LoaderComponent from '../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT, ROUTES } from '../../../common/constants';
import { downloadAsExcel } from '../../../common/utils';
import {
  getDocRevenueOrders,
  getDoctorRevenueData
} from '../doctor-repository';

function DoctorRevenue({ startDateVal, endDateVal, doctorId, forcedRefetch }) {
  const limit = GLOBAL_PAGINATION_LIMIT;
  // const format = defaultDateFormat;
  const navigate = useNavigate();

  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [activeReports, setActiveReports] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [skipVal, setSkipVal] = useState(0);
  const [isExporting, setIsExporting] = useState(false);

  // FETCHING ACTIVE REPORTS
  const fetchRevenueOrders = async ({ currentSkip, sDate, eDate }) => {
    setLoadingData(true);
    try {
      const filters = {
        skip: currentSkip,
        limit: limit,
        startDate: sDate,
        endDate: eDate
      };
      const response = await getDocRevenueOrders(doctorId, filters);
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setActiveReports(response.orders.slice());
      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchRevenueOrders({
      currentSkip: skipVal,
      sDate: startDateVal,
      eDate: endDateVal
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateVal, endDateVal, forcedRefetch]);

  const selectRow = (record) => {
    const url = `${ROUTES.REPORT_DETAIL}?orderId=${record.id}`;
    navigate(url);
  };

  const columns = [
    {
      title: 'Order#',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (value, record) => {
        const { source } = record;
        return (
          <div>
            <Typography
              className="clickable-title"
              onClick={() => {
                selectRow(record);
              }}
            >
              {value}
            </Typography>
            {getSourceTag(source)}
          </div>
        );
      }
    },
    {
      title: 'Patient',
      dataIndex: 'patient',
      key: 'patient',
      render: (value) => {
        return value.name;
      }
    },
    {
      title: 'Total amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (value) => {
        return `₹ ${value}`;
      }
    },
    {
      title: 'Profit Per Order',
      dataIndex: 'profitPerOrder',
      key: 'profitPerOrder',
      render: (value) => {
        return `₹ ${value}`;
      }
    },
    {
      title: "Doctor's Commission ",
      dataIndex: 'doctorsCommission',
      key: 'doctorsCommission',
      render: (value) => {
        return `₹ ${value}`;
      }
    },
    {
      title: 'Profit after commission ',
      dataIndex: 'profitWithoutDoctorCommission',
      key: 'profitWithoutDoctorCommission',
      render: (value) => {
        return `₹ ${value}`;
      }
    }
  ];

  // EXCEL EXPORT HERE

  const fetchLinkedOrdersToExport = async ({ sDate, eDate }) => {
    try {
      const payload = {
        doctorId: doctorId,
        startDate: sDate,
        endDate: eDate
      };
      const response = await getDoctorRevenueData(payload);
      const { data } = response;
      return data;
    } catch (error) {
      message.error('Failed to fetch data');
    }
  };

  const exportExelFile = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Processing'
    });
    setIsExporting(true);
    const toUpdateArray = [];
    try {
      const dataList = await fetchLinkedOrdersToExport({
        sDate: startDateVal,
        eDate: endDateVal
      });

      for (let i = 0; i < dataList.length; i += 1) {
        const orderData = dataList[i];
        toUpdateArray.push({
          ORDER_ID: orderData.orderId,
          PATIENT: orderData.customerName,
          PATIENT_TESTS: orderData.customerTests.join(', '),
          INVOICE_AMT: `₹ ${orderData.invoiceAmount}`,
          PERCENTAGE: `${orderData.percentageDefined} %`,
          COMMISSION_AMT: `₹ ${orderData.amountToPay}`
        });
      }
      downloadAsExcel(toUpdateArray);
      message.destroy(key);
      setIsExporting(false);
    } catch (error) {
      message.error({
        key,
        type: 'error',
        content: 'Failed to import'
      });
      message.destroy(key);
      setIsExporting(false);
    }
  };

  return (
    <div className="main-content customers-content ">
      <div className="export-btn">
        <Button loading={isExporting} onClick={exportExelFile}>
          Export to XL
        </Button>
      </div>
      <div className="customers-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={activeReports}
            onChange={(onChange) => {
              setLoadingData(true);
              setSkipVal((onChange.current - 1) * limit);
              fetchRevenueOrders({
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}

export default DoctorRevenue;
