/* eslint-disable no-console */
import {
  CloseCircleFilled,
  PlusCircleOutlined,
  WarningFilled
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import { BsArrowRightCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT,
  ROUTES
} from '../../common/constants';
import { clearSearchField, formValidatorRules } from '../../common/utils';
import { getLiveTestsAndPackages } from '../tests/test-repository';
import {
  checkIfCouponCodeExist,
  createOffer,
  getOffers,
  toggleAutoApply,
  toggleEnabledOffer,
  toggleHiddenOffer,
  updateLinkedTest,
  updateOffer
} from './offers-repository';
import './offers.less';

function Offers() {
  const { required } = formValidatorRules;
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const limit = GLOBAL_PAGINATION_LIMIT;
  const navigate = useNavigate();
  // STATES
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [isSavingData, setIsSavingData] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [openDisableOffer, setOpenDisableOffer] = useState(false);
  const [codeValue, setCodeValue] = useState();
  const [disable, setDisable] = useState(false);
  // MODAL STATES
  const [discountType, setDiscountType] = useState('PERCENTAGE');
  const [isUpdateOffer, setIsUpdateOffer] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [todaysDate, setTodaysDate] = useState();
  const [ifChecked, setIfChecked] = useState(false);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);

  // ADD-REMOVE TESTS STATES
  const [addTestModal, setAddTestModal] = useState(false);
  const [showingTestResults, setShowingTestResults] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const [fetchedTests, setFetchedTests] = useState([]);
  const [isFetchingTests, setIsFetchingTests] = useState(false);
  const [offerId, setOfferId] = useState('');
  const [offerName, setOfferName] = useState('');

  const fetchOffers = async ({ currentSkip, currentQuery }) => {
    setCurrentGlobalSkip(currentSkip);
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getOffers({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setOffers(response.offers.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  // ADD REMOVE TESTS
  const fetchLiveTests = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getLiveTestsAndPackages({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { tests } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < tests.length; i += 1) {
        const test = tests[i];
        const { id } = test;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedTests.length; j += 1) {
          if (selectedTests[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          test
        });
      }
      setFetchedTests(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetchingTests(false);
  };

  const getTestsBelongsToCategory = async (value, record) => {
    setLoadingData(true);
    const { linkedTests } = record;
    setSelectedTests(linkedTests);
    setTimeout(() => {
      setLoadingData(false);
      setAddTestModal(true);
    }, 500);
  };

  useEffect(() => {
    fetchOffers({
      currentQuery: '',
      currentSkip: 0
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOffers]);

  // TOGGLE ENABLE/DISABLE
  const toggleEnabled = async (index) => {
    offers[index] = {
      ...offers[index],
      isDisabled: !(offers[index].isDisabled || false)
    };
    toggleEnabledOffer(offers[index].id);
    setOffers(offers.slice());
  };

  // UPDATE MODAL
  const offerUpdate = async (index, value, record) => {
    setLoadingData(true);
    setIsUpdateOffer(true);
    setTimeout(() => {
      setIsModalOpen(true);
      setLoadingData(false);
      if (record.isFirstUser === true) {
        setIfChecked(true);
        setDisable(true);
      } else {
        setIfChecked(false);
        setDisable(false);
      }
      setDiscountType(record.kind);
      form.setFieldsValue({
        title: record.title,
        discountValue: record.discount,
        startDate: dayjs(record.startDate),
        validTill: dayjs(record.tillValid),
        coupon: record.couponCode,
        minOrder: record.minimumOrderOf,
        offerDesc: record.description,
        maxOrder: record.maximumOrder
      });
    }, 500);
  };

  const toggleAutoApplyFeature = async (index) => {
    offers[index] = {
      ...offers[index],
      isAutoApply: !offers[index].isAutoApply
    };
    toggleAutoApply(offers[index].id);
    setOffers(offers.slice());
  };

  const toggleHiddenFeature = async (index) => {
    offers[index] = {
      ...offers[index],
      isHidden: !offers[index].isHidden
    };
    toggleHiddenOffer(offers[index].id);
    setOffers(offers.slice());
  };

  const handleCopyCode = (code) => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(code).then(() => {
      message.success('Code copied to clipboard');
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      render: (value, record, index) => {
        if (value?.length < 20) {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateOffer(true);
                setSelectedId(record.id);
                setTimeout(() => {
                  offerUpdate(index, value, record);
                }, 200);
              }}
            >
              {value}
            </Typography>
          );
        }
        // eslint-disable-next-line no-lone-blocks
        {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateOffer(true);
                setSelectedId(record.id);
                setTimeout(() => {
                  offerUpdate(index, value, record);
                }, 200);
              }}
            >
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 20)}...
              </Tooltip>
            </Typography>
          );
        }
      }
    },
    {
      title: 'Coupon Code',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => {
        return (
          <span
            className="copy-code"
            onClick={() => handleCopyCode(record.couponCode)}
          >
            {record.couponCode}
          </span>
        );
      }
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      render: (value, record) => {
        return `${value} ${record.kind === 'PERCENTAGE' ? '%' : 'Rs'}`;
        // return value;
      }
    },
    {
      title: 'Validity',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (value, record) => {
        const newDate = moment(value).format(dateFormat);
        const validTill = moment(record.tillValid).format(dateFormat);
        return `${newDate} - ${validTill}`;
      }
    },
    {
      title: 'Status',
      dataIndex: 'tillValid',
      key: 'tillValid',
      render: (value, record) => {
        const now = new Date();
        const tillValid = new Date(value);
        const startDate = new Date(record.startDate);
        if (record.isDisabled) {
          return <Tag className="expired-tag">Inactive</Tag>;
        }
        if (startDate > now) {
          return <Tag className="future-tag">Future</Tag>;
        }
        if (tillValid < now) {
          return <Tag className="expired-tag">Expired</Tag>;
        }
        return <Tag className="active-tag">Active</Tag>;
      }
    },
    {
      title: 'Used Count',
      dataIndex: 'usedFor',
      key: 'usedFor',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Action',
      dataIndex: 'isDisabled',
      key: 'isDisabled',
      render: (value, record, index) => {
        return value ? (
          <span
            className="disable-class"
            onClick={() => {
              toggleEnabled(index);
            }}
          >
            Disabled
          </span>
        ) : (
          <span
            className="enable-class"
            onClick={() => {
              setCurrentSelectedIndex(index);
              setTimeout(() => {
                setOpenDisableOffer(true);
              }, 300);
            }}
          >
            Enabled
          </span>
        );
      }
    },
    {
      title: 'Auto apply',
      dataIndex: 'isAutoApply',
      key: 'isAutoApply',
      render: (value, record, index) => {
        return (
          <Checkbox
            defaultChecked={value}
            onClick={() => {
              toggleAutoApplyFeature(index);
            }}
            disabled={record?.linkedTests?.length === 0 || record.isHidden}
          />
        );
      }
    },
    {
      title: 'Hidden',
      dataIndex: 'isHidden',
      key: 'isHidden',
      render: (value, record, index) => {
        return (
          <Checkbox
            defaultChecked={value}
            onClick={() => {
              toggleHiddenFeature(index);
            }}
            disabled={record.isAutoApply}
          />
        );
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (value, record, index) => {
        return (
          <div className="offer-action-buttons">
            <PlusCircleOutlined
              size={40}
              onClick={() => {
                setOfferId(value);
                setOfferName(record.title);
                setTimeout(() => {
                  getTestsBelongsToCategory(value, record, index);
                }, 200);
              }}
            />
            <BsArrowRightCircle
              size={20}
              onClick={() => {
                const url = `${ROUTES.OFFERS}/${value}`;
                navigate(url);
              }}
            />
          </div>
        );
      }
    }
  ];

  // MODAL
  const handleCancel = () => {
    form?.resetFields();
    setDiscountType('PERCENTAGE');
    setIfChecked(false);
    setDisable(false);
    setIsUpdateOffer(false);
    setCodeValue();
    setIsModalOpen(false);
  };

  const discountChange = (e) => {
    e.preventDefault();
    form?.resetFields(['discountValue']);
    setDiscountType(e.target.value);
  };

  // SUBMIT FORM
  const onSubmitCreateOffer = async (formValue) => {
    const {
      title,
      offerDesc,
      discountValue,
      validTill,
      startDate,
      coupon,
      minOrder,
      maxOrder
    } = formValue;

    const startDateInDate = startDate.toDate();
    const tillValidInDate = validTill.toDate();
    if (tillValidInDate < startDateInDate) {
      message.error('Invalid dates');
      return;
    }
    if (parseFloat(discountValue) < 0) {
      message.error('Invalid value');
      return;
    }
    if (parseFloat(minOrder) < 0) {
      message.error('Invalid value');
      return;
    }
    if (parseInt(maxOrder, 10) < 0) {
      message.error('Invalid value');
      return;
    }
    if (title.length > 50) {
      message.error('Exceeded title limit');
      return;
    }
    if (title.length < 2) {
      message.error('Title should have atleast 2 characters');
      return;
    }
    if (offerDesc.length < 2) {
      message.error('Description should have atleast 2 characters');
      return;
    }
    if (coupon.length < 2) {
      message.error('Coupon should have atleast 2 characters');
      return;
    }
    if (codeValue === true) {
      message.error('Coupon code already exist');
      return;
    }
    if (discountType === 'FLAT') {
      if (minOrder < discountValue) {
        message.error(
          'Minimum order value should be more than or equal to discount rupees'
        );
        return;
      }
    }

    setIsSavingData(true);
    const createdOffer = await createOffer({
      title,
      kind: discountType,
      discount: parseFloat(discountValue),
      tillValid: tillValidInDate.toISOString(),
      startDate: startDateInDate.toISOString(),
      couponCode: coupon,
      minimumOrderOf: parseFloat(minOrder),
      isFeatured: true,
      description: offerDesc,
      maximumOrder: disable === true ? 1 : parseInt(maxOrder, 10),
      isFirstUser: ifChecked
    });
    if (
      offers.length === 0 ||
      offers.length < limit ||
      offers[offers.length - 1].serialNumber + 1 === createdOffer.serialNumber
    ) {
      const updatedOffer = offers.slice();
      updatedOffer.push(createdOffer);
      setOffers(updatedOffer.slice());
    }
    form.resetFields();
    setDiscountType('PERCENTAGE');
    setIfChecked(false);
    setDisable(false);
    setIsSavingData(false);
    setIsModalOpen(false);
    fetchOffers({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  // SUBMIT UPDATED FORM
  const onSubmitUpdateOffer = async (formValue) => {
    const {
      title,
      startDate,
      validTill,
      offerDesc,
      discountValue,
      minOrder,
      maxOrder
    } = formValue;
    const startDateInDate = startDate.toDate();
    const tillValidInDate = validTill.toDate();
    if (tillValidInDate < startDateInDate) {
      message.error('Invalid dates');
      return;
    }
    if (title.length > 50) {
      message.error('Exceeded title limit');
      return;
    }
    if (parseFloat(minOrder) < 0) {
      message.error('Invalid value');
      return;
    }
    if (parseInt(maxOrder, 10) < 0) {
      message.error('Invalid value');
      return;
    }
    if (discountType === 'FLAT') {
      if (minOrder < discountValue) {
        message.error(
          'Minimum order value should be more than or equal to discount rupees'
        );
        return;
      }
    }
    setIsSavingData(true);
    const payload = {
      title,
      kind: discountType,
      discount: parseFloat(discountValue),
      offerId: selectedId,
      startDate: startDateInDate.toISOString(),
      tillValid: tillValidInDate.toISOString(),
      description: offerDesc,
      minimumOrderOf: parseFloat(minOrder),
      maximumOrder: disable === true ? 1 : parseInt(maxOrder, 10),
      isFirstUser: ifChecked
    };
    const updatedOffer = await updateOffer(payload);
    const updatedOffers = offers.slice();
    updatedOffers[currentSelectedIndex] = updatedOffer;
    setOffers(updatedOffers);
    form?.resetFields();
    setDiscountType('PERCENTAGE');
    setIfChecked(false);
    setDisable(false);
    setIsSavingData(false);
    setIsModalOpen(false);
    setIsUpdateOffer(false);
    fetchOffers({
      currentQuery: '',
      currentSkip: 0
    });
  };

  const checkCouponFunc = async (e) => {
    const code = e.target.value;
    await checkIfCouponCodeExist(code).then((res) => {
      setCodeValue(res);
    });
  };

  const handleCancelADdTestsModal = () => {
    clearSearchField('Tests');
    setSelectedTests([]);
    setOfferId('');
    setOfferName('');
    setAddTestModal(false);
  };

  const uploadSelectedTests = async () => {
    setIsSavingData(true);
    const testsId = [];
    for (let i = 0; i < selectedTests.length; i += 1) {
      testsId.push(selectedTests[i].id);
    }
    const data = {
      offerId,
      tests: testsId
    };
    await updateLinkedTest(data);
    setIsSavingData(false);
    fetchOffers({
      currentQuery: '',
      currentSkip: 0
    });
    handleCancelADdTestsModal();
  };

  return (
    <div className="content-wrapper">
      {/* DISABLE OFFER MODAL */}
      <Modal
        title={
          <div>
            <Space className="warning-container">
              <WarningFilled />
              Disable offer
            </Space>
          </div>
        }
        centered
        closeIcon={<CloseCircleFilled className="close-btn" />}
        open={openDisableOffer}
        okButtonProps={{ type: 'primary', danger: 'danger' }}
        cancelButtonProps={{ className: 'no-display' }}
        okText="Disable offer"
        onOk={() => {
          toggleEnabled(currentSelectedIndex);
          setOpenDisableOffer(false);
        }}
        onCancel={() => setOpenDisableOffer(false)}
      >
        <Divider />
        <p>Are you sure you want to disable this offer?</p>
        <Divider />
      </Modal>

      {/* ADD/REMOVE TESTS MODAL */}
      <Modal
        title={offerName || 'UPDATE TESTS'}
        centered
        className="create-test-modal"
        open={addTestModal}
        onCancel={handleCancelADdTestsModal}
      >
        <Divider />
        <div className="tests-modal-search">
          <SearchComponent
            name="Tests"
            debounceTime={DEBOUNCE_TIME}
            getData={fetchLiveTests}
          />

          {!isFetchingTests && showingTestResults && (
            <div className="search-result" id="test-searcher">
              {fetchedTests.length <= 0 ? (
                <Empty />
              ) : (
                fetchedTests.map((currentTest) => {
                  const { isSelected, test } = currentTest;
                  return (
                    <div className="tests-list" key={test.id}>
                      <Typography.Text>{test.title}</Typography.Text>
                      {isSelected ? (
                        <Button
                          type="text"
                          onClick={() => {
                            const tests = selectedTests.slice();
                            for (let i = 0; i < tests.length; i += 1) {
                              if (tests[i].id === test.id) {
                                tests.splice(i, 1);
                              }
                            }
                            const tempFetchedTests = fetchedTests.slice();
                            for (
                              let i = 0;
                              i < tempFetchedTests.length;
                              i += 1
                            ) {
                              if (tempFetchedTests[i].test.id === test.id) {
                                tempFetchedTests[i] = {
                                  ...tempFetchedTests[i],
                                  isSelected: false
                                };
                                break;
                              }
                            }
                            setSelectedTests(tests);
                            setFetchedTests(tempFetchedTests);
                          }}
                        >
                          Remove
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            const tests = selectedTests.slice();
                            tests.push(test);
                            const tempFetchedTests = fetchedTests.slice();
                            for (
                              let i = 0;
                              i < tempFetchedTests.length;
                              i += 1
                            ) {
                              if (tempFetchedTests[i].test.id === test.id) {
                                tempFetchedTests[i] = {
                                  ...tempFetchedTests[i],
                                  isSelected: true
                                };
                                break;
                              }
                            }
                            setSelectedTests(tests);
                            setFetchedTests(tempFetchedTests);
                          }}
                          type="default"
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  );
                })
              )}
            </div>
          )}
        </div>
        <div className="selected-test-container category-container">
          {selectedTests?.length <= 0 ? (
            <Empty />
          ) : (
            selectedTests.map((currentTest) => {
              const { id, title } = currentTest;
              return (
                <div className="tests-list" key={id}>
                  <Typography.Text>{title}</Typography.Text>
                  <Button
                    type="text"
                    onClick={() => {
                      const tests = selectedTests.slice();
                      for (let i = 0; i < tests.length; i += 1) {
                        if (tests[i].id === id) {
                          tests.splice(i, 1);
                        }
                      }
                      setSelectedTests(tests);
                    }}
                  >
                    Remove
                  </Button>
                </div>
              );
            })
          )}
        </div>
        <Divider />
        <Button
          loading={isSavingData}
          className="create-btn offer-save-btn"
          onClick={uploadSelectedTests}
        >
          SAVE
        </Button>
      </Modal>
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Offers
            </Typography.Text>
            <SearchComponent
              name="Offers"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchOffers({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button className="create-btn" onClick={() => setIsModalOpen(true)}>
            + Create New
          </Button>
          {/* CREATE MODAL */}
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            className="offer-modal"
            centered
            title={isUpdateOffer ? 'Edit Offer' : 'Create Offer'}
          >
            <Divider />
            <Form
              onFinish={
                isUpdateOffer ? onSubmitUpdateOffer : onSubmitCreateOffer
              }
              layout="vertical"
              form={form}
              name="creationForm"
            >
              <div className="form-contents">
                <Row className="upper-contents">
                  <Col>
                    <Form.Item
                      rules={[required]}
                      name="title"
                      className="input-title"
                      label="Offer title"
                    >
                      <Input type="text" placeholder="Title" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="upper-contents">
                  <Col>
                    <div className="offers-html">
                      <Form.Item
                        rules={[required]}
                        name="offerDesc"
                        className="input-title"
                        label="Offer description"
                      >
                        <Input.TextArea type="text" placeholder="Description" />
                      </Form.Item>
                      <Button
                        type="link"
                        onClick={() => {
                          const url = 'https://wordtohtml.net/';
                          // eslint-disable-next-line no-undef
                          window.open(url, '_blank');
                        }}
                      >
                        Click to generate description
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="center-contents">
                  <div className="top-center">
                    <div className="discount-type">
                      <span>Select discount type</span>
                      <div className="discount-items">
                        <Radio.Group
                          // disabled={isUpdateOffer}
                          onChange={discountChange}
                          value={discountType}
                        >
                          <Radio value="PERCENTAGE">Discount in %</Radio>
                          <Radio value="FLAT">Discount in ₹</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="rupees">
                      <Form.Item
                        rules={[required]}
                        name="discountValue"
                        label={
                          discountType === 'PERCENTAGE'
                            ? 'Percentage'
                            : 'Rupees'
                        }
                      >
                        <InputNumber
                          // disabled={isUpdateOffer}
                          controls={false}
                          onChange={(value) => {
                            if (discountType === 'PERCENTAGE') {
                              if (value > 100) {
                                form?.resetFields(['discountValue']);
                              }
                            }
                          }}
                          placeholder="0"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="bottom-center">
                    <div className="offer-date">
                      <Form.Item
                        rules={[required]}
                        name="startDate"
                        label="Start date?"
                      >
                        <DatePicker
                          format={dateFormat}
                          onChange={(value) => setTodaysDate(value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="offer-date">
                      <Form.Item
                        rules={[required]}
                        name="validTill"
                        label="Till what date it is valid?"
                      >
                        <DatePicker
                          format={dateFormat}
                          disabledDate={(value) =>
                            new Date(todaysDate + 24 * 60 * 60 * 1000) > value
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="bottom-center">
                    <div className="coupon">
                      <Form.Item
                        rules={[
                          {
                            validator(rule, value) {
                              const currentValue = value || '';
                              if (currentValue.toString().trim() === '') {
                                return Promise.reject(new Error('Required'));
                              }
                              if (currentValue.toString().length < 2) {
                                return Promise.reject(
                                  new Error('Minimum 2 characters required')
                                );
                              }
                              if (currentValue.toString().length > 20) {
                                return Promise.reject(
                                  new Error('Maximum 20 characters allowed')
                                );
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                        name="coupon"
                        label="Coupon code for this offer"
                      >
                        <Input
                          disabled={isUpdateOffer}
                          type="text"
                          placeholder="Coupon"
                          // eslint-disable-next-line no-return-assign
                          onInput={(e) =>
                            (e.target.value = e.target.value.toUpperCase())
                          }
                          onChange={checkCouponFunc}
                        />
                      </Form.Item>
                      {codeValue === true ? (
                        <span className="code-warning">
                          Coupon code already exists
                        </span>
                      ) : null}
                    </div>
                    <div className="coupon order-value">
                      <Form.Item
                        rules={[
                          {
                            validator(rule, value) {
                              const currentValue = value || '';
                              if (currentValue.toString().trim() === '') {
                                return Promise.reject(new Error('Required'));
                              }
                              if (discountType === 'FLAT') {
                                const dVal =
                                  form.getFieldValue('discountValue');
                                if (value < dVal) {
                                  return Promise.reject(
                                    new Error(
                                      'Should be more than discount rupees'
                                    )
                                  );
                                }
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                        name="minOrder"
                        label="Minimum order of value"
                      >
                        <InputNumber
                          // disabled={isUpdateOffer}
                          controls={false}
                          placeholder="0"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <Row className="bottom-contents">
                  <Col>
                    <div className="first-customer">
                      <span>1st customer :</span>
                      <Checkbox
                        value={ifChecked}
                        checked={ifChecked}
                        className="first-checkbox"
                        onChange={(e) => {
                          setIfChecked(e.target.checked);
                          if (e.target.checked === true) {
                            form.setFieldValue('maxOrder', 1);
                            setDisable(true);
                          } else {
                            form.setFieldValue('maxOrder', 1000);
                            setDisable(false);
                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Item name="maxOrder" label="Maximum time applicable">
                      <Input
                        placeholder="0"
                        disabled={disable}
                        defaultValue={1000}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Divider />
              <div className="btn">
                <Button
                  loading={isSavingData}
                  htmlType="submit"
                  className="create-btn"
                >
                  {isUpdateOffer ? 'Update' : 'Create'}
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </Header>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={offers}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchOffers({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default Offers;
