import { gql } from '@apollo/client';

export const GET_SYSTEM_USERS = gql`
  query getSystemUsers($filters: GetAllCustomerInput) {
    getSystemUsers(filters: $filters) {
      total
      users {
        id
        email
        name
        avatar
        role
        age
        sex
        countryCode
        mobileNumber
        isDisabled
        isAvailable
        documents {
          title
          url
          type
        }
      }
    }
  }
`;
export const GET_ALREADY_EXIST_EMAIL = gql`
  query getAlreadyExistEmails($systemUserId: String, $email: String) {
    checkIfSystemUserEmailExist(systemUserId: $systemUserId, email: $email)
  }
`;

export const GET_EQUIPMENT_LISTS = gql`
  query getAllotedEquipmentsToSampleCollector($sampleCollectorId: String!) {
    getAllotedEquipmentsToSampleCollector(
      sampleCollectorId: $sampleCollectorId
    ) {
      allotedEquipments {
        equipment {
          id
          name
        }
        quantity
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
