import { gql } from '@apollo/client';

export const GET_ADMIN_REPORTS = gql`
  query getActiveOrdersAsAdmin($filters: GetOrdersInput) {
    getActiveOrdersAsAdmin(filters: $filters) {
      total
      orders {
        id
        otpForTest
        sampleSubmitTo {
          id
          name
          displayName
        }
        orderId
        usedCoins
        orderTransitStatus
        coinsApplied
        patient {
          id
          name
          age
          sex
        }
        address {
          id
          name
          pincode
          state
          city
          addressLine1
          addressLine2
        }
        scheduledAt
        tests {
          id
          title
          equipmentsRequired {
            equipment {
              id
              name
            }
          }
        }
        subTotal
        paymentVia
        paymentStatus
        orderStatus
        sampleCollectionPartner {
          id
          name
        }
        source
        doctorAssigned
      }
    }
  }
`;

export const GET_ADMIN_REPORT_ORDER = gql`
  query getOrderByIdAsAdmin($orderId: String!) {
    getOrderByIdAsAdmin(orderId: $orderId) {
      order {
        id
        orderId
        orderStatus
        usedCoins
        paymentStatus
        coinsApplied
        deliverReport
        cancellableUpTo
        sampleCollectedAt
        invoiceUrl
        offerApplied {
          couponCode
        }
        customer {
          id
          name
          mobileNumber
        }
        reports {
          test
          reportUrl
          rejectionReason
          uploadedAt
          isApproved
        }
        wholeReport {
          reportUrl
          rejectionReason
          uploadedAt
          isApproved
        }
        isWholeReportUpload
        orderSummary {
          tests {
            testId
            title
            price
            discount
            kind
            productionCost
          }
          delivery {
            isEnabled
            charges
          }
          coins {
            isEnabled
            used
          }
          offer {
            isApplied
            offerDetails {
              offerId
              title
              kind
              discount
            }
          }
          convenienceFees
          overallTestDiscount
          cartTotalPrice
          discountByCoupon
          payableToLab
          subTotal
        }
        bookedTimeSlot {
          id
          selectedDate
          slot {
            id
            startTime
            day
            name
          }
        }
        patient {
          id
          name
          age
          sex
        }
        customer {
          id
          mobileNumber
        }
        address {
          id
          name
          pincode
          state
          city
          addressLine1
          addressLine2
        }
        scheduledAt
        tests {
          id
          title
          price
          equipmentsRequired {
            requiredQuantity
            equipment {
              id
              name
            }
          }
        }
        subTotal
        paymentVia
        sampleCollectionPartner {
          id
          name
        }
        orderTransitStatus
        transactions {
          razorpayOrderId
          isSuccessful
          transactionCreatedAt
        }
        requiredSamples {
          sample {
            id
            name
          }
          linkedEquipment
        }
        testimonial {
          id
          testimonial
          ratings
        }
        doctorAssigned
        offerApplied {
          id
          couponCode
          linkedTests {
            id
            title
          }
        }
      }
      isOrderExist
    }
  }
`;

export const GET_AVAILABLE_SAMPLE_COLLECTORS = gql`
  query getAvailableSampleCollectorsAtTime($date: String!, $time: String!) {
    getAvailableSampleCollectorsAtTime(data: { date: $date, time: $time }) {
      sampleCollectors {
        id
        name
      }
    }
  }
`;

export const GET_SLOTS_BY_DAY = gql`
  query getSlotsByDay($filter: FetchSlotsInput!) {
    getSlotsByDay(filter: $filter) {
      morning {
        id
        name
        startTime
        maxBookingLimit
        day
      }
      afternoon {
        id
        name
        startTime
        maxBookingLimit
        day
      }
      evening {
        id
        name
        startTime
        maxBookingLimit
        day
      }
    }
  }
`;

export const GET_ORDER_LOGS = gql`
  query getOrderLogs($filters: GetLogsInput!, $id: String!) {
    getOrderLogs(filters: $filters, id: $id) {
      total
      logs {
        message
        updatedAt
        orderId
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;

export const GET_HISTORY_ADMIN_REPORTS = gql`
  query getOrderHistory($filters: GetOrdersInput) {
    getOrderHistory(filters: $filters) {
      total
      orders {
        id
        otpForTest
        orderId
        usedCoins
        reason
        sampleSubmitTo {
          id
          name
          displayName
        }
        orderTransitStatus
        coinsApplied
        patient {
          id
          name
          age
          sex
        }
        address {
          id
          name
          pincode
          state
          city
          addressLine1
          addressLine2
        }
        scheduledAt
        tests {
          id
          title
          equipmentsRequired {
            equipment {
              id
              name
            }
          }
        }
        subTotal
        paymentVia
        paymentStatus
        orderStatus
        cancelledBy {
          userRole
        }
        sampleCollectionPartner {
          id
          name
        }
        source
        doctorAssigned
      }
    }
  }
`;

export const GET_ORDER_TRANSACHTIONS = gql`
  query getOrderTransactions($orderId: String!) {
    getOrderByIdAsAdmin(orderId: $orderId) {
      order {
        id
        transactions {
          razorpayOrderId
          isSuccessful
          transactionCreatedAt
        }
      }
    }
  }
`;

export const GENERATE_INVOICE = gql`
  query getInvoiceByOrderId($orderId: String!) {
    getInvoiceByOrderId(orderId: $orderId) {
      url
    }
  }
`;

export const GET_CUSTOMER_ADDRESS_AS_ADMIN = gql`
  query getCustomerAddressesAsAdmin(
    $filters: AddressInput!
    $customerId: String!
  ) {
    getCustomerAddressesAsAdmin(filters: $filters, customerId: $customerId) {
      addresses {
        id
        addressLine1
        addressLine2
        name
        pincode
        state
        city
      }
    }
  }
`;

export const GET_CUSTOMER_PATIENTS = gql`
  query getPatientsAsAdmin($filters: GetPatientInput!, $customerId: String!) {
    getPatientsAsAdmin(filters: $filters, customerId: $customerId) {
      patients {
        id
        name
      }
    }
  }
`;

export const GET_ORDERS_BY_ID_AS_ADMIN = gql`
  query getOrdersByIdAsAdmin($orderIds: [ID!]!) {
    getOrdersByIdAsAdmin(orderIds: $orderIds) {
      orders {
        id
        orderId
        patient {
          id
          name
          age
          sex
        }
        address {
          id
          addressLine1
          addressLine2
          city
          state
          pincode
        }
        scheduledAt
        orderSummary {
          tests {
            testId
            title
          }
        }
        requiredSamples {
          sample {
            id
            name
          }
          linkedEquipment
        }
        sampleCollectedAt
        doctorAssigned
      }
    }
  }
`;
