/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import { Button, React, Space, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Back } from '../../../../assets/icons/back.svg';
import CommonTable from '../../../../common/components/CommonTable';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../../../common/constants';
import { getTestCustomers } from '../../test-repository';
// import './customer-detail.less';

function TestCustomersList() {
  const { cID } = useParams();
  const navigate = useNavigate();
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [paginationProp, setPaginationProp] = useState();
  const [testCustomers, setTestCustomers] = useState([]);

  const fetchTestCustomers = async ({ currentSkip }) => {
    try {
      const response = await getTestCustomers(cID, {
        skip: currentSkip,
        limit: limit
      });

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setTestCustomers(response.customers.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchTestCustomers({
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Phone No.',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      render: (value, record) => {
        return `${record?.countryCode} ${record?.mobileNumber}`;
      }
    },
    {
      title: 'Email id',
      dataIndex: 'email',
      key: 'email',
      render: (value) => {
        return value || '-';
      }
    },
    {
      title: 'Gender',
      dataIndex: 'sex',
      key: 'sex'
    }
  ];

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Space>
              <Typography.Text className="main-header-title">
                Customers
              </Typography.Text>
            </Space>
          </Space>
        </div>
      </Header>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={testCustomers}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchTestCustomers({
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default TestCustomersList;
