import { gql } from '@apollo/client';

// CREATE BANNER
export const CREATE_BANNER = gql`
  mutation createBanner(
    $title: title_String_NotNull_minLength_2_maxLength_255!
    $thumbnail: thumbnail_String_NotNull_maxLength_5000_format_uri!
  ) {
    createBanner(data: { title: $title, thumbnail: $thumbnail }) {
      banner {
        id
        title
        thumbnail
        order
        serialNumber
        isFeatured
      }
    }
  }
`;

// TOGGLE FEATURE
export const TOGGLE_FEATURE_BANNER = gql`
  mutation toggleFeatureBanner($id: String!) {
    toggleFeatureBanner(id: $id) {
      result
    }
  }
`;

// UPDATE BANNER
export const UPDATE_BANNER = gql`
  mutation updateBanner(
    $title: title_String_NotNull_minLength_2_maxLength_255!
    $thumbnail: thumbnail_String_NotNull_maxLength_5000_format_uri!
    $bannerId: String!
  ) {
    updateBanner(
      data: { title: $title, thumbnail: $thumbnail }
      bannerId: $bannerId
    ) {
      banner {
        id
        title
        thumbnail
        order
        serialNumber
        isFeatured
        test {
          id
          title
        }
      }
    }
  }
`;

// DELETE BANNER
export const DELETE_BANNER = gql`
  mutation removeBanner($bannerId: String!) {
    removeBanner(bannerId: $bannerId) {
      result
    }
  }
`;

export const TOGGLE_BANNER_FIRST_TIME = gql`
  mutation toggleBannerFirstTime($bannerId: String!) {
    toggleBannerFirstTime(bannerId: $bannerId)
  }
`;

export const LINK_TEST_WITH_BANNER = gql`
  mutation updateTestToBanner($test: String, $bannerId: String!) {
    updateTestToBanner(test: $test, bannerId: $bannerId) {
      banner {
        id
        title
        thumbnail
        order
        serialNumber
        isFeatured
        test {
          id
          title
        }
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
