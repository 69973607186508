/* eslint-disable no-lone-blocks */
/* eslint-disable no-console */
import { Button, message, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../../../../common/components/CommonTable';
import LoaderComponent from '../../../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../../../../common/constants';
import { getArchivedTests, unarchiveTest } from '../../../test-repository';

function ArchiveTable({ parentWantToLoad, reloadData, query, forceToRefetch }) {
  // STATES
  const limit = GLOBAL_PAGINATION_LIMIT;
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [archive, setArchive] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [globalCurrentSkip, setGlobalCurrentSkip] = useState();
  const [previousFetchArchive, setPreviousFetchArchive] = useState({});

  const fetchArchiveTests = async ({ currentSkip }) => {
    try {
      setPreviousFetchArchive({
        currentSkip
      });
      setGlobalCurrentSkip(currentSkip);
      const response = await getArchivedTests({
        skip: currentSkip,
        limit: limit,
        query: query
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setArchive(response.tests);
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchArchiveTests({
      currentSkip: globalCurrentSkip || 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData, query, forceToRefetch]);

  const columns = [
    {
      title: '#',
      dataIndex: 'serialNumber',
      key: '1'
    },
    {
      title: 'Test',
      dataIndex: 'title',
      key: '2',
      render: (value) => {
        if (value.length < 20) {
          return value;
        }
        {
          return (
            <Tooltip title={value} color="#232e78">
              {value.slice(0, 20)}...
            </Tooltip>
          );
        }
      }
    },

    {
      title: 'Samples',
      dataIndex: 'samples',
      key: '3',
      render: (value) => {
        const sampleImg = [];

        for (let j = 0; j < value.length; j += 1) {
          sampleImg.push(
            <img
              className="sampleImg"
              alt={value[j].id}
              size="small"
              src={value[j].thumbnail}
            />
          );
        }
        return sampleImg;
      }
    },
    {
      title: ' Equipments',
      dataIndex: 'equipmentsRequired',
      key: '4',
      render: (value) => {
        const equipmentsTitle = [];
        const limitLength = value.length <= 2 ? value.length : 2;
        for (let i = 0; i < limitLength; i += 1) {
          equipmentsTitle.push(
            <Tag className="title-tag">
              <Tag color="green" className="count-tag">
                {value[i].requiredQuantity}
              </Tag>
              {value[i].equipment.name}
            </Tag>
          );
        }
        const remainingValue = value.length - 2;
        return remainingValue <= 0 ? (
          <div>{equipmentsTitle}</div>
        ) : (
          <div>
            {equipmentsTitle} <Tag color="blue">+{remainingValue}</Tag>
          </div>
        );
      }
    },
    {
      title: 'Total amt',
      dataIndex: 'price',
      key: '5',
      render: (value) => {
        return ` ₹ ${value}`;
      }
    },
    {
      title: 'Offers',
      dataIndex: 'discount',
      key: '6',
      render: (value, record) => {
        return (
          <span>
            {value} {record.kind === 'PERCENTAGE' ? '% OFF' : '₹ FLAT OFF'}
          </span>
        );
      }
    },
    {
      title: '',
      dataIndex: 'title',
      key: '6',
      render: (value, record) => {
        return (
          <Button
            onClick={async () => {
              const key = 'updatable';
              message.open({
                key,
                type: 'loading',
                content: 'Un-archiving test'
              });
              await unarchiveTest(record.id);
              fetchArchiveTests(previousFetchArchive);
              message.open({
                key,
                type: 'success',
                content: 'Test is Un-archived',
                duration: 1
              });
            }}
            className="unarchive-btn"
          >
            Un-archive
          </Button>
        );
      }
    }
  ];
  return (
    <div className="sample-table">
      {loading ? (
        <LoaderComponent />
      ) : (
        <CommonTable
          rowKey={(record) => record.id}
          columns={columns}
          loadingData={parentWantToLoad || loadingData}
          paginationConfig={paginationProp}
          data={archive}
          onChange={(onChange) => {
            // eslint-disable-next-line no-console
            setLoadingData(true);
            fetchArchiveTests({
              currentSkip: (onChange.current - 1) * limit
            });
          }}
        />
      )}
    </div>
  );
}

export default ArchiveTable;
