import client from '../../apollo';
import {
  SEND_TEMPLATE_CART_MESSAGE,
  SEND_TEMPLATE_CART_MESSAGE_TO_ALL
} from './graphql/mutation';
import { GET_ALL_CUSTOMER_CARTS, GET_CART_TEMPLATES } from './graphql/query';

export const getAllCustomerCarts = async (filters) => {
  try {
    const response = await client.query({
      query: GET_ALL_CUSTOMER_CARTS,
      fetchPolicy: 'network-only',
      variables: {
        filters: filters
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getCustomerCartHistory;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getCartTemplates = async () => {
  try {
    const response = await client.query({
      query: GET_CART_TEMPLATES,
      fetchPolicy: 'network-only'
    });
    // eslint-disable-next-line no-console
    return response?.data?.getWhatsAppCampaignTemplates;
  } catch (error) {
    // eslint-disable-next-line no-console
    throw Error(error);
  }
};

export const sendCartTemplateMessage = async (data) => {
  const { customerId, templateId } = data;
  try {
    const response = await client.mutate({
      mutation: SEND_TEMPLATE_CART_MESSAGE,
      fetchPolicy: 'network-only',
      variables: {
        customerId,
        templateId
      }
    });
    return response?.data;
  } catch (error) {
    throw Error(error);
  }
};

export const sendCartTemplateMessageToAll = async (data) => {
  const { templateId } = data;
  try {
    const response = await client.mutate({
      mutation: SEND_TEMPLATE_CART_MESSAGE_TO_ALL,
      fetchPolicy: 'network-only',
      variables: {
        templateId
      }
    });
    return response?.data;
  } catch (error) {
    throw Error(error);
  }
};

export const test = async () => {};
