import client from '../../apollo';
import { APPROVE_PRESCRIPTION, CANCEL_PRESCRIPTION } from './graphql/mutation';
import {
  GET_ACTIVE_PRESCRIPTIONS,
  GET_HISTORY_PRESCRIPTIONS
} from './graphql/query';

export const getActivePrescriptions = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_ACTIVE_PRESCRIPTIONS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getActivePrescriptions;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getHistoryPrescriptions = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_HISTORY_PRESCRIPTIONS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getHistoryPrescriptions;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const cancelPrescriptionAsAdmin = async (prescriptionId) => {
  try {
    const response = await client.query({
      query: CANCEL_PRESCRIPTION,
      fetchPolicy: 'network-only',
      variables: {
        prescriptionId
      }
    });
    return response?.data;
  } catch (error) {
    return [];
  }
};

export const resolvePrescription = async (prescriptionId) => {
  try {
    const response = await client.query({
      query: APPROVE_PRESCRIPTION,
      fetchPolicy: 'network-only',
      variables: {
        prescriptionId
      }
    });
    return response?.data;
  } catch (error) {
    return [];
  }
};

export const test = async () => {};
