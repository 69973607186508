/* eslint-disable no-console */
import { Button, Space, Table, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import './orderReport.less';
// Icons
import { Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as Back } from '../../../../assets/icons/back.svg';
// import CommonTable from '../../../../common/components/CommonTable';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import { getOrderTransactions } from '../../reports-repository';

function OrderTransactions() {
  // STATES
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // STATES
  const [viewOrder, setViewOrder] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);

  // FETCHING TRANSACTIONS
  const fetchViewHistoryOrders = async () => {
    try {
      const id = searchParams.get('orderId');
      const response = await getOrderTransactions(id);
      // const newPaginationProp = {
      //   total: response
      // };
      // setPaginationProp(newPaginationProp);
      setViewOrder(response.order.transactions);
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchViewHistoryOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(viewOrder);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'razorpayOrderId',
      key: '1'
    },
    {
      title: 'Payment status',
      dataIndex: 'isSuccessful',
      key: '2',
      render: (value) => {
        if (value) {
          return <Tag className="active-tag additional-tag ">Successfull</Tag>;
        }
        <Tag className="expired-tag additional-tag ">Failed</Tag>;
      }
    },
    {
      title: 'Created at',
      dataIndex: 'transactionCreatedAt',
      key: '3',
      render: (value) => {
        return dayjs(value).format('DD/MM/YYYY');
      }
    }
  ];

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Typography.Text className="main-header-title">
              Transactions
            </Typography.Text>
          </Space>
        </div>
      </Header>

      <div className="equipments-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <Table
            columns={columns}
            dataSource={viewOrder}
            loading={loadingData}
            pagination={false}
          />
        )}
      </div>
    </div>
  );
}
export default OrderTransactions;
