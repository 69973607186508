import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import './forgot-pass.less';
// SVG LOGO
import { Button, Form, Input, Typography, message } from 'antd';
import loginLogo from '../../../assets/loginLogo.svg';
import { formValidatorRules } from '../../../common/utils';

function ForgotPassword() {
  const [form] = Form.useForm();
  const { required, email } = formValidatorRules;

  const auth = getAuth();

  const [loading, setLoading] = useState(false);

  const sendPassword = async (formValue) => {
    // eslint-disable-next-line no-console
    console.log(formValue.email);
    setLoading(true);

    await sendPasswordResetEmail(auth, formValue.email)
      .then(() => {
        message.success('Link sent to your email');
      })
      .catch((error) => {
        if (error) {
          message.error('User not found');
        }
      });
    setLoading(false);
  };

  return (
    <div className="forgot-password">
      <div className="image-content">
        <img src={loginLogo} alt="logo" />
      </div>
      <div className="forgot-pass-box">
        <div className="forgot-text">
          <Typography.Text>Forgot your password ?</Typography.Text>
        </div>
        <div className="form-items">
          <Form form={form} layout="vertical" onFinish={sendPassword}>
            <Form.Item label="Email" name="email" rules={[required, email]}>
              <Input className="email-inp" type="text" name="text" />
            </Form.Item>
            <Button loading={loading} htmlType="submit" className="send-btn">
              Send Link
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
