/* eslint-disable no-console */
import { Button, Popconfirm, Space, Typography, message } from 'antd';
import { Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as Back } from '../../assets/icons/back.svg';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import {
  cancelScheduledNotification,
  getScheduledNotification
} from './messages-repository';

function MessageDetails() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const limit = GLOBAL_PAGINATION_LIMIT;
  // STATES
  const [scheduledNotifications, setScheduledNotifications] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [paginationProp, setPaginationProp] = useState();

  const fetchScheduledNotifications = async ({ currentSkip }) => {
    try {
      const id = searchParams.get('messageId');
      const response = await getScheduledNotification(id, {
        skip: currentSkip,
        limit: limit
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setScheduledNotifications(response.notifications.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchScheduledNotifications({ currentSkip: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: 'Message Title',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => {
        return record?.notification?.title;
      }
    },
    {
      title: 'Scheduled on',
      dataIndex: 'sendAt',
      key: 'sendAt',
      render: (value) => {
        return dayjs(value).format('hh:mm a');
      }
    },
    {
      title: 'Scheduled date',
      dataIndex: 'sendAt',
      key: 'sendAt',
      render: (value) => {
        return dayjs(value).format('DD/MM/YY');
      }
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },

    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => {
        return (
          <div className="message-action">
            {record?.status === 'PENDING' ? (
              <Popconfirm
                title="Delete"
                description="Are you sure to cancel this scheduled notification?"
                onConfirm={async () => {
                  const key = 'updatable';
                  message.open({
                    key,
                    type: 'loading',
                    content: 'Cancelling notification'
                  });
                  await cancelScheduledNotification(value);
                  fetchScheduledNotifications({ currentSkip: 0 });
                  message.open({
                    key,
                    type: 'success',
                    content: 'Notification cancelled'
                  });
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button className="delete-btn-message">Cancel</Button>
              </Popconfirm>
            ) : (
              '-'
            )}
          </div>
        );
      }
    }
  ];

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Space>
              <Typography.Text className="main-header-title">
                Message Scheduling Details
              </Typography.Text>
            </Space>
          </Space>
        </div>
      </Header>
      <div className="main-content customers-content">
        <div className="customers-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              data={scheduledNotifications}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchScheduledNotifications({
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MessageDetails;
