import { Navigate } from 'react-router-dom';
import React from 'react';
import { ROUTES } from './common/constants';

function PrivateRoute({ user, children }) {
  if (!user) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  return children;
}

export default PrivateRoute;
