/* eslint-disable no-lone-blocks */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  InputNumber,
  Modal,
  Select,
  Space,
  Tooltip,
  Typography,
  message
} from 'antd';
import Input from 'antd/es/input/Input';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Warning } from '../../../../assets/icons/warning.svg';
import CommonTable from '../../../../common/components/CommonTable';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import SearchComponent from '../../../../common/components/SearchDebounce';
import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT
} from '../../../../common/constants';
import { formValidatorRules } from '../../../../common/utils';

import {
  createEquipment,
  getEquipments,
  updateEquipment
} from '../../test-repository';
import {
  assignEquipmentsToCollector,
  assignMultipleEquipments,
  getSampleCollectors
} from './equipments-repository';
import './equipments.less';

function TestEquipments() {
  const { required } = formValidatorRules;
  const [form] = Form.useForm();
  const [updateAssignForm] = Form.useForm();
  const [assignMultipleForm] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;
  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [equipments, setEquipments] = useState([]);
  const [allEquipments, setAllEquipments] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [isUpdateEquipment, setIsUpdateEquipment] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [equipmentName, setEquipmentName] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [isAssignEquipments, setIsAssignEquipments] = useState(false);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [sampleCollectors, setSampleCollectors] = useState([]);
  const [previousFetchEquipments, setPreviousFetchEquipments] = useState({});
  const [updateAssignModal, setUpdateAssignModal] = useState(false);
  const [availableQty, setAvailableQty] = useState();
  const [list, setList] = useState([]);
  const [isDisable, setIsDisable] = useState(true);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  const fetchEquipments = async ({ currentSkip, currentQuery }) => {
    setCurrentGlobalSkip(currentSkip);
    setPreviousFetchEquipments({
      currentSkip,
      currentQuery
    });
    try {
      //
      const response = await getEquipments({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setEquipments(response.equipments);
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  const fetchSampleCollectors = async () => {
    try {
      //
      const response = await getSampleCollectors();
      setSampleCollectors(response.sampleCollectors);
    } catch (error) {
      //
    }
  };
  useEffect(() => {
    fetchSampleCollectors();
    fetchEquipments({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // UPDATE MODAL
  const getEquipmentByIdToUpdate = (record, value) => {
    setLoadingData(true);
    setTimeout(() => {
      setEquipmentName(value);
      form.setFieldsValue({
        equipment: record.name,
        amount: record.stock.availableQuantity
      });
      setIsModalOpen(true);
      setSelectedId(record.id);
      setLoadingData(false);
    }, 500);
  };

  // UPDATE ASSIGN MODAL
  const updateAssignEquipments = (record, value) => {
    setLoadingData(true);
    setTimeout(() => {
      setEquipmentName(value);
      setAvailableQty(record.stock.availableQuantity);
      setUpdateAssignModal(true);
      setSelectedId(record.id);
      setLoadingData(false);
    }, 500);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      width: 75,
      align: 'center',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Equipments',
      dataIndex: 'name',
      key: 'name',
      render: (value, record, index) => {
        if (value.length < 20) {
          return (
            <Typography.Text
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setIsUpdateEquipment(true);
                setCurrentSelectedIndex(index);
                setTimeout(() => {
                  getEquipmentByIdToUpdate(record, value);
                }, 200);
              }}
            >
              {value}
            </Typography.Text>
          );
        }
        {
          return (
            <Typography.Text
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setIsUpdateEquipment(true);
                setCurrentSelectedIndex(index);
                setTimeout(() => {
                  getEquipmentByIdToUpdate(record, value);
                }, 200);
              }}
            >
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 20)}...
              </Tooltip>
            </Typography.Text>
          );
        }
      }
    },
    {
      title: 'Available Qty',
      dataIndex: 'stock',
      key: 'stock',
      render: (value, record) => {
        return (
          <Space className="warning-container">
            {value?.availableQuantity}
            {value?.availableQuantity < record?.minimumQuantity && <Warning />}
          </Space>
        );
      }
    },
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      render: (value, record, index) => {
        return (
          <span
            className="update-assign"
            onClick={() => {
              form?.resetFields();
              // setUpdateAssignModal(true);
              setCurrentSelectedIndex(index);
              setTimeout(() => {
                updateAssignEquipments(record, value);
              }, 200);
            }}
          >
            <PlusCircleOutlined />
          </span>
        );
      }
    }
  ];

  // CREATE NEW MODAL
  const handleCancel = () => {
    form.resetFields();
    updateAssignForm.resetFields();
    setIsModalOpen(false);
    setIsUpdateEquipment(false);
    setUpdateAssignModal(false);
    setList([]);
  };

  // ASSIGN EQUIPMENTS
  const handleCancelAssignEquipments = () => {
    assignMultipleForm.resetFields();
    setSelectedEquipments([]);
    setIsAssignEquipments(false);
  };

  // SUBMIT FORM
  const submitForm = async (formValue) => {
    const { equipment, amount } = formValue;
    if (amount < 0) {
      message.error('Invalid amount');
      return;
    }
    if (equipment.length > 50) {
      message.error('Exceeded name limit');
      return;
    }
    if (equipment.length < 2) {
      message.error('Minimum name length should be 2');
      return;
    }
    setIsSavingData(true);
    const createdEquipment = await createEquipment({
      name: equipment.toString(),
      availableQuantity: amount
    });
    if (
      equipments.length === 0 ||
      equipments.length < limit ||
      equipments[equipments.length - 1].serialNumber + 1 ===
        createdEquipment.serialNumber
    ) {
      const updatedEquipments = equipments.slice();
      updatedEquipments.push(createdEquipment);
      setEquipments(updatedEquipments);
    }
    form.resetFields();
    setIsSavingData(false);
    setIsModalOpen(false);
    fetchEquipments({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };
  // SUBMIT UPDATED FORM
  const updateForm = async (formValue) => {
    const { equipment, amount } = formValue;
    if (amount < 0) {
      message.error('Invalid amount');
      return;
    }
    if (equipment.length > 50) {
      message.error('Exceeded name limit');
      return;
    }
    if (equipment.length < 2) {
      message.error('Minimum name length should be 2');
      return;
    }
    setIsSavingData(true);
    const updatedEquipment = await updateEquipment({
      name: equipment,
      availableQuantity: amount,
      id: selectedId
    });
    const updatedEquipments = equipments.slice();
    updatedEquipments[currentSelectedIndex] = updatedEquipment;
    setEquipments(updatedEquipments);
    form.resetFields();
    setIsSavingData(false);
    setIsUpdateEquipment(false);
    setIsModalOpen(false);
  };

  // SUBMIT ASSIGN EQUIPMENTS
  const submitAssignedEquipments = async (formValue) => {
    if (selectedEquipments.length <= 0) {
      message.error('At least one equipment required to assign');
      return;
    }
    setIsSavingData(true);
    const { assignTo } = formValue;

    const response = await assignMultipleEquipments({
      sampleCollectorId: assignTo,
      assignEquipments: selectedEquipments.map((element) => {
        return {
          equipment: element.equipment.id,
          quantity: element.quantity
        };
      })
    });
    setIsSavingData(false);
    if (response) {
      message.success('Equipments assigned!');
      fetchEquipments(previousFetchEquipments);
      assignMultipleForm.resetFields();
      setSelectedEquipments([]);
      setIsAssignEquipments(false);
    }
  };

  const onClickAdd = () => {
    let indexOfAvailableEquipment = -1;
    for (let i = 0; i < allEquipments.length; i += 1) {
      if (!allEquipments[i].isDisabled) {
        indexOfAvailableEquipment = i;
        break;
      }
    }
    if (indexOfAvailableEquipment === -1) {
      message.error('No more equipments');
      return;
    }
    const currentAllEquipments = allEquipments.slice();
    currentAllEquipments[indexOfAvailableEquipment] = {
      ...currentAllEquipments[indexOfAvailableEquipment],
      isDisabled: true
    };
    const currentSelectedEquipments = [
      ...selectedEquipments,
      {
        equipment: currentAllEquipments[indexOfAvailableEquipment],
        quantity: 0
      }
    ];
    setAllEquipments(currentAllEquipments);
    setSelectedEquipments(currentSelectedEquipments);
  };

  const assignAddList = () => {
    setIsDisable(true);
    const assignTo = updateAssignForm.getFieldValue('assignTo');
    const quantity = updateAssignForm.getFieldValue('quantity');
    if (assignTo !== undefined && quantity !== undefined) {
      const arr = list.slice();
      for (let i = 0; i < sampleCollectors.length; i += 1) {
        if (assignTo === sampleCollectors[i].id) {
          const index = arr.findIndex((Obj) => Obj.id === assignTo);
          if (index !== -1) {
            const newVal =
              parseInt(availableQty) + parseInt(arr[index].quantity);
            setAvailableQty(newVal);
            arr[index].quantity = quantity;
            setAvailableQty(newVal - quantity);
          } else {
            arr.push({
              id: assignTo,
              name: sampleCollectors[i].name,
              quantity: quantity
            });
            setAvailableQty(availableQty - quantity);
          }
        }
      }
      setList(arr.slice());
      updateAssignForm.resetFields();
    }
  };

  // SUBMIT UPDATED ASSIGN
  const submitUpdatedAssign = async () => {
    setIsSavingData(true);
    const data = [];
    for (let i = 0; i < list.length; i += 1) {
      data.push({
        sampleCollectorId: list[i].id,
        quantity: parseInt(list[i].quantity)
      });
    }
    const newUpdatedEqp = await assignEquipmentsToCollector(data, selectedId);
    const newUpdatedEqps = equipments.slice();
    newUpdatedEqps[currentSelectedIndex] = newUpdatedEqp;
    setEquipments(newUpdatedEqps);
    message.success('Equipments assigned!');
    updateAssignForm.resetFields();
    setList([]);
    fetchEquipments();
    setIsSavingData(false);
    setUpdateAssignModal(false);
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Equipments
            </Typography.Text>
            <SearchComponent
              name="Equipments"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchEquipments({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <div className="equipments-btn">
            <Button
              className="secondary-btn"
              onClick={async () => {
                const key = 'updatable';
                message.open({
                  key,
                  type: 'loading',
                  content: 'Wait! fetching equipments...'
                });
                const response = await getEquipments({
                  skip: 0,
                  limit: 1000,
                  query: ''
                });
                const currentEquipments = response.equipments.slice();
                for (let i = 0; i < currentEquipments.length; i += 1) {
                  currentEquipments[i] = {
                    ...currentEquipments[i],
                    isDisabled:
                      currentEquipments[i].stock.availableQuantity <= 0
                  };
                }
                setAllEquipments(currentEquipments);
                setIsAssignEquipments(true);
                message.open({
                  key,
                  type: 'success',
                  content: 'Equipment fetched',
                  duration: 1
                });
              }}
            >
              Assign equipments
            </Button>
            <Button
              onClick={() => {
                setIsModalOpen(true);
                fetchSampleCollectors();
              }}
              className="create-btn"
            >
              + Create New
            </Button>
          </div>

          {/* CREATE MODAL */}
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            className="create-equipment-modal"
            footer={null}
            centered
            title={isUpdateEquipment ? equipmentName : 'Create Equipment'}
          >
            <Divider />
            <Form
              form={form}
              onFinish={isUpdateEquipment ? updateForm : submitForm}
              className="equipment-form"
              layout="vertical"
            >
              <div className="equipment-form-control">
                <Form.Item
                  rules={[required]}
                  name="equipment"
                  label="Equipment name"
                >
                  <Input
                    className="inp-equiment"
                    name="equipment"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  rules={[required]}
                  name="amount"
                  label="Available quantity"
                >
                  <InputNumber
                    controls={false}
                    // min={1}
                    name="availableQuantity"
                  />
                </Form.Item>
              </div>

              <Divider />
              <div
                className="create-wrapper"
                style={{
                  textAlign: 'right'
                }}
              >
                <Button
                  loading={isSavingData}
                  htmlType="submit"
                  className="create-btn-modal"
                >
                  {isUpdateEquipment ? 'Save' : 'Create'}
                </Button>
              </div>
            </Form>
          </Modal>

          {/* UPDATE ASSIGN MODAL */}
          <Modal
            open={updateAssignModal}
            onCancel={handleCancel}
            className="create-equipment-modal"
            footer={null}
            centered
            title={updateAssignModal ? equipmentName : 'Create Equipment'}
          >
            <Divider />
            <Form
              form={updateAssignForm}
              onFinish={submitUpdatedAssign}
              className="equipment-form"
              layout="vertical"
            >
              <div className="equipments-alloted">
                <Typography.Text>Equipments alloted</Typography.Text>

                <div className="assigns">
                  <Form.Item
                    label="Assign to"
                    // rules={[required]}
                    name="assignTo"
                  >
                    <Select
                      placeholder="Select name"
                      options={sampleCollectors.map((sampleCollector) => {
                        return {
                          value: sampleCollector.id,
                          label: sampleCollector.name
                        };
                      })}
                    />
                  </Form.Item>
                  <b>
                    {' '}
                    <p className="req-amt">Available : {availableQty}</p>
                  </b>
                  <Form.Item
                    label="Assign qty"
                    // rules={[required]}
                    name="quantity"
                  >
                    <Input
                      placeholder="0"
                      onChange={(e) => {
                        let { value } = e.target;
                        // eslint-disable-next-line radix
                        value = parseInt(value);
                        if (value > 0) {
                          setIsDisable(false);
                        } else {
                          setIsDisable(true);
                        }
                        if (value > availableQty) {
                          message.error('Exceeded available quantity');
                          updateAssignForm.resetFields(['quantity']);
                          setIsDisable(true);
                        }
                        if (value < 0 || !value) {
                          message.error('Invalid value ');
                          updateAssignForm.resetFields(['quantity']);
                        }
                      }}
                    />
                  </Form.Item>
                  <Button
                    onClick={assignAddList}
                    disabled={isDisable}
                    className={isDisable ? 'disable-btn' : ''}
                  >
                    Assign
                  </Button>
                </div>
                {list
                  ? list.map((item) => {
                      return (
                        <div key={item.id} className="names-list">
                          <span>{item.name}</span>
                          <span className="add-sub">
                            <Button
                              onClick={() => {
                                const { id } = item;
                                const arr = list.slice();
                                const index = arr.findIndex(
                                  (Obj) => Obj.id === id
                                );
                                if (index !== -1) {
                                  // eslint-disable-next-line radix
                                  const int = parseInt(arr[index].quantity);
                                  if (availableQty !== 0) {
                                    arr[index].quantity = (int + 1).toString();
                                    setAvailableQty(availableQty - 1);
                                  }
                                  setList(arr);
                                }
                              }}
                            >
                              +
                            </Button>
                            <span className="quantity">{item.quantity}</span>
                            <Button
                              onClick={() => {
                                const { id } = item;
                                const arr = list.slice();
                                const index = arr.findIndex(
                                  (Obj) => Obj.id === id
                                );
                                if (index !== -1) {
                                  // eslint-disable-next-line radix
                                  const int = parseInt(arr[index].quantity);
                                  if (int - 1 <= 0) {
                                    arr[index].quantity = 1;
                                  } else {
                                    arr[index].quantity = (int - 1).toString();

                                    setAvailableQty(availableQty + 1);
                                  }

                                  setList(arr);
                                }
                              }}
                            >
                              -
                            </Button>
                            <Button
                              onClick={() => {
                                const { id, quantity } = item;

                                const arr = list.slice();
                                const index = arr.findIndex(
                                  (Obj) => Obj.id === id
                                );
                                arr.splice(index, 1);
                                setList(arr);

                                setAvailableQty(
                                  parseInt(availableQty) + parseInt(quantity)
                                );
                              }}
                              className="remove-btn"
                              type="text"
                            >
                              <CloseCircleOutlined />
                            </Button>
                          </span>
                        </div>
                      );
                    })
                  : null}
              </div>

              <Divider />
              <div
                className="create-wrapper"
                style={{
                  textAlign: 'right'
                }}
              >
                <Button
                  // eslint-disable-next-line no-unneeded-ternary
                  disabled={list.length <= 0 ? true : false}
                  loading={isSavingData}
                  htmlType="submit"
                  className="create-btn-modal"
                >
                  {updateAssignEquipments ? 'Save' : 'Create'}
                </Button>
              </div>
            </Form>
          </Modal>

          {/* ASSIGN EQUIPMENTS MODAL */}
          <Modal
            open={isAssignEquipments}
            onCancel={handleCancelAssignEquipments}
            className="create-equipment-modal"
            centered
            title="Assign equipments"
          >
            <Divider />
            <Form
              form={assignMultipleForm}
              onFinish={submitAssignedEquipments}
              className="equipment-form"
              layout="vertical"
            >
              <div className="assign-person-name">
                <Form.Item label="Assign to" rules={[required]} name="assignTo">
                  <Select
                    placeholder="Select name"
                    options={sampleCollectors.map((sampleCollector) => {
                      return {
                        value: sampleCollector.id,
                        label: sampleCollector.name
                      };
                    })}
                  />
                </Form.Item>
              </div>

              {selectedEquipments.map((selectedEquipment, index) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div className="selection">
                    <div className="assign-equipment-name">
                      <Form.Item
                        label="Select equipments"
                        name={`selectEquipment_${index}`}
                        initialValue={selectedEquipment.equipment.id}
                        rules={[required]}
                      >
                        <Select
                          placeholder="Select equipment"
                          options={allEquipments.map((equipment) => {
                            return {
                              value: equipment.id,
                              label: equipment.name,
                              disabled: equipment.isDisabled
                            };
                          })}
                          onChange={(id) => {
                            const currentSelectedEquipments =
                              selectedEquipments.slice();
                            const currentEquipment =
                              currentSelectedEquipments[index].equipment;
                            const currentAllEquipments = allEquipments.slice();
                            let newEquipmentIndex = 0;
                            for (
                              let i = 0;
                              i < currentAllEquipments.length;
                              i += 1
                            ) {
                              if (
                                currentAllEquipments[i].id ===
                                currentEquipment.id
                              ) {
                                //
                                currentAllEquipments[i] = {
                                  ...currentAllEquipments[i],
                                  isDisabled: false
                                };
                              }
                              if (currentAllEquipments[i].id === id) {
                                newEquipmentIndex = i;
                                currentAllEquipments[i] = {
                                  ...currentAllEquipments[i],
                                  isDisabled: true
                                };
                              }
                            }
                            currentSelectedEquipments.splice(index, 1);
                            setAllEquipments(currentAllEquipments);
                            setSelectedEquipments([
                              ...currentSelectedEquipments,
                              {
                                equipment:
                                  currentAllEquipments[newEquipmentIndex],
                                quantity: 0
                              }
                            ]);
                            assignMultipleForm.setFieldValue(
                              `equipmentAmount_${index}`,
                              ``
                            );
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="amount-req">
                      <p>
                        Available : &nbsp;
                        {selectedEquipment.equipment.stock.availableQuantity -
                          selectedEquipment.quantity}
                      </p>
                      <Form.Item
                        label="Amount qty"
                        rules={[required]}
                        name={`equipmentAmount_${index}`}
                      >
                        <Input
                          value={0}
                          onChange={(e) => {
                            let { value } = e.target;
                            // eslint-disable-next-line no-restricted-globals
                            if (isNaN(value) || value === '') {
                              assignMultipleForm.setFieldValue(
                                `equipmentAmount_${index}`,
                                ''
                              );
                              value = '0';
                            }
                            let quantity = parseInt(value, 10);
                            if (
                              quantity >
                              selectedEquipment.equipment.stock
                                .availableQuantity
                            ) {
                              assignMultipleForm.setFieldValue(
                                `equipmentAmount_${index}`,
                                `${selectedEquipment.equipment.stock.availableQuantity}`
                              );
                              quantity =
                                selectedEquipment.equipment.stock
                                  .availableQuantity;
                            }
                            const currentSelectedEquipments =
                              selectedEquipments.slice();
                            currentSelectedEquipments[index] = {
                              ...currentSelectedEquipments[index],
                              quantity
                            };
                            setSelectedEquipments(currentSelectedEquipments);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                );
              })}

              <Button
                onClick={onClickAdd}
                type="dashed"
                className="add-equipment-modal"
              >
                + Add equipment
              </Button>
              <Divider />
              <Button
                loading={isSavingData}
                htmlType="submit"
                className="create-btn-modal"
              >
                Assign
              </Button>
            </Form>
          </Modal>
        </div>
      </Header>
      <div className="equipments-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            rowKey={(record) => record?.id}
            columns={columns}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={equipments}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchEquipments({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default TestEquipments;
