import client from '../../apollo';
import { COLLECT_SAMPLES_AS_OPERATOR } from './graphql/mutation';
import { GET_SAMPLE_COLLECTED_ORDERS } from './graphql/query';

export const getSampleCollectedOrders = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_SAMPLE_COLLECTED_ORDERS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getSampleCollectedOrders;
  } catch (error) {
    return [];
  }
};

export const collectSamplesAsOperator = async (orderId) => {
  try {
    const response = await client.mutate({
      mutation: COLLECT_SAMPLES_AS_OPERATOR,
      fetchPolicy: 'network-only',
      variables: {
        orderId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const test = async () => {};
