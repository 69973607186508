import client from '../../apollo';
import { UPDATE_REMOTE_CONFIG } from './graphql/mutation';
import { GET_REMOTE_CONFIG } from './graphql/query';

export const getRemoteConfigValue = async () => {
  try {
    const response = await client.query({
      query: GET_REMOTE_CONFIG,
      fetchPolicy: 'network-only'
    });
    return response?.data?.getRemoteConfigValue;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const updateRemoteConfigValue = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_REMOTE_CONFIG,
      fetchPolicy: 'network-only',
      variables: {
        data: data
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const test = async () => {};
