/* eslint-disable no-console */
/* eslint-disable no-lone-blocks */
import { LoadingOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Space,
  Tooltip,
  Typography,
  Upload,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import { getType } from 'mime';
import React, { useEffect, useState } from 'react';
import { ReactComponent as UploadImage } from '../../assets/upload.svg';
import {
  getFileSignedUrl,
  postUploadPhoto
} from '../../common/common-repository';
import CommonTable from '../../common/components/CommonTable';
import CountryCodePicker from '../../common/components/CountryCodePicker';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import { DEBOUNCE_TIME, GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import {
  createLab,
  getLabs,
  toggleIsFeatureLab,
  updateLab
} from './labs-repository';
import './labs.less';

function Labs() {
  const [form] = Form.useForm();
  const { required, number } = formValidatorRules;
  const limit = GLOBAL_PAGINATION_LIMIT;
  // STATES

  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [labs, setLabs] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [userName, setUserName] = useState('');
  const [uploadedImage, setUploadedImage] = useState();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);

  const fetchLabs = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getLabs({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setLabs(response.labs.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchLabs({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // VALIDATING IMG AS SVG
  const beforeUpload = (file) => {
    const isLT5MB = file.size / 1024 / 1024 <= 5;
    if (!isLT5MB) {
      message.error(`File must smaller than 5MB!`);
      return !isLT5MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  // LAB PHOTO UPLOAD
  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await postUploadPhoto(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setUploadedImage(undefined);
    setIsCreateModalOpen(false);
    setIsUpdateUser(false);
  };

  // UPDATE FORM MODAL
  const userUpdate = async (index, value, record) => {
    setLoadingData(true);
    setIsUpdateUser(true);
    setTimeout(() => {
      setIsCreateModalOpen(true);
      setLoadingData(false);
      setUserName(value);
      form.setFieldsValue({
        name: record.name,
        displayName: record.displayName,
        address: record.address,
        countryCode: (record.countryCode || '91').replace('+', ''),
        mobileNumber: record.mobileNumber
      });
    }, 500);
  };

  // TOGGLE FEATURE
  const toggleFeatured = (index) => {
    labs[index] = {
      ...labs[index],
      isFeatured: !labs[index].isFeatured
    };
    toggleIsFeatureLab(labs[index].id);
    setLabs(labs.slice());
  };

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record, index) => {
        if (value.length < 20) {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setSelectedId(record.id);
                setUploadedImage(record.thumbnail);
                setTimeout(() => {
                  userUpdate(index, value, record);
                }, 200);
              }}
            >
              {value}
            </Typography>
          );
        }
        {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setSelectedId(record.id);
                setTimeout(() => {
                  userUpdate(index, value, record);
                }, 200);
              }}
            >
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 20)}...
              </Tooltip>
            </Typography>
          );
        }
      }
    },

    {
      title: 'Nick Name',
      dataIndex: 'displayName',
      key: 'displayName'
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',

      render: (value, record) => {
        return (
          <p>
            {record.countryCode}-{value}
          </p>
        );
      }
    },
    {
      title: 'Address ',
      dataIndex: 'address',
      key: 'address',
      render: (value) => {
        if (value.length < 20) {
          return <Typography.Text>{value}</Typography.Text>;
        }
        {
          return (
            <Typography.Text>
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 20)}...
              </Tooltip>
            </Typography.Text>
          );
        }
      }
    },
    {
      title: 'Availability',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
      render: (value, record, index) => {
        return (
          <Checkbox
            defaultChecked={value}
            onClick={() => toggleFeatured(index)}
          />
        );
      }
    },
    {
      title: ' ',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (value, record) => {
        return <Image src={value} alt={record.name} width={50} />;
      }
    }
  ];

  // SUBMIT FORM
  const onSubmitForm = async (formValue) => {
    const { name, displayName, mobileNumber, address, countryCode } = formValue;

    const data = {
      name,
      thumbnail: uploadedImage,
      displayName,
      mobileNumber,
      address,
      countryCode: `+${countryCode || '91'}`
    };

    if (mobileNumber.length !== 10) {
      message.error('Invalid mobile number');
      return;
    }
    if (name.length > 50) {
      message.error('Exceeded name limit');
      return;
    }
    setIsSavingData(true);
    const createdLab = await createLab(data);
    if (
      labs.length === 0 ||
      labs.length < limit ||
      labs[labs.length - 1].serialNumber + 1 === createdLab.serialNumber
    ) {
      const updatedLab = labs.slice();
      updatedLab.push(createdLab);
      setLabs(updatedLab.slice());
    }
    form.resetFields();
    setUploadedImage(undefined);
    setIsSavingData(false);
    setIsCreateModalOpen(false);
    fetchLabs({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  // UPDATE FORM
  const onSubmitUpdateUser = async (formValue) => {
    const { name, displayName, mobileNumber, address, countryCode } = formValue;
    if (mobileNumber.length !== 10) {
      message.error('Invalid mobile number');
      return;
    }
    if (name.length > 50) {
      message.error('Exceeded name limit');
      return;
    }
    setIsSavingData(true);
    const updatedLab = await updateLab({
      name,
      thumbnail: uploadedImage,
      displayName,
      countryCode: `+${countryCode || '91'}`,
      mobileNumber,
      address,
      labId: selectedId
    });
    const updatedLabs = labs.slice();
    updatedLabs[currentSelectedIndex] = updatedLab;
    setLabs(updatedLabs);
    form?.resetFields();
    setUploadedImage(undefined);
    setIsSavingData(false);
    setIsUpdateUser(false);
    setIsCreateModalOpen(false);
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Labs
            </Typography.Text>
            <SearchComponent
              name="Labs"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchLabs({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button
            onClick={() => setIsCreateModalOpen(true)}
            className="create-btn"
          >
            + Create New
          </Button>
          {/* CREATE MODAL */}
          <Modal
            open={isCreateModalOpen}
            onCancel={handleCancel}
            className="create-test-modal create-wrapper"
            centered
            title={isUpdateUser ? userName : 'Create Lab'}
          >
            <Divider />
            <Form
              onFinish={isUpdateUser ? onSubmitUpdateUser : onSubmitForm}
              form={form}
              layout="vertical"
            >
              <div className="card-height-labs">
                <div
                  className="profile"
                  style={{
                    paddingBottom: '20px',
                    paddingTop: '10px'
                  }}
                >
                  <Upload
                    name="upload-icon"
                    onChange={onIconUpload}
                    accept="image/*"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                  >
                    {uploadedImage && !uploadingImage && (
                      <Avatar
                        src={uploadedImage}
                        alt="Selected image"
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    {!uploadedImage && !uploadingImage && (
                      <UploadImage className="category-image" />
                    )}
                    {uploadingImage && <LoadingOutlined />}
                    {uploadedImage ? (
                      <p>Update lab image</p>
                    ) : (
                      <p>Upload lab image</p>
                    )}
                  </Upload>
                </div>
                <div className="row-one">
                  <Form.Item rules={[required]} name="name" label="Lab Name">
                    <Input type="text" placeholder="Enter lab name" />
                  </Form.Item>
                  <Form.Item
                    rules={[required]}
                    name="displayName"
                    label="Display Name"
                  >
                    <Input type="text" placeholder="Enter display name" />
                  </Form.Item>
                </div>
                <div className="row-one">
                  <Form.Item
                    rules={[
                      required,
                      number,
                      () => ({
                        validator(rule, value) {
                          if (value.length > 10) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise?.reject('Number limit exceeded');
                          }
                          return Promise?.resolve();
                        }
                      })
                    ]}
                    name="mobileNumber"
                    label="Mobile Number"
                  >
                    <Input
                      type="text"
                      addonBefore={<CountryCodePicker name="countryCode" />}
                      placeholder="Enter mobile number"
                    />
                  </Form.Item>
                </div>
                <div className="row-one">
                  <Form.Item rules={[required]} name="address" label="Address">
                    <Input
                      className="lab-address"
                      type="text"
                      placeholder="Enter address"
                    />
                  </Form.Item>
                </div>
              </div>
              <Divider />
              <Button
                loading={isSavingData}
                htmlType="submit"
                className="create-btn-modal"
              >
                {isUpdateUser ? 'Save' : 'Create'}
              </Button>
            </Form>
          </Modal>
        </div>
      </Header>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={labs}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchLabs({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Labs;
