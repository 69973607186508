/* eslint-disable no-console */
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import {
  DEBOUNCE_TIME,
  defaultDateFormat,
  GLOBAL_PAGINATION_LIMIT
} from '../../common/constants';
import { getLabs } from '../labs/labs-repository';
import { submitSampleTo } from '../reports/reports-repository';
import {
  collectSamplesAsOperator,
  getSampleCollectedOrders
} from './labSamples-repository';
import './sample.less';

function Samples() {
  const limit = GLOBAL_PAGINATION_LIMIT;
  const format = defaultDateFormat;
  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [sampleCollectedByOrder, setSampleCollectedByOrder] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [samplesReceivedData, setSamplesReceivedData] = useState();
  const [selectOrderId, setSelectOrderId] = useState('');
  const [query, setQuery] = useState('');
  const [isPartnerSelected, setIsPartnerSelected] = useState(false);
  const [labs, setLabs] = useState([]);
  const [sampleData, setSampleData] = useState();

  const fetchSamplesByOrders = async ({ currentSkip, currentQuery }) => {
    try {
      //
      const response = await getSampleCollectedOrders({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setSampleCollectedByOrder(response.orders.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  const fetchLabs = async () => {
    try {
      //
      const response = await getLabs({
        skip: 0,
        limit: 10000,
        query: ''
      });
      setLabs(response.labs.slice());
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    fetchSamplesByOrders({
      currentQuery: '',
      currentSkip: 0
    });
    fetchLabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Order#',
      dataIndex: 'orderId',
      key: '1'
    },
    {
      title: 'Patients',
      dataIndex: 'patient',
      key: '2',
      render: (value) => {
        return <Typography.Text>{value.name}</Typography.Text>;
      }
    },
    {
      title: 'Gender',
      dataIndex: 'patient',
      key: '3',
      render: (value) => {
        return <Typography.Text>{value.sex}</Typography.Text>;
      }
    },
    {
      title: ' Age',
      dataIndex: 'patient',
      key: '4',
      render: (value) => {
        return <Typography.Text>{value.age}</Typography.Text>;
      }
    },
    {
      title: 'Sample collected on',
      dataIndex: 'sampleCollectedAt',
      key: '5',
      render: (value) => {
        if (!value) return '';
        return moment(value).format(format);
      }
    }
  ];

  const selectRow = (record) => {
    if (!record.sampleSubmitTo) {
      setIsPartnerSelected(false);
    } else {
      setIsPartnerSelected(true);
    }
    setSelectOrderId(record.id);
    setSampleData(record);
    setSamplesReceivedData(record.requiredSamples);
  };

  // SAMPLES COLLECTED

  const samplesCollected = () => {
    if (!isPartnerSelected) {
      message.error('Please select collection partner');
      return;
    }
    // eslint-disable-next-line no-undef
    const elements = document.getElementsByClassName('ant-checkbox-input');
    for (let i = 0; i < elements.length; i += 1) {
      if (!elements[i].checked) {
        message.error('All equipment should be checked');
        return;
      }
    }
    collectSamplesAsOperator(selectOrderId);
    const orderIndex = sampleCollectedByOrder.findIndex(
      (obj) => obj.id.toString() === selectOrderId
    );
    sampleCollectedByOrder.splice(orderIndex, 1);
    setSampleCollectedByOrder(sampleCollectedByOrder.slice());
    setSamplesReceivedData();
  };

  const handleSelect = async (value, ID) => {
    const { orderId } = ID;
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Processing'
    });

    setSampleData((prevSampleData) => ({
      ...prevSampleData,
      sampleSubmitTo: { id: value }
    }));

    await submitSampleTo(orderId, value);
    setIsPartnerSelected(true);
    message.open({
      key,
      type: 'success',
      content: 'Done'
    });
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Samples
            </Typography.Text>
            <SearchComponent
              name="Samples"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchSamplesByOrders({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
        </div>
      </Header>
      <div className="sample-contents">
        <div className="main-content test-categories-content">
          {loading ? (
            <LoaderComponent />
          ) : (
            <Row>
              <Col span={samplesReceivedData ? 16 : 24}>
                <CommonTable
                  rowKey={(record) => record.id}
                  rowClassName={(record) =>
                    record.id === selectOrderId ? 'selected-row' : ''
                  }
                  columns={columns}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        selectRow(record);
                      }
                    };
                  }}
                  loadingData={loadingData}
                  paginationConfig={paginationProp}
                  data={sampleCollectedByOrder}
                  onChange={(onChange) => {
                    // eslint-disable-next-line no-console
                    setLoadingData(true);
                    fetchSamplesByOrders({
                      currentQuery: query,
                      currentSkip: (onChange.current - 1) * limit
                    });
                  }}
                />
              </Col>
              {samplesReceivedData ? (
                <Col span={8}>
                  <Form className="sample-card" onFinish={samplesCollected}>
                    <Card title="Samples received for">
                      <div className="sample-card-content">
                        {samplesReceivedData.map((data) => {
                          const sampleName = data.sample.name;
                          return (
                            <div key={data.id}>
                              <div className="sample-checkbox-container">
                                <p className="samples-checkbox">
                                  <span className="main-sample-title">
                                    {sampleName} Sample
                                  </span>
                                </p>
                                <Divider />
                              </div>
                              {data.linkedEquipment.map((eqp, index) => {
                                return (
                                  <div
                                    key={eqp}
                                    className="sample-checkbox-container"
                                  >
                                    <p className="samples-checkbox">
                                      <Checkbox className="equipment-checkbox">
                                        Equipment #{index + 1}
                                      </Checkbox>
                                      <span>{eqp}</span>
                                    </p>
                                    <Divider />
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          padding: '10px'
                        }}
                      />
                      <Select
                        className="sample-selector"
                        onChange={handleSelect}
                        value={sampleData?.sampleSubmitTo?.id}
                        placeholder="Select lab"
                        options={labs.map((lab) => {
                          return {
                            value: lab.id,
                            label: lab.displayName,
                            orderId: selectOrderId
                          };
                        })}
                      />
                      <div className="sample-btn-space">
                        <Button htmlType="submit" className="samples-btn">
                          Samples collected
                        </Button>
                      </div>
                    </Card>
                  </Form>
                </Col>
              ) : (
                <Col span={0} />
              )}
            </Row>
          )}
        </div>
      </div>
    </div>
  );
}

export default Samples;
