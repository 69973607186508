import { Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSourceTag } from '../../../common/components/common-components';
import CommonTable from '../../../common/components/CommonTable';
import LoaderComponent from '../../../common/components/LoaderComponent';
import {
  defaultDateFormat,
  GLOBAL_PAGINATION_LIMIT,
  ROUTES
} from '../../../common/constants';
import { getLinkedOrderOfDoctor } from '../doctor-repository';

function LinkedOrders({ startDateVal, endDateVal, doctorId, forcedRefetch }) {
  const limit = GLOBAL_PAGINATION_LIMIT;
  const format = defaultDateFormat;
  const navigate = useNavigate();

  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [activeReports, setActiveReports] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [skipVal, setSkipVal] = useState(0);

  // FETCHING ACTIVE REPORTS
  const fetchLinkedOrders = async ({ currentSkip, sDate, eDate }) => {
    setLoadingData(true);
    try {
      const filters = {
        skip: currentSkip,
        limit: limit,
        startDate: sDate,
        endDate: eDate
      };
      const response = await getLinkedOrderOfDoctor(doctorId, filters);
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setActiveReports(response.orders.slice());
      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchLinkedOrders({
      currentSkip: skipVal,
      sDate: startDateVal,
      eDate: endDateVal
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateVal, endDateVal, forcedRefetch]);

  const selectRow = (record) => {
    const url = `${ROUTES.REPORT_DETAIL}?orderId=${record.id}`;
    navigate(url);
  };

  const columns = [
    {
      title: 'Order#',
      dataIndex: 'orderId',
      key: '1',
      render: (value, record) => {
        const { source } = record;
        return (
          <div>
            <Typography
              className="clickable-title"
              onClick={() => {
                selectRow(record);
              }}
            >
              {value}
            </Typography>
            {getSourceTag(source)}
          </div>
        );
      }
    },

    {
      title: 'Patient',
      dataIndex: 'patient',
      key: '2',
      render: (value) => {
        return value.name;
      }
    },
    {
      title: 'Booked on',
      dataIndex: 'scheduledAt',
      key: '3',
      render: (value) => {
        if (!value) return '';
        return dayjs(value).format(format);
      }
    },
    {
      title: 'Scheduled at',
      dataIndex: 'scheduledAt',
      key: '5',
      render: (value) => {
        if (!value) return '';
        return dayjs(value).format(format);
      }
    },
    {
      title: 'Total amount',
      dataIndex: 'subTotal',
      key: '6',
      render: (value) => {
        return `₹ ${value}`;
      }
    },
    {
      title: 'Payment mode',
      dataIndex: 'paymentVia',
      key: '7'
    },
    {
      title: 'Booked Status',
      dataIndex: 'orderStatus',
      key: '8',
      align: 'right'
    },
    {
      title: 'Order Transit Status',
      dataIndex: 'orderTransitStatus',
      key: '9',
      align: 'right'
    }
  ];

  return (
    <div className="main-content customers-content">
      <div className="customers-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={activeReports}
            onChange={(onChange) => {
              setLoadingData(true);
              setSkipVal((onChange.current - 1) * limit);
              fetchLinkedOrders({
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}

export default LinkedOrders;
