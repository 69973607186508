import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.less';
// SVG LOGO
import { Button, Checkbox, Form, Input, Typography } from 'antd';
import loginLogo from '../../../assets/loginLogo.svg';
import LoaderComponent from '../../../common/components/LoaderComponent';
import { sleep } from '../../../common/time';
import { formValidatorRules } from '../../../common/utils';
import { authenticateUser, signIn } from '../auth-repository';

const { required, email } = formValidatorRules;

function Login({ setUser }) {
  // NAVIGATE
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);

  const onLoginSubmit = async (formValue) => {
    setLoginLoading(true);
    try {
      const response = await signIn(formValue.email, formValue.password);
      if (response) {
        setUser(response);
      }
    } catch (error) {
      //
    }
    setLoginLoading(false);
  };

  useEffect(() => {
    //
    sleep(1000).then(() => {
      authenticateUser().then((user) => {
        if (user === null) {
          setIsLoading(false);
        } else {
          setUser(user);
        }
      });
    });
  });
  if (isLoading) {
    return <LoaderComponent />;
  }
  return (
    <div className="login-page">
      <div className="image-content">
        <img src={loginLogo} alt="logo" />
      </div>
      <div className="input-form">
        <div className="login-text">
          <Typography.Text>Login to your account</Typography.Text>
        </div>
        <div className="form-items">
          <Form layout="vertical" onFinish={onLoginSubmit}>
            <Form.Item label="Email" name="email" rules={[required, email]}>
              <Input className="email-inp" type="text" name="text" />
            </Form.Item>
            <Form.Item label="Password" name="password" rules={[required]}>
              <Input.Password className="password-inp" name="password" />
            </Form.Item>
            <div className="remember-forgot">
              <Checkbox className="check-box">Remember Me</Checkbox>
              <Button
                className="forgot-pass"
                type="link"
                onClick={() => navigate('/forgot-password')}
              >
                Forgot password?
              </Button>
            </div>
            <div className="h-15" />
            <Button
              type="primary"
              className="login-btn"
              htmlType="submit"
              loading={loginLoading}
            >
              Login
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
export default Login;
