import client from '../../apollo';
import { CREATE_DEEP_LINK, DELETE_DEEP_LINK } from './graphql/mutation';
import { GET_ALL_DEEP_LINKS } from './graphql/query';

export const test = async () => {};

export const getDeepLinkList = async (filters) => {
  const { skip, limit, query } = filters;

  try {
    const response = await client.query({
      query: GET_ALL_DEEP_LINKS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getAllDeepLinks;
  } catch (error) {
    return [];
  }
};

export const addNewDeepLink = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_DEEP_LINK,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return response?.data?.createDeepLinkAsAdmin?.deepLink;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const removeDeepLink = async (deepLinkId) => {
  try {
    const response = await client.query({
      query: DELETE_DEEP_LINK,
      fetchPolicy: 'network-only',
      variables: {
        deepLinkId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};
