import React from 'react';
import './404.less';

// Icons
import { Button, Result } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../common/constants';

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className="content-wrapper">
      <Header className="main-header" />
      <div className="not-found">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button
              className="create-btn"
              onClick={() => {
                navigate(ROUTES.MAIN);
              }}
            >
              Back Home
            </Button>
          }
        />
      </div>
    </div>
  );
}
export default PageNotFound;
