import { gql } from '@apollo/client';

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;

export const CREATE_DEEP_LINK = gql`
  mutation CreateDeepLinkAsAdmin($data: CreateDeepLinkInput) {
    createDeepLinkAsAdmin(data: $data) {
      deepLink {
        id
        link
        description
        clickCount
      }
      isCreated
    }
  }
`;

export const DELETE_DEEP_LINK = gql`
  mutation ($deepLinkId: String) {
    removeDeepLink(deepLinkId: $deepLinkId)
  }
`;
