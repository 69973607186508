import { gql } from '@apollo/client';

export const GET_CTA_BANNER_AS_ADMIN = gql`
  query getCtaBanners($filters: GetBannerInput!) {
    getCtaBanners(filters: $filters) {
      total
      CtaBanner {
        id
        title
        thumbnail
        buttonTitle
        content
        coupon
        serialNumber
        isFeatured
        isFirstTime
        isGuestUser
        test {
          id
          title
        }
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
