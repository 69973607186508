import { gql } from '@apollo/client';

export const GET_OVERALL_DASHBOARD_DATA = gql`
  query getOverallDashboardData {
    getOverallDashboardData {
      totalCustomers
      totalOrders
      totalRevenue
      totalProductionCost
      # totalEarning
      totalConvenienceFees
      totalAppliedDiscount
      totalCoinsUsed
      totalFreeReports
      totalPaidReports
      totalDeletedAccounts
      totalMaleCustomers
      totalFemaleCustomers
      totalRepeatedCustomer
      totalMoreThanThreeOrdersRepeatedCustomer
      totalMoreThanFiveOrdersRepeatedCustomer
      totalPatient
    }
  }
`;

export const GET_ACTIVE_DASHBOARD_DATA = gql`
  query getActiveDashboardData($filters: ActiveDashboardInput) {
    getActiveDashboardData(filters: $filters) {
      totalReportBooked
      totalSampleCollected
      totalSentToLab
      totalReportUploaded
      totalReportPublished
      totalReportDelivered
    }
  }
`;

export const GET_OVERALL_DATA = gql`
  query getDashboardWithinTimeFrame($filters: DashboardWithinTimeFrameInput) {
    getDashboardWithinTimeFrame(filters: $filters) {
      totalCustomers
      totalOrders
      totalRevenue
      totalProductionCost
      totalConvenienceFees
      totalAppliedDiscount
      totalCoinsUsed
      totalFreeReports
      totalPaidReports
      totalPaidProductionCost
      totalFreeProductionCost
      totalPaidRevenue
      totalDeletedAccounts
      totalMaleCustomers
      totalFemaleCustomers
      totalRepeatedCustomer
      totalMoreThanThreeOrdersRepeatedCustomer
      totalMoreThanFiveOrdersRepeatedCustomer
      totalPatient
      totalOrdersWithMobile
      totalOrdersWithWeb
      totalOrdersWithAdmin
    }
  }
`;

export const GET_REPEATED_CUSTOMERS_DATA = gql`
  query getRepeatedCustomers(
    $repeatedCount: Int!
    $filters: RepeatedCustomerDataInput!
  ) {
    getRepeatedCustomers(repeatedCount: $repeatedCount, filters: $filters) {
      totalRepeatedCustomer
      totalRepeatedCustomerDetails {
        customerId
        totalOrders
        customerDetails {
          name
          countryCode
          mobileNumber
          email
        }
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
