/* eslint-disable no-constant-condition */
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Tooltip,
  Typography,
  Upload
} from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  CloseCircleOutlined,
  LoadingOutlined,
  WarningFilled
} from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import dayjs from 'dayjs';
import { getType } from 'mime';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as CalenderIcon } from '../../assets/calendar.svg';
import { ReactComponent as UploadImage } from '../../assets/upload.svg';
import {
  getFileSignedUrl,
  postUploadPhoto
} from '../../common/common-repository';
import CommonTable from '../../common/components/CommonTable';
import CountryCodePicker from '../../common/components/CountryCodePicker';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT,
  ROUTES
} from '../../common/constants';
import {
  downloadAsExcel,
  formValidatorRules,
  getStartAndEndDateFromWeek,
  toLocalISOString
} from '../../common/utils';
import {
  addCustomerAsAdmin,
  getAllCustomers,
  getExcelCustomerData,
  toggleCustomerEnable
} from './customers-repository';
import './customers.less';

function Customers() {
  const { pageNo } = useParams();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('query') || '';
  const currentPage = parseInt(pageNo, 10) || 1;
  const regex = /^[0-9]+$/;
  const floatRegex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { required, number } = formValidatorRules;
  const limit = GLOBAL_PAGINATION_LIMIT;
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [query, setQuery] = useState(searchQuery);
  const [paginationProp, setPaginationProp] = useState();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [gender, setGender] = useState('MALE');
  const [isSavingData, setIsSavingData] = useState(false);
  const [openDisableUser, setOpenDisableUser] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  const [excelModal, setExcelModal] = useState(false);
  const [type, setType] = useState('all');
  const [startDateVal, setStartDateVal] = useState(null);
  const [endDateVal, setEndDateVal] = useState(null);
  const [isDownload, setIsDownload] = useState(false);

  const fetchCustomers = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getAllCustomers({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setCustomers(response.customers.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchCustomers({
      currentQuery: query,
      currentSkip: (currentPage - 1) * limit
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // TOGGLE ENABLE DISABLE

  const handlePageChange = (page) => {
    // Use the navigate function to update the URL
    navigate(`/customers/${page}`);
  };

  const toggleEnabled = async (index) => {
    customers[index] = {
      ...customers[index],
      isDisabled: !(customers[index].isDisabled || false)
    };
    toggleCustomerEnable(customers[index].id);
    setCustomers(customers.slice());
  };

  // FUNCTIONS
  const handleCancel = () => {
    form.resetFields();
    setGender('MALE');
    setUploadedImage(undefined);
    setIsCreateModalOpen(false);
  };

  const handleCancelExcel = () => {
    setType('all');
    setStartDateVal(null);
    setEndDateVal(null);
    setExcelModal(false);
  };

  const handleDownload = async () => {
    const payload = {
      filters: {
        endDate: endDateVal,
        startDate: startDateVal
      }
    };
    try {
      setIsDownload(true);
      const response = await getExcelCustomerData(payload);
      const { data } = response;
      const excelData = [];
      for (let i = 0; i < data.length; i += 1) {
        const customer = data[i];
        excelData.push({
          Name: customer.name,
          Age: customer.age,
          Gender: customer.sex,
          Phone_No: `${customer.countryCode} ${customer.mobileNumber}`
        });
      }
      downloadAsExcel(excelData);
      setIsDownload(false);
      handleCancelExcel();
    } catch (error) {
      setIsDownload(false);
    }
  };

  // COLUMNS
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        // eslint-disable-next-line no-lone-blocks
        {
          if (value.length < 20) {
            return (
              <Typography
                className="clickable-title"
                onClick={() => {
                  const url = `${ROUTES.CUSTOMER_DETAIL}?customerId=${record.id}`;
                  navigate(url);
                }}
              >
                {value}
              </Typography>
            );
          }

          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                const url = `${ROUTES.CUSTOMER_DETAIL}?customerId=${record.id}`;
                navigate(url);
              }}
            >
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 20)}...
              </Tooltip>
            </Typography>
          );
        }
      }
    },
    {
      title: 'Mobile number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      render: (value, record) => {
        return (
          <p>
            {record.countryCode}-{value}
          </p>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Action',
      dataIndex: 'isDisabled',
      key: 'isDisabled',
      render: (value, record, index) => {
        return value ? (
          <span
            className="disable-class"
            onClick={() => {
              toggleEnabled(index);
            }}
          >
            Disabled
          </span>
        ) : (
          <span
            className="enable-class"
            onClick={() => {
              setCurrentSelectedIndex(index);
              setTimeout(() => {
                setOpenDisableUser(true);
              }, 300);
            }}
          >
            Enabled
          </span>
        );
      }
    }
  ];

  // VALIDATING IMG AS SVG
  const beforeUpload = (file) => {
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error('Image must smaller than 10MB!');
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  // BANNER UPLOAD
  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await postUploadPhoto(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  const onSubmitForm = async (formValue) => {
    const { name, email, mobileNo, dob, coinBalance, countryCode } = formValue;

    if (mobileNo.length !== 10) {
      message.error('Invalid mobile number');
      return;
    }
    if (!mobileNo.match(regex)) {
      message.error('Invalid mobile number');
      return;
    }

    if (parseFloat(coinBalance) < 0) {
      message.error('Balance should not be negative');
      return;
    }
    if (coinBalance) {
      if (!coinBalance.match(floatRegex)) {
        message.error('Balance should be number');
        return;
      }
    }

    setIsSavingData(true);
    const createdCustomer = await addCustomerAsAdmin({
      avatar: uploadedImage || null,
      name,
      email: email || null,
      mobileNumber: mobileNo,
      dob: dayjs(dob).format('DD/MM/YYYY'),
      sex: gender,
      countryCode: `+${countryCode || '91'}`,
      appCoinBalance: parseFloat(coinBalance) || 0
    });
    if (createdCustomer) {
      if (
        customers.length === 0 ||
        customers.length < limit ||
        customers[customers.length - 1].serialNumber + 1 ===
          createdCustomer.serialNumber
      ) {
        const updatedCustomer = customers.slice();
        updatedCustomer.push(createdCustomer);
        setCustomers(updatedCustomer.slice());
      }
    }
    form.resetFields();
    setGender('MALE');
    setUploadedImage(undefined);
    setIsSavingData(false);
    setIsCreateModalOpen(false);
    fetchCustomers({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };
  const genderChange = (e) => {
    e.preventDefault();
    setGender(e.target.value);
  };

  const dateSelectionType = () => {
    switch (type) {
      case 'date':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(value) => {
              const utcTime = value;
              const utcDate = new Date(utcTime);
              // Convert to IST by adding 5 hours and 30 minutes
              utcDate.setHours(utcDate.getHours() + 5);
              utcDate.setMinutes(utcDate.getMinutes() + 30);
              const istTime = dayjs(utcDate.toISOString()).format('YYYY-MM-DD');
              setStartDateVal(istTime);
              setEndDateVal(istTime);
            }} // Pass the function reference without invoking it
          />
        );
      case 'week':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(date, dateString) => {
              const match = dateString.match(/^(\d+)-(\d+)(?:st|nd|rd|th)$/);
              if (match) {
                const year = match[1];
                const day = match[2];
                const { startDate, endDate } = getStartAndEndDateFromWeek(
                  year,
                  day
                );
                setStartDateVal(dayjs(startDate).format('YYYY-MM-DD'));
                setEndDateVal(dayjs(endDate).format('YYYY-MM-DD'));
              } else {
                //
              }
            }}
          />
        );
      case 'month':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(value) => {
              const selectedDate = new Date(value);

              // Set the start date to the 1st day of the selected month
              const startDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                1
              );
              // Calculate the end of the month
              const endOfMonth = new Date(startDate);
              endOfMonth.setMonth(startDate.getMonth() + 1, 0); // Set the day to 0 of the next month (last day of the current month)

              // Check if the year is a leap year
              const year = startDate.getFullYear();
              const isLeapYear =
                (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

              if (isLeapYear) {
                // Modify endOfMonth for leap years (February has 29 days)
                if (startDate.getMonth() === 1) {
                  // February (0-based index)
                  endOfMonth.setDate(29);
                }
              } else {
                //
              }

              // Set your values
              const startDateValue = toLocalISOString(startDate);
              const endDateValue = toLocalISOString(endOfMonth);

              setStartDateVal(dayjs(startDateValue).format('YYYY-MM-DD'));
              setEndDateVal(dayjs(endDateValue).format('YYYY-MM-DD'));
            }}
          />
        );
      case 'custom':
        return (
          <RangePicker
            suffixIcon={<CalenderIcon />}
            onChange={(value) => {
              if (value && value.length === 2) {
                setStartDateVal(dayjs(value[0]).format('YYYY-MM-DD'));
                setEndDateVal(dayjs(value[1]).format('YYYY-MM-DD'));
              } else {
                setStartDateVal(undefined); // Clear the start date when no range is selected
                setEndDateVal(undefined); // Clear the end date when no range is selected
              }
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Customers
            </Typography.Text>
            <SearchComponent
              name="Customers"
              debounceTime={DEBOUNCE_TIME}
              initialValue={query}
              getData={(value) => {
                setQuery(value);
                // searchParams.set('query', value);
                // eslint-disable-next-line no-undef
                const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?query=${value}`;
                // eslint-disable-next-line no-undef
                window.history.pushState({ path: newUrl }, '', newUrl);
                setLoadingData(true);
                fetchCustomers({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <div
            style={{
              display: 'flex',
              gap: '10px'
            }}
          >
            <Button
              className="orderHistory"
              onClick={() => setExcelModal(true)}
            >
              Export to excel
            </Button>
            <Button
              className="create-btn"
              onClick={() => setIsCreateModalOpen(true)}
            >
              + Create New
            </Button>
          </div>
        </div>
        {/* CREATE MODAL */}
        <Modal
          open={isCreateModalOpen}
          onCancel={handleCancel}
          className="create-test-modal"
          centered
          title="Create Customer"
        >
          <Divider />
          <Form form={form} onFinish={onSubmitForm} layout="vertical">
            <Form.Item name="upload">
              <div className="profile">
                <Upload
                  name="upload-icon"
                  onChange={onIconUpload}
                  accept="image/*"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                >
                  {uploadedImage && !uploadingImage && (
                    <img
                      className="uploaded-image"
                      src={uploadedImage}
                      alt="xyz"
                    />
                  )}
                  {!uploadedImage && !uploadingImage && (
                    <UploadImage className="sample-image" />
                  )}
                  {uploadingImage && <LoadingOutlined />}
                  <p>Upload customer image</p>
                </Upload>
              </div>
            </Form.Item>
            <div className="row-one">
              <Form.Item rules={[required]} name="name" label="Name">
                <Input type="text" />
              </Form.Item>
              <Form.Item
                rules={[number]}
                name="coinBalance"
                label="App coin balance"
              >
                <Input type="text" />
              </Form.Item>
            </div>
            <div className="row-one">
              <Form.Item name="email" label="Email id">
                <Input type="email" />
              </Form.Item>
              <Form.Item
                rules={[
                  required,
                  number,
                  () => ({
                    validator(rule, value) {
                      if (value.length > 10) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject('Number limit exceeded');
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
                className="mobile-number"
                name="mobileNo"
                label="Mobile number"
              >
                <Input
                  type="text"
                  style={{ width: '100%' }}
                  addonBefore={<CountryCodePicker name="countryCode" />}
                />
              </Form.Item>
            </div>
            <div className="row-one ">
              <Form.Item
                className="dob-form"
                rules={[required]}
                name="dob"
                label="D.O.B."
              >
                <DatePicker format="DD/MM/YYYY" />
              </Form.Item>

              <div className="age-box">
                <p className="gender"> Sex</p>
                <Radio.Group
                  rules={[required]}
                  onChange={genderChange}
                  value={gender}
                >
                  <Radio value="MALE">Male</Radio>
                  <Radio value="FEMALE">Female</Radio>
                  <Radio value="OTHER"> Other</Radio>
                </Radio.Group>
              </div>
            </div>

            <Divider />
            <Button
              loading={isSavingData}
              htmlType="submit"
              className="create-btn-modal"
            >
              Create
            </Button>
          </Form>
        </Modal>
        {/* EXCEL MODAL */}
        <Modal
          open={excelModal}
          onCancel={handleCancelExcel}
          onOk={handleDownload}
          className="excel-modal"
          centered
          title="Export to excel"
          footer={[
            <Button
              key="submit"
              className="excel-btn"
              loading={isDownload}
              onClick={handleDownload}
            >
              {isDownload ? 'Downloading' : ' Download excel'}
            </Button>
          ]}
        >
          <Divider />
          <div className="excel-content">
            <div className="select-date-time selector-class">
              <Select
                className="day-selector"
                value={type}
                onChange={(value) => {
                  setType(value);
                  if (value === 'all') {
                    setStartDateVal(null);
                    setEndDateVal(null);
                  }
                }}
              >
                <Option value="all">All</Option>
                <Option value="date">Date</Option>
                <Option value="week">Week</Option>
                <Option value="month">Month</Option>
                <Option value="custom">Custom</Option>
              </Select>
              {dateSelectionType()}
            </div>
          </div>
          {/* <Button>Download excel</Button> */}
          <Divider />
        </Modal>
      </Header>
      <div className="main-content customers-content">
        <div className="customers-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              data={customers}
              onChange={(onChange) => {
                setLoadingData(true);
                fetchCustomers({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
                handlePageChange(onChange.current);
              }}
            />
          )}
        </div>
        {/* DISABLE MODAL */}
        <Modal
          title={
            <div>
              <Space className="warning-container">
                <WarningFilled />
                Disable Customer
              </Space>
            </div>
          }
          centered
          closeIcon={<CloseCircleOutlined className="close-btn" />}
          open={openDisableUser}
          okButtonProps={{ type: 'primary', danger: 'danger' }}
          cancelButtonProps={{ className: 'no-display' }}
          okText="Disable customer"
          onOk={() => {
            toggleEnabled(currentSelectedIndex);
            setOpenDisableUser(false);
          }}
          onCancel={() => setOpenDisableUser(false)}
        >
          <Divider />
          <p>Are you sure you want to disable this customer?</p>
          <Divider />
        </Modal>
      </div>
    </div>
  );
}
export default Customers;
