/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */

import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Space,
  Typography
} from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DeleteOutlined } from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import {
  addNewDeepLink,
  getDeepLinkList,
  removeDeepLink
} from './deep-link-repository';

function DeepLinkMonitor() {
  const [form] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;

  const { required } = formValidatorRules;

  const [skipVal, setSkipVal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [linkData, setLinkData] = useState([]);
  const [paginationProp, setPaginationProp] = useState(false);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const fetchLinkData = async ({ currentSkip }) => {
    try {
      setLoadingData(true);
      setLoading(true);
      const response = await getDeepLinkList({
        skip: currentSkip,
        limit: limit
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      const { deepLinks } = response;
      setLinkData(deepLinks);
      setLoadingData(false);
      setLoading(false);
    } catch (error) {
      setLoadingData(false);
      setLoading(false);
      message.error('Failed to fetch link data');
    }
  };

  useEffect(() => {
    fetchLinkData({
      currentSkip: skipVal
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteDeepLink = async (ID) => {
    const key = 'updatable';
    try {
      message.open({
        key,
        type: 'loading',
        content: 'Deleting link...'
      });
      await removeDeepLink(ID);
      message.destroy(key);
      fetchLinkData({
        currentSkip: skipVal
      });
      message.success('Deleted successfully');
    } catch (error) {
      message.error('Failed to delete the deep link');
    }
  };

  // COLUMNS
  const columns = [
    {
      title: 'Title',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link'
    },
    {
      title: 'Taps',
      dataIndex: 'clickCount',
      key: 'clickCount'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (value) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px'
            }}
          >
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this link?"
              onConfirm={() => {
                deleteDeepLink(value);
              }}
              okText="Yes"
              cancelText="No"
            >
              {/* <Button className="delete-btn-banner"> */}
              <DeleteOutlined className="delete-btn " size={40} />
              {/* </Button> */}
            </Popconfirm>
          </div>
        );
      }
    }
  ];

  const handleCancelCreateModal = () => {
    form.resetFields();
    setIsCreateModalOpen(false);
  };

  const onSubmitForm = async (formValues) => {
    const { title, link } = formValues;
    const payload = {
      description: title,
      link
    };

    try {
      setSavingData(true);

      // Call the mutation function to create a deep link
      const newLink = await addNewDeepLink(payload);

      if (!newLink) {
        throw new Error('Failed to create a new deep link');
      }

      if (
        linkData.length === 0 ||
        linkData.length < limit ||
        linkData[linkData.length - 1].serialNumber + 1 === linkData.serialNumber
      ) {
        const updatedData = linkData.slice();
        updatedData.push(newLink);
        setLinkData(updatedData.slice());
      }
      handleCancelCreateModal(); // Close the modal
      setSavingData(false);
    } catch (error) {
      message.error('Failed to add deep link');
      setSavingData(false);
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Deep Link Monitor
            </Typography.Text>
          </Space>
          <div
            style={{
              display: 'flex',
              gap: '10px'
            }}
          >
            <Button
              className="create-btn"
              onClick={() => setIsCreateModalOpen(true)}
            >
              + Add Link
            </Button>
          </div>
        </div>
      </Header>
      <div className="main-content customers-content">
        <div className="customers-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              data={linkData}
              onChange={(onChange) => {
                setSkipVal((onChange.current - 1) * limit);
                setLoadingData(true);
                fetchLinkData({
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
      {/* CREATE MODAL */}
      <Modal
        open={isCreateModalOpen}
        onCancel={handleCancelCreateModal}
        className="create-test-modal"
        centered
        title="Add Deep Link"
      >
        <Divider />
        <Form form={form} onFinish={onSubmitForm} layout="vertical">
          <Form.Item rules={[required]} name="title" label="Title">
            <Input type="text" />
          </Form.Item>
          <Form.Item rules={[required]} name="link" label="Link">
            <Input type="text" />
          </Form.Item>
          <Divider />
          <Button
            loading={savingData}
            htmlType="submit"
            className="create-btn-modal"
          >
            Add Link
          </Button>
        </Form>
      </Modal>
    </div>
  );
}
export default DeepLinkMonitor;
