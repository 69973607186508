/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import {
  Button,
  DatePicker,
  Divider,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Space,
  Switch,
  TimePicker,
  Typography,
  Upload,
  message
} from 'antd';
import { getType } from 'mime';
import React, { useEffect, useState } from 'react';
import './send-message.less';
// Icons
import {
  FileImageOutlined,
  HistoryOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT,
  ROUTES
} from '../../common/constants';

import { clearSearchField, formValidatorRules } from '../../common/utils';

import {
  getFileSignedUrl,
  postUploadPhoto
} from '../../common/common-repository';
import { getAllCustomers } from '../customers/customers-repository';
import {
  getAllLiveTests,
  getLiveTestsAndPackages,
  getTestById
} from '../tests/test-repository';
import {
  composeNotification,
  createNotification,
  deleteNotification,
  getNotifications
} from './messages-repository';

function Messages() {
  const { required } = formValidatorRules;
  const [form] = Form.useForm();
  const [sendMessageForm] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;
  const navigate = useNavigate();

  // // STATES
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [composeModalOpen, setComposeModalOpen] = useState(false);
  const [selectUserType, setSelectUserType] = useState('ALL_CUSTOMERS');
  const [selectScheduleType, setSelectScheduleType] = useState('NOW');
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [showingTestResults, setShowingTestResults] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const [liveTests, setLiveTests] = useState([]);
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [isFetchingTests, setIsFetchingTests] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [isSavingData, setIsSavingData] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  const [isViewModal, setIsViewModal] = useState(false);
  const [notificationName, setNotificationName] = useState('');
  const [dateStringValue, setDateStringValue] = useState('');
  const [timeStringValue, setTimeStringValue] = useState('');
  const [isSubstitution, setIsSubstitution] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const [gender, setGender] = useState('MALE');

  // FETCH MESSAGES
  const fetchMessages = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getNotifications({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setMessages(response.notifications.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchMessages({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FETCHING USERS
  const fetchUsers = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getAllCustomers({
        skip: 0,
        limit: 4,
        query: currentSearch,
        hideDeletedUser: true
      });
      const { customers } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < customers.length; i += 1) {
        const user = customers[i];
        const { id } = user;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedTests.length; j += 1) {
          if (selectedTests[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          user
        });
      }
      setFetchedUsers(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetchingTests(false);
  };

  const fetchLiveTests = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getAllLiveTests({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { tests } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < tests.length; i += 1) {
        const test = tests[i];
        const { id } = test;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedTests.length; j += 1) {
          if (selectedTests[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          test
        });
      }
      setLiveTests(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetchingTests(false);
  };

  const fetchLiveTestsAndPackages = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getLiveTestsAndPackages({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { tests } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < tests.length; i += 1) {
        const test = tests[i];
        const { id } = test;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedTests.length; j += 1) {
          if (selectedTests[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          test
        });
      }
      setLiveTests(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetchingTests(false);
  };

  const handleCancel = () => {
    form.resetFields();
    clearSearchField('Test');
    setSelectedTests([]);
    setUploadedImage(undefined);
    setIsModalOpen(false);
    setIsViewModal(false);
    setIsFetchingTests(false);
    setIsSubstitution(false);
  };

  const handleCancelCompose = () => {
    setIsTest(false);
    if (selectUserType === 'SELECT_CUSTOMERS') {
      clearSearchField('Users');
      setSelectedTests([]);
    }
    setSelectUserType('ALL_CUSTOMERS');
    setSelectScheduleType('NOW');
    form.resetFields();
    sendMessageForm.resetFields();
    setDateStringValue('');
    setTimeStringValue('');
    setGender('MALE');
    setComposeModalOpen(false);
  };

  const discountChange = (e) => {
    setSelectedTests([]);
    setGender('MALE');
    e.preventDefault();
    setSelectUserType(e.target.value);
  };

  const genderChange = (e) => {
    e.preventDefault();
    setGender(e.target.value);
  };

  const scheduleChange = (e) => {
    e.preventDefault();
    setSelectScheduleType(e.target.value);
  };

  const onChangeDate = (date, dateString) => {
    setDateStringValue(dateString);
  };

  const onChangeTime = (time, timeString) => {
    setTimeStringValue(timeString);
  };

  const deleteBanner = async (notificationId) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Deleting message'
    });
    await deleteNotification(notificationId);
    fetchMessages({
      currentQuery: '',
      currentSkip: 0
    });
  };

  // VIEW MODAL
  const getEquipmentByIdToUpdate = async (record, value) => {
    setLoadingData(true);
    setTimeout(async () => {
      setNotificationName(value);
      if (record.linkedTest) {
        const response = await getTestById(record.linkedTest);
        setSelectedTests([response]);
      }

      form.setFieldsValue({
        title: record.title,
        description: record.description,
        buttonText: record.buttonTitle
      });
      setIsModalOpen(true);
      setSelectedId(record.id);
      setLoadingData(false);
    }, 500);
  };

  const selectRow = (value) => {
    const url = `${ROUTES.MESSAGE_DETAILS}?messageId=${value}`;
    navigate(url);
  };

  const openComposeModal = (record) => {
    const { description } = record;
    if (description.includes('{{test}}')) {
      setIsTest(true);
    } else {
      setIsTest(false);
    }
    setSelectedId(record.id);
    setComposeModalOpen(true);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: '1',
      render: (value, record) => {
        return (
          <Typography.Text
            className="clickable-title"
            onClick={() => {
              form?.resetFields();
              if (record.imageUrl) {
                setUploadedImage(record.imageUrl);
              }
              setNotificationName(record.title);
              setIsViewModal(true);
              setTimeout(() => {
                getEquipmentByIdToUpdate(record, value);
              }, 200);
            }}
          >
            {value}
          </Typography.Text>
        );
      }
    },
    {
      title: 'Image',
      dataIndex: 'imageUrl',
      key: '2',
      render: (value, record) => {
        if (value) {
          return <Image src={value} height={50} alt={record.id} />;
        }
        return <p>No image</p>;
      }
    },
    {
      title: 'Action',
      dataIndex: 'title',
      key: '3',
      render: (value, record, index) => {
        return (
          <div className="message-action">
            <Button
              className="compose-btn-message"
              onClick={() => {
                openComposeModal(record);
              }}
            >
              Compose
            </Button>
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this message?"
              onConfirm={() => {
                deleteBanner(record.id, index);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button className="delete-btn-message">Delete</Button>
            </Popconfirm>
          </div>
        );
      }
    },
    {
      title: 'Upcoming schedule',
      dataIndex: 'id',
      key: '4',
      render: (value, record) => {
        if (!record?.scheduledNotification) {
          return '';
        }
        return (
          <span>
            {dayjs(record?.scheduledNotification?.sendAt).format('DD/MM/YYYY')}{' '}
            - {dayjs(record?.scheduledNotification?.sendAt).format('hh:mm a')}
          </span>
        );
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: '5',
      render: (value) => {
        return (
          <HistoryOutlined
            className="view-more"
            onClick={() => {
              selectRow(value);
            }}
          />
        );
      }
    }
  ];

  // VALIDATING IMG AS SVG
  const beforeUpload = (file) => {
    const isLT1MB = file.size / 1024 / 1024 <= 0.3;
    if (!isLT1MB) {
      message.error(`File must smaller than 300KB`);
      return !isLT1MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  // BANNER UPLOAD
  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await postUploadPhoto(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  // CREATE MESSAGE SUBMIT
  const createMessageForm = async (formValue) => {
    const { title, description, buttonText } = formValue;
    if (title.length < 2) {
      message.error('Title should be in between 2 to 50 characters');
      return;
    }
    if (title.length > 50) {
      message.error('Title should be in between 2 to 50 characters');
      return;
    }
    if (description.length < 2) {
      message.error('Description should be in between 2 to 120 characters');
      return;
    }
    if (description.length > 120) {
      message.error('Description should be in between 2 to 120 characters');
      return;
    }

    setIsSavingData(true);
    const payload = {
      title,
      description,
      imageUrl: uploadedImage || null,
      buttonTitle: buttonText || null,
      linkedTest: selectedTests.length > 0 ? selectedTests[0].id : null,
      isSubstitution
    };

    const createdMessage = await createNotification(payload);

    if (
      messages.length === 0 ||
      messages.length < limit ||
      messages[messages.length - 1].serialNumber + 1 ===
        createdMessage.serialNumber
    ) {
      const updatedMessages = messages.slice();
      updatedMessages.push(createdMessage);
      setMessages(updatedMessages.slice());
    }

    form.resetFields();
    setUploadedImage(undefined);
    setIsSavingData(false);
    setIsModalOpen(false);
    setIsSubstitution(false);
    fetchMessages({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  // SEND MESSAGE TO USERS
  const sendMessageToUsers = async () => {
    const dateAndTimeString = `${dateStringValue} ${timeStringValue}`;
    setIsSavingData(true);
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Sending message'
    });
    if (selectUserType === 'SELECT_CUSTOMERS') {
      if (selectedTests.length === 0) {
        message.open({
          key,
          type: 'error',
          content: 'Please select any customer'
        });
        setIsSavingData(false);
        return;
      }
      const usersId = [];
      for (let i = 0; i < selectedTests.length; i += 1) {
        usersId.push(selectedTests[i].id);
      }
      await composeNotification({
        notificationId: selectedId,
        sendTo: selectUserType,
        selectedCustomers: usersId,
        isScheduled: selectScheduleType !== 'NOW',
        scheduledAt: dateAndTimeString || null
      });
      clearSearchField('Users');
    } else if (selectUserType === 'TEST_WISE_CUSTOMERS') {
      if (selectedTests.length === 0) {
        message.open({
          key,
          type: 'error',
          content: 'Please select any test'
        });
        setIsSavingData(false);
        return;
      }
      const usersId = [];
      for (let i = 0; i < selectedTests.length; i += 1) {
        usersId.push(selectedTests[i].id);
      }
      await composeNotification({
        notificationId: selectedId,
        sendTo: selectUserType,
        selectedCustomers: selectedTests[0].id,
        isScheduled: selectScheduleType !== 'NOW',
        scheduledAt: dateAndTimeString || null
      });
      clearSearchField('Test');
    } else if (selectUserType === 'GENDER_WISE_CUSTOMERS') {
      await composeNotification({
        notificationId: selectedId,
        sendTo: selectUserType,
        gender,
        isScheduled: selectScheduleType !== 'NOW',
        scheduledAt: dateAndTimeString || null
      });
    } else if (selectUserType === 'ALL_GUEST_USERS') {
      await composeNotification({
        notificationId: selectedId,
        sendTo: selectUserType,
        gender,
        isScheduled: selectScheduleType !== 'NOW',
        scheduledAt: dateAndTimeString || null
      });
    } else {
      await composeNotification({
        notificationId: selectedId,
        sendTo: selectUserType,
        isScheduled: selectScheduleType !== 'NOW',
        scheduledAt: dateAndTimeString || null
      });
    }
    setSelectUserType('ALL_CUSTOMERS');
    setSelectScheduleType('NOW');
    sendMessageForm.resetFields();
    setSelectedTests([]);
    setSelectedId();
    setGender('MALE');
    message.destroy(key);
    setIsSavingData(false);
    setComposeModalOpen(false);
  };

  const disabledDate = (current) => {
    // Get the current date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to the beginning of the day

    // Disable dates before today
    return current && current.valueOf() < today.valueOf();
  };

  // Get the current hour and minute
  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();

  // Custom function to disable hours
  const disabledHours = () => {
    const today = new Date();
    const convertedDate = dayjs(today).format('YYYY-MM-DD');
    if (dateStringValue === convertedDate) {
      const hours = [];
      for (let i = 0; i < currentHour; i += 1) {
        hours.push(i);
      }
      return hours;
    }
  };

  // Custom function to disable minutes
  const disabledMinutes = (selectedHour) => {
    const today = new Date();
    const convertedDate = dayjs(today).format('YYYY-MM-DD');
    if (dateStringValue === convertedDate) {
      if (selectedHour === currentHour) {
        const minutes = [];
        for (let i = 0; i < currentMinute; i += 1) {
          minutes.push(i);
        }
        return minutes;
      }
      return [];
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Notifications
            </Typography.Text>
            <SearchComponent
              name="Notifications"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchMessages({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button
            onClick={() => {
              setIsModalOpen(true);
            }}
            className="create-btn"
          >
            + Create New
          </Button>
        </div>
      </Header>
      {/* CREATE MODAL */}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        className="create-equipment-modal"
        footer={null}
        centered
        title={isViewModal ? notificationName : 'Create Message'}
      >
        <Divider />
        <Form
          form={form}
          onFinish={createMessageForm}
          className="equipment-form"
          layout="vertical"
        >
          <div className="equipment-form-control message-form">
            <Form.Item rules={[required]} name="title" label="Message title">
              <Input name="messageTitle" type="text" disabled={isViewModal} />
            </Form.Item>
            <Form.Item
              rules={[required]}
              name="description"
              label="Message content"
            >
              <Input.TextArea
                name="messageDesc"
                className="message-desc"
                type="text"
                disabled={isViewModal}
              />
            </Form.Item>
            <Form.Item name="buttonText" label="Button text">
              <Input name="buttonText" type="text" disabled={isViewModal} />
            </Form.Item>
            <div className="users-container">
              <div className="tests-modal-search ">
                <SearchComponent
                  name="Test"
                  debounceTime={DEBOUNCE_TIME}
                  getData={fetchLiveTestsAndPackages}
                  id="search-box"
                  disabled={isViewModal}
                />
                {!isFetchingTests && showingTestResults && (
                  <div className="search-result" id="test-searcher">
                    {liveTests.length <= 0
                      ? null
                      : liveTests.map((currentTest) => {
                          const { isSelected, test } = currentTest;
                          return (
                            <div className="tests-list" key={test.id}>
                              <Typography.Text>{test.title}</Typography.Text>
                              {isSelected ? (
                                <Button
                                  type="text"
                                  onClick={() => {
                                    const tests = selectedTests.slice();
                                    for (let i = 0; i < tests.length; i += 1) {
                                      if (tests[i].id === test.id) {
                                        tests.splice(i, 1);
                                      }
                                    }
                                    const tempFetchedTests = liveTests.slice();
                                    for (
                                      let i = 0;
                                      i < tempFetchedTests.length;
                                      i += 1
                                    ) {
                                      if (
                                        tempFetchedTests[i].test.id === test.id
                                      ) {
                                        tempFetchedTests[i] = {
                                          ...tempFetchedTests[i],
                                          isSelected: false
                                        };
                                        break;
                                      }
                                    }
                                    setSelectedTests(tests);
                                    setLiveTests(tempFetchedTests);
                                  }}
                                >
                                  Remove
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    selectedTests[0] = test;
                                    setShowingTestResults(false);
                                  }}
                                  type="default"
                                >
                                  Add
                                </Button>
                              )}
                            </div>
                          );
                        })}
                  </div>
                )}
              </div>
              <div className="selected-test-container create-notification-container">
                {selectedTests.length <= 0 ? (
                  <Empty />
                ) : (
                  selectedTests.map((currentTest) => {
                    const { id, title } = currentTest;
                    return (
                      <div className="tests-list" key={id}>
                        <Typography.Text>{title}</Typography.Text>
                        <Button
                          disabled={isViewModal}
                          type="text"
                          onClick={() => {
                            const tests = selectedTests.slice();
                            for (let i = 0; i < tests.length; i += 1) {
                              if (tests[i].id === id) {
                                tests.splice(i, 1);
                              }
                            }
                            setSelectedTests(tests);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <Form.Item name="upload" label="Upload image">
              <div className="profile-test message-img">
                <Upload
                  disabled={isViewModal}
                  name="upload-banner "
                  onChange={onIconUpload}
                  accept="image/*"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                >
                  {uploadedImage && !uploadingImage && (
                    <img
                      src={uploadedImage}
                      className="message-sample-img "
                      alt="xyz"
                    />
                  )}
                  {!uploadedImage && !uploadingImage && (
                    <p className="upload-banner-text">
                      <FileImageOutlined />
                    </p>
                  )}
                  {uploadingImage && <LoadingOutlined />}
                </Upload>
              </div>
            </Form.Item>

            <Divider />
            <div
              className="banner-align"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              {isViewModal ? null : (
                <Button
                  loading={isSavingData}
                  htmlType="submit"
                  className="create-btn-modal banner-btn"
                >
                  Create
                </Button>
              )}
              {isViewModal ? null : (
                <Switch
                  defaultChecked={false}
                  checked={isSubstitution}
                  onChange={(checked) => {
                    setIsSubstitution(checked);
                  }}
                  checkedChildren="Substitute"
                  unCheckedChildren="Not Substitute"
                />
              )}
            </div>
          </div>
        </Form>
      </Modal>

      {/* COMPOSE MODAL */}
      <Modal
        open={composeModalOpen}
        onCancel={handleCancelCompose}
        className="create-equipment-modal"
        footer={null}
        centered
        title="Compose message"
      >
        <Divider />
        <Form form={sendMessageForm} onFinish={sendMessageToUsers}>
          <div className="schedule-box">
            <div className="s-left">
              <p className="gender"> Select schedule type</p>
              <Radio.Group onChange={scheduleChange} value={selectScheduleType}>
                <Radio value="NOW">Now</Radio>
                <Radio value="SCHEDULE">Schedule</Radio>
              </Radio.Group>
            </div>
            {selectScheduleType === 'SCHEDULE' ? (
              <div className="s-right">
                <Form.Item rules={[required]} name="datePicker">
                  <DatePicker
                    onChange={onChangeDate}
                    value={dateStringValue}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                <Form.Item rules={[required]} name="timePicker">
                  <TimePicker
                    use12Hours
                    value={timeStringValue}
                    format="h:mm A"
                    onChange={onChangeTime}
                    placeholder="00:00 am/pm"
                    showNow={false}
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                    disabled={!dateStringValue}
                  />
                </Form.Item>
              </div>
            ) : null}
          </div>
          <div className="user-box">
            <p className="gender"> Select customer type</p>
            <Radio.Group onChange={discountChange} value={selectUserType}>
              {isTest ? null : (
                <>
                  <Radio value="ALL_CUSTOMERS">All customers</Radio>
                  {selectScheduleType === 'NOW' && (
                    <Radio value="ALL_GUEST_USERS">All guest customers</Radio>
                  )}
                  <Radio value="NEW_CUSTOMERS">New customers</Radio>
                  <Radio value="SELECT_CUSTOMERS"> Select customers</Radio>
                  <Radio value="GENDER_WISE_CUSTOMERS"> Select gender</Radio>
                </>
              )}
              <Radio value="TEST_WISE_CUSTOMERS"> Select Test</Radio>
            </Radio.Group>
          </div>
          {selectUserType === 'SELECT_CUSTOMERS' ? (
            <div className="users-container">
              <div className="tests-modal-search message-searchbox">
                <SearchComponent
                  name="Users"
                  debounceTime={DEBOUNCE_TIME}
                  getData={fetchUsers}
                  id="search-box"
                />
                {!isFetchingTests && showingTestResults && (
                  <div className="search-result" id="user-searcher">
                    {fetchedUsers.length <= 0 ? (
                      <Empty />
                    ) : (
                      fetchedUsers.map((currentTest) => {
                        const { isSelected, user } = currentTest;
                        return (
                          <div className="tests-list" key={user.id}>
                            <Typography.Text>{user.name}</Typography.Text>
                            {isSelected ? (
                              <Button
                                type="text"
                                onClick={() => {
                                  const users = selectedTests.slice();
                                  for (let i = 0; i < users.length; i += 1) {
                                    if (users[i].id === user.id) {
                                      users.splice(i, 1);
                                    }
                                  }
                                  const tempFetchedTests = fetchedUsers.slice();
                                  for (
                                    let i = 0;
                                    i < tempFetchedTests.length;
                                    i += 1
                                  ) {
                                    if (
                                      tempFetchedTests[i].user.id === user.id
                                    ) {
                                      tempFetchedTests[i] = {
                                        ...tempFetchedTests[i],
                                        isSelected: false
                                      };
                                      break;
                                    }
                                  }
                                  setSelectedTests(users);
                                  setFetchedUsers(tempFetchedTests);
                                }}
                              >
                                Remove
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  const users = selectedTests.slice();
                                  users.push(user);
                                  const tempFetchedTests = fetchedUsers.slice();
                                  for (
                                    let i = 0;
                                    i < tempFetchedTests.length;
                                    i += 1
                                  ) {
                                    if (
                                      tempFetchedTests[i].user.id === user.id
                                    ) {
                                      tempFetchedTests[i] = {
                                        ...tempFetchedTests[i],
                                        isSelected: true
                                      };
                                      break;
                                    }
                                  }
                                  setSelectedTests(users);
                                  setFetchedUsers(tempFetchedTests);
                                }}
                                type="default"
                              >
                                Add
                              </Button>
                            )}
                          </div>
                        );
                      })
                    )}
                  </div>
                )}
              </div>

              <div className="selected-test-container category-container">
                {selectedTests.length <= 0 ? (
                  <Empty />
                ) : (
                  selectedTests.map((currentTest) => {
                    const { id, name } = currentTest;
                    return (
                      <div className="tests-list" key={id}>
                        <Typography.Text>{name}</Typography.Text>
                        <Button
                          type="text"
                          onClick={() => {
                            const tests = selectedTests.slice();
                            for (let i = 0; i < tests.length; i += 1) {
                              if (tests[i].id === id) {
                                tests.splice(i, 1);
                              }
                            }
                            setSelectedTests(tests);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          ) : selectUserType === 'TEST_WISE_CUSTOMERS' ? (
            <div className="users-container">
              <div className="tests-modal-search message-searchbox">
                <SearchComponent
                  name="Test"
                  debounceTime={DEBOUNCE_TIME}
                  getData={fetchLiveTests}
                  id="search-box"
                />
                {!isFetchingTests && showingTestResults && (
                  <div className="search-result" id="test-searcher">
                    {liveTests.length <= 0
                      ? null
                      : liveTests.map((currentTest) => {
                          const { isSelected, test } = currentTest;
                          return (
                            <div className="tests-list" key={test.id}>
                              <Typography.Text>{test.title}</Typography.Text>
                              {isSelected ? (
                                <Button
                                  type="text"
                                  onClick={() => {
                                    const tests = selectedTests.slice();
                                    for (let i = 0; i < tests.length; i += 1) {
                                      if (tests[i].id === test.id) {
                                        tests.splice(i, 1);
                                      }
                                    }
                                    const tempFetchedTests = liveTests.slice();
                                    for (
                                      let i = 0;
                                      i < tempFetchedTests.length;
                                      i += 1
                                    ) {
                                      if (
                                        tempFetchedTests[i].test.id === test.id
                                      ) {
                                        tempFetchedTests[i] = {
                                          ...tempFetchedTests[i],
                                          isSelected: false
                                        };
                                        break;
                                      }
                                    }
                                    setSelectedTests(tests);
                                    setLiveTests(tempFetchedTests);
                                  }}
                                >
                                  Remove
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    selectedTests[0] = test;
                                    setShowingTestResults(false);
                                  }}
                                  type="default"
                                >
                                  Add
                                </Button>
                              )}
                            </div>
                          );
                        })}
                  </div>
                )}
              </div>
              <div className="selected-test-container">
                {selectedTests.length <= 0 ? (
                  <Empty />
                ) : (
                  selectedTests.map((currentTest) => {
                    const { id, title } = currentTest;
                    return (
                      <div className="tests-list" key={id}>
                        <Typography.Text>{title}</Typography.Text>
                        <Button
                          type="text"
                          onClick={() => {
                            const tests = selectedTests.slice();
                            for (let i = 0; i < tests.length; i += 1) {
                              if (tests[i].id === id) {
                                tests.splice(i, 1);
                              }
                            }
                            setSelectedTests(tests);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          ) : selectUserType === 'GENDER_WISE_CUSTOMERS' ? (
            <div
              style={{
                paddingBottom: '15px',
                marginTop: '-10px'
              }}
            >
              <Radio.Group onChange={genderChange} value={gender}>
                <Radio value="MALE"> Male</Radio>
                <Radio value="FEMALE">Female</Radio>
              </Radio.Group>
            </div>
          ) : null}

          <Divider />
          <Button
            loading={isSavingData}
            htmlType="submit"
            className="create-btn-modal banner-btn"
          >
            {selectScheduleType === 'SCHEDULE' ? 'Submit' : 'Send'}
          </Button>
        </Form>
      </Modal>

      <div className="equipments-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            rowKey={(record) => record.id}
            columns={columns}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={messages}
            onChange={(onChange) => {
              setLoadingData(true);
              fetchMessages({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default Messages;
