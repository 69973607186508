/* eslint-disable no-console */
import { Avatar, Menu, Popconfirm, Space, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../appSideBar/sidebar.less';

// LOGO
import { LogoutOutlined } from '@ant-design/icons';
import profile from '../../../assets/fakeProfile.svg';
import logo from '../../../assets/loginLogo.svg';
// Components of svg
import { ReactComponent as LabReports } from '../../../assets/icons/reports.svg';
import { ReactComponent as LabSample } from '../../../assets/icons/tests.svg';

// External Icons svg
import { signOutAdmin } from '../../../modules/auth/auth-repository';

function LabSidebar({ user }) {
  // Navigate
  const navigate = useNavigate();
  return (
    <div className="app-sidebar">
      <div className="sidebar-logo">
        <img src={logo} alt="logo" />
      </div>
      <Menu
        onClick={(item) => {
          navigate(item.key);
        }}
        // eslint-disable-next-line no-undef
        defaultSelectedKeys={[window.location.pathname]}
        // eslint-disable-next-line no-undef
        selectedKeys={[window.location.pathname]}
        mode="inline"
        items={[
          {
            label: 'Samples',
            key: '/',
            icon: <LabSample />
          },
          {
            label: 'Reports',
            key: '/lab-reports',
            icon: <LabReports />
          }
        ]}
      />
      <div className="profile-content-wrapper">
        <Space size={10} className="profile-content">
          <Avatar src={profile} size="large" />
          <Typography.Text>{user.name}</Typography.Text>
          <Popconfirm
            title="Logout"
            description="Are you sure to logout from system?"
            onConfirm={async () => {
              await signOutAdmin();
              // eslint-disable-next-line no-restricted-globals, no-undef
              location.href = '/';
            }}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <LogoutOutlined className="logout-icon" />
          </Popconfirm>
        </Space>
      </div>
    </div>
  );
}

export default LabSidebar;
