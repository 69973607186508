import { gql } from '@apollo/client';

export const ADD_CUSTOMER = gql`
  mutation addCustomerAsAdmin(
    $avatar: String
    $name: name_String_minLength_2_maxLength_255
    $email: email_String_maxLength_255_format_email
    $dob: String
    $sex: Sex
    $countryCode: String!
    $mobileNumber: String!
    $appCoinBalance: Float
  ) {
    addCustomerAsAdmin(
      data: {
        avatar: $avatar
        name: $name
        email: $email
        dob: $dob
        sex: $sex
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        appCoinBalance: $appCoinBalance
      }
    ) {
      customer {
        id
        emailVerified
        email
        countryCode
        mobileNumber
        name
        age
        dob
        avatar
        sex
        userReferenceId
        isDisabled
      }
      isCreated
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomerAsAdmin(
    $customerId: String!
    $data: UpdateCustomerDataAsAdminInput!
  ) {
    updateCustomerAsAdmin(data: { customerId: $customerId, data: $data }) {
      customer {
        id
        emailVerified
        email
        countryCode
        mobileNumber
        name
        age
        dob
        avatar
        firebaseReferenceId
        userReferenceId
        sex
        isDisabled
        appCoinBalance
      }
    }
  }
`;

// TOGGLE ENABLE CATEGORY
export const TOGGLE_ENABLE_CUSTOMER = gql`
  mutation toggleCustomerEnable($customerId: String!) {
    toggleCustomerEnable(customerId: $customerId) {
      response
    }
  }
`;

// TOGGLE ENABLE CATEGORY
export const UPDATE_PATIENT_AS_ADMIN = gql`
  mutation updatePatientAsAdmin(
    $data: CreatePatientInput!
    $patientId: String!
  ) {
    updatePatientAsAdmin(data: { patientId: $patientId, data: $data }) {
      patient {
        id
      }
    }
  }
`;

// ADD PATIENT AS ADMIN
export const ADD_PATIENT = gql`
  mutation createPatientAsAdmin(
    $data: CreatePatientInput!
    $customerId: String!
  ) {
    createPatientAsAdmin(customerId: $customerId, data: $data) {
      patient {
        id
        name
        age
        dob
        sex
      }
    }
  }
`;

export const DELETE_PATIENT = gql`
  mutation removePatientAsAdmin($patientId: String!) {
    removePatientAsAdmin(patientId: $patientId) {
      delete
    }
  }
`;

// UPDATE ADDRESS
export const UPDATE_ADDRESS_AS_ADMIN = gql`
  mutation updateCustomerAddressAsAdmin(
    $customerId: String!
    $data: updateAddressInput!
  ) {
    updateCustomerAddressAsAdmin(customerId: $customerId, data: $data) {
      updatedAddress {
        id
      }
    }
  }
`;

// ADD ADDRESS
export const ADD_ADDRESS_AS_ADMIN = gql`
  mutation createCustomerAddressAsAdmin(
    $customerId: String!
    $data: CreateAddressInput!
  ) {
    createCustomerAddressAsAdmin(customerId: $customerId, data: $data) {
      address {
        id
        name
        countryCode
        mobileNumber
        pincode
        state
        addressType
        addressLine1
        addressLine2
        city
        location {
          id
          latitude
          longitude
          pincode
          state
          address
          city
        }
      }
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation removeAddressAsAdmin($addressId: String!) {
    removeAddressAsAdmin(addressId: $addressId) {
      delete
    }
  }
`;

export const ADD_TEST_TO_CUSTOMER_CART = gql`
  mutation addTestToCustomerCart($customerId: String!, $tests: [String!]!) {
    addTestsInCustomerCart(data: { customerId: $customerId, tests: $tests }) {
      cart {
        id
        tests {
          id
          title
        }
      }
    }
  }
`;

export const CLEAR_CART = gql`
  mutation clearCustomerCart($cartId: String!) {
    clearCustomerCart(cartId: $cartId)
  }
`;

export const PLACE_ORDER_AS_ADMIN = gql`
  mutation placeOrderAsAdmin($data: PlaceOrderAsAdminInput) {
    placeOrderAsAdmin(data: $data) {
      order {
        id
      }
    }
  }
`;

export const APPLY_COUPON_AS_ADMIN = gql`
  mutation applyOfferAsAdmin($offerId: String!, $customerId: String!) {
    applyOfferAsAdmin(offerId: $offerId, customerId: $customerId) {
      cart {
        id
      }
    }
  }
`;

export const TOGGLE_USE_COINS = gql`
  mutation toggleUseCoinsAsAdmin($customerId: String!) {
    toggleUseCoinsAsAdmin(customerId: $customerId) {
      cart {
        id
        tests {
          id
          title
        }
        coinsApplied
        usedCoins
        deliveryCharges
        offerApplied {
          discount
          couponCode
        }
        subTotal
        convenienceFees
        total
      }
    }
  }
`;

export const TOGGLE_DELIVER_REPORT = gql`
  mutation toggleDeliveryAtHomeAsAdmin($customerId: String!) {
    toggleDeliveryAtHomeAsAdmin(customerId: $customerId) {
      cart {
        id
        tests {
          id
          title
        }
        coinsApplied
        usedCoins
        deliveryCharges
        offerApplied {
          discount
          couponCode
        }
        subTotal
        convenienceFees
        total
      }
    }
  }
`;

export const BOOK_AGAIN_AS_ADMIN = gql`
  mutation placeOrderAgainAsAdmin($orderId: String!) {
    placeOrderAgainAsAdmin(orderId: $orderId) {
      success
      error
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
