import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPLOAD_REPORT = gql`
  mutation uploadReport(
    $orderId: String!
    $reportFileUrl: String!
    $testId: String!
  ) {
    uploadReport(
      data: {
        orderId: $orderId
        testId: $testId
        reportFileUrl: $reportFileUrl
      }
    ) {
      order {
        id
      }
      isOrderExist
    }
  }
`;

export const UPLOAD_WHOLE_REPORT = gql`
  mutation uploadWholeReport($orderId: String!, $reportFileUrl: String!) {
    uploadWholeReport(
      data: { orderId: $orderId, reportFileUrl: $reportFileUrl }
    ) {
      order {
        id
      }
      isOrderExist
    }
  }
`;
