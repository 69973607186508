import { message } from 'antd';
import client from '../../apollo';
import {
  ADD_SLOT_BY_DAY,
  REMOVE_ALL_SLOT_BY_DAY,
  REMOVE_SLOT_BY_DAY,
  REMOVE_SLOT_MAX_LIMIT,
  TOGGLE_HOLIDAY,
  UPDATE_SLOT_MAX_LIMIT
} from './graphql/mutation';
import { GET_MONTH_CALENDER_HOLIDAYS, GET_SLOTS_BY_DAY } from './graphql/query';

export const getSlotsByDay = async (filter) => {
  const { day } = filter;
  try {
    const response = await client.query({
      query: GET_SLOTS_BY_DAY,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          day
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getSlotsCategorizedByDay;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const addSlotToDay = async (data) => {
  try {
    const response = await client.mutate({
      mutation: ADD_SLOT_BY_DAY,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.addSlotToDay?.slot;
  } catch (error) {
    // eslint-disable-next-line no-console
    message.error(error.message);
    return false;
  }
};

export const removeSlotToDay = async (id) => {
  try {
    const response = await client.mutate({
      mutation: REMOVE_SLOT_BY_DAY,
      fetchPolicy: 'network-only',
      variables: { id: id }
    });
    return response?.data?.removeSlotToDay;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const removeAllSlotsByDay = async (day) => {
  try {
    const response = await client.mutate({
      mutation: REMOVE_ALL_SLOT_BY_DAY,
      fetchPolicy: 'network-only',
      variables: { day: day }
    });
    return response?.data?.removeSlotToDay;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const updateSlotMaxLimit = async (slotId, limit) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_SLOT_MAX_LIMIT,
      fetchPolicy: 'network-only',
      variables: {
        slotId,
        limit
      }
    });
    return response?.data?.updateSlotMaxLimit?.slot;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const removeSlotMaxLimit = async (slotId) => {
  try {
    const response = await client.mutate({
      mutation: REMOVE_SLOT_MAX_LIMIT,
      fetchPolicy: 'network-only',
      variables: {
        slotId
      }
    });
    return response?.data?.removeSlotMaxLimit?.slot;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const getMonthCalendarHolidays = async (month, year) => {
  // eslint-disable-next-line no-console
  try {
    const response = await client.query({
      query: GET_MONTH_CALENDER_HOLIDAYS,
      fetchPolicy: 'network-only',
      variables: {
        month,
        year
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getMonthCalendarHolidays;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const toggleCalendarHoliday = async (date) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_HOLIDAY,
      fetchPolicy: 'network-only',
      variables: {
        date
      }
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const test = async () => {};
