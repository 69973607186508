/* eslint-disable no-console */
import { StarFilled, StarOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import { DEBOUNCE_TIME, GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import './rule-based.less';
import {
  createRuleBasedOffer,
  getRuleBasedOfferAsAdmin,
  toggleFeatureRuleBasedOffer,
  updateRuleBasedOffer
} from './ruleBased-repository';

function RuleBased() {
  const { required } = formValidatorRules;
  const [form] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [ruleBasedOffers, setRuleBasedOffers] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [isSavingData, setIsSavingData] = useState(false);
  const [discountType, setDiscountType] = useState('PERCENTAGE');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [isUpdateOffer, setIsUpdateOffer] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [offerName, setOfferName] = useState('');
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);

  const discountChange = (e) => {
    e.preventDefault();
    form?.resetFields(['value']);
    setDiscountType(e.target.value);
  };

  // MODAL
  const handleCancel = () => {
    form?.resetFields();
    setDiscountType('PERCENTAGE');
    setIsUpdateOffer(false);
    setIsModalOpen(false);
  };

  const fetchRuleBasedOffers = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getRuleBasedOfferAsAdmin({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setRuleBasedOffers(response.offers.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchRuleBasedOffers({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleFeatured = (index) => {
    ruleBasedOffers[index] = {
      ...ruleBasedOffers[index],
      isFeatured: !ruleBasedOffers[index].isFeatured
    };
    toggleFeatureRuleBasedOffer(ruleBasedOffers[index].id);
    setRuleBasedOffers(ruleBasedOffers.slice());
  };

  const offerUpdate = async (index, value, record) => {
    setLoadingData(true);
    setIsUpdateOffer(true);
    setTimeout(() => {
      setIsModalOpen(true);
      setLoadingData(false);
      setOfferName(value);
      form.setFieldsValue({
        title: record.title,
        kind: discountType,
        value: record.value
      });
    }, 500);
  };

  // COLUMNS
  const columns = [
    {
      title: '',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
      render: (value, record, index) => {
        return value ? (
          <StarFilled
            className="filled-star"
            onClick={() => {
              toggleFeatured(index);
            }}
          />
        ) : (
          <StarOutlined
            className="outlined-star"
            onClick={() => {
              toggleFeatured(index);
            }}
          />
        );
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (value, record, index) => {
        if (value.length < 20) {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateOffer(true);
                setSelectedId(record.id);
                setDiscountType(record.kind);
                setTimeout(() => {
                  offerUpdate(index, value, record);
                }, 200);
              }}
            >
              {value}
            </Typography>
          );
        }
        // eslint-disable-next-line no-lone-blocks
        {
          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateOffer(true);
                setSelectedId(record.id);
                setDiscountType(record.kind);
                setTimeout(() => {
                  offerUpdate(index, value, record);
                }, 200);
              }}
            >
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 20)}...
              </Tooltip>
            </Typography>
          );
        }
      }
    },
    {
      title: 'Discount value ',
      dataIndex: 'value',
      key: 'value'
    },
    {
      title: 'Discount kind',
      dataIndex: 'kind',
      key: 'kind',
      render: (value) => {
        return <Tag color="blue">{value}</Tag>;
      }
    }
  ];
  // MODAL

  // CREATE FORM
  const createForm = async (formValue) => {
    const { title, value } = formValue;
    if (parseFloat(value) < 0) {
      message.error('Invalid discount');
      return;
    }
    if (title.length > 50) {
      message.error('Exceeded title limit');
      return;
    }
    setIsSavingData(true);
    const createdRuleBasedOffer = await createRuleBasedOffer({
      title,
      kind: discountType,
      value: parseFloat(value)
    });
    if (
      ruleBasedOffers.length === 0 ||
      ruleBasedOffers.length < limit ||
      ruleBasedOffers[ruleBasedOffers.length - 1].serialNumber + 1 ===
        createdRuleBasedOffer.serialNumber
    ) {
      const updatedOffer = ruleBasedOffers.slice();
      updatedOffer.push(createdRuleBasedOffer);
      setRuleBasedOffers(updatedOffer.slice());
    }
    form.resetFields();
    setDiscountType('PERCENTAGE');
    setIsSavingData(false);
    setIsModalOpen(false);
    fetchRuleBasedOffers({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  // UPDATE FORM
  const updateForm = async (formValue) => {
    const { title, value } = formValue;
    if (parseFloat(value) < 0) {
      message.error('Invalid discount');
      return;
    }
    if (title.length > 50) {
      message.error('Exceeded title limit');
      return;
    }
    setIsSavingData(true);
    const createdRuleBasedOffer = await updateRuleBasedOffer({
      title,
      kind: discountType,
      value: parseFloat(value),
      offerId: selectedId
    });

    const updatedOffers = ruleBasedOffers.slice();
    updatedOffers[currentSelectedIndex] = createdRuleBasedOffer;
    setRuleBasedOffers(updatedOffers);

    form.resetFields();
    setDiscountType('PERCENTAGE');
    setIsSavingData(false);
    setIsModalOpen(false);
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Rule Based
            </Typography.Text>
            <SearchComponent
              name="Rule based"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchRuleBasedOffers({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button onClick={() => setIsModalOpen(true)} className="create-btn">
            + Create
          </Button>

          {/* CREATE MODAL */}
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            className="offer-modal"
            title={isUpdateOffer ? offerName : 'Add rule based offer'}
            centered
          >
            <Divider />
            <div className="form-contents">
              <Form
                form={form}
                onFinish={isUpdateOffer ? updateForm : createForm}
                className="equipment-form"
                layout="vertical"
              >
                <Row className="upper-contents">
                  <Col>
                    <Form.Item
                      rules={[required]}
                      name="title"
                      className="input-title"
                      label="Offer title"
                    >
                      <Input type="text" placeholder="Title" />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="top-center-rule-based">
                  <div className="rule-based-discount-type">
                    <span>Select kind type</span>
                    <div className="kind-items">
                      <Radio.Group
                        onChange={discountChange}
                        value={discountType}
                      >
                        <Radio value="PERCENTAGE">PERCENTAGE</Radio>
                        <Radio value="FLAT">FLAT</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="rule-based-rupees">
                    <Form.Item
                      rules={[required]}
                      name="value"
                      label="Discount value"
                    >
                      <InputNumber
                        controls={false}
                        onChange={(value) => {
                          if (discountType === 'PERCENTAGE') {
                            if (value > 100) {
                              form?.resetFields(['value']);
                            }
                          }
                        }}
                        placeholder="0"
                      />
                    </Form.Item>
                  </div>
                </div>
                <Divider />
                <div className="timely-btn-space ">
                  <Button loading={isSavingData} htmlType="submit">
                    {isUpdateOffer ? 'Update' : ' Create'}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </div>
      </Header>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={ruleBasedOffers}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchRuleBasedOffers({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default RuleBased;
