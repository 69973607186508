import client from '../../apollo';
import {
  CREATE_CTA_BANNER,
  DELETE_CTA_BANNER,
  LINK_TEST_WITH_CTA_BANNER,
  TOGGLE_CTA_FIRST_TIME,
  TOGGLE_FEATURE_CTA_BANNER,
  TOGGLE_GUEST_CTA,
  UPDATE_CTA_BANNER
} from './graphql/mutation';
import { GET_CTA_BANNER_AS_ADMIN } from './graphql/query';

export const getCtaBanners = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_CTA_BANNER_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getCtaBanners;
  } catch (error) {
    return [];
  }
};

export const createCtaBanner = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_CTA_BANNER,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return response?.data?.createCtaBanner?.CtaBanner;
  } catch (error) {
    return false;
  }
};

export const toggleFeatureCtaBanner = async (id) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_FEATURE_CTA_BANNER,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const updateCtaBanner = async (data, bannerId) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_CTA_BANNER,
      fetchPolicy: 'network-only',
      variables: { data, bannerId }
    });
    return response?.data?.updateCtaBanner?.CtaBanner;
  } catch (error) {
    return false;
  }
};

export const removeCtaBanner = async (bannerId) => {
  try {
    const response = await client.query({
      query: DELETE_CTA_BANNER,
      fetchPolicy: 'network-only',
      variables: {
        bannerId
      }
    });
    return response?.data;
  } catch (error) {
    return [];
  }
};

export const updateTestToCtaBanner = async (data) => {
  const { test, bannerId } = data;
  try {
    const response = await client.mutate({
      mutation: LINK_TEST_WITH_CTA_BANNER,
      fetchPolicy: 'network-only',
      variables: {
        test,
        bannerId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const toggleCtaFirstTime = async (bannerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_CTA_FIRST_TIME,
      fetchPolicy: 'network-only',
      variables: {
        bannerId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const toggleGuestCtaBanner = async (bannerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_GUEST_CTA,
      fetchPolicy: 'network-only',
      variables: {
        bannerId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const sample = () => {};
