/* eslint-disable no-console */
import client from '../../apollo';
import { HARD_REFRESH_OVERALL_DATA } from './graphql/mutation';
import {
  GET_ACTIVE_DASHBOARD_DATA,
  GET_OVERALL_DASHBOARD_DATA,
  GET_OVERALL_DATA,
  GET_REPEATED_CUSTOMERS_DATA
} from './graphql/query';

export const getOverallDashboardData = async () => {
  try {
    const response = await client.query({
      query: GET_OVERALL_DASHBOARD_DATA,
      fetchPolicy: 'network-only'
    });
    return response?.data?.getOverallDashboardData;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getActiveDashboardData = async () => {
  try {
    const response = await client.query({
      query: GET_ACTIVE_DASHBOARD_DATA,
      fetchPolicy: 'network-only'
    });

    return response?.data?.getActiveDashboardData;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getDashboardWithinTimeFrame = async (filters) => {
  try {
    let response; // Declare response variable here
    if (filters) {
      const { startDate, endDate } = filters;

      response = await client.query({
        query: GET_OVERALL_DATA,
        fetchPolicy: 'network-only',
        variables: {
          filters: {
            startDate,
            endDate
          }
        }
      });
    } else {
      response = await client.query({
        query: GET_OVERALL_DATA,
        fetchPolicy: 'network-only'
      });
    }

    return response?.data?.getDashboardWithinTimeFrame;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const refreshOverallDashboardData = async () => {
  try {
    const response = await client.mutate({
      mutation: HARD_REFRESH_OVERALL_DATA,
      fetchPolicy: 'network-only'
    });
    return response?.data?.refreshOverallDashboardData;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const getMonthlyDashboardData = async (filters) => {
  const { startDate, endDate } = filters;
  if (startDate === undefined) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so January is 0, February is 1, etc.

    // Get the first day of the current month
    const stDate = `${year}-${month < 10 ? '0' : ''}${month}-01`;

    // Get the last day of the current month
    const lastDay = new Date(year, month, 0).getDate();
    const eDate = `${year}-${month < 10 ? '0' : ''}${month}-${lastDay}`;

    try {
      const response = await client.query({
        query: GET_OVERALL_DATA,
        fetchPolicy: 'network-only',
        variables: {
          filters: {
            startDate: stDate,
            endDate: eDate
          }
        }
      });
      return response?.data?.getDashboardWithinTimeFrame;
    } catch (error) {
      // eslint-disable-next-line no-console
      return [];
    }
  } else {
    try {
      const response = await client.query({
        query: GET_OVERALL_DATA,
        fetchPolicy: 'network-only',
        variables: {
          filters: {
            startDate,
            endDate
          }
        }
      });
      return response?.data?.getDashboardWithinTimeFrame;
    } catch (error) {
      // eslint-disable-next-line no-console
      return [];
    }
  }
};

export const getRepeatedCustomersData = async (repeatedCount, data) => {
  try {
    const response = await client.query({
      query: GET_REPEATED_CUSTOMERS_DATA,
      fetchPolicy: 'network-only',
      variables: {
        repeatedCount,
        filters: data
      }
    });
    return response?.data?.getRepeatedCustomers;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const test = async () => {};
