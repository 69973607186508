/* eslint-disable no-console */
import { Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../../common/components/CommonTable';
import LoaderComponent from '../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../../common/constants';
import { withHttp } from '../../../common/utils';
import { getHistoryPrescriptions } from '../prescriptions-repository';

function HistoryPrescriptions({ query }) {
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [historyPrescriptions, setHistoryPrescriptions] = useState([]);
  const [paginationProp, setPaginationProp] = useState();

  // FETCHING HISTORY PRESCRIPTIONS

  const fetchHistoryPrescriptions = async ({ currentSkip }) => {
    setLoadingData(true);
    try {
      //
      const response = await getHistoryPrescriptions({
        skip: currentSkip,
        limit: limit,
        query: query
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setHistoryPrescriptions(response.prescriptions.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchHistoryPrescriptions({
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (value) => {
        if (value.name.length < 20) {
          return value.name;
        }
        // eslint-disable-next-line no-lone-blocks
        {
          return (
            <Tooltip title={value.name} color="#232e78">
              {value.name.slice(0, 20)}...
            </Tooltip>
          );
        }
      }
    },
    {
      title: 'Uploaded at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => {
        return (
          <span>
            {dayjs(value).format('DD/MM/YYYY')} -{' '}
            {dayjs(value).format('hh:mm A')}
          </span>
        );
      }
    },
    {
      title: 'Prescription',
      dataIndex: 'prescriptions',
      key: '2',
      render: (value, record) => {
        return (
          <a href={withHttp(record.url)} target="blank">
            image.png
          </a>
        );
      }
    },
    {
      title: ' Status',
      dataIndex: 'prescriptions',
      key: '3',
      render: (value, record) => {
        if (record.status === 'RESOLVED') {
          return <Tag className="active-tag active-fix">Completed</Tag>;
        }

        return <Tag className="expired-tag cancelled-fix">Cancelled</Tag>;
      }
    },
    {
      title: 'Action taken at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (value) => {
        return (
          <span>
            {dayjs(value).format('DD/MM/YYYY')} -{' '}
            {dayjs(value).format('hh:mm A')}
          </span>
        );
      }
    }
  ];

  return (
    <div className="main-content test-categories-content">
      {loading ? (
        <LoaderComponent />
      ) : (
        <CommonTable
          columns={columns}
          rowKey={(record) => record.id}
          loadingData={loadingData}
          paginationConfig={paginationProp}
          data={historyPrescriptions}
          onChange={(onChange) => {
            // eslint-disable-next-line no-console
            setLoadingData(true);
            fetchHistoryPrescriptions({
              // currentQuery: query,
              currentSkip: (onChange.current - 1) * limit
            });
          }}
        />
      )}
    </div>
  );
}

export default HistoryPrescriptions;
