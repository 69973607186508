import { gql } from '@apollo/client';

// GETTING SAMPLES
export const GET_ALL_CUSTOMERS = gql`
  query getAllCustomers($filters: GetAllCustomerInput) {
    getAllCustomers(filters: $filters) {
      total
      customers {
        id
        emailVerified
        email
        countryCode
        mobileNumber
        name
        age
        avatar
        firebaseReferenceId
        userReferenceId
        sex
        isDisabled
      }
    }
  }
`;

export const GET_CUSTOMER_BY_ID = gql`
  query getPatientsAsAdmin($customerId: String!) {
    getCustomerByIdAsAdmin(customerId: $customerId) {
      customer {
        id
        emailVerified
        email
        countryCode
        mobileNumber
        name
        age
        dob
        createdAt
        avatar
        firebaseReferenceId
        userReferenceId
        sex
        isDisabled
        wallet {
          id
          balance
        }
      }
    }
    getPatientsAsAdmin(
      filters: { skip: 0, limit: 1000 }
      customerId: $customerId
    ) {
      total
      patients {
        id
        name
        age
        dob
        sex
      }
    }
    getCustomerAddressesAsAdmin(
      filters: { skip: 0, limit: 1000 }
      customerId: $customerId
    ) {
      total
      addresses {
        id
        name
        countryCode
        addressType
        mobileNumber
        pincode
        state
        addressLine1
        addressLine2
        city
        location {
          id
          latitude
          longitude
          pincode
          state
          address
          city
        }
      }
    }
  }
`;

export const GET_CUSTOMER_ORDER_HISTORY = gql`
  query getCustomerOrders(
    $customerId: String!
    $filters: GetAllCustomerInput!
  ) {
    getCustomerOrders(customerId: $customerId, filters: $filters) {
      total
      orders {
        id
        orderId
        scheduledAt
        createdAt
        source
        tests {
          id
        }
        subTotal
        paymentVia
        paymentStatus
        sampleCollectionPartner {
          name
        }
        customer {
          id
          name
        }
      }
    }
  }
`;

export const GET_CUSTOMER_CART_AS_ADMIN = gql`
  query getCustomerCartAsAdmin($customerId: String!) {
    getCustomerCartAsAdmin(customerId: $customerId) {
      cart {
        id
        tests {
          id
          title
          price
          discount
          kind
          productionCostWRTLab {
            productionCost
            lab {
              id
            }
          }
        }
        deliverReport
        coinsApplied
        usedCoins
        deliveryCharges
        offerApplied {
          discount
          couponCode
          kind
        }
        subTotal
        convenienceFees
        total
      }
    }
  }
`;

export const GET_COUPONS_AS_ADMIN = gql`
  query getOfferCouponsAsAdmin($customerId: String!) {
    getOfferCouponsAsAdmin(customerId: $customerId) {
      offers {
        id
        title
        couponCode
      }
    }
  }
`;

export const GET_LABS_BY_CART = gql`
  query getLabsFromTestsAsAdmin($customerId: String!) {
    getLabsFromTestsAsAdmin(customerId: $customerId) {
      labs {
        id
        name
        displayName
      }
      total
    }
  }
`;

export const GET_WHATSAPP_MESSAGES_BY_ID = gql`
  query getWhatsappMessageHistoryByCustomerIdAsAdmin(
    $customerId: String!
    $filters: GetAllWhatsappInput
  ) {
    getWhatsappMessageHistoryByCustomerIdAsAdmin(
      customerId: $customerId
      filters: $filters
    ) {
      whatsappMessageHistory {
        id
        interaktUserId
        templateId
        phoneNumber
        status
        messageSentTime
        messageDeliveredTime
        messageSeenTime
        createdAt
        updatedAt
        templateName
        customerId
      }
      total
    }
  }
`;

export const GET_EXCEL_CUSTOMER_DATA = gql`
  query GetExcelForCustomer($filters: getExcelCustomerInput) {
    getExcelForCustomer(filters: $filters) {
      data {
        id
        name
        sex
        email
        age
        mobileNumber
        countryCode
        dob
      }
    }
  }
`;
