/* eslint-disable no-lone-blocks */
/* eslint-disable no-console */
import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Space,
  Tooltip,
  Typography,
  Upload
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReactComponent as UploadImage } from '../../../../assets/upload.svg';
import {
  getFileSignedUrl,
  postUploadIcon
} from '../../../../common/common-repository';
import CommonTable from '../../../../common/components/CommonTable';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import SearchComponent from '../../../../common/components/SearchDebounce';
import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT
} from '../../../../common/constants';
import { formValidatorRules } from '../../../../common/utils';
import { createSample, getSamples, updateSample } from '../../test-repository';
import './samples.less';

function TestSamples() {
  const { required } = formValidatorRules;
  const [form] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [samples, setSamples] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  // CREATE NEW MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [sampleName, setSampleName] = useState('');
  const [isUpdatedSample, setIsUpdatedSample] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [selectedId, setSelectedId] = useState('');
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);

  const fetchSamples = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getSamples({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setSamples(response.samples);
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchSamples({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // UPDATE MODAL
  const getUpdatedSample = (record) => {
    setLoadingData(true);
    setIsUpdatedSample(true);
    form.setFieldsValue({
      name: record.name
    });
    setTimeout(() => {
      setIsModalOpen(true);
      setSampleName(record.name);
      setSelectedId(record.id);
      fetchSamples();
      setLoadingData(false);
    }, 500);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      width: 75,
      align: 'center',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Samples',
      dataIndex: 'name',
      key: 'name',
      render: (value, record, index) => {
        if (value.length < 20) {
          return (
            <Typography.Text
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setIsUpdatedSample(true);
                setUploadedImage(record.thumbnail);
                setCurrentSelectedIndex(index);
                setTimeout(() => {
                  getUpdatedSample(record, value);
                }, 200);
              }}
            >
              {value}
            </Typography.Text>
          );
        }
        {
          return (
            <Typography.Text
              className="clickable-title"
              onClick={() => {
                form?.resetFields();
                setIsUpdatedSample(true);
                setUploadedImage(record.thumbnail);
                setCurrentSelectedIndex(index);
                setTimeout(() => {
                  getUpdatedSample(record, value);
                }, 200);
              }}
            >
              <Tooltip title={value} color="#232e78">
                {value.slice(0, 20)}...
              </Tooltip>
            </Typography.Text>
          );
        }
      }
    },
    {
      title: 'Image',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      align: 'center',
      render: (value, index) => {
        // eslint-disable-next-line no-console
        return (
          <div className="image-sample">
            <img alt={index.name} className="sample-img" src={value} />
          </div>
        );
      }
    }
  ];

  const handleCancel = () => {
    form.resetFields();
    setIsUpdatedSample(false);
    setUploadedImage(undefined);
    setIsModalOpen(false);
  };

  // VALIDATING SVG
  const beforeUpload = (file) => {
    const isSvg = file.type === 'image/svg+xml';
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error('Image must smaller than 10MB!');
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    if (!isSvg) {
      message.error(`${file?.name} is not a svg file`);
    }
    return false;
  };

  // UPLOADING ICON
  const onIconUpload = async (selectedFile) => {
    setUploadingImage(true);
    const fileName = selectedFile.file.name;
    const response = await postUploadIcon(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': 'image/svg+xml'
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  // FORM SUBMITTING
  const onSubmit = async (formValue) => {
    const { name } = formValue;
    if (!uploadedImage) {
      message.error('Image is not uploaded');
      return;
    }
    if (name.length > 50) {
      message.error('Exceeded name limit');
      return;
    }
    if (name.length < 2) {
      message.error('Minimum name length should be 2');
      return;
    }
    setIsSavingData(true);
    const createdSample = await createSample({
      name,
      thumbnail: uploadedImage
    });
    if (
      samples.length === 0 ||
      samples.length < limit ||
      samples[samples.length - 1].serialNumber + 1 ===
        createdSample.serialNumber
    ) {
      const updatedSamples = samples.slice();
      updatedSamples.push(createdSample);
      setSamples(updatedSamples);
    }
    form.resetFields();
    setUploadedImage();
    setIsSavingData(false);
    setIsModalOpen(false);
    fetchSamples({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  // SUBMIT UPDATED FORM
  const onSubmitUpdateSample = async (formValue) => {
    const { name } = formValue;
    if (name.length > 50) {
      message.error('Exceeded name limit');
      return;
    }
    if (name.length < 2) {
      message.error('Minimum name length should be 2');
      return;
    }
    setIsSavingData(true);
    const updatedSample = await updateSample({
      name,
      thumbnail: uploadedImage,
      id: selectedId
    });
    const updatedSamples = samples.slice();
    updatedSamples[currentSelectedIndex] = updatedSample;
    setSamples(updatedSamples);
    form?.resetFields();
    setUploadedImage(undefined);
    setIsSavingData(false);
    setIsUpdatedSample(false);
    setIsModalOpen(false);
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Samples
            </Typography.Text>
            <SearchComponent
              name="Samples"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchSamples({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button onClick={() => setIsModalOpen(true)} className="create-btn">
            + Create New
          </Button>
          {/* CREATE MODAL */}
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            className="create-test-modal"
            centered
            title={isUpdatedSample ? sampleName : 'Create Sample'}
          >
            <Divider />
            <Form
              onFinish={isUpdatedSample ? onSubmitUpdateSample : onSubmit}
              form={form}
              layout="vertical"
            >
              <Form.Item name="upload">
                <div className="profile">
                  <Upload
                    name="upload-icon"
                    onChange={onIconUpload}
                    accept=".svg"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                  >
                    {uploadedImage && !uploadingImage && (
                      <img
                        src={uploadedImage}
                        className="sample-img "
                        alt="xyz"
                      />
                    )}
                    {!uploadedImage && !uploadingImage && (
                      <UploadImage className="sample-image" />
                    )}
                    {uploadingImage && <LoadingOutlined />}
                    <p>Upload sample image</p>
                  </Upload>
                </div>
              </Form.Item>
              <Form.Item rules={[required]} name="name" label="Sample name">
                <Input name="sample" type="text" />
              </Form.Item>
              <Divider />
              <div className="create-wrapper">
                <Button
                  loading={isSavingData}
                  htmlType="submit"
                  className="create-btn-modal"
                >
                  {isUpdatedSample ? 'Save' : 'Create'}
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </Header>
      <div className="sample-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            rowKey={(record) => record.id}
            columns={columns}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={samples}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchSamples({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default TestSamples;
