import { Button, Space, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as Back } from '../../assets/icons/back.svg';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import { getCustomerWhatasappHistory } from './customers-repository';

function CustomerAppNotifications() {
  const limit = GLOBAL_PAGINATION_LIMIT;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // STATES
  const [whatsappData, setWhatsappData] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);

  const fetchViewHistoryOrders = async ({ currentSkip }) => {
    try {
      const id = searchParams.get('customerId');
      const response = await getCustomerWhatasappHistory(id, {
        skip: currentSkip,
        limit: limit
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setWhatsappData(response.whatsappMessageHistory.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchViewHistoryOrders({
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: '',
      dataIndex: '',
      key: 'id',
      render: (value, record, index) => {
        return index + 1;
      }
    },

    {
      title: 'Template Name',
      dataIndex: 'templateName',
      key: 'templateName',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'Message sent at',
      dataIndex: 'messageSentTime',
      key: 'messageSentTime',
      render: (value) => {
        return dayjs(value).format('hh:mm:ss A - DD/MM/YYYY');
      }
    },
    {
      title: 'Message seen at',
      dataIndex: 'messageSeenTime',
      key: 'messageSentTime',
      render: (value) => {
        if (value) {
          return dayjs(value).format('hh:mm:ss A - DD/MM/YYYY');
        }
        return <p className="not-seen">Not seen</p>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => {
        return value.toUpperCase();
      }
    }
  ];

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Space>
              <Typography.Text className="main-header-title">
                Whatsapp's History
              </Typography.Text>
            </Space>
          </Space>
          {/* <SearchComponent
            name="Customers"
            debounceTime={DEBOUNCE_TIME}
            getData={(value) => {
              setQuery(value);
              setLoadingData(true);
              fetchViewHistoryOrders({
                currentQuery: value,
                currentSkip: 0
              });
            }}
          /> */}
        </div>
      </Header>
      <div className="main-content customers-content">
        <div className="customers-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              data={whatsappData}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchViewHistoryOrders({
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerAppNotifications;
