import { gql } from '@apollo/client';

export const GET_OFFERS = gql`
  query getOffers($filters: GetOffersRequest) {
    getOffers(filters: $filters) {
      total
      offers {
        id
        title
        kind
        discount
        tillValid
        startDate
        couponCode
        minimumOrderOf
        order
        usedFor
        serialNumber
        isDisabled
        description
        maximumOrder
        isFirstUser
        isAutoApply
        isHidden
        linkedTests {
          id
          title
        }
      }
    }
  }
`;

export const CHECK_COUPON = gql`
  query checkIfCouponCodeExist($code: String!) {
    checkIfCouponCodeExist(code: $code)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;

export const OFFER_DETAILS = gql`
  query getCustomerCouponHistory($filters: CustomerCouponHistoryInput!) {
    getCustomerCouponHistory(filters: $filters) {
      history {
        orderId {
          id
          orderId
          bookedAt
          subTotal
          patient {
            name
          }
        }
      }
      couponCode
      totalCount
    }
  }
`;
