import { Navigate } from 'react-router-dom';
import React from 'react';
import { ROUTES } from './common/constants';

function PublicRoutes({ user, children }) {
  if (user) {
    return <Navigate to={ROUTES.MAIN} replace />;
  }

  return children;
}

export default PublicRoutes;
