import client from '../../apollo';
import {
  CREATE_RULE_BASED_OFFERS,
  TOGGLE_FEATURE_RULE_BASED,
  UPDATE_RULE_BASED_OFFERS
} from './graphql/mutation';
import { GET_RULE_BASED_OFFERS } from './graphql/query';

export const getRuleBasedOfferAsAdmin = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_RULE_BASED_OFFERS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getRuleBasedOfferAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const toggleFeatureRuleBasedOffer = async (offerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_FEATURE_RULE_BASED,
      fetchPolicy: 'network-only',
      variables: {
        offerId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const createRuleBasedOffer = async (data) => {
  const { title, kind, value } = data;
  try {
    const response = await client.mutate({
      mutation: CREATE_RULE_BASED_OFFERS,
      fetchPolicy: 'network-only',
      variables: { title, kind, value }
    });

    return response?.data?.createRuleBasedOffer?.offer;
  } catch (error) {
    return false;
  }
};

export const updateRuleBasedOffer = async (data) => {
  const { title, kind, value, offerId } = data;
  try {
    const response = await client.mutate({
      mutation: UPDATE_RULE_BASED_OFFERS,
      fetchPolicy: 'network-only',
      variables: { title, kind, value, offerId }
    });

    return response?.data?.updateRuleBasedOffer?.offer;
  } catch (error) {
    return false;
  }
};

export const test = async () => {};
