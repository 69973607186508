import client from '../../apollo';
import {
  CREATE_TEMPLATE_ADDRESS,
  DELETE_TEMPLATE_ADDRESS,
  UPDATE_TEMPLATE_ADDRESS
} from './graphql/mutation';
import { GET_ADDRESS_TEMPLATES } from './graphql/query';

export const getAddressTemplates = async (filters) => {
  const { skip, limit } = filters;
  try {
    const response = await client.query({
      query: GET_ADDRESS_TEMPLATES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getAllTemplateAddresses;
  } catch (error) {
    return [];
  }
};

export const createCustomerAddressAsAdmin = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_TEMPLATE_ADDRESS,
      fetchPolicy: 'network-only',
      variables: {
        data
      }
    });

    return response?.data?.createTemplateAddress?.TemplateAddress;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const deleteTemplateAddress = async (id) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_TEMPLATE_ADDRESS,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    });
    // eslint-disable-next-line no-console
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const updateTemplateAddress = async (id, data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_TEMPLATE_ADDRESS,
      fetchPolicy: 'network-only',
      variables: {
        id,
        data
      }
    });
    return response?.data?.updateTemplateAddress?.TemplateAddress;
  } catch (error) {
    return false;
  }
};

export const sample = () => {};
