/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Modal,
  React,
  Row,
  Space,
  TimePicker,
  Typography,
  Upload
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import dayjs from 'dayjs';
import { getAuth } from 'firebase/auth';
import { getType } from 'mime';
import { useEffect, useState } from 'react';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Back } from '../../assets/icons/back.svg';
import { ReactComponent as DollarIcon } from '../../assets/revenue.svg';
import { ReactComponent as UploadImage } from '../../assets/upload.svg';
import CountryCodePicker from '../../common/components/CountryCodePicker';
import LoaderComponent from '../../common/components/LoaderComponent';
import { clearSearchField, formValidatorRules } from '../../common/utils';

import {
  getFileSignedUrl,
  postUploadPhoto
} from '../../common/common-repository';
import SearchComponent from '../../common/components/SearchDebounce';
import config from '../../common/config';
import { DEBOUNCE_TIME, MOBILE_REGEX, ROUTES } from '../../common/constants';
import {
  getDoctorDetails,
  getDoctorProfileUrl,
  updateDoctorAsAdmin
} from './doctor-repository';
import './doctor.less';

function DoctorDetails() {
  const { id: docID } = useParams();
  const { required, number } = formValidatorRules;
  const [form] = Form.useForm();
  const [locationForm] = Form.useForm();
  const navigate = useNavigate();
  const timeFormat = 'hh:mm a';

  const [isSavingData, setIsSavingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [doctorDetails, setDoctorDetails] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(undefined);
  const [uploadingImage, setUploadingImage] = useState(false);

  const [locationObjects, setLocationObjects] = useState([]);
  const [openLocationPicker, setOpenLocationPicker] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [currentClinicIndex, setCurrentClinicIndex] = useState(null);

  const fetchDoctorDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getDoctorDetails(docID);
      if (response) {
        setDoctorDetails(response);
        const { profilePicture } = response;
        setUploadedImage(profilePicture);
        // Initialize locationObjects based on clinicDetails
        const locationDataArray = response.clinicDetails.map((clinic) => ({
          address: clinic.location?.address || '',
          city: clinic.location?.city || '',
          latitude: clinic.location?.latitude || null,
          longitude: clinic.location?.longitude || null,
          pincode: clinic.location?.pincode || '',
          state: clinic.location?.state || ''
        }));

        setLocationObjects(locationDataArray);

        const { clinicDetails } = response;

        // Format clinicDetails times and set up initial values
        const formattedClinicDetails = clinicDetails.map((clinic) => {
          const [morningStart, morningEnd] = clinic.morningTime
            ? clinic.morningTime
                .split(' - ')
                .map((time) => dayjs(time, timeFormat))
            : [null, null];
          const [eveningStart, eveningEnd] = clinic.eveningTime
            ? clinic.eveningTime
                .split(' - ')
                .map((time) => dayjs(time, timeFormat))
            : [null, null];

          return {
            ...clinic,
            morningTime: clinic.morningTime ? [morningStart, morningEnd] : null,
            eveningTime: clinic.eveningTime ? [eveningStart, eveningEnd] : null
          };
        });

        // Set the form fields including clinicDetails
        form.setFieldsValue({
          name: response.name,
          email: response.email,
          mobileNo: response.mobileNumber,
          countryCode: response.countryCode,
          designation: response.designation,
          experience: response.experience,
          slug: response.doctorSlug,
          commissionValue: response.commissionPercentage,
          clinicDetails: formattedClinicDetails,
          instagram: response.social.instagram,
          facebook: response.social.facebook,
          youtube: response.social.youtube,
          linkedin: response.social.linkedin
        });
      } else {
        message.error('Failed to fetch doctor details');
      }
    } catch (error) {
      message.error('Failed to fetch doctor details');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDoctorDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // LOCATION STUFF

  const handleLocationPickerCancel = () => {
    locationForm.resetFields();
    setOpenLocationPicker(false);
    clearSearchField('Location');
  };

  const fetchAddresses = async (value) => {
    const auth = getAuth();
    let userToken = '';
    const currentUser = auth?.currentUser;
    if (currentUser) {
      userToken = await currentUser.getIdToken(true);
    }
    const request = {
      method: 'get',
      url: `${config.SERVER_URL}api/get-location-suggestions?search=${value}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    };
    setLoadingSearchResults(true);
    const response = await axios(request);
    setPredictions(response.data.predictions);
    setLoadingSearchResults(false);
  };

  const selectAddress = async (placeId) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Location is getting selected'
    });

    const auth = getAuth();
    let userToken = '';
    const currentUser = auth?.currentUser;
    if (currentUser) {
      userToken = await currentUser.getIdToken(true);
    }

    const request = {
      method: 'get',
      url: `${config.SERVER_URL}api/decode-location-with-id?id=${placeId}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    };

    const response = await axios(request);
    const location = response.data;

    const locationData = {
      address: location.address,
      // city: location.city,
      latitude: location.latitude,
      longitude: location.longitude
      // pincode: location.pincode,
      // state: location.state
    };

    if (currentClinicIndex !== null) {
      // Set clinic location directly in form fields
      form.setFieldsValue({
        clinicDetails: form
          .getFieldValue('clinicDetails')
          .map((clinic, idx) =>
            idx === currentClinicIndex
              ? { ...clinic, clinicAddress: locationData.address }
              : clinic
          )
      });
    }

    // Update locationObjects array with the new locationData
    setLocationObjects((prev) => {
      const updatedLocations = [...prev];
      updatedLocations[currentClinicIndex] = locationData;
      return updatedLocations;
    });

    // Trigger form validation to remove the required message
    form.validateFields([
      ['clinicDetails', currentClinicIndex, 'clinicAddress']
    ]);

    handleLocationPickerCancel();
    message.open({
      key,
      type: 'success',
      content: 'Location picked',
      duration: 1
    });
  };

  const onSubmitForm = async (formValues) => {
    const {
      name,
      designation,
      countryCode,
      mobileNo,
      commissionValue,
      password,
      experience,
      clinicDetails = [], // Initialize as empty array if undefined
      instagram,
      facebook,
      linkedin,
      youtube
    } = formValues;
    if (mobileNo.length !== 10) {
      message.error('Invalid mobile number');
      return;
    }
    if (!mobileNo.match(MOBILE_REGEX)) {
      message.error('Invalid mobile number');
      return;
    }

    if (clinicDetails.length < 1) {
      message.error('Minimum 1 clinic is required');
      return;
    }

    const missingTimeIndex = clinicDetails.findIndex(
      (clinic) => !clinic.morningTime && !clinic.eveningTime
    );

    if (missingTimeIndex !== -1) {
      message.error(`At least one time is required for each clinic`);
      return;
    }

    const nullLocation = {
      latitude: 0,
      longitude: 0
    };

    setIsSavingData(true);
    try {
      const payload = {
        name,
        designation,
        countryCode: countryCode || '+91',
        mobileNumber: mobileNo,
        commissionPercentage: parseInt(commissionValue, 10),
        password,
        experience: parseInt(experience, 10),
        clinicDetails: clinicDetails.map((clinic, index) => ({
          clinicName: clinic.clinicName,
          clinicAddress: clinic.clinicAddress,
          morningTime: clinic.morningTime
            ? `${clinic.morningTime[0].format(
                timeFormat
              )} - ${clinic.morningTime[1].format(timeFormat)}`
            : null,
          eveningTime: clinic.eveningTime
            ? `${clinic.eveningTime[0].format(
                timeFormat
              )} - ${clinic.eveningTime[1].format(timeFormat)}`
            : null,
          location: locationObjects[index]
            ? {
                latitude: locationObjects[index].latitude,
                longitude: locationObjects[index].longitude
              }
            : nullLocation // Attach corresponding location object
        })),
        instagram,
        facebook,
        linkedin,
        youtube,
        profilePicture: uploadedImage || null
      };

      await updateDoctorAsAdmin(docID, payload);
      setIsSavingData(false);
      message.success('Details updated');
      fetchDoctorDetails();
    } catch (error) {
      message.error('Failed to update doctor details');
      setIsSavingData(false);
    }
  };

  const beforeUpload = (file) => {
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error('Image must smaller than 10MB!');
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  // BANNER UPLOAD
  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await postUploadPhoto(fileName);
    const { signedUrl, getUrl } = response;
    const request = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(request);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  const openInNewTab = async () => {
    try {
      message.loading('Opening Profile', 1);
      const response = await getDoctorProfileUrl(doctorDetails?.doctorSlug);
      if (response) {
        const { publicUrl } = response;
        // eslint-disable-next-line no-undef
        window.open(publicUrl, '_blank', 'noreferrer');
      }
    } catch (error) {
      message.error('Failed to find profile');
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Space>
              <Typography.Text
                style={{
                  display: 'flex',
                  gap: '5px'
                }}
                className="main-header-title"
              >
                {doctorDetails?.name || 'Doctor'}
                <span
                  style={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                    fontSize: '16px'
                  }}
                >
                  ( {`${doctorDetails?.profileViewCount || 0} `} Views)
                </span>
              </Typography.Text>
            </Space>
          </Space>
          <div
            style={{
              display: 'flex',
              gap: '10px'
            }}
          >
            <Button
              className="orderHistory"
              type="default"
              onClick={() => {
                const url = `${ROUTES.DOCTORS}/${docID}/more-details`;
                navigate(url);
              }}
            >
              More Details
            </Button>
            <Button onClick={openInNewTab} className="orderHistory">
              <AiOutlineShareAlt size={25} />
            </Button>
          </div>
        </div>
      </Header>
      {/* LOCATION MODAL */}
      <Modal
        open={openLocationPicker}
        onCancel={handleLocationPickerCancel}
        centered
        footer={null}
        title="Pick location"
      >
        <Form form={locationForm}>
          <SearchComponent
            name="Location"
            debounceTime={DEBOUNCE_TIME}
            getData={(value) => {
              fetchAddresses(value);
            }}
            style={{
              height: '45px',
              borderRadius: '10px'
            }}
          />

          {loadingSearchResults ? (
            <div className="empty-state">
              <LoaderComponent />
            </div>
          ) : predictions.length === 0 ? (
            <Empty className="empty-state" />
          ) : (
            <div className="fixed-height">
              {predictions.map((prediction, index) => {
                const predictionId = `prediction-${index}`;
                return (
                  <div
                    className="location-container"
                    key={predictionId}
                    onClick={() => {
                      const { place_id: placeId } = prediction;
                      selectAddress(placeId);
                    }}
                  >
                    {prediction.description}
                  </div>
                );
              })}
            </div>
          )}
        </Form>
      </Modal>
      <div
        className="main-content customer-detail"
        style={{ backgroundColor: '#F2F4F7' }}
      >
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <Row>
            <Col span={24}>
              <div className="card user-basic-form">
                <Form form={form} layout="vertical" onFinish={onSubmitForm}>
                  <Form.Item name="upload">
                    <div className="profile">
                      <Upload
                        name="upload-icon"
                        onChange={onIconUpload}
                        accept="image/*"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                      >
                        {uploadedImage && !uploadingImage && (
                          <img
                            className="uploaded-image"
                            src={uploadedImage}
                            alt="xyz"
                          />
                        )}
                        {!uploadedImage && !uploadingImage && (
                          <UploadImage className="sample-image" />
                        )}
                        {uploadingImage && <LoadingOutlined />}
                        {uploadedImage ? (
                          <p>Re-upload image</p>
                        ) : (
                          <p>Upload image</p>
                        )}
                      </Upload>
                    </div>
                  </Form.Item>
                  <Row className="row-one">
                    <Col span={8} className="left">
                      <Form.Item rules={[required]} name="name" label="Name">
                        <Input type="text" />
                      </Form.Item>
                    </Col>
                    <Col span={8} className="right">
                      <Form.Item
                        rules={[required]}
                        name="designation"
                        label="Specialization"
                      >
                        <Input type="text" />
                      </Form.Item>
                    </Col>
                    <Col span={8} className="right">
                      <Form.Item
                        name="email"
                        label="Email id"
                        // rules={[required]}
                      >
                        <Input type="email" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="row-one">
                    <Col span={8} className="left">
                      <Form.Item
                        rules={[
                          required,
                          number,
                          () => ({
                            validator(rule, value) {
                              if (value.length > 10) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise?.reject('Number limit exceeded');
                              }
                              return Promise?.resolve();
                            }
                          })
                        ]}
                        className="mobile-number"
                        name="mobileNo"
                        label="Mobile number"
                      >
                        <Input
                          type="text"
                          style={{ width: '100%' }}
                          addonBefore={<CountryCodePicker name="countryCode" />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8} className="right">
                      <Form.Item
                        rules={[number, required]}
                        name="commissionValue"
                        label="Commission %"
                      >
                        <Input type="text" />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="right">
                      <Form.Item
                        rules={[number]}
                        name="experience"
                        label="Experience"
                      >
                        <Input type="text" maxLength={2} />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="right">
                      <Form.Item name="slug" label="Slug">
                        <Input type="text" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: '600'
                    }}
                  >
                    CLINIC DETAILS
                  </div>
                  <Form.List name="clinicDetails">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <div key={key} className="clinic-details-group">
                            <div className="row-one doc-time-row">
                              <Form.Item
                                {...restField}
                                name={[name, 'clinicName']}
                                label="Clinic Name"
                                rules={[required]}
                              >
                                <Input type="text" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'clinicAddress']}
                                label="Clinic Address"
                                rules={[required]}
                              >
                                <Input type="text" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'location']}
                                label="Location address"
                                // rules={[
                                //   { required: true, message: 'Location is required' }
                                // ]}
                              >
                                <Button
                                  style={{
                                    width: '100%',
                                    height: '48px',
                                    borderRadius: '10px'
                                  }}
                                  onClick={() => {
                                    setCurrentClinicIndex(name);
                                    setOpenLocationPicker(true);
                                  }}
                                >
                                  Select Location
                                  {/* {form.getFieldValue([
                              'clinicDetails',
                              name,
                              'location'
                            ])
                              ? 'Change location'
                              : 'Select location'} */}
                                </Button>
                              </Form.Item>
                            </div>
                            <div className="row-one doc-time-row">
                              <Form.Item
                                {...restField}
                                name={[name, 'morningTime']}
                                label="Morning Time"
                              >
                                <TimePicker.RangePicker format={timeFormat} />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'eveningTime']}
                                label="Evening Time"
                              >
                                <TimePicker.RangePicker format={timeFormat} />
                              </Form.Item>
                            </div>
                            <Button
                              className="remove-clinic"
                              onClick={() => remove(name)}
                              style={{ marginBottom: '10px' }}
                            >
                              Remove Clinic
                            </Button>
                            <Divider />
                          </div>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            className="add-clinic-btn"
                            onClick={() => add()}
                            block
                            style={{
                              width: '50%'
                            }}
                          >
                            + Add Clinic Details
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: '600'
                    }}
                  >
                    SOCIALS
                  </div>
                  <Row className="row-one">
                    <Col span={6} className="left">
                      <Form.Item name="instagram" label="Instagram">
                        <Input type="text" />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="left">
                      <Form.Item name="facebook" label="Facebook">
                        <Input type="text" />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="left">
                      <Form.Item name="linkedin" label="LinkedIn">
                        <Input type="text" />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="left">
                      <Form.Item name="youtube" label="Youtube">
                        <Input type="text" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider />
                  <Button
                    loading={isSavingData}
                    htmlType="submit"
                    className="create-btn"
                    style={{ left: 0 }}
                  >
                    Save
                  </Button>
                </Form>
              </div>
              <div className="doctor-analytics">
                <div
                  style={{
                    fontSize: '20px',
                    fontWeight: '600',
                    paddingLeft: '10px'
                  }}
                >
                  ANALYTICS
                </div>
                <div className="analytic-content">
                  <Card className="analytic-card">
                    <Typography.Text>
                      ₹{doctorDetails?.revenueGenerated.toFixed(2) || 0}
                    </Typography.Text>
                    <div className="card-details">
                      <Typography.Text className="text-details">
                        <DollarIcon /> Total Revenue
                      </Typography.Text>
                    </div>
                  </Card>
                  <Card className="analytic-card">
                    <Typography.Text>
                      {' '}
                      ₹{doctorDetails?.totalCommissionEarned.toFixed(2) || 0}
                    </Typography.Text>
                    <div className="card-details">
                      <Typography.Text className="text-details">
                        <DollarIcon /> Total Commission
                      </Typography.Text>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}
export default DoctorDetails;
