import { gql } from '@apollo/client';

export const GET_SYSTEM_USER_IF_EXISTS = gql`
  query {
    getSystemUserIfExists {
      systemUser {
        id
        email
        name
        role
        emailVerified
        countryCode
        mobileNumber
        age
        sex
        firebaseReferenceId
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
