/* eslint-disable no-console */
import { DatePicker, Select, Space, Tabs, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CalenderIcon } from '../../assets/calendar.svg';
import SearchComponent from '../../common/components/SearchDebounce';
import { DEBOUNCE_TIME } from '../../common/constants';
import {
  getStartAndEndDateFromWeek,
  toLocalISOString
} from '../../common/utils';
import { getDoctorsList } from '../doctors-tab/doctor-repository';
import ActiveReports from './components/ActiveReports';
import HistoryReports from './components/HistoryReports';
import './reports.less';

function Reports() {
  const [query, setQuery] = useState('');
  const [type, setType] = useState('all');
  const [startDateVal, setStartDateVal] = useState(null);
  const [endDateVal, setEndDateVal] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const { reportType } = useParams();
  const navigate = useNavigate();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const fetchDoctorList = async () => {
    try {
      const response = await getDoctorsList({
        skip: 0,
        limit: 1000,
        query: ''
      });
      setDoctorList(response.doctors);
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    fetchDoctorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Determine the default active tab based on reportType
  const defaultActiveTabKey = reportType === 'history' ? '2' : '1';

  const handleTabChange = (activeTabKey) => {
    setStartDateVal(null);
    setEndDateVal(null);
    setType('all');
    // Update the URL based on the active tab and the current page number
    const newReportType = activeTabKey === '2' ? 'history' : 'active';
    navigate(`/reports/${newReportType}/1`);
  };

  const dateSelectionType = () => {
    switch (type) {
      case 'date':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(value) => {
              const utcTime = value;
              const utcDate = new Date(utcTime);
              // Convert to IST by adding 5 hours and 30 minutes
              utcDate.setHours(utcDate.getHours() + 5);
              utcDate.setMinutes(utcDate.getMinutes() + 30);
              const istTime = dayjs(utcDate.toISOString()).format('YYYY-MM-DD');
              setStartDateVal(istTime);
              setEndDateVal(istTime);
            }} // Pass the function reference without invoking it
          />
        );
      case 'week':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(date, dateString) => {
              const match = dateString.match(/^(\d+)-(\d+)(?:st|nd|rd|th)$/);
              if (match) {
                const year = match[1];
                const day = match[2];
                const { startDate, endDate } = getStartAndEndDateFromWeek(
                  year,
                  day
                );
                setStartDateVal(dayjs(startDate).format('YYYY-MM-DD'));
                setEndDateVal(dayjs(endDate).format('YYYY-MM-DD'));
              } else {
                //
              }
            }}
          />
        );
      case 'month':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(value) => {
              const selectedDate = new Date(value);

              // Set the start date to the 1st day of the selected month
              const startDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                1
              );
              // Calculate the end of the month
              const endOfMonth = new Date(startDate);
              endOfMonth.setMonth(startDate.getMonth() + 1, 0); // Set the day to 0 of the next month (last day of the current month)

              // Check if the year is a leap year
              const year = startDate.getFullYear();
              const isLeapYear =
                (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

              if (isLeapYear) {
                // Modify endOfMonth for leap years (February has 29 days)
                if (startDate.getMonth() === 1) {
                  // February (0-based index)
                  endOfMonth.setDate(29);
                }
              } else {
                //
              }

              // Set your values
              const startDateValue = toLocalISOString(startDate);
              const endDateValue = toLocalISOString(endOfMonth);

              setStartDateVal(dayjs(startDateValue).format('YYYY-MM-DD'));
              setEndDateVal(dayjs(endDateValue).format('YYYY-MM-DD'));
            }}
          />
        );
      case 'custom':
        return (
          <RangePicker
            suffixIcon={<CalenderIcon />}
            onChange={(value) => {
              if (value && value.length === 2) {
                setStartDateVal(dayjs(value[0]).format('YYYY-MM-DD'));
                setEndDateVal(dayjs(value[1]).format('YYYY-MM-DD'));
              } else {
                setStartDateVal(undefined); // Clear the start date when no range is selected
                setEndDateVal(undefined); // Clear the end date when no range is selected
              }
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Reports booked
            </Typography.Text>
            <SearchComponent
              name="Reports"
              debounceTime={DEBOUNCE_TIME}
              getData={(change) => {
                setQuery(change);
              }}
            />
          </Space>
          {/* <Button className="create-btn invoice-btn">Download Invoices</Button> */}
        </div>
      </Header>
      <div className="content content-class">
        <div className="tabs-header-reports">
          <Tabs
            onTabClick={handleTabChange} // Call the handleTabChange function
            defaultActiveKey={defaultActiveTabKey}
            items={[
              {
                key: '1',
                label: `Active`,
                children: (
                  <ActiveReports
                    query={query}
                    startDateVal={startDateVal}
                    endDateVal={endDateVal}
                    doctorList={doctorList}
                  />
                )
              },
              {
                key: '2',
                label: `History`,
                children: (
                  <HistoryReports
                    query={query}
                    startDateVal={startDateVal}
                    endDateVal={endDateVal}
                    doctorList={doctorList}
                  />
                )
              }
            ]}
          />
        </div>
        <div className="select-date-time selector-class">
          <Select
            className="day-selector"
            value={type}
            onChange={(value) => {
              setType(value);
              if (value === 'all') {
                setStartDateVal(null);
                setEndDateVal(null);
              }
            }}
          >
            <Option value="all">All</Option>
            <Option value="date">Date</Option>
            <Option value="week">Week</Option>
            <Option value="month">Month</Option>
            <Option value="custom">Custom</Option>
          </Select>
          {dateSelectionType()}
        </div>
      </div>
    </div>
  );
}
export default Reports;
