/* eslint-disable no-console */
import {
  CheckCircleOutlined,
  DownOutlined,
  UpOutlined
} from '@ant-design/icons';
import { Table, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../../common/components/CommonTable';
import LoaderComponent from '../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../../common/constants';
import { getOrderHistory } from '../labReports-repository';

function LabHistoryReports({ query, forceToRefetch }) {
  // CONSTANTS
  const limit = GLOBAL_PAGINATION_LIMIT;

  //  STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [orderHistory, setOrderHistory] = useState([]);
  const [paginationProp, setPaginationProp] = useState();

  const fetchCurrentSamplesByOrders = async ({ currentSkip }) => {
    try {
      //
      const response = await getOrderHistory({
        skip: currentSkip,
        limit: limit,
        query: query.trim()
      });

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setOrderHistory(response.orders.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchCurrentSamplesByOrders({
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, forceToRefetch]);

  const columns = [
    {
      title: 'Order#',
      dataIndex: 'orderId'
    },
    {
      title: 'Patient',
      dataIndex: 'patient',
      render: (value) => {
        return <Typography.Text>{value.name}</Typography.Text>;
      }
    },
    {
      title: 'Gender',
      dataIndex: 'patient',
      render: (value) => {
        return <Typography.Text>{value.sex}</Typography.Text>;
      }
    },
    {
      title: 'Age',
      dataIndex: 'patient',
      render: (value) => {
        return <Typography.Text>{value.age}</Typography.Text>;
      }
    },
    {
      title: '# reports',
      dataIndex: 'reports',
      align: 'center',
      render: (value, record) => {
        return `${record.tests.length}`;
      }
    },
    {
      title: 'Sample tube no.',
      dataIndex: 'requiredSamples',
      render: (record) => {
        const data = [];
        for (let i = 0; i < record.length; i += 1) {
          data.push(record[i].linkedEquipment);
        }

        return (
          <span>
            {data.length > 1 ? (
              <p>
                <Tooltip
                  className="tooltipNo"
                  color="#232e78"
                  placement="topLeft"
                  title={`${data}`}
                >
                  {data[0]}..
                </Tooltip>
              </p>
            ) : (
              <Tooltip
                className="tooltipNo"
                color="#232e78"
                placement="topLeft"
                title={`${data}`}
              >
                {data[0]}..
              </Tooltip>
            )}
          </span>
        );
      }
    },
    {
      title: 'Full report',
      dataIndex: 'wholeReport',
      align: 'center',
      render: (value) => {
        if (!value) {
          return <div />;
        }
        if (value.isApproved)
          return (
            <CheckCircleOutlined className="completed-test-verification" />
          );
      }
    }
  ];

  return (
    <div className="main-content test-categories-content">
      {loading ? (
        <LoaderComponent />
      ) : (
        <CommonTable
          columns={columns}
          rowKey={(record) => record.id}
          loadingData={loadingData}
          paginationConfig={paginationProp}
          data={orderHistory}
          onChange={(onChange) => {
            setLoadingData(true);
            fetchCurrentSamplesByOrders({
              currentSkip: (onChange.current - 1) * limit
            });
          }}
          expandable={{
            rowExpandable: () => true,
            // eslint-disable-next-line react/no-unstable-nested-components
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpOutlined
                  className="up-down-btn"
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <DownOutlined
                  className="up-down-btn"
                  onClick={(e) => onExpand(record, e)}
                />
              ),

            // eslint-disable-next-line react/no-unstable-nested-components
            expandedRowRender: (parent, index) => {
              const { reports } = parent;
              const expandedCol = [
                {
                  title: '#Test',
                  dataIndex: 'orderId',
                  width: 150,
                  render: () => {
                    return `${parent.orderId}#${index + 1}`;
                  }
                },
                {
                  title: 'Test name',

                  dataIndex: 'orderId',
                  render: (value, record) => {
                    return `${record.title}`;
                  }
                },
                {
                  title: 'Upload',
                  width: 250,
                  dataIndex: 'id',
                  render: (value) => {
                    let report;

                    for (let i = 0; i < reports.length; i += 1) {
                      const currentReport = reports[i];
                      if (value === currentReport.test) {
                        report = currentReport;
                      }
                    }
                    if (!report) {
                      return <div />;
                    }
                    if (report.isApproved)
                      return (
                        <CheckCircleOutlined className="completed-test-verification" />
                      );
                  }
                }
              ];
              return (
                <Table
                  // eslint-disable-next-line no-shadow
                  rowKey={(record) => record.id}
                  className="extended-table"
                  columns={expandedCol}
                  dataSource={parent.tests}
                  pagination={false}
                />
              );
            }
          }}
        />
      )}
    </div>
  );
}

export default LabHistoryReports;
