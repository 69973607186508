/* eslint-disable no-console */
import { Button, message, Space, Tabs, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import moment from 'moment';
import React, { useState } from 'react';
import SearchComponent from '../../common/components/SearchDebounce';
import { exportToExcel } from '../../common/utils';
import CurrentReports from './components/CurrentReports';
import LabHistoryReports from './components/LabHistoryReports';
import './lab-reports.less';
import { getOrderHistory } from './labReports-repository';
import { DEBOUNCE_TIME } from '../../common/constants';

function LabReports() {
  const [query, setQuery] = useState('');
  const [isBtn, setIsBtn] = useState(false);
  const [forceToRefetch, setForceToRefetch] = useState(false);
  const [loading, setLoading] = useState(false);

  const exportExcel = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Generating report'
    });
    setLoading(true);
    const response = await getOrderHistory({
      skip: 0,
      limit: 1000000
    });
    const orderHistory = response.orders;
    // MAPPING JSON DATA
    const jsonData = [];
    for (let i = 0; i < orderHistory.length; i += 1) {
      const currentOrder = orderHistory[i];
      const testNames = currentOrder?.tests
        ?.map((test) => {
          return test.title;
        })
        .toString();
      let linkedEquipments = '';
      for (let k = 0; k < currentOrder.requiredSamples.length; k += 1) {
        const currentSample = currentOrder.requiredSamples[k];
        linkedEquipments = currentSample.linkedEquipment
          .map((eqp) => {
            return eqp;
          })
          .toString();
      }
      jsonData.push({
        ORDER_ID: currentOrder.orderId,
        PATIENT: currentOrder.patient.name,
        GENDER: currentOrder.patient.sex,
        AGE: currentOrder.patient.age,
        REPORTS: currentOrder.tests.length,
        TESTS: testNames,
        EQUIPMENTS: linkedEquipments,
        SAMPLE_COLLECTED_AT: moment(currentOrder.sampleCollectedAt).format(
          'HH/MM/YYYY'
        )
      });
    }
    await exportToExcel({
      excelData: jsonData,
      fileName: 'report-history'
    });

    setLoading(false);
    message.open({
      key,
      type: 'success',
      content: 'Report generated!'
    });
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Reports
            </Typography.Text>
            <SearchComponent
              name="Reports"
              debounceTime={DEBOUNCE_TIME}
              getData={(change) => {
                setQuery(change);
              }}
            />
          </Space>
        </div>
      </Header>
      <div className="content-labReport">
        <div className="tabs-header-reports">
          <Tabs
            onChange={(key) => {
              if (key === '2') {
                setIsBtn(true);
              } else {
                setIsBtn(false);
              }
              setForceToRefetch(!forceToRefetch);
            }}
            items={[
              {
                key: '1',
                label: `Current`,
                children: (
                  <CurrentReports
                    query={query}
                    forceToRefetch={forceToRefetch}
                  />
                )
              },
              {
                key: '2',
                label: `History`,
                children: (
                  <LabHistoryReports
                    query={query}
                    forceToRefetch={forceToRefetch}
                  />
                )
              }
            ]}
          />
        </div>
        {isBtn ? (
          <div className="excel-btn">
            <Button loading={loading} onClick={exportExcel}>
              Export in excel
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default LabReports;
