import { gql } from '@apollo/client';

// CREATE TEMPLATE ADDRESS
export const CREATE_TEMPLATE_ADDRESS = gql`
  mutation createTemplateAddress($data: CreateTemplateAddressInput!) {
    createTemplateAddress(data: $data) {
      templateAddress {
        id
        addressLine1
        addressLine2
        pincode
        state
        city
        serialNumber
        location {
          id
          latitude
          longitude
          pincode
          state
          address
          city
        }
      }
    }
  }
`;

export const UPDATE_TEMPLATE_ADDRESS = gql`
  mutation updateTemplateAddress($id: ID!, $data: UpdateTemplateAddressInput) {
    updateTemplateAddress(id: $id, data: $data) {
      templateAddress {
        id
        addressLine1
        addressLine2
        pincode
        state
        city
        serialNumber
        location {
          id
          latitude
          longitude
          pincode
          state
          address
          city
        }
      }
    }
  }
`;

export const DELETE_TEMPLATE_ADDRESS = gql`
  mutation deleteTemplateAddress($id: ID!) {
    deleteTemplateAddress(id: $id)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
