import { gql } from '@apollo/client';

export const GET_TIMELY_OFFERS = gql`
  query getTimelyOffers($filters: GetAllCustomerInput!) {
    getTimelyOffers(filters: $filters) {
      offers {
        id
        offerLinkedTo
        title
        thumbnailUrl
        startDate
        tillValid
        serialNumber
        test {
          id
          title
          archive
          isSandbox
        }
        category {
          id
          name
        }
        categoryTests {
          id
          title
        }
        offerPriceDetails {
          originalPrice
          totalDiscount
          discountedPrice
        }
        isDisabled
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
