import { gql } from '@apollo/client';

// CREATE MESSAGE
export const CREATE_MESSAGE = gql`
  mutation createNotification(
    $title: title_String_NotNull_minLength_2_maxLength_255!
    $description: description_String_NotNull_minLength_2_maxLength_255!
    $buttonTitle: String
    $linkedTest: String
    $imageUrl: imageUrl_String_maxLength_5000_format_uri
    $isSubstitution: Boolean
  ) {
    createNotification(
      data: {
        title: $title
        description: $description
        imageUrl: $imageUrl
        isSubstitution: $isSubstitution
        buttonTitle: $buttonTitle
        linkedTest: $linkedTest
      }
    ) {
      notification {
        id
        title
        description
        imageUrl
        isSubstitution
        buttonTitle
        linkedTest
      }
    }
  }
`;

export const COMPOSE_MESSAGE = gql`
  mutation composeNotification(
    $notificationId: String!
    $sendTo: NotificationSendTo!
    $selectedCustomers: [String]
    $isScheduled: Boolean!
    $scheduledAt: Date!
    $gender: String
  ) {
    composeNotification(
      data: {
        notificationId: $notificationId
        sendTo: $sendTo
        selectedCustomers: $selectedCustomers
        isScheduled: $isScheduled
        scheduledAt: $scheduledAt
        gender: $gender
      }
    )
  }
`;

// DELETE MESSAGE
export const DELETE_MESSAGE = gql`
  mutation deleteNotification($notificationId: String!) {
    deleteNotification(notificationId: $notificationId)
  }
`;
export const CANCEL_SCHEDULED_NOTIFICATION = gql`
  mutation cancelScheduledNotification($notificationId: String!) {
    cancelScheduledNotification(notificationId: $notificationId)
  }
`;

// DELETE SCHEDULED NOTIFICATION

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
