import client from '../../apollo';
import { ADD_PINCODE, TOGGLE_ENABLE_PINCODE } from './graphql/mutation';
import { GET_PINCODES } from './graphql/query';

export const getAllPincodes = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_PINCODES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getPinCodes;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const createPincode = async (data) => {
  try {
    const response = await client.mutate({
      mutation: ADD_PINCODE,
      fetchPolicy: 'network-only',
      variables: {
        data
      }
    });
    return response?.data?.createPinCode?.pinCode;
  } catch (error) {
    return false;
  }
};

export const toggleEnablePinCode = async (pinCodeId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_ENABLE_PINCODE,
      fetchPolicy: 'network-only',
      variables: {
        pinCodeId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const test = async () => {};
