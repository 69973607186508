import { Spin } from 'antd';
import React from 'react';
import '../../app/app.less';

function LoaderComponent(props) {
  const {
    spinning = true,
    size = 'large',
    children,
    setHeight = 100,
    ...rest
  } = props;
  return (
    <Spin
      className="main-loader"
      spinning={spinning}
      size={size}
      style={{
        height: `${setHeight}vh`
      }}
      {...rest}
    >
      {children}
    </Spin>
  );
}
export default LoaderComponent;
