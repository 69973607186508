/* eslint-disable no-console */
import client from '../../apollo';
import {
  TOGGLE_ENABLE_USER,
  TOGGLE_USER_AVAILABILITY,
  UPDATE_USER,
  UPLOAD_DOCUMENT_IMAGE
} from './graphql/mutation';
import {
  GET_ALREADY_EXIST_EMAIL,
  GET_EQUIPMENT_LISTS,
  GET_SYSTEM_USERS
} from './graphql/query';

export const getSystemUsers = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_SYSTEM_USERS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getSystemUsers;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const uploadUserDocument = async (fileName) => {
  try {
    const response = await client.mutate({
      mutation: UPLOAD_DOCUMENT_IMAGE,
      fetchPolicy: 'network-only',
      variables: {
        fileName
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.uploadUserDocument;
  } catch (error) {
    return '';
  }
};

export const toggleSystemUserEnable = async (systemUserId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_ENABLE_USER,
      fetchPolicy: 'network-only',
      variables: {
        systemUserId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const toggleSystemUserAvailability = async (systemUserId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_USER_AVAILABILITY,
      fetchPolicy: 'network-only',
      variables: {
        systemUserId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const updateSystemUser = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_USER,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.updateSystemUser?.user;
  } catch (error) {
    return false;
  }
};

export const getAlreadyExistEmails = async (systemUserId, email) => {
  try {
    const response = await client.query({
      query: GET_ALREADY_EXIST_EMAIL,
      fetchPolicy: 'network-only',
      variables: { systemUserId, email }
    });
    return response?.data?.checkIfSystemUserEmailExist;
  } catch (error) {
    return false;
  }
};

export const getAllotedEquipmentsToSampleCollector = async (
  sampleCollectorId
) => {
  try {
    const response = await client.query({
      query: GET_EQUIPMENT_LISTS,
      fetchPolicy: 'network-only',
      variables: {
        sampleCollectorId
      }
    });
    return response?.data?.getAllotedEquipmentsToSampleCollector
      ?.allotedEquipments;
  } catch (error) {
    return false;
  }
};

export const test = async () => {};
