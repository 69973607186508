import client from '../../apollo';
import { UPLOAD_REPORT, UPLOAD_WHOLE_REPORT } from './graphql/mutation';
import {
  GET_CURRENT_SAMPLE_COLLECTED_ORDERS,
  GET_ORDER_HISTORY
} from './graphql/query';

export const getCurrentSampleCollectedOrders = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_CURRENT_SAMPLE_COLLECTED_ORDERS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getCurrentOrdersAsOperator;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getOrderHistory = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_ORDER_HISTORY,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getOrderHistory;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const uploadReport = async (data) => {
  try {
    await client.mutate({
      mutation: UPLOAD_REPORT,
      fetchPolicy: 'network-only',
      variables: {
        ...data
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const uploadWholeReport = async (data) => {
  try {
    await client.mutate({
      mutation: UPLOAD_WHOLE_REPORT,
      fetchPolicy: 'network-only',
      variables: {
        ...data
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};
